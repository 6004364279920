import {useContext} from "react";
import {AppContext} from "../../lib/AppContext";

export default function Loading(props) {
  const [context, setContext] = useContext(AppContext);

  return (
    <div className={'loading mt-5'}>
      <p className={'text-center'}>
        <span className={'fa fa-circle-o-notch fa-spin'}></span> Loading...
      </p>
    </div>
  );

}