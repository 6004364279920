import {useNavigate, useParams} from "react-router-dom";
import {AppContext} from "../../lib/AppContext";
import {useContext, useEffect, useState} from "react";
import Meta from "../common/Meta";
import {setAppGuidCookie} from "../common/appCookie";
import MDBox from "../../components/MDBox";
import CustomerHeader from "../common/CustomerHeader";
import Footer from "../Footer";
import CustomerPageLayout from "../Layout/CustomerPageLayout";
import CustomerStorePane from "./pane/CustomerStorePane";

export default function CustomerStore(props) {
  const [context, setContext] = useContext(AppContext);

  const navigate = useNavigate();
  let {guid} = useParams();
  const [pageReady, setPageReady] = useState(false);

  useEffect(() => {
    if (guid) {
      setAppGuidCookie(guid);
    }
  }, [guid]);

  useEffect(() => {
    if (context.sessionUser?.companyGk) {
      setPageReady(true);
    }
  }, [context?.sessionUser]);


  return (
    <>
      <CustomerPageLayout>
        <Meta pageTitle={'Store'}/>

        <CustomerHeader props={props}/>

        {pageReady && <MDBox p={3}>
          <MDBox m={3}>
            <CustomerStorePane/>
          </MDBox>
        </MDBox>
        }

        <Footer/>
      </CustomerPageLayout>
    </>
  )
}