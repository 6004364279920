import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../lib/AppContext";
import {Col, Container, Row} from "react-bootstrap";
import {getStates} from "./api/PortalCustomerApi";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import {checkFormValidity, displayPhone, handleDefaultValues} from "./Utils";
import {createProfile} from "./api/PortalCustomerApi";
import MDButton from "../../components/MDButton";
import MDInput from "../../components/MDInput";
import {NativeSelect} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

export default function CreateAccount(props) {
  const navigate = useNavigate();
  const [context, setContext] = useContext(AppContext);
  const [showLogin, setShowLogin] = useState(false);
  const [username, setUsername] = useState('');
  const [passwd, setPasswd] = useState('');
  const [loggingIn, setLoggingIn] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [createAccount, setCreateAccount] = useState(props?.createAccount || false);
  const [states, setStates] = useState([]);
  const [statesReady, setStatesReady] = useState(false);
  const [formInvalid, setFormInvalid] = useState(false);
  const [saving, setSaving] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const [card, setCard] = useState(null);
  const [expyError, setExpyError] = useState(false);

  useEffect(() => {
    if (!card?.isValid) {
      setFormInvalid(true);
    }
  }, [card]);

  const [customer, setCustomer] = useState();
  const {register, handleSubmit, setValue, control, reset} = useForm({
    values: handleDefaultValues(customer),
  });

  const handleSaveCustomer = async (customer) => {

    setSaving(true);
    let c = {...customer};

    let result = await createProfile(c);

    setSaving(false);

    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      let c = {...context};
      c.sessionUser = {...result};
      c.refreshIdentity = true;
      setContext({...c});
    }
  }

  useEffect(() => {
    getStates().then(result => {
      setStates(result);
    });
  }, []);

  return (
    <>
      <div>
        <form
          onChange={(e) => setFormInvalid(!!!checkFormValidity(e))}
          onSubmit={handleSubmit(handleSaveCustomer)} id={'customerForm'}>
          <Container>
            <Row>
              <Col className={'col'} sm={6} xs={12}>
                <div className="form-group">
                  <MDInput type="text" required
                           variant="outlined"
                           {...register('firstName')}
                           autoComplete="off"
                           className="form-control" id="firstName"
                           label="Enter First name"/>
                </div>
              </Col>

              <Col className={'col'} sm={6} xs={12}>
                <div className="form-group">
                  <MDInput type="text" required
                           variant="outlined"
                           {...register('lastName')}
                           className="form-control" id="lastName"
                           label="Enter Last name"/>
                </div>
              </Col>
            </Row>
            <Row className={'my-2'}>
              <Col className={'col'} md={6} sm={12} xs={12}>
                <div className="form-group">
                  <MDInput type="email" required
                           {...register('email', {
                             onChange: (e) => {
                               let email = e.target.value;
                               let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                               setInvalidEmail(!re.test(email));
                             }
                           })}
                           autoComplete="off"
                           variant="outlined"
                           className="form-control" id="email"
                           label="Enter Email"/>
                  <label htmlFor="emailEnabled">
                    <Checkbox defaultChecked={true}
                              {...register('emailEnabled')}
                              id="emailEnabled" />
                    &nbsp;Email Allowed
                  </label>
                </div>
              </Col>

              <Col className={'col'} md={6} sm={12} xs={12}>
                <div className="form-group">
                  <MDInput type="text" required
                           {...register('primaryPhone', {
                             onChange: (e) => {
                               e.target.value = displayPhone(e.target.value);
                             }
                           })}
                           variant="outlined"
                           autoComplete="off"
                           className="form-control" id="primaryPhone"
                           label="Enter Phone"/>

                  <label htmlFor="smsEnabled">
                    <Checkbox defaultChecked={true}
                              {...register('smsEnabled')}
                              id="smsEnabled" />
                    &nbsp;SMS Allowed
                  </label>
                </div>
              </Col>
            </Row>

            <Row className={'my-2'}>
              <Col className={'col'} sm={6} xs={12}>
                <div className="form-group">
                  <MDInput type="text" required
                           variant="outlined"
                           {...register('address1')}
                           autoComplete="off"
                           className="form-control" id="address1"
                           label="Enter Address"/>
                </div>
              </Col>

              <Col className={'col'} sm={6} xs={12}>
                <div className="form-group">
                  <MDInput type="text"
                           variant="outlined"
                           {...register('address2')}
                           autoComplete="off"
                           className="form-control" id="address2"
                           label="Enter Address 2"/>
                </div>
              </Col>
            </Row>

            <Row className={'mt-2'}>
              <Col className={'col'} sm={4} xs={12}>
                <div className="form-group">
                  <MDInput type="text" required
                           variant="outlined"
                           {...register('city')}
                           autoComplete="off"
                           className="form-control" id="city"
                           label="Enter City"/>
                </div>
              </Col>

              <Col className={'col'} sm={4} xs={12}>
                <div className="form-group">
                  {states?.length > 0 && <NativeSelect
                    variant="standard" required
                    id={'state'} name={'state'} className={'form-control'}
                    {...register('state.stateId')}
                    defaultValue={states[0].stateId}>
                    {
                      states?.map((c) =>
                        <option value={c.stateId} key={c.stateId}>
                          {c.stateName}
                        </option>)
                    }
                  </NativeSelect>}
                </div>
              </Col>

              <Col className={'col'} sm={4} xs={12}>
                <div className="form-group">
                  <MDInput type="text" required
                           variant="outlined"
                           {...register('zipCode')}
                           autoComplete="off"
                           className="form-control" id="zipCode"
                           label="Enter Zip"/>
                </div>
              </Col>
            </Row>

            <hr/>

            <MDButton type="submit" color="info"
                      className={'pull-right'}
                      disabled={formInvalid || invalidEmail || props.saving}>
              {props.saving ? 'Saving...' : 'Save'}
            </MDButton>
          </Container>
        </form>
      </div>
    </>
  )
}