import {getAppSessionCookieHeader} from "../appCookie";
import api from "../../Api";

export const createProfile = async (customer) => {
  const resp = await api.post('profile/save' + getAppSessionCookieHeader(), customer).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const saveCustomerFamily = async (customer) => {
  const resp = await api.post('profile/family/save' + getAppSessionCookieHeader(), customer).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getCustomerSales = async (guid) => {
  const resp = await api.get('client/sales/' + guid + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}


function handleError(error) {
  let err = {
    error: true,
    message: error.response.data.message
  }
  return err;
}
