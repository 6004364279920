import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {searchCustomers} from "../common/api/WebApi";

import * as pagination from "../common/pagination";
import DataTable from "react-data-table-component";
import BootyPagination from "../common/bootyPagination";
import {AppContext} from "../../lib/AppContext";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Grid from "@mui/material/Grid";
import MDButton from "../../components/MDButton";
import {Divider} from "@mui/material";
import MDInput from "../../components/MDInput";
import {formatDateTime} from "../common/Utils";

export default function ListCustomersResults(props) {

  const [context, setContext] = useContext(AppContext);
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [PaginationOptions, setPaginationOptions] = useState(pagination._PaginationOptions);

  const [totalRecords, setTotalRecords] = useState(0);
  const [totalFiltered, setTotalFiltered] = useState(0);
  const [loading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);

  const [render, setRender] = useState(0);
  const [searchCriteria, setSearchCriteria] = useState(searchParams.get('q') || '');
  const [searchIndex, setSearchIndex] = useState(1);

  const [sortDir, setSortDir] = useState(searchParams.get('sortDir') || 'asc');
  const [sortOrder, setSortOrder] = useState(searchParams.get('sortOrder') || 1);
  const [pageSize, setPageSize] = useState(searchParams.get('pageSize') || 10);
  const [startPage, setStartPage] = useState(searchParams.get('startPage') || 0);
  const [searchDeleted, setSearchDeleted] = useState(searchParams.get('searchDeleted') || false);

  const [searchResults, setSearchResults] = useState([]);
  const [tmpResults, setTmpResults] = useState({});

  useEffect(() => {
    let result = tmpResults;

    if ((result?.searchIndex + 1) === (getSearchIndex() + 1)) {

      if (result.error) {
        // todo: handle error
      } else {
        setSearchResults(result?.customers.data);
        setTotalRecords(result?.customers.total - result?.customers.recordsFiltered);
        setTotalFiltered(result?.customers.recordsFiltered);
        setLoading(false);
        setFirstLoad(false);
      }
    }
  }, [tmpResults]);

  const renderFamilyMembers = (val, row) => {
    let family = [];
    val.familyMembers?.sort((a, b) => a.id > b.id ? 1 : -1).map((m, index) => {
      family.push(m.firstName + ' ' + m.lastName + ' (' + m.familyMemberType.name + ')');
    })
    return <MDBox>{family.join(', ')}</MDBox>;
  }


  const _columns = [
    {
      name: <span className='th'>Name</span>,
      id: 1,
      selector: row => row.title,
      cell: row => {
        let link = '/client/' + row.guid;
        return <Link to={link} className="link"> {row.firstName} {row.lastName} </Link>;
      },
      sortable: true,
    },
    {
      name: <span className='th'>Created On</span>,
      id: 2,
      selector: row => row.title,
      cell: row => {
        return row?.createDate ? formatDateTime(row.createDate) : 'None';
      },
      sortable: true,
    },
    {
      name: <span className='th'>Last Visit</span>,
      id: 3,
      selector: row => row.title,
      cell: row => {
        return row?.lastVisitDate ? formatDateTime(row.lastVisitDate) : 'None';
      },
      sortable: true,
    },
    {
      name: <span className='th'>Family Members</span>,
      id: 4,
      selector: row => row.title,
      cell: renderFamilyMembers,
      sortable: false,
    },
    {
      name: <span className='th'>View</span>,
      id: 5,
      right: true,
      selector: row => row.guid,
      cell: row => {
        return viewClientFn(row);
      },
      sortable: false,
    }
  ];

  const viewClientFn = (row) => {
    let link = '/client/' + row.guid;
    return <MDButton variant="gradient" color="info" onClick={() => {
      props.action(row);
    }} size="small">{props?.actionLabel || 'View'}</MDButton>
  }

  const [columns, setColumns] = useState(_columns);

  const handleSort = (column, sortDirection) => {

    setSortOrder(column.id);
    setSortDir(sortDirection);

    setRender(render + 1);

  };

  const handlePageChange = (selector, page) => {
    setStartPage(selector - 1);
    setRender(render + 1);
  };

  const getSearchIndex = () => {
    return searchIndex;
  }

  const searchCustomersFn = async () => {
    const searchParams = {
      sortDir: sortDir,
      searchCriteria: searchCriteria,
      sortOrder: sortOrder,
      pageSize: pageSize,
      startPage: startPage,
      searchDeleted: searchDeleted
    }

    let idx = searchIndex + 1;
    setSearchIndex(idx);

    let result = await searchCustomers(searchParams, idx);

    setTmpResults(result);

  };

  useEffect(() => {
    const timeOutId = setTimeout(() =>
      searchCustomersFn(), firstLoad ? 1 : 500);
    return () => clearTimeout(timeOutId);
  }, [searchCriteria]);


  const handleSearchChange = (e) => {
    setLoading(true);
    setSearchCriteria(e.target.value);
  }

  useEffect(() => {
    if (render === 0) {
      return;
    }
    searchCustomersFn();
  }, [render]);

  return (
    <>
      {props?.embedded &&
        <MDInput type="text"
                 variant="outlined"
                 value={searchCriteria || ''}
                 onChange={handleSearchChange}
                 className="form-control mb-2" id="searchCriteria" aria-describedby="searchCriteria"
                 label="Search"/>
      }
      {!!!props?.embedded && <MDBox pt={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8}>
            <MDTypography variant="h5" fontWeight="regular">
              List Customers
            </MDTypography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <MDInput type="text"
                     variant="outlined"
                     value={searchCriteria || ''}
                     onChange={handleSearchChange}
                     className="form-control mb-2" id="searchCriteria" aria-describedby="searchCriteria"
                     label="Search"/>
            <div className={'form-switch d-block text-end'}>
              <input type="checkbox"
                     role={'switch'}
                     id="searchDeleted"
                     className={'form-check-input mr-2'}
                     name="searchDeleted"
                     checked={searchDeleted}
                     onChange={(e) => {
                       setSearchDeleted(!searchDeleted);
                       setRender(render + 1);
                     }}/>
              <label className={'form-check-label'} htmlFor="searchDeleted">
                &nbsp;Search Archived
              </label>
            </div>
          </Grid>
        </Grid>
      </MDBox>}
      <MDBox py={1}>

        {firstLoad && <>
          <MDTypography variant="h5" fontWeight="light">
            <center>Loading...</center>
            <Divider/>
          </MDTypography>
        </>
        }

        {!firstLoad && <DataTable
          disabled={loading}
          striped
          columns={columns}
          keyField={'guid'}
          data={searchResults}
          onSort={handleSort}
          sortServer
          highlightOnHover
          pagination
          paginationServer
          paginationPerPage={10}
          paginationComponent={BootyPagination}
          paginationTotalRows={totalRecords}
          totalFiltered={totalFiltered}
          onChangePage={handlePageChange}
          defaultSortFieldId={1}
          defaultSortAsc
          paginationComponentOptions={PaginationOptions}
        />}

      </MDBox>
    </>
  );
}