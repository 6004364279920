import {Col, Container, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import {useContext, useEffect, useState} from "react";
import {checkFormValidity, formatCreditCard, formatExpiration, handleDefaultValues} from "../common/Utils";
import {useForm} from 'react-hook-form';
import MDButton from "../../components/MDButton";

export default function ManageClientBillingModal(props) {

  const [context, setContext] = useContext(AppContext);

  const [clientBilling, setClientBilling] = useState(props.clientBilling);
  const [card, setCard] = useState(null);
  const [expyError, setExpyError] = useState(false);

  useEffect(() => {
    setClientBilling(props?.clientBilling);
  }, [props?.clientBilling]);

  useEffect(() => {
    if (!card?.isValid) {
      setFormInvalid(true);
    }
  }, [card]);

  const [isNew, setIsNew] = useState(!!!props.familyMember?.guid);
  const [formInvalid, setFormInvalid] = useState(true);

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(clientBilling)
  });

  const handleCloseClientBilling = () => {
    props.handleCloseClientBilling();
  };


  const handleSaveClientBilling = (data) => {
    let c = {...data};
    c.cardType = card?.card?.type;
    props.handleSaveClientBilling(c);
  };

  return (
    <Modal onHide={handleCloseClientBilling} size={'lg'} show={props.show}>
      <form
        onChange={(e) => setFormInvalid(!!!checkFormValidity(e))}
        onSubmit={handleSubmit(handleSaveClientBilling)} id={'memberForm'}>
        <Modal.Header closeButton>
          <Modal.Title>Add Credit or Debit Card</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className={'col col-main'} xs={12}>
              <Container>
                <Row>
                  <Col className={'col'} sm={6} xs={12}>
                    <div className="form-group">
                      <label htmlFor="nameOnCard">Cardholder Name</label>
                      <input type="text" required
                             {...register('nameOnCard')}
                             className="form-control" id="nameOnCard"
                             placeholder="Enter Cardholder name"/>
                    </div>
                  </Col>
                  <Col className={'col'} sm={6} xs={6}>
                    <div className="form-group">
                      <label htmlFor="billingZip">Billing Postal Code</label>
                      <input type="text" required
                             {...register('billingZip', {
                               required: true
                             })}
                             className="form-control" id="billingZip"
                             placeholder="Postal Code"/>
                    </div>
                  </Col>
                </Row>
                <Row className={'mt-2'}>
                  <Col className={'col'} sm={6} xs={12}>
                    <label htmlFor="cardNumber">Card Number</label>
                    <div className={'input-group mb-3'}>
                      <span className={'input-group-text'} id="basic-addon1">
                        <span
                          className={'fa fa-credit-card fa-cc-' + (card?.card?.type)}></span>
                      </span>
                      <input type="text" required
                             {...register('cardNumber', {
                               required: true,
                               onChange: (e) => {
                                 let c = formatCreditCard(e.target.value);
                                 setCard(c);
                                 e.target.value = c.cardNumber;
                               }
                             })}
                             className="form-control" id="cardNumber"
                             placeholder="Enter Card Number"/>
                    </div>
                  </Col>

                  <Col className={'col'} sm={3} xs={6}>
                    <div className="form-group">
                      <label htmlFor="ccExpy">Expiration</label>
                      <input type="text" required
                             {...register('ccExpy', {
                               required: true,
                               onChange: (e) => {
                                 let c = formatExpiration(e.target.value);
                                 let cc = {...card, ...c};
                                 setCard(cc);
                                 e.target.value = c.expy;
                                 let hasExpyError = false;
                                 if (!c.month || !c.year) {
                                 }

                                 if (!c.month || !c.year) {
                                   hasExpyError = true;
                                 } else {
                                   let year = parseInt(c.year, 10);
                                   if (year < 100) {
                                     year = year + 2000;
                                   }
                                   if (year < new Date().getFullYear()) {
                                     hasExpyError = true;
                                   }

                                   if (year === new Date().getFullYear() && parseInt(c.month, 10) - 1 < new Date().getMonth() + 1) {
                                     hasExpyError = true;
                                   }
                                 }
                                 setExpyError(hasExpyError);
                               }
                             })}
                             className="form-control" id="ccExpy"
                             placeholder="MM/YY"/>
                      {expyError && <p className={'text-danger'}>Expiration is invalid</p>}
                    </div>
                  </Col>

                  <Col className={'col'} sm={3} xs={6}>
                    <div className="form-group">
                      <label htmlFor="securityCode">Security Code</label>
                      <input type="password" required
                             {...register('securityCode', {
                               required: true
                             })}
                             className="form-control" id="securityCode"
                             placeholder="Security Code"/>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <MDButton type="submit" color="info" disabled={formInvalid || props.saving}>
            {props.saving ? 'Saving...' : 'Save'}
          </MDButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
}