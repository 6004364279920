import {Col, Container, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import {useContext, useEffect, useState} from "react";
import {handleDefaultValues} from "../common/Utils";
import {useForm} from 'react-hook-form';
import {getAccountTypes, getInventoryTypes} from "../common/api/InventoryApi";
import MDButton from "../../components/MDButton";

export default function ManageInventoryMembershipModal(props) {

  const [context, setContext] = useContext(AppContext);
  const [inventory, setInventory] = useState(props.inventory);
  const [isInventoryEvent, setIsInventoryEvent] = useState(props.isInventoryEvent);

  const [accountTypes, setAccountTypes] = useState();
  const [inventoryEventTypes, setInventoryEventTypes] = useState();

  const [inventoryImage, setInventoryImage] = useState(null);
  const [inventoryImageName, setInventoryImageName] = useState(null);
  const [inventoryImageType, setInventoryImageType] = useState(null);

  const [preview, setPreview] = useState(null);
  const [inventoryTypes, setInventoryTypes] = useState();

  const [formInvalid, setFormInvalid] = useState(false);
  const [typesReady, setTypesReady] = useState(false);

  useEffect(() => {
    getInventoryTypes().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setInventoryTypes(result);
      }
    });
    getAccountTypes().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setAccountTypes(result);
      }
    });
  }, [props.show]);

  useEffect(() => {
    setTypesReady(inventoryTypes?.length && accountTypes?.length);
  }, [inventoryTypes, accountTypes]);

  useEffect(() => {
    setInventory(props?.inventory);
  }, [props?.inventory]);

  const [isNew, setIsNew] = useState(!!!props.inventory?.guid);

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(inventory)
  });

  const handleClose = () => {
    props.handleClose();
  };

  const handleSave = (data) => {
    let d = {...data};
    d.inventoryImage = inventoryImage;
    d.inventoryImageName = inventoryImageName;
    d.inventoryImageType = inventoryImageType;
    d.inventoryMembership = true;
    props.handleSave(d);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setPreview(null);

      setInventoryImageName(null);
      setInventoryImageType(null);
      setInventoryImage(null);
      return;
    }
    setInventoryImageName(file.name);
    setInventoryImageType(file.type);

    const reader = new FileReader();

    reader.onload = function (evt) {
      setInventoryImage(btoa(evt.target.result));
    }
    reader.readAsBinaryString(file);

    setPreview(URL.createObjectURL(file))
  }

  return (
    <>
      <Modal onHide={handleClose} size={'lg'} show={props.show}>
        <form onSubmit={handleSubmit(handleSave)} id={'inventoryForm'}>
          <Modal.Header closeButton>
            <Modal.Title>{isNew ? 'Create New' : 'Manage'} Membership</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col className={'col col-main'} xs={12}>
                <Container>
                  <Row>
                    <Col className={'col'} sm={6} xs={12}>
                      <div className="form-group">
                        <label htmlFor="name">Membership Name</label>
                        <input type="text" required
                               {...register('name')}
                               autoComplete="off"
                               className="form-control" id="name"
                               placeholder="Enter Inventory Name"/>
                      </div>
                    </Col>
                    <Col className={'col'} sm={6} xs={12}>
                      <div className="form-group">
                        <label htmlFor="name">Price</label>
                        <div className={'input-group'}>
                        <span className="input-group-text">
                          <span className={'fa fa-dollar'}></span>
                        </span>
                          <input type="number" step="0.01" required
                                 {...register('price', {
                                   valueAsNumber: true,
                                 })}
                                 defaultValue={'0.00'}
                                 autoComplete="off"
                                 className="form-control" id="price"
                                 placeholder="Price"/>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row className={'mt-2'}>

                    <Col className={'col'} sm={6} xs={12}>
                      <div className="form-group">
                        <label htmlFor="inventoryType">Inventory Type</label>
                        {typesReady && <select
                          id={'inventoryType'} name={'inventoryType'} className={'form-control'}
                          {...register('inventoryType.id', {
                            valueAsNumber: true
                          })} >
                          {
                            inventoryTypes.map((c) =>
                              <option value={c.id} key={c.id}>
                                {c.name}
                              </option>)
                          }
                        </select>}
                      </div>
                      <div className="form-group mt-2">
                        <label htmlFor="inventoryType">Account Type</label>
                        {typesReady && <select
                          id={'accountType'} name={'accountType'} className={'form-control'}
                          {...register('accountType.id', {
                            valueAsNumber: true
                          })} >
                          {
                            accountTypes.map((c) =>
                              <option value={c.id} key={c.id}>
                                {c.name} {c.recurring ? '(Recurring)' : ''}
                              </option>)
                          }
                        </select>}
                      </div>
                    </Col>


                    <Col className={'col'} sm={6} xs={12}>
                      <div className="form-group">
                        <label htmlFor="inventoryImage">Display Image</label>
                        <input type="file"
                               accept="image/*"
                               {...register('inventoryImage', {
                                 onChange: (e) => {
                                   handleFileChange(e);
                                 }
                               })}
                               autoComplete="off"
                               className="form-control" id="inventoryImage"/>

                        {preview && <img className={'mt-2 img-thumbnail'} src={preview} alt="img"/>}
                        {!preview && inventory?.imageUrl &&
                          <img className={'mt-2 img-thumbnail'} src={inventory.imageUrl} alt="img"/>}
                      </div>
                    </Col>

                  </Row>

                  <Row className={'mt-2'}>
                    <Col className={'col'} sm={12} xs={12}>
                      <div className="form-group">
                        <label htmlFor="name">Public Description</label>
                        <textarea type="text"
                                  {...register('desc')}
                                  autoComplete="off"
                                  maxLength={255}
                                  className="form-control" id="desc"
                                  placeholder="Enter Public Description"/>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>

          </Modal.Body>
          <Modal.Footer>
            <MDButton type="submit" color="info" disabled={formInvalid || props.saving}>
              {props.saving ? 'Saving...' : 'Save'}
            </MDButton>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}