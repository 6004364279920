import {useNavigate} from "react-router-dom";
import {AppContext} from "../../lib/AppContext";
import {useContext, useEffect, useRef, useState} from "react";
import {
  deleteInventory,
  getAccountTypes,
  getBrands,
  getInventoryEventTypes,
  getInventoryTypes,
  saveAccountType,
  saveBrand,
  saveInventory,
  saveInventoryType
} from "../common/api/InventoryApi";
import {Card, CardContent} from "@mui/material";
import ManageInventoryModal from "./ManageInventoryModal";
import ManageInventoryTypeModal from "./ManageInventoryTypeModal";
import Meta from "../common/Meta";
import Confirm from "../common/Confirm";
import ContextSearchNavbar from "../navbars/ContextSearchNavbar";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Divider from "@mui/material/Divider";
import Footer from "../Footer";
import Grid from "@mui/material/Grid";
import ManageAccountTypeModal from "./ManageAccountTypeModal";
import ManageBrandModal from "./ManageBrandModal";
import InventoryDataTable from "../inventory/pane/InventoryDataTable";

export default function Inventory(props) {

  const navigate = useNavigate();
  const [context, setContext] = useContext(AppContext);
  const [renderIndex, setRenderIndex] = useState(1);

  const myRefs = useRef([]);

  const refHandler = (index) => {
    myRefs.current[index].visible = !!!myRefs.current[index].visible;
    setRenderIndex(renderIndex + 1);
  };

  const [inventoryTypes, setInventoryTypes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [accountTypes, setAccountTypes] = useState([]);
  const [inventoryEventTypes, setInventoryEventTypes] = useState([]);
  const [inventoryType, setInventoryType] = useState(null);
  const [brand, setBrand] = useState(null);
  const [accountType, setAccountType] = useState(null);

  const [renderDate, setRenderDate] = useState(new Date().getTime());

  const [saving, setSaving] = useState(false);
  const [manageInventoryType, setManageInventoryType] = useState(false);
  const [manageBrand, setManageBrand] = useState(false);
  const [manageAccountType, setManageAccountType] = useState(false);

  const [manageInventory, setManageInventory] = useState(false);
  const [isInventoryEvent, setIsInventoryEvent] = useState(false);
  const [inventory, setInventory] = useState(null);
  const [inventories, setInventories] = useState(null);
  const [sortInventory, setSortInventory] = useState({field: 'id', direction: 'asc'});

  const [confirmation, setConfirmation] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [reloadInventory, setReloadInventory] = useState(false);

  const reloadInventoryCallback = () => {
    setReloadInventory(false);
  }

  const confirmDeleteInventory = (inventory) => {
    let confirmation = {
      title: 'Archive ' + inventory.name + '?',
      message: 'Confirm archive ' + inventory.name + '?',
      entity: inventory
    }
    setConfirmation(confirmation);
    setConfirmDelete(true);
  }

  const handleDeleteInventory = async (inventory) => {
    let result = await deleteInventory(inventory.guid);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setConfirmDelete(false);
      setReloadInventory(true);
    }
  }

  const handleCloseDeleteInventory = async () => {
    setConfirmDelete(false);
  };

  const loadInventoryTypes = () => {
    getInventoryTypes().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setInventoryTypes(result);
      }
    });
  }

  const loadBrands = () => {
    getBrands().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setBrands(result);
      }
    });
  }

  const loadAccountTypes = () => {
    getAccountTypes().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setAccountTypes(result);
      }
    });
  }

  const loadInventoryEventTypes = () => {
    getInventoryEventTypes().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setInventoryEventTypes(result);
      }
    });
  }

  const saveInventoryTypeFn = async (inventoryType) => {
    let result = await saveInventoryType(inventoryType);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      closeInventoryTypeFn();
      loadInventoryTypes();
    }
  }

  const saveBrandTypeFn = async (brand) => {
    let result = await saveBrand(brand);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      closeBrandFn();
      loadBrands();
    }
  }


  const saveAccountTypeFn = async (accountType) => {
    let result = await saveAccountType(accountType);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      closeAccountTypeFn();
      loadAccountTypes();
    }
  }

  const saveInventoryFn = async (inventory) => {
    let result = await saveInventory(inventory);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      closeInventoryFn();
      loadInventoryTypes();
    }
  }

  const handleSaveInventory = async (inventory) => {
    const inv = {...inventory};
    inv.inventoryEvent = isInventoryEvent;
    let result = await saveInventory(inv);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      closeInventoryFn();
      setReloadInventory(true);
    }
  }

  const handleCloseInventory = () => {
    setManageInventory(false);
  }

  useEffect(() => {
    // get inventory types
    loadInventoryTypes();
    loadInventoryEventTypes();
    loadAccountTypes();
    loadBrands();
  }, []);

  const addInventoryTypeFn = () => {
    setInventoryType(null);
    setManageInventoryType(true);
  }

  const addBrandFn = () => {
    setBrand(null);
    setManageBrand(true);
  }

  const addAccountTypeFn = () => {
    setAccountType(null);
    setManageAccountType(true);
  }

  const addInventoryFn = () => {
    setInventory(null);
    setIsInventoryEvent(false);
    setManageInventory(true);
  }

  const addInventoryEventFn = () => {
    setInventory(null);
    setIsInventoryEvent(true);
    setManageInventory(true);
  }

  const manageInventoryFn = (inventory) => {
    let t = null;
    if (inventory) {
      t = {...inventory};
    }
    setIsInventoryEvent(false);
    setInventory(t);
    setManageInventory(true);
  }

  const manageInventoryEventFn = (inventory) => {
    let t = null;
    if (inventory) {
      t = {...inventory};
    }
    setIsInventoryEvent(true);
    setInventory(t);
    setManageInventory(true);
  }

  const manageInventoryTypeFn = (type) => {
    let t = null;
    if (type) {
      t = {...type};
    }
    setInventoryType(t);
    setManageInventoryType(true);
  }

  const manageBrandTypeFn = (brand) => {
    let t = null;
    if (brand) {
      t = {...brand};
    }
    setBrand(t);
    setManageBrand(true);
  }

  const closeInventoryTypeFn = () => {
    setInventoryType(null);
    setManageInventoryType(false);
  }

  const closeBrandFn = () => {
    setBrand(null);
    setManageBrand(false);
  }

  const closeAccountTypeFn = () => {
    setAccountType(null);
    setManageAccountType(false);
  }
  const closeInventoryFn = () => {
    setInventory(null);
    setManageInventory(false);
  }

  const sortInventoryFn = (a, b) => {
    const sortDir = sortInventory.direction === 'asc' ? 1 : -1;
    if (sortInventory.field === 'name') {
      return a.name.toUpperCase() > b.name.toUpperCase() ? sortDir : sortDir * -1;
    }
    if (sortInventory.field === 'id') {
      return a.id > b.id ? sortDir : sortDir * -1;
    }
    return a.id > b.id ? sortDir : sortDir * -1;
  }

  return (
    <DashboardLayout>
      <Meta pageTitle={'View Inventory'}/>
      <ContextSearchNavbar title={'View Inventory'}/>
      <MDBox py={3}>
        <MDBox mb={3}>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={3}>
              <Card>
                <MDBox p={2}>
                  <MDTypography mb={1} lineHeight={1} variant="h6" fontWeight="regular">
                    Inventory Types
                  </MDTypography>
                  <ul className={'list-group'}>
                    {inventoryTypes.map((type, index) => {
                      return (
                        <li
                          className={'list-group-item '}
                          key={index}>
                          {type.id <= -1 && type.name}
                          {type.id > -1 && type.name &&
                            <a
                              className={'link cursor-pointer'}
                              onClick={(e) => {
                                manageInventoryTypeFn(type);
                              }}>{type.name}</a>

                          }
                          {type.desc && <>
                            <small className={'text-small my-n2'}><br/>{type.desc}</small>
                          </>}
                        </li>
                      );
                    })}
                    <li
                      className={'list-group-item background-primary cursor-pointer'}
                      onClick={addInventoryTypeFn}>
                      <span className={'fa fa-plus'}></span> Add Inventory Type
                    </li>
                  </ul>
                </MDBox>
              </Card>
              <Divider/>

              <Card>
                <MDBox p={2}>
                  <MDTypography mb={1} lineHeight={1} variant="h6" fontWeight="regular">
                    Brands
                  </MDTypography>
                  <ul className={'list-group'}>
                    {brands.map((type, index) => {
                      return (
                        <li
                          className={'list-group-item '}
                          key={index}>
                          {type.id <= -1 && type.name}
                          {type.id > -1 && type.name &&
                            <a
                              className={'link cursor-pointer'}
                              onClick={(e) => {
                                manageBrandTypeFn(type);
                              }}>{type.name}</a>

                          }
                          {/*{type.desc && <>*/}
                          {/*  <small className={'text-small my-n2'}><br/>{type.desc}</small>*/}
                          {/*</>}*/}
                        </li>
                      );
                    })}
                    <li
                      className={'list-group-item background-primary cursor-pointer'}
                      onClick={addBrandFn}>
                      <span className={'fa fa-plus'}></span> Add Brand
                    </li>
                  </ul>
                </MDBox>
              </Card>
              <Divider/>

              <Card mt={2}>
                <MDBox p={2}>
                  <MDTypography mb={1} lineHeight={1} variant="h6" fontWeight="regular">
                    Account Types
                  </MDTypography>
                  <ul className={'list-group'}>
                    {accountTypes.map((type, index) => {
                      return (
                        <li
                          className={'list-group-item '}
                          key={index}>
                          {type.id <= -1 && type.name}
                          {type.id > -1 && type.name &&
                            <a
                              className={'link cursor-pointer'}
                              onClick={(e) => {
                                manageAccountType(type);
                              }}>{type.name}</a>

                          }
                          {type.desc && <>
                            <small className={'text-small my-n2'}><br/>{type.desc}</small>
                          </>}
                        </li>
                      );
                    })}
                    <li
                      className={'list-group-item background-primary cursor-pointer'}
                      onClick={addAccountTypeFn}>
                      <span className={'fa fa-plus'}></span> Add Account Type
                    </li>
                  </ul>
                </MDBox>
              </Card>
            </Grid>

            <Grid item xs={12} sm={9}>
              <Card>

                <CardContent>

                  <InventoryDataTable
                    addInventoryFn={addInventoryFn}
                    reloadInventoryCallback={reloadInventoryCallback}
                    manageInventoryFn={manageInventoryFn}
                    confirmDeleteInventory={confirmDeleteInventory}
                    reloadInventory={reloadInventory}
                  />

                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {manageInventoryType && <ManageInventoryTypeModal
            inventoryType={inventoryType}
            show={manageInventoryType}
            saving={saving}
            handleSave={saveInventoryTypeFn}
            handleClose={closeInventoryTypeFn}/>}

          {manageBrand && <ManageBrandModal
            brand={brand}
            show={manageBrand}
            saving={saving}
            handleSave={saveBrandTypeFn}
            handleClose={closeBrandFn}/>}

          {manageAccountType && <ManageAccountTypeModal
            accountType={accountType}
            show={manageAccountType}
            saving={saving}
            handleSave={saveAccountTypeFn}
            handleClose={closeAccountTypeFn}/>}

          {manageInventory && <ManageInventoryModal
            inventory={inventory}
            isInventoryEvent={isInventoryEvent}
            show={manageInventory}
            saving={saving}
            handleSave={handleSaveInventory}
            handleClose={handleCloseInventory}/>}


          {confirmDelete && <Confirm confirmation={confirmation}
                                     show={confirmDelete}
                                     saving={saving}
                                     handleConfirm={handleDeleteInventory}
                                     handleClose={handleCloseDeleteInventory}/>}
        </MDBox>
      </MDBox>

      <Footer mt={5}/>

    </DashboardLayout>
  );
}