import {useNavigate, useParams} from "react-router-dom";
import {AppContext} from "../../lib/AppContext";
import {useContext, useEffect, useState} from "react";
import {setAppGuidCookie} from "../common/appCookie";
import {Card, CardContent} from "@mui/material";
import MDBox from "../../components/MDBox";
import CustomerHeader from "../common/CustomerHeader";
import Footer from "../Footer";
import CustomerPageLayout from "../Layout/CustomerPageLayout";
import Grid from "@mui/material/Grid";
import MDTypography from "../../components/MDTypography";
import {getInventoryEventRegistration, saveInventoryEventCancellation} from "../common/api/PortalCustomerApi";
import Confirm from "../common/Confirm";
import CompanyCard from "./pane/CompanyCard";

export default function CustomerCancelEvent(props) {
  const [context, setContext] = useContext(AppContext);
  const [company, setCompany] = useState();
  const [confirmation, setConfirmation] = useState(null);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [saving, setSaving] = useState(false);

  const navigate = useNavigate();
  let {guid, eventGuid, customerGuid} = useParams();

  const handleEventCancel = (reservation) => {
    saveInventoryEventCancellation(reservation).then(result => {
      setConfirmCancel(false);
      setConfirmation(null);
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        navigate('/cx/');
      }
    });
  }

  const handleCloseConfirm = () => {
    setConfirmation(null);
    setConfirmCancel(false);
  };

  const handleConfirmCancel = (reservation) => {
    let confirmation = {
      title: 'Event Cancellation',
      message: 'Confirm ' + reservation.eventName + ' cancellation.',
      entity: reservation
    }
    setConfirmation(confirmation);
    setConfirmCancel(true);
  }

  useEffect(() => {
    if (guid) {
      setAppGuidCookie(guid);
    }
    if (eventGuid && customerGuid) {
      getInventoryEventRegistration(eventGuid, customerGuid).then(result => {
        if (result.error) {
          setContext({...context, error: {message: result.message}});
        } else {
          handleConfirmCancel(result);
        }
      });
    }
  }, [guid]);

  useEffect(() => {
    if (context.sessionUser?.companyGk) {
      setPageReady(true);
      setCompany(context.sessionUser.company);
    }
  }, [context?.sessionUser]);

  const [pageReady, setPageReady] = useState(false);


  return (
    <>
      <CustomerPageLayout>

        <CustomerHeader props={props}/>

        {pageReady && <MDBox p={3}>
          <MDBox m={3}>
            <Grid container cols={3} spacing={3} direction="row">
              <Grid item sm={3} xs={12}>
                <CompanyCard/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card>
                  <CardContent>
                    <MDTypography variant="h5" color="secondary" fontWeight="light">
                      Confirm Event Cancellation
                    </MDTypography>
                    <MDTypography variant="p" color="primary" fontWeight="regular">
                      Are you sure you want to cancel this event?
                    </MDTypography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        }
        <Footer/>
      </CustomerPageLayout>

      {confirmCancel && <Confirm confirmation={confirmation}
                                 show={confirmCancel}
                                 saving={saving}
                                 handleConfirm={handleEventCancel}
                                 handleClose={handleCloseConfirm}/>}

    </>
  )
}