import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../lib/AppContext";
import {colorize, customerInitials} from "./Utils";
import {useNavigate} from "react-router-dom";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";

export default function CustomerAvatar(props) {

  const [customer, setCustomer] = useState(props?.customer);

  const [context, setContext] = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!props?.customer) {
      return;
    }
    setCustomer(props.customer);
  }, [props.customer]);

  return (
    <>
      {customer &&
        <Badge className={'cursor-pointer'} onClick={()=> {
          navigate('/cx/my-profile');
        }}>
          <Avatar alt={customer?.email} style={{
            backgroundColor: colorize(customer?.email),
          }}>
            {customerInitials(customer)}
          </Avatar>
        </Badge>
      }
    </>
  )
}