import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../lib/AppContext";
import logo from '../assets/images/psb-logo.png';
import {CONSTANTS} from "./constants";
import CustomerCart from "./CustomerCart";
import {useNavigate} from "react-router-dom";
import CustomerAvatar from "./CustomerAvatar";
import {isAuthenticatedCustomer, isSessionCustomer} from "./Utils";
import {Grid} from "@mui/material";
import Toast from "./Toast";
import Button from "@mui/material/Button";
import MDButton from "../../components/MDButton";
import {logout} from "./api/PortalCustomerApi";
import CustomerLoginForm from "./CustomerLoginForm";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import NotificationItem from "../Items/NotificationItem";

export default function CustomerHeader(props) {

  const navigate = useNavigate();

  const [context, setContext] = useContext(AppContext);
  const [stickyHeader, setStickyHeader] = useState(false);
  const [headerHtml, setHeaderHtml] = useState(null);
  const [sessionCustomer, setSessionCustomer] = useState(false);
  const [navItems, setNavItems] = useState([]);
  const [assignedWaivers, setAssignedWaivers] = useState([]);
  const [showLogin, setShowLogin] = useState(false);
  const [showCreateAccount, setShowCreateAccount] = useState(false);

  const populateNavItems = () => {
    let _items = [];

    if (!isAuthenticatedCustomer(context?.sessionUser)) {
      _items.push({
        name: 'Login',
        itemId: 990,
        onClick: (e) => {
          setShowLogin(true)
        }
      })
    }
    if (isAuthenticatedCustomer(context?.sessionUser)) {
      _items.push({
        name: 'Logout',
        itemId: 991,
        onClick: (e) => {
          logoutFn();
        }
      })
    }

    if (isAuthenticatedCustomer(context?.sessionUser) && assignedWaivers?.length > 0) {
      _items.push({
        name: 'Assigned Waivers',
        itemId: 991,
        href: '/cx/waivers'
      })
    }
    for (let j in context?.sessionUser?.company?.navigationItems) {
      _items.push(context?.sessionUser?.company?.navigationItems[j]);
    }


    if (!isAuthenticatedCustomer(context?.sessionUser)) {
      _items.push({
        name: 'Sign Up',
        itemId: 995,
        onClick: (e) => {
          handleCreateAccount();
        }
      })
    }

    setNavItems(_items);


    /*

<span>
                  <Button onClick={(e) => {
                    e.preventDefault();
                    navigate('/cx/events');
                  }}>Events</Button>
                </span>
              <span>
                  <Button onClick={(e) => {
                    e.preventDefault();
                    navigate('/cx/store');
                  }}>Store</Button>
                </span>
              <span>
                <MDButton variant="outlined"
                          color="dark"
                          onClick={(e) => {
                            handleCreateAccount()
                          }}
                >
                  Sign Up
                </MDButton>

                {isAuthenticatedCustomer(context?.sessionUser) &&
                  <Button onClick={(e) => {
                    logoutFn();
                  }}>Logout</Button>
                }
                {isAuthenticatedCustomer(context?.sessionUser) && assignedWaivers?.length > 0 &&
                  <Button onClick={(e) => {
                    e.preventDefault();
                    navigate('/cx/waivers');
                  }}>Assigned Waivers</Button>
                }
     */
  }

  useEffect(() => {
    populateNavItems();
  }, [context?.sessionUser])

  useEffect(() => {
    setStickyHeader(context?.sessionUser?.userTypeId !== CONSTANTS.UTYPE_CUSTOMER);
    setHeaderHtml(context?.sessionUser?.userTypeId === CONSTANTS.UTYPE_CUSTOMER && context?.sessionUser?.companyHeader);
    setSessionCustomer(isSessionCustomer(context?.sessionUser));
    setAssignedWaivers(context?.sessionUser?.assignedWaivers);

    if (context?.refreshIdentity) {
      setShowLogin(false);
      setShowCreateAccount(false);
    }

    populateNavItems();

  }, [context?.sessionUser]);

  useEffect(() => {
    if (context?.showLogin) {
      let _c = {...context};
      _c.showLogin = false;
      setContext({..._c});
      setShowLogin(true);
    }
  }, [context?.showLogin])

  const handleCreateAccount = () => {
    setShowCreateAccount(true);
  }

  const handleLogin = () => {
    setShowLogin(false);
  }

  const image = {
    imageUrl: logo,
  }

  const logoutFn = async () => {
    let portalGuid = context?.sessionUser?.companyGuid;
    let resp = await logout();
    if (resp) {
      let _c = {...context};
      _c.refreshIdentity = true;
      setContext({..._c});
      window.location.href = '/cx/' + portalGuid;
    }
  }

  const MiniNavActions = () => {
    return (<PopupState variant="popover" popupId={'mini-nav-popup-menu'}>
      {(popupState) => (
        <>
          <h6 variant="contained" {...bindTrigger(popupState)}>
            <Icon>menu</Icon>&nbsp;Menu
          </h6>
          <Menu {...bindMenu(popupState)}>
            {navItems.map((item, idx) => {
              return (
                <span key={idx}>
                  <NotificationItem
                    onClick={(e) => {
                      e.preventDefault();
                      if (item.onClick) {
                        item.onClick(e);
                      } else {
                        navigate('/cx' + item.url)
                      }
                      popupState.close();
                    }} icon={<Icon></Icon>} title={item.name}/>
                </span>
              )
            })}
          </Menu>
        </>
      )}
    </PopupState>);
  }

  return (
    <>

      <Toast props={props}/>

      <Grid id={'psb-header'} container cols={2} spacing={3} direction="row" className="customer-header">
        <Grid item xs={12}>
            <span>

              {isAuthenticatedCustomer(context?.sessionUser) &&
                <span className={'pull-right mx-2 mb-2'}>
                  <CustomerAvatar
                    customer={context?.sessionUser}/>
                </span>
              }

                 <MDButton
                   className="d-block d-sm-none d-md-none d-lg-none pull-left mln-2 cursor-pointer">
                    <MiniNavActions/>
                  </MDButton>

              <span className={'pull-right'} style={{
                position: 'relative',
                margin: '0 20px'
              }}>
                    <CustomerCart props={props}/>
                  </span>


              </span>

          <div className={'d-none d-md-block d-lg-block'}>
            {navItems && navItems.map((item, index) => {
              return (
                <span key={index}>
                    <Button onClick={(e) => {
                      e.preventDefault();
                      if (item.onClick) {
                        item.onClick(e);
                      } else {
                        navigate('/cx' + item.url)
                      }
                    }}>{item.name}</Button>
                  </span>
              )
            })
            }
          </div>

        </Grid>
      </Grid>

      {
        showLogin && <CustomerLoginForm
          show={showLogin}
          handleClose={() => {
            setShowLogin(false)
          }}
          handleLogin={handleLogin}/>
      }

      {
        showCreateAccount && <CustomerLoginForm
          show={showCreateAccount}
          createAccount={true}
          handleClose={() => {
            setShowCreateAccount(false);
          }}
          handleLogin={handleLogin}/>
      }

    </>
  );

}