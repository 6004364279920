import {Col, Container, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {formatCurrency, handleDefaultValues} from "../../common/Utils";
import {useForm} from 'react-hook-form';
import {useNavigate} from "react-router-dom";
import MDButton from "../../../components/MDButton";
import {InputAdornment} from "@mui/material";
import MDInput from "../../../components/MDInput";
import Avatar from "@mui/material/Avatar";
import {addToCart} from "../../common/api/PortalCustomerApi";

export default function ViewInventoryModal(props) {

  const navigate = useNavigate();
  const [context, setContext] = useContext(AppContext);
  const [inventory, setInventory] = useState(props.event);
  const [addingToCart, setAddingToCart] = useState(false);
  const [maxQuantity, setMaxQuantity] = useState(props?.inventory?.unlimited ? 999 : props?.inventory?.quantity);
  const [membershipType, setMembershipType] = useState(props?.membershipType);

  const [inventoryType, setInventoryType] = useState(props.inventoryType);
  const [formInvalid, setFormInvalid] = useState(false);

  const [isNew, setIsNew] = useState(!!!props.inventoryType?.guid);
  const [quantity, setQuantity] = useState(1);

  useEffect(()=> {
    setQuantity(1);
  },[props]);

  useEffect(() => {
    setInventory(props?.inventory);
  }, [props?.inventory]);

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(inventoryType)
  });

  const loadCartFn = () => {
    let _context = {...context};
    _context.reloadCart = true;
    setContext({..._context});
  };

  const addToCartFn = async (event) => {
    setAddingToCart(true);
    let _e = {...event};
    _e.quantity = quantity;

    addToCart(_e).then((result) => {
      handleClose();
      setAddingToCart(false);
      let _context = {...context};
      _context.viewCart = true;
      _context.reloadCart = true;
      setContext({..._context});
    });
  };

  const handleClose = () => {
    props.handleClose();
  };

  const handleSave = (data) => {
    props.handleSave(data);
  };


  return (
    <>
      {inventory &&
        <Modal onHide={handleClose} size={'lg'} show={props.show}>
          <form onSubmit={handleSubmit(handleSave)} id={'registerEventForm'}>
            <Modal.Header closeButton>
              <Modal.Title>{inventory.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col className={'col col-main'} xs={12}>
                  <Container>
                    <Row>
                      <Col className={'col'} sm={12}>
                        <h5 htmlFor="name">Name
                          {inventory.imageUrl &&
                            <Avatar alt={inventory?.name}
                                    className={'pull-right m-3'}
                                    sx={{width: 164, height: 164}}>
                              {inventory?.imageUrl && <img src={inventory?.imageThumbUrl || inventory?.imageUrl}
                                                       style={{width: '200%', height: 'auto'}}
                                                       alt={inventory?.name}/>}
                            </Avatar>}
                        </h5>
                        <p>
                          {inventory.name}
                        </p>
                      </Col>
                      <Col className={'col'} sm={12} xs={12}>
                        <h5 htmlFor="name">Details</h5>
                        <p>
                          {inventory.desc}
                        </p>
                      </Col>

                      <Col className={'col'} lg={3} md={6} sm={12} xs={12}>
                        <h5 htmlFor="name">Price</h5>
                        <p>
                          {formatCurrency(inventory.price)}
                        </p>
                        {quantity > 1 &&
                          <>
                            <h5 htmlFor="name">Price Total</h5>
                            <p>
                              {formatCurrency(inventory.price * quantity)}
                            </p>
                          </>
                        }
                      </Col>

                    </Row>

                  </Container>
                </Col>
              </Row>

            </Modal.Body>
            <Modal.Footer>

              {!membershipType &&
                <MDInput type="text" step="1" required
                         onChange={(e) => {
                           setQuantity(e.target.value);
                         }}
                         InputProps={{
                           readOnly: true,
                           startAdornment: <InputAdornment position="start"
                                                           className={'cursor-pointer'}
                                                           onClick={(e) => {
                                                             let _q = quantity - 1;
                                                             if (_q > 0) {
                                                               setQuantity(_q);
                                                             }
                                                           }}>
                             <span className={'fa fa-minus'}></span>
                           </InputAdornment>,
                           endAdornment: <InputAdornment position="end"
                                                         className={'cursor-pointer'}
                                                         onClick={(e) => {
                                                           let _q = quantity;
                                                           if (_q + 1 <= maxQuantity) {
                                                             setQuantity(_q + 1);
                                                           }
                                                         }}>
                             <span className={'fa fa-plus'}></span>
                           </InputAdornment>,
                         }}
                         value={quantity || 1}
                         autoComplete="off"
                         className="form-control pull-left" id="quantity"
                         label="Quantity"
                         style={{width: '125px'}}/>
              }

              <MDButton variant="gradient"
                        color="info"
                        onClick={(e) => {
                          addToCartFn(inventory)
                        }}
                        style={{marginLeft: '10px'}}
                        disabled={addingToCart}>
                {addingToCart ? 'Adding to Cart...' : 'Add to Cart'}
              </MDButton>

            </Modal.Footer>
          </form>
        </Modal>
      }
    </>
  );
}