import {
  Autoformat,
  Base64UploadAdapter,
  BlockQuote,
  Bold,
  CKFinder,
  CKFinderUploadAdapter,
  CloudServices,
  Essentials, FileRepository, GeneralHtmlSupport,
  Heading,
  Image,
  ImageCaption,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  MediaEmbed,
  Mention,
  Paragraph,
  PasteFromOffice,
  PictureEditing, SourceEditing,
  Table,
  TableColumnResize,
  TableToolbar,
  TextTransformation, Underline
} from "ckeditor5";

export const CONFIG = {
  PLUGINS : [
    Autoformat,
    BlockQuote,
    Bold,
    CKFinder,
    CKFinderUploadAdapter,
    CloudServices,
    Essentials,
    Heading,
    Image,
    ImageUpload,
    Base64UploadAdapter,
    Indent,
    IndentBlock,
    Italic,
    Link,
    List,
    MediaEmbed,
    Mention,
    Paragraph,
    PasteFromOffice,
    PictureEditing,
    Table,
    TableColumnResize,
    TableToolbar,
    TextTransformation,
    Underline,
    SourceEditing,
    GeneralHtmlSupport,
    FileRepository
  ],
  TOOLBAR: {
    htmlSupport: {
      allow: [
        {
          name: /.*/,
          attributes: true,
          classes: true,
          styles: true
        }
      ]
    },
    items: [
      'heading',
      '|',
      'bold',
      'italic',
      'underline',
      '|',
      'link',
      'uploadImage',
      'insertTable',
      'blockQuote',
      'mediaEmbed',
      '|',
      'bulletedList',
      'numberedList',
      '|',
      'sourceEditing',
      'outdent',
      'indent',
      'undo',
      'redo',
    ]
  }

};
