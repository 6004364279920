import {Col, Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {AppContext} from "../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {Card} from "@mui/material";
import Meta from "../common/Meta";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Footer from "../Footer";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import {useMaterialUIController} from "../../context";
import {handleDefaultValues} from "../common/Utils";
import {useForm} from "react-hook-form";
import {getCommunicationTemplates, getCustomer, sendEmail, uploadTemplateFile,} from "../common/api/WebApi";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";

import {CKEditor} from '@ckeditor/ckeditor5-react';
import {ClassicEditor, FileRepository} from "ckeditor5";
import Divider from "@mui/material/Divider";
import ContextSearchNavbar from "../navbars/ContextSearchNavbar";
import {CONFIG} from "../common/EditorConfig";


export default function ComposeEmail(props) {

  let {guid} = useParams();

  const [isNew, setIsNew] = useState(true);

  const [controller] = useMaterialUIController();
  const {darkMode} = controller;

  const navigate = useNavigate();

  const [context, setContext] = useContext(AppContext);
  const [saving, setSaving] = useState(false);
  const [templateReady, setTemplateReady] = useState(false);
  const [email, setEmail] = useState();
  const [emailBody, setEmailBody] = useState();
  const [templates, setTemplates] = useState([]);
  const [templateIndex, setTemplateIndex] = useState(0);

  const [editorState, setEditorState] = useState();
  const [client, setClient] = useState();

  useEffect(() => {
    reset();
    getCompanyTemplates();
    getCustomerFn();

    setEmail({
      emailSubject: null,
      emailBody: '',
    });

    setTemplateReady(true);

  }, []);

  const getCustomerFn = async () => {
    let customer = await getCustomer(guid);
    setClient(customer);
  }

  const {register, handleSubmit, setValue, reset} = useForm({
    values: handleDefaultValues(email)
  });

  const sidenavItems = [
    {icon: "person", label: "First Name", replacement: "{firstName}"},
    {icon: "person", label: "Last Name", replacement: "{lastName}"},
    {icon: "person_outline", label: "Child Name", replacement: "{childName}"},

    {icon: "apps", label: "Account", replacement: "{membership}"},

    {icon: "", type: "divider"},

    {icon: "store", label: "Company Address", replacement: "{companyAddress}"},
    {icon: "store", label: "Company Contact", replacement: "{companyContact}"},

    {icon: "", type: "divider"},

    {icon: "sell", label: "Formatted Receipt", replacement: "{formattedReceipt}"},
    {icon: "event", label: "Event Name", replacement: "{eventName}"},
    {icon: "event", label: "Event Date", replacement: "{eventDate}"},
    {icon: "event_busy", label: "Event Cancellation", replacement: "{eventCancellation}"},

  ];

  const handleChange = (e, editor) => {
    setEmailBody(editor.getData());
  }

  const getCompanyTemplates = async () => {
    getCommunicationTemplates().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setTemplates(result);
      }
    });
  }

  const handleSendEmail = async (data) => {
    setSaving(true);
    let d = {...data};
    d.emailBody = emailBody;
    d.toUser = {...client};

    let promise = sendEmail(d);

    promise.then(result => {
      setSaving(false);
      navigate(`/client/${guid}`);
    });
  }

  const renderSidenavItems = sidenavItems.map(({icon, label, href, replacement, type}, key) => {
    const itemKey = `item-${key}`;

    return (

      type === "divider" ? <Divider key={itemKey} sx={{my: 1}}/> :
        <MDBox key={itemKey} component="li" pt={0}>
          <MDTypography
            component="a"
            href={`#${href}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              insertText(replacement);
            }}
            variant="button"
            fontWeight="regular"
            textTransform="capitalize"
            sx={({
                   borders: {borderRadius},
                   functions: {pxToRem},
                   palette: {light},
                   transitions,
                 }) => ({
              display: "flex",
              alignItems: "center",
              borderRadius: borderRadius.md,
              padding: `${pxToRem(10)} ${pxToRem(16)}`,
              transition: transitions.create("background-color", {
                easing: transitions.easing.easeInOut,
                duration: transitions.duration.shorter,
              }),

              "&:hover": {
                backgroundColor: light.main,
              },
            })}
          >
            <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}>
              <Icon fontSize="small">{icon}</Icon>
            </MDBox>
            {label}
          </MDTypography>
        </MDBox>
    );
  });


  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {

          const body = new FormData();
          loader.file.then((file) => {
            const fileType = file.type;
            const fileName = file.name;
            const reader = new FileReader();

            reader.onload = function (evt) {
              let fileRequest = {
                imageType: fileType,
                imageName: fileName,
                data: btoa(evt.target.result)
              }

              uploadTemplateFile(fileRequest).then(result => {
                if (result.error) {
                  setContext({...context, error: {message: result.message}});
                } else {
                  loader.uploaded = true;
                  loader.uploadUrl = result;
                }
                resolve({
                  default: result.fileLocation
                })
              });
            }
            reader.readAsBinaryString(file);
          });
        })
      }
    }
  }

  const [blurEditor, setBlurEditor] = useState();

  const insertText = (text) => {
    blurEditor.model.change(writer => {
      writer.insertText(text + " ", blurEditor.model.document.selection.getFirstPosition());
    });
  };

  const handleBlur = (event, editor) => {
    setBlurEditor(editor);
  };

  function uploadPlugin(editor) {
    editor.plugins.get(FileRepository).createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <DashboardLayout>
      <Meta pageTitle={'Compose Email'}/>
      <ContextSearchNavbar title="Compose Email"/>

      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <MDBox
              sx={{
                position: "sticky",
                top: "95px",
              }}>
              <Card
                sx={{
                  borderRadius: ({borders: {borderRadius}}) => borderRadius.lg,
                }}
              >
                <MDTypography variant="h5" fontWeight="regular" p={2} pb={0}>
                  Replacement Variables
                </MDTypography>
                <Divider/>
                <MDBox
                  component="ul"
                  display="flex"
                  flexDirection="column"
                  p={2}
                  pt={0}
                  m={0}
                  sx={{listStyle: "none"}}
                >
                  {renderSidenavItems}
                </MDBox>
              </Card>

              <Card
                sx={{
                  borderRadius: ({borders: {borderRadius}}) => borderRadius.lg,
                  marginTop: "15px",
                }}>
                <MDTypography variant="h5" fontWeight="regular" p={2} pb={0}>
                  Your Templates
                </MDTypography>
                <Divider/>
                <MDBox
                  component="ul"
                  display="flex"
                  flexDirection="column"
                  p={2}
                  pt={0}
                  m={0}
                  sx={{listStyle: "none"}}
                >
                  <MDBox key={'comm-template-new'} component="li" pt={0}>
                    <MDTypography
                      component="a"
                      className={'cursor-pointer '}
                      onClick={(e) => {
                        setEmail({});
                        navigate(`/communication/templates`)
                      }}
                      variant="button"
                      fontWeight={!guid ? 'bold' : 'regular'}
                      sx={({
                             borders: {borderRadius},
                             functions: {pxToRem},
                             palette: {light},
                             transitions,
                           }) => ({
                        display: "flex",
                        alignItems: "center",
                        borderRadius: borderRadius.md,
                        padding: `${pxToRem(10)} ${pxToRem(16)}`,
                        transition: transitions.create("background-color", {
                          easing: transitions.easing.easeInOut,
                          duration: transitions.duration.shorter,
                        }),
                        "&:hover": {
                          backgroundColor: light.main,
                        },
                      })}
                    >
                      <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}>
                        <Icon fontSize="small">mail_outline</Icon>
                      </MDBox>
                      Create New
                    </MDTypography>
                  </MDBox>

                  {templates?.sort((a, b) => b.id - a.id).map((item, index) => {
                      return (
                        <MDBox key={'comm-template-' + index} component="li" pt={1}>
                          <MDTypography
                            component="a"
                            className={'cursor-pointer '}
                            onClick={(e) => {
                              navigate(`/communication/templates/${item.guid}`)
                            }}
                            variant="button"
                            fontWeight={item.guid === guid ? 'bold' : 'regular'}
                            sx={({
                                   borders: {borderRadius},
                                   functions: {pxToRem},
                                   palette: {light},
                                   transitions,
                                 }) => ({
                              display: "flex",
                              alignItems: "center",
                              borderRadius: borderRadius.md,
                              padding: `${pxToRem(10)} ${pxToRem(16)}`,
                              transition: transitions.create("background-color", {
                                easing: transitions.easing.easeInOut,
                                duration: transitions.duration.shorter,
                              }),
                              "&:hover": {
                                backgroundColor: light.main,
                              },
                            })}
                          >
                            <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}>
                              <Icon fontSize="small">email</Icon>
                            </MDBox>
                            {item.templateName}
                          </MDTypography>
                        </MDBox>
                      )
                    }
                  )}
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>

              <Grid container spacing={3}>
                <Grid item xs={12} id="company-info">
                  <Card>
                    <MDBox p={3}>
                      <MDTypography variant="h5" fontWeight="regular" className={'mb-2'}>
                        Email {client?.firstName} {client?.lastName}
                      </MDTypography>

                      {templateReady && <form onSubmit={handleSubmit(handleSendEmail)} id={'templateForm'}>
                        <Row>

                          <Col className={'col'} sm={6} xs={12}>
                            <div className="form-group">
                              <MDInput
                                variant="standard" type="text"
                                {...register('emailSubject')}
                                autoComplete="off" required
                                className="form-control" id="emailSubject"
                                label="Enter Subject"/>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col className={'col'} sm={12} xs={12}>

                            <CKEditor
                              editor={ClassicEditor}
                              config={{
                                htmlSupport: {
                                  allow: [
                                    {
                                      name: /.*/,
                                      attributes: true,
                                      classes: true,
                                      styles: true
                                    }
                                  ]
                                },
                                placeholder: 'Enter Email Body',
                                toolbar: CONFIG.TOOLBAR,
                                plugins: CONFIG.PLUGINS,
                                extraPlugins: [
                                  uploadPlugin
                                ]
                              }}
                              data={email?.emailBody || ''}
                              onReady={editor => {
                              }}
                              onChange={(event, editor) => {
                                handleChange(event, editor);
                              }}
                              onBlur={(event, editor) => {
                                handleBlur(event, editor);
                              }}
                              onFocus={(event, editor) => {
                              }}
                            />
                            <Divider/>
                          </Col>
                        </Row>
                        <Row>
                          <Col className={'col'} sm={12} xs={12}>
                            <MDButton
                              type="submit"
                              size={'medium'}
                              disabled={saving}
                              color="info"
                              className={'pull-right'}>
                              Send Email</MDButton>
                          </Col>
                        </Row>

                      </form>
                      }
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <Footer mt={5}/>

    </DashboardLayout>
  );
}