import {useNavigate} from "react-router-dom";
import {AppContext} from "../../lib/AppContext";
import {useContext, useEffect, useState} from "react";
import {Card, CardContent} from "@mui/material";
import MDBox from "../../components/MDBox";
import CustomerHeader from "../common/CustomerHeader";
import Footer from "../Footer";
import CustomerPageLayout from "../Layout/CustomerPageLayout";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MDButton from "../../components/MDButton";

export default function CustomerWaivers(props) {
  const [context, setContext] = useContext(AppContext);
  const [company, setCompany] = useState(context?.sessionUser?.company);
  const [assignedWaivers, setAssignedWaivers] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (context.sessionUser?.companyGk) {
      setPageReady(true);
      setCompany(context.sessionUser.company);
    }
    setAssignedWaivers(context?.sessionUser?.assignedWaivers);
  }, [context?.sessionUser]);

  const [pageReady, setPageReady] = useState(false);

  const handleClose = () => {
    // setShowMembershipModal(false);
  }
  const handleSave = (data) => {
    //  setSavingMembership(true);
  }

  return (
    <>
      <CustomerPageLayout>

        <CustomerHeader props={props}/>

        {pageReady && <MDBox p={3}>
          <MDBox m={3}>
            <Grid container cols={3} spacing={3} direction="row">
              <Grid item sm={3} xs={12}>
                <Card>
                  <CardContent>

                    <MDBox display="flex" justifyContent="center">
                      {company.logoUrl && <img
                        onClick={() => {
                          navigate('/cx/')
                        }}
                        src={(company.logoUrl)}
                        className={'img-thumbnail m-2 ' + props.className} style={{width: '90%'}}/>}
                    </MDBox>

                    <Divider/>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Card>
                  <CardContent>
                    <table className="table table-striped">
                      <thead>
                      <tr>
                        <th scope="col">Waiver Name</th>
                        <th scope="col" className="text-right">Version</th>
                        <th scope="col" className="text-right px-5">Complete</th>
                      </tr>
                      </thead>
                      <tbody>
                      {assignedWaivers?.sort((a, b) => b.id - a.id).map((waiver, key) => (
                        <tr key={key}>
                          <td>{waiver.waiverName}</td>
                          <td className="text-right">{waiver.version}</td>
                          <td className="text-right px-5">
                            <MDButton size="small" color="primary"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        navigate('/cx/waiver/' + waiver.guid);
                                      }}>Complete</MDButton>
                            {/*<Link to={'/cx/waiver/' + waiver.guid}>Complete</Link> */}
                          </td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        }

        <Footer/>
      </CustomerPageLayout>
    </>
  )
}