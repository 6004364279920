import {Modal} from "react-bootstrap";
import {AppContext} from "../../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {formatCurrency, handleDefaultValues} from "../../common/Utils";
import {useForm} from 'react-hook-form';
import {useNavigate} from "react-router-dom";
import {Divider, Grid} from "@mui/material";
import MDButton from "../../../components/MDButton";
import MDBox from "../../../components/MDBox";
import {getInventoryMemberships, addToCart} from "../../common/api/PortalCustomerApi";
import Avatar from "@mui/material/Avatar";

export default function ClientMembershipsModal(props) {
  const navigate = useNavigate();
  const [context, setContext] = useContext(AppContext);
  const [addingToCart, setAddingToCart] = useState(false);
  const [memberships, setMemberships] = useState();

  const [inventoryType, setInventoryType] = useState(props.inventoryType);
  const [formInvalid, setFormInvalid] = useState(false);

  const [isNew, setIsNew] = useState(!!!props.inventoryType?.guid);

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(inventoryType)
  });

  useEffect(() => {
    getInventoryMembershipsFn();
  }, []);

  const getInventoryMembershipsFn = async () => {

    let result = await getInventoryMemberships();
    setMemberships(result);
  }

  const loadCartFn = () => {
    let _context = {...context};
    _context.reloadCart = true;
    setContext({..._context});
  };

  const addToCartFn = async (event) => {
    setAddingToCart(true);
    addToCart(event).then((result) => {
      handleClose();
      setAddingToCart(false);
      let _context = {...context};
      _context.viewCart = true;
      _context.reloadCart = true;
      setContext({..._context});
    });
  };

  const handleClose = () => {
    props.handleClose();
  };

  const handleSave = (data) => {
    props.handleSave(data);
  };


  return (
    <>
      <Modal onHide={handleClose} size={'lg'} show={props.show}>
        <form onSubmit={handleSubmit(handleSave)} id={'registerEventForm'}>
          <Modal.Header closeButton>
            <Modal.Title>View Memberships</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              <MDBox p={3}>
                <MDBox>
                  {memberships?.length > 0 && <>
                    {memberships?.sort((a, b) => new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime())
                      // .filter(item => showEvent(item))
                      .map((inventory, index) => {
                        return (
                          <div key={index}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={9}>
                                <h6>{inventory.name}</h6>
                                <p><small>
                                  {inventory.accountType?.name}
                                </small><br/>
                                  <small>
                                    {formatCurrency(inventory?.price)}
                                  </small>
                                </p>
                                <p>
                                  <small>
                                    {inventory.desc}
                                  </small>
                                </p>
                              </Grid>
                              <Grid item xs={12} sm={3}>
                                <MDBox display="flex" justifyContent="flex-end">
                                  <Avatar alt={inventory?.name}
                                          className={'pull-left m-3'}
                                          sx={{width: 96, height: 96}}>
                                    {inventory?.imageUrl && <img src={inventory?.imageThumbUrl || inventory?.imageUrl}
                                                                 style={{width: '200%', height: 'auto'}}
                                                                 alt={inventory?.name}/> }
                                  </Avatar>
                                </MDBox>
                                {
                                  <MDBox display="flex" justifyContent="flex-end">
                                    <MDButton variant="gradient"
                                              size={'small'}
                                              color="light"
                                              onClick={(e) => {
                                                addToCartFn(inventory);
                                              }}>Add To Cart</MDButton>
                                  </MDBox>
                                }
                              </Grid>
                            </Grid>
                            <Divider/>
                          </div>
                        )
                      })
                    }
                  </>}
                </MDBox>
              </MDBox>
            </>

          </Modal.Body>

        </form>
      </Modal>
    </>
  );
}