import React, {useContext, useEffect, useState} from "react";
import Meta from "./common/Meta";
import ContextSearchNavbar from "./navbars/ContextSearchNavbar";
import DashboardLayout from "./LayoutContainers/DashboardLayout";
import MDBox from "../components/MDBox";
import Grid from "@mui/material/Grid";
import Footer from "./Footer";
import StatisticsCard from "../app/reports/card/StatisticsCard";

import {
  getActiveMembers,
  getReportDataLastMonth,
  getReportDataThisMonth,
  getReportDataToday,
  getReportDataUpcomingVisits
} from "./common/api/ReportApi";
import {formatCurrency} from "./common/Utils";
import {AppContext} from "../lib/AppContext";
import SalesCard from "./reports/card/SalesCard";

export default function SalesDashboard(props) {

  const [context, setContext] = useContext(AppContext);

  const [reportDataSales, setReportDataSales] = useState();
  const [reportDataVisits, setReportDataVisits] = useState();

  const [reportDataTodaySales, setReportDataTodaySales] = useState();
  const [reportDataTodayVisits, setReportDataTodayVisits] = useState();

  const [lastMonthSalesData, setLastMonthSalesData] = useState();
  const [activeMembers, setActiveMembers] = useState();
  const [passMembers, setPassMembers] = useState();
  const [lastMonthVisitsData, setLastMonthVisitsData] = useState();
  const [upcomingVisits, setUpcomingVisits] = useState();

  const getSalesData = () => {
    getReportDataToday().then((response) => {
      setReportDataTodaySales(formatCurrency(response?.totalSales));
      setReportDataTodayVisits(response?.totalVisits || 0);
    });
    getReportDataThisMonth().then((response) => {
      setReportDataSales(formatCurrency(response?.totalSales));
      setReportDataVisits(response?.totalVisits || 0);
    });
    getActiveMembers().then((response) => {
      setActiveMembers(response?.activeMembers || 0);
      setPassMembers(response?.passMembers || 0);
    });

    getReportDataLastMonth().then((response) => {
      setLastMonthSalesData(formatCurrency(response?.totalSales));
      setLastMonthVisitsData(response?.totalVisits || 0);
    });
  }

  const getUpcomingReservations = async () => {
    getReportDataUpcomingVisits().then((response) => {
      if (response.error) {
        setContext({...context, error: {message: response.message}});
      } else {
        setUpcomingVisits(response);
      }
    });
  }

  useEffect(() => {
    getSalesData();
    getUpcomingReservations();
  }, []);

  const getLastMonthSalesRange = () => {
    const d = new Date();
    return d.getFullYear() + '/' + (parseInt(d.getMonth() , 10) -1);
  }

  const getCurrentMonthSalesRange = () => {
    const d = new Date();
    return d.getFullYear() + '/' + (parseInt(d.getMonth() , 10));
  }


  return (
    <DashboardLayout>

      <Meta pageTitle={'Dashboard'}/>

      <ContextSearchNavbar title="Dashboard"/>

      <MDBox py={3}>

        <MDBox mb={3}>
          <Grid container spacing={3}>

            <Grid item xs={12} sm={4}>


              {reportDataTodaySales &&
                <>
                  <SalesCard
                    title="sales"
                    count={reportDataTodaySales}
                    range={null}
                    percentage={{
                      color: "success",
                      label: "Sales Today",
                    }}
                  />

                </>
              }

              {reportDataTodayVisits &&
                <MDBox mt={2}>
                  <StatisticsCard
                    title="visits"
                    count={reportDataTodayVisits}
                    percentage={{
                      color: "success",
                      label: "Visits Today",
                    }}
                  />
                </MDBox>
              }
            </Grid>

            <Grid item xs={12} sm={4}>


              {reportDataSales &&
                <>
                  <SalesCard
                    title="sales"
                    count={reportDataSales}
                    range={getCurrentMonthSalesRange()}
                    percentage={{
                      color: "success",
                      label: "Sales This Month",
                    }}
                  />

                </>
              }

              {reportDataVisits &&
                <MDBox mt={2}>
                  <StatisticsCard
                    title="visits"
                    count={reportDataVisits}
                    percentage={{
                      color: "success",
                      label: "Visits This Month",
                    }}
                  />
                </MDBox>
              }
            </Grid>

            <Grid item xs={12} sm={4}>
              {lastMonthSalesData &&
                <>
                  <SalesCard
                    title="sales"
                    range={getLastMonthSalesRange()}
                    count={lastMonthSalesData}
                    percentage={{
                      color: "success",
                      label: "Sales Last Month",
                    }}
                  />
                </>
              }

              {lastMonthVisitsData &&
                <MDBox mt={2}>
                  <StatisticsCard
                    title="visits"
                    count={lastMonthVisitsData}
                    percentage={{
                      color: "success",
                      label: "Visits Last Month",
                    }}
                  />
                </MDBox>
              }
            </Grid>

          </Grid>
          <Grid container spacing={3} pt={2}>
            <Grid item xs={12} sm={4}>
              {activeMembers >= 0 &&
                <>
                  <StatisticsCard
                    title="Memberships"
                    count={activeMembers}
                    percentage={{
                      color: "success",
                      label: "Active Recurring Memberships",
                    }}
                  />
                </>
              }
            </Grid>

            <Grid item xs={12} sm={4}>
              {passMembers >= 0 &&
                <>
                  <StatisticsCard
                    title="Memberships"
                    count={passMembers}
                    percentage={{
                      color: "success",
                      label: "Active Pass Memberships",
                    }}
                  />
                </>
              }
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer/>
    </DashboardLayout>
  );
}