import {AppContext} from "../../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {formatCurrency} from "../../common/Utils";
import {useNavigate} from "react-router-dom";

import {Chip, Divider, Grid} from "@mui/material";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import Loading from "../../common/Loading";
import Avatar from "@mui/material/Avatar";
import {getInventory} from "../../common/api/InventoryApi";
import MDInput from "../../../components/MDInput";

export default function InventoryCardTable(props) {
  const navigate = useNavigate();
  const [context, setContext] = useContext(AppContext);
  const [inventoryLoading, setInventoryLoading] = useState(true);
  const [client, setClient] = useState();
  const [inventory, setInventory] = useState([]);
  const [searchCriteria, setSearchCriteria] = useState();

  const sendToCart = (item) => {
    props?.sendToCart(item);
  }

  const highlight = (haystack) => {
    const needle = searchCriteria;
    if (!needle) {
      return haystack;
    }
    var querystr = needle;
    var result = haystack;
    var reg = new RegExp(querystr.split(' ').join('|'), 'gi');
    var final_str = result.replace(reg, function (str) {
      return '<b>' + str + '</b>'
    });

    return final_str;

  }

  useEffect(() => {
    setClient(props?.client);
  }, [props?.client]);

  useEffect(() => {
    getInventoryFn();
  }, []);

  const filterFn = (item) => {
    if (!searchCriteria || !searchCriteria.length) {
      return true;
    }
    let crit = item.name.toLowerCase();
    let match = crit.match(searchCriteria.toLowerCase());
    if (match && match.length > 0) {
      return true;
    }

    crit = item.desc.toLowerCase();
    match = crit.match(searchCriteria.toLowerCase());
    if (match && match.length > 0) {
      return true;
    }
    if (match && match.length > 0) {
      return true;
    }
    if (item?.tags?.length > 0) {
      let hasTagMatch = false;
      for (let j in item?.tags) {
        crit = item?.tags[j].name.toLowerCase();
        match = crit.match(searchCriteria.toLowerCase());
        if (match && match.length > 0) {
          return true;
        }
      }
    }

    return false;
  }

  const getInventoryFn = async () => {
    setInventoryLoading(true);

    getInventory().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setInventory(result);
        setInventoryLoading(false);
      }
    })

  }

  const highlightTag = (tag) => {
    return <span dangerouslySetInnerHTML={{__html: highlight(tag.name)}}/>
  }

  return (
    <>
      <MDBox>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDBox width="100%" lineHeight={1}>

              <MDInput type="text"
                       autoComplete={'off'}
                       variant="outlined"
                       value={searchCriteria || ''}
                       onChange={(e) => setSearchCriteria(e.target.value)}
                       className="form-control" id="code" aria-describedby="searchInventory"
                       label="Search Inventory"/>
            </MDBox>
          </Grid>
        </Grid>
        <Divider/>

        <Grid container spacing={2}>

          <Grid item xs={12}>
            {inventoryLoading && <Loading/>}

            {!inventoryLoading &&
              <table className="table table-striped">
                <tbody>
                {inventory?.length > 0 && inventory?.sort((a, b) => b.id - a.id) //
                  .filter((item) => filterFn(item))
                  .map((item, index) => {
                    return (
                      <tr index={index} key={index}>
                        <td index={index} key={index}>
                          <Avatar alt={item.name} sx={{width: 32, height: 32}}>
                            <img src={item.imageUrl} style={{width: '200%', height: 'auto'}} alt={item.name}/>
                          </Avatar>
                        </td>
                        <td>
                          <MDTypography
                            variant="p"
                            fontWeight={'regular'}>
                          <span
                            dangerouslySetInnerHTML={{__html: highlight(item.name)}}/>

                          </MDTypography>
                          <span className="d-block"><b>{formatCurrency(item.price)}</b>
                            <br/>
                            <small style={{
                              whiteSpace: 'normal',
                              padding: '0',
                              margin: '0'
                            }} dangerouslySetInnerHTML={{__html: highlight(item.desc)}}/>
                          </span>
                          <span className="d-block">{item?.tags?.map((item, index) => {
                            return (
                              <span index={index} key={index}>
                              <Chip
                                variant="outlined"
                                label={highlightTag(item)}
                              />&nbsp;
                            </span>
                            )
                          })}</span>
                          <span className="d-block">
                            <MDButton
                              color="secondary"
                              size="small"
                              className={'pull-right mr-2'}
                              //disabled={recordingVisit || clientDisabled || !(clientCart?.items?.length > 0)}
                              onClick={() => {
                                sendToCart(item);
                              }}>
                              <span className={'fa fa-circle-plus text-small'}></span>&nbsp;Add to cart
                            </MDButton>
                          </span>
                        </td>
                      </tr>
                    )
                  })
                }
                </tbody>
              </table>
            }
          </Grid>
        </Grid>

      </MDBox>
    </>
  )
}