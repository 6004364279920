import {Col, Container, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import {useContext, useEffect, useState} from "react";
import {handleDefaultValues} from "../common/Utils";
import {Controller, useForm} from 'react-hook-form';
import ReactDatePicker from "react-datepicker";
import {addDays} from "@fullcalendar/core/internal";
import {parseISO} from "date-fns";
import MDButton from "../../components/MDButton";

export default function ManageTimeEntryModal(props) {

  const [context, setContext] = useContext(AppContext);
  const [timeEntry, setTimeEntry] = useState(props.timeEntry);
  const [timeEntryId, setTimeEntryId] = useState(props.timeEntry?.id);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [eventDate, setEventDate] = useState(new Date());

  useEffect(() => {
    if (props.timeEntry) {
      setTimeEntryId(props.timeEntry?.id);
      if (typeof props.timeEntry?.startDate === 'string') {
        setStartDate(parseISO(props?.timeEntry?.startDate));
        setEventDate(parseISO(props?.timeEntry?.startDate));
      } else {
        setStartDate(props?.timeEntry?.startDate);
        setEventDate(props?.timeEntry?.startDate);
      }
      if (typeof props.timeEntry?.endDate === 'string') {
        setEndDate(parseISO(props?.timeEntry?.endDate));
      } else {
        setEndDate(props?.timeEntry?.endDate);
      }
    }
  }, [props?.timeEntry]);

  const [isNew, setIsNew] = useState(!!!props.timeEntry?.id);
  const [formInvalid, setFormInvalid] = useState(false);

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(timeEntry)
  });

  const handleClose = () => {
    props.handleClose();
  };

  const handleSave = (data) => {
    let _timeEntry = {
      startDate: startDate,
      endDate: endDate,
      timeEntryId: timeEntryId
    }
    props.handleSave(_timeEntry);
  };

  const updateEventDates = (date) => {
    setEventDate(date);
    setStartDate(date);
    setEndDate(date);
  }

  return (
    <Modal onHide={handleClose} size={'lg'} show={props.show}>
      <form onSubmit={handleSubmit(handleSave)} id={'memberForm'}>
        <Modal.Header closeButton>
          <Modal.Title>{timeEntryId ? 'Manage ' : 'New '}Time Entry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className={'col col-main'} xs={12}>
              <Container>
                <Row className={'mt-2'}>
                  <Col className={'col'} sm={6} xs={6}>
                    <Controller
                      className={'d-block'}
                      control={control}
                      name="eventDate"
                      id="eventDate"
                      /*
                      TODO set min and max times based on COMPANY PREFERENCES
                       */
                      render={({field: {onChange, onBlur, value, ref}}) => (
                        <ReactDatePicker
                          todayButton="Today"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          maxDate={timeEntryId ? startDate : new Date()}
                          minDate={timeEntryId ? endDate : addDays(new Date(), -7)}
                          dropdownMode="select"
                          calendarClassName="psb-form-control"
                          // showTimeSelect
                          dateFormat="MMMM d, yyyy h:mm aa"
                          className={'form-control'}
                          onChange={(date) => updateEventDates(date)}
                          onBlur={onBlur}
                          inline
                          selected={startDate}
                        />
                      )}
                    />

                  </Col>

                  <Col className={'col'} sm={6} xs={6}>
                    <div className="form-group">
                      <label htmlFor="startDate">Start Time</label>
                      <div className={'input-group d-block'}>
                        <Controller
                          className={'d-block'}
                          control={control}
                          name="startDate"
                          id="startDate"
                          render={({field: {onChange, onBlur, value, ref}}) => (
                            <ReactDatePicker
                              // minTime={setHours(setMinutes(new Date(), 0), 9)}
                              // maxTime={setHours(setMinutes(new Date(), 15), 17)}
                              showTimeSelect
                              showTimeSelectOnly
                              calendarClassName="psb-form-control"
                              timeIntervals={5}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              className={'form-control'}
                              onChange={(date) => setStartDate(date)}
                              onBlur={onBlur}
                              selected={startDate}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="endDate">End Time</label>
                      <div className={'input-group d-block'}>
                        <Controller
                          className={'d-block'}
                          control={control}
                          name="endDate"
                          id="endDate"
                          render={({field: {onChange, onBlur, value, ref}}) => (
                            <ReactDatePicker
                              showTimeSelect
                              showTimeSelectOnly
                              calendarClassName="psb-form-control"
                              timeIntervals={5}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              className={'form-control'}
                              onChange={(date) => setEndDate(date)}
                              onBlur={onBlur}
                              selected={endDate}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <MDButton type="submit" color="info" disabled={formInvalid || props.saving}>
            {props.saving ? 'Saving...' : 'Save'}
          </MDButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
}