import {AppContext} from "../../lib/AppContext";
import {useContext} from "react";
import {Card} from "@mui/material";
import ContextSearchNavbar from "../navbars/ContextSearchNavbar";
import Meta from "../common/Meta";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import Footer from "../Footer";
import MDTypography from "../../components/MDTypography";

export default function TermsOfService(props) {

  const [context, setContext] = useContext(AppContext);

  return (
    <DashboardLayout>

      <Meta pageTitle={'Employees'}/>

      <ContextSearchNavbar title={'Terms of Service'}/>
      <MDBox py={3}>
        <Card>
          <MDBox p={3}>
            <MDTypography variant="h5" fontWeight="regular" className={'mb-3'}>
              Terms of Service
            </MDTypography>

            <h2>1. Accounts</h2>
            <p>
              1.1. <strong>Account Creation</strong>. In order to use the Service, you must create an account by
              providing a valid email address and any other information requested during the registration process.<br/>
              1.2. <strong>Account Responsibilities</strong>. You are responsible for maintaining the security of your
              account and any associated passwords. Play Space Brain, LLC cannot and will not be liable for any loss or
              damage from your failure to comply with this security obligation.
            </p>

            <h2>2. Use of the Service</h2>
            <p>
              2.1. <strong>License</strong>. Subject to these Terms, Play Space Brain, LLC grants you a
              non-transferable, non-exclusive license to use the Service for your personal or business use.<br/>
              2.2. <strong>Prohibited Use</strong>. You may not use the Service for any illegal or unauthorized purpose.
              You agree to comply with all applicable laws and regulations regarding your use of the Service.
            </p>

            <h2>3. Payment and Billing</h2>
            <p>
              3.1. <strong>Subscription Fees</strong>. The use of certain features of the Service may require payment of
              subscription fees. By subscribing to the Service, you agree to pay all applicable fees as described on the
              Play Space Brain, LLC website.<br/>
              3.2. <strong>Billing Information</strong>. You agree to provide accurate, current, and complete billing
              information for all purchases made through the Service. You further agree to promptly update such
              information as necessary to ensure that it is accurate and complete at all times.
            </p>

            <h2>4. Intellectual Property</h2>
            <p>
              4.1. <strong>Ownership</strong>. Play Space Brain, LLC retains all rights, title, and interest in and to
              the Service, including all associated intellectual property rights.<br/>
              4.2. <strong>License Grant</strong>. By submitting, posting, or displaying any content on or through the
              Service, you grant Play Space Brain, LLC a worldwide, non-exclusive, royalty-free license (with the right
              to sublicense) to use, copy, reproduce, process, adapt, modify, publish, transmit, display, and distribute
              such content.
            </p>

            <h2>5. Termination</h2>
            <p>
              5.1. <strong>Termination by You</strong>. You may terminate your account and these Terms at any time by
              contacting Play Space Brain, LLC customer support.<br/>
              5.2. <strong>Termination by Play Space Brain, LLC</strong>. Play Space Brain, LLC may terminate or suspend
              your account and access to the Service at any time, with or without cause, and with or without notice.
            </p>

            <h2>6. Limitation of Liability</h2>
            <p>
              6.1. <strong>Disclaimer of Warranties</strong>. The Service is provided "as is" and "as available" without
              warranties of any kind, either express or implied, including, but not limited to, implied warranties of
              merchantability, fitness for a particular purpose, or non-infringement.<br/>
              6.2. <strong>Limitation of Liability</strong>. In no event shall Play Space Brain, LLC be liable for any
              indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of
              profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or
              inability to access or use the Service; (ii) any conduct or content of any third party on the Service;
              (iii) any content obtained from the Service; and (iv) unauthorized access, use, or alteration of your
              transmissions or content, whether based on warranty, contract, tort (including negligence), or any other
              legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy
              set forth herein is found to have failed of its essential purpose.
            </p>

            <h2>7. Governing Law</h2>
            <p>
              These Terms shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without
              regard to its conflict of law provisions.
            </p>

            <h2>8. Changes to These Terms</h2>
            <p>
              Play Space Brain, LLC reserves the right to modify these Terms at any time. If we make changes to these
              Terms, we will post the revised Terms on the Play Space Brain, LLC website and update the "Last Updated"
              date at the top of these Terms. Your continued use of the Service after the effective date of the revised
              Terms constitutes your acceptance of the terms.
            </p>

            <h2>9. Contact Us</h2>
            <p>
              If you have any questions about these Terms, please contact us at help@playspacebrain.com.
            </p>

          </MDBox>
        </Card>
      </MDBox>

      <Footer mt={5}/>

    </DashboardLayout>
);
}