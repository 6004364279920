import {AppContext} from "../../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {formatCurrency, handleDefaultValues} from "../../common/Utils";
import {useForm} from 'react-hook-form';
import {getInventoryEvent, getInventoryMemberships} from "../../common/api/PortalCustomerApi";
import {Chip, Divider, Grid} from "@mui/material";
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import ViewInventoryModal from "../modal/ViewInventoryModal";
import Avatar from "@mui/material/Avatar";

export default function InventoryMiniPane(props) {
  const [context, setContext] = useContext(AppContext);

  const [eventReady, setEventReady] = useState(false);
  const [event, setEvent] = useState();
  const [memberships, setMemberships] = useState([]);

  useEffect(() => {
    getInventoryMembershipsFn()
  }, []);

  const [inventory, setInventory] = useState();
  const [showMembershipModal, setShowMembershipModal] = useState(false);
  const [savingMembership, setSavingMembership] = useState(false);

  const getInventoryEventFn = async (eventId) => {
    getInventoryEvent(eventId).then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setEvent(result);
        setEventReady(true);
      }
    });
  }

  const [inventoryType, setInventoryType] = useState();
  const [formInvalid, setFormInvalid] = useState(false);

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(inventoryType)
  });

  const handleClose = () => {
    setShowMembershipModal(false);
  };

  const handleSave = (data) => {
    props.handleSave(data);
  };

  const getInventoryMembershipsFn = async () => {
    let result = await getInventoryMemberships();
    setMemberships(result);
  }

  const viewInventory = (e) => {
    setInventory(e);
    setShowMembershipModal(true);
    // navigate('/cx/register/' + e.guid);
  };

  const loadCartFn = () => {

    let _context = {...context};
    _context.reloadCart = true;
    _context.toast = {
      message: 'Added to cart!',
      type: 'success'
    }
    setContext({..._context});
  };

  return (
    <>
      <MDBox>
        <MDBox>
          {memberships?.length > 0 && <>
            {memberships?.sort((a, b) => new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime())
              // .filter(item => showEvent(item))
              .map((inventory, index) => {
                return (
                  <div key={index}>
                    <Grid container spacing={3}>
                      <Grid item xs={6} sm={2}>
                          <Avatar alt={inventory?.name} >
                            {inventory?.imageUrl && <img src={inventory?.imageThumbUrl || inventory?.imageUrl}
                                                         style={{width: '200%', height: 'auto'}}
                                                         alt={inventory?.name}/>}
                          </Avatar>
                      </Grid>
                      <Grid item xs={6} sm={10}>
                        <h6>{inventory.name}</h6>
                        <p><small>
                          {inventory.accountType?.name}
                        </small><br/>
                          <small>
                            {formatCurrency(inventory?.price)}
                          </small>
                        </p>
                        <p>
                          <small>
                            {inventory.desc}
                          </small>
                        </p>
                        {inventory?.accountType?.recurring &&
                          <><Chip
                            label="Recurring"
                            variant="outlined"
                            color="secondary"/>&nbsp;
                          </>
                        }
                        {inventory?.accountType?.unlimitedVisits &&
                          <><Chip
                            label="Unlimited Visits"
                            variant="outlined"
                            color="secondary"/>
                          &nbsp;
                        </>
                        }
                        <MDBox display="flex" className={'mt-2'} justifyContent="flex-end">
                          <MDButton
                            size={'small'}
                            color="secondary"
                            onClick={(e) => {
                              viewInventory(inventory);
                            }}>View Details</MDButton>
                        </MDBox>
                      </Grid>
                    </Grid>
                    <Divider/>
                  </div>
                )
              })
            }
          </>}
        </MDBox>
      </MDBox>


      {
        inventory && <ViewInventoryModal
          handleClose={handleClose}
          handleSave={handleSave}
          membershipType={true}
          show={showMembershipModal}
          inventory={inventory}
        />
      }

    </>
  )
}