import {Col, Row} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import {useContext, useEffect, useState} from "react";
import {displayDay, formatCurrency, handleDefaultValues} from "../common/Utils";
import {useForm} from 'react-hook-form';
import {useLocation, useNavigate} from "react-router-dom";
import Meta from "../common/Meta";
import {addToCart, checkout, getCartFull} from "../common/api/PortalCustomerApi";
import {Card, CardContent, Grid} from "@mui/material";
import CustomerHeader from "../common/CustomerHeader";
import CustomerPageLayout from "../Layout/CustomerPageLayout";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";

export default function CustomerViewCart(props) {
  const navigate = useNavigate();
  const [context, setContext] = useContext(AppContext);

  const location = useLocation();

  const [checkoutActive, setCheckoutActive] = useState(false);
  const [cartReady, setCartReady] = useState(false);
  const [cart, setCart] = useState();
  const [event, setEvent] = useState();
  const [events, setEvents] = useState([]);
  const [inventoryRender, setInventoryRender] = useState(1);

  useEffect(() => {
    getCartFn();
  }, []);

  const getCartFn = async () => {
    getCartFull().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setCart(result);
        setCartReady(true);
      }
    });
  }

  const [formInvalid, setFormInvalid] = useState(false);

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues()
  });

  const handleClose = () => {
    props.handleSave();
  };

  const handleSave = (data) => {
    props.handleSave(data);
  };

  const checkoutFn = async () => {
    setCheckoutActive(true);
    const resp = await checkout();
    if (resp) {
      setCheckoutActive(false);
      alert('All Done!')
    }
  };

  const loadCartFn = () => {

    let _context = {...context};
    _context.reloadCart = true;
    setContext({..._context});

  };

  const addToCartFn = async (e) => {
    const resp = await addToCart(event);
    loadCartFn();
  };

  return (
    <CustomerPageLayout>

      <CustomerHeader props={props}/>
      {cartReady &&
        <>

          <Meta pageTitle={'View Cart'}/>

          <MDBox p={3}>
            <MDBox m={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>
                  <Card>
                    <CardContent>
                      <MDTypography variant="h4" color="secondary" fontWeight="light">
                        Your Shopping Cart
                      </MDTypography>
                    </CardContent>
                  </Card>

                  <MDBox py={3}>
                    <Row>
                      <Col className={'col'} sm={12}>
                        {cart?.items
                          .sort((a, b) => a.cartItemId > b.cartItemId ? 1 : -1)
                          .map((item, index) => {
                            return (
                              <Card key={index}
                                    className={'my-2 p-0 '}>
                                <CardContent>
                                  <>
                                    <div>
                                      <h6>{item.inventoryItem.name}</h6>
                                      <p><small>{item.inventoryItem.desc}</small><br/>
                                        {item.inventoryItem?.eventType?.id && <>
                                          <small>
                                            {displayDay(item.inventoryItem.eventDate, 'hh:mm a')} - {displayDay(item.inventoryItem.eventEndDate, 'hh:mm a')}
                                          </small>
                                        </>
                                        }
                                      </p>
                                      <p>
                                        Price: {formatCurrency(item.inventoryItem.price)}
                                      </p>
                                    </div>
                                  </>
                                </CardContent>
                              </Card>
                            )
                          })
                        }
                      </Col>
                    </Row>
                  </MDBox>

                </Grid>
                <Grid item item xs={12} sm={4}>
                  <Card>
                    <CardContent>
                      <h5>Total</h5>
                      <hr/>
                      <p className={'text-right'}>{formatCurrency(cart.total)}</p>

                      <p className={'text-right'}>
                        <button
                          disabled={checkoutActive}
                          className={'btn btn-primary'}
                          onClick={() => {
                            checkoutFn();
                          }}>
                          {checkoutActive ? 'Checking' : 'Checkout'}
                        </button>
                      </p>

                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </>
      }
    </CustomerPageLayout>
  );
}