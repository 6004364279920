import {Modal} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import {useContext, useEffect, useState} from "react";
import ManageInventoryEventForm from "./ManageInventoryEventForm";
import MDBox from "../../components/MDBox";
import {getInventoryEvent} from "../common/api/InventoryApi";

export default function ManageInventoryEventModal(props) {

  const [context, setContext] = useContext(AppContext);
  const [event, setEvent] = useState();
  const [refreshEvent, setRefreshEvent] = useState(false);
  const [guid, setGuid] = useState(props.inventory?.guid);
  const [isNew, setIsNew] = useState(!!!props.inventory?.guid);
  const [inventory, setInventory] = useState();
  const [inventoryReady, setInventoryReady] = useState(false);
  const [sessionEvent, setSessionEvent] = useState(false);

  useEffect(()=> {
    setInventory(props?.inventory);
    setInventoryReady(true);
  }, [props.inventory?.parentEventGuid]);

  const getEventFn = async (guid) => {
    return await getInventoryEvent(guid);
  }

  useEffect(() => {
    if (props.inventory?.guid) {
      getEventFn(guid).then(result => {
        if (result.error) {
          setContext({...context, error: {message: result.message}});
        } else {
          let e = {...result};
          e.eventDate = new Date(e.eventDate);
          e.eventEndDate = new Date(e.eventEndDate);

          setInventory(e);
          setRefreshEvent(false);
          setIsNew(false);
          setInventoryReady(true);
        }
      });
    } else {
      setIsNew(true);
      setInventoryReady(true);
    }
  }, [props.inventory?.guid, refreshEvent]);

  const handleClose = () => {
    props.handleClose();
  };

  const displayModalTitle = (inventory ) => {
    if (inventory?.manageRecurring) {
      return 'Manage Recurring Event';
    } else if (inventory?.sessionEvent) {
      return 'Manage Session Event';
    } else {
      return 'Manage Event';
    }
  }
  return (

    <Modal onHide={handleClose} size={'xl'} show={props.show}>
        <Modal.Header closeButton>
          <Modal.Title>{isNew ? 'Create New' : displayModalTitle(props?.inventory)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <MDBox py={3}>
            <MDBox mb={3}>
              <div className={'row'}>
                <div className={'col col-12'}>
                  {inventoryReady && <ManageInventoryEventForm props={props}
                                                               manageRecurring={props?.inventory?.manageRecurring}
                                                               manageSession={props?.inventory?.sessionEvent}
                                                               handleSave={props.handleSave}
                                                               inventory={inventory}
                                                               isInventoryEvent={true}
                  />}
                </div>
              </div>
            </MDBox>
          </MDBox>

        </Modal.Body>

    </Modal>
  );
}