import {Col, Row} from "react-bootstrap";
import {AppContext} from "../../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {
  displayDay,
  displayDuration,
  formatCurrency,
  formatDate,
  formatDateTime,
  handleDefaultValues,
  inventoryAvailable
} from "../../common/Utils";
import {useForm} from 'react-hook-form';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {addToCart, getInventoryEvent, getInventoryEvents} from "../../common/api/PortalCustomerApi";
import Meta from "../../common/Meta";
import {Card, CardContent, Divider, Grid} from "@mui/material";
import {addtoWaitlist} from "../../common/api/CartApi";
import CustomerPageLayout from "../../Layout/CustomerPageLayout";
import CustomerHeader from "../../common/CustomerHeader";
import MDBox from "../../../components/MDBox";

import Footer from "../../Footer";

import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import Icon from "@mui/material/Icon";

export default function CustomerViewEvent(props) {
  const navigate = useNavigate();
  const [context, setContext] = useContext(AppContext);

  const location = useLocation();
  let {eventId} = useParams();

  const [eventReady, setEventReady] = useState(false);
  const [event, setEvent] = useState();
  const [events, setEvents] = useState([]);
  const [inventoryRender, setInventoryRender] = useState(1);

  const onAccountChange = (props) => {
  }

  useEffect(() => {
    getInventoryEventFn();
  }, [eventId]);

  useEffect(() => {
    if (!event) {
      return;
    }

    let startDate = new Date(event.eventDate);
    startDate.setHours(0, 0, 0, 0);

    let endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 1);

    let req = {
      start: startDate,
      end: endDate
    }
    getInventoryEventsFn(req)

  }, [event]);

  const getInventoryEventFn = async () => {
    getInventoryEvent(eventId).then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setEvent(result);
        setEventReady(true);
      }
    });
  }

  const [inventoryType, setInventoryType] = useState();
  const [formInvalid, setFormInvalid] = useState(false);

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(inventoryType)
  });

  const handleClose = () => {
    if (props.handleSave) {
      props.handleSave();
    }
  };

  const handleSave = (data) => {
    props.handleSave(data);
  };

  const getInventoryEventsFn = async (req) => {
    let result = await getInventoryEvents(req, inventoryRender);
    setEvents(result.events);
  }

  const viewEvent = (e) => {
    navigate('/cx/register/' + e.guid);
  };

  const loadCartFn = () => {
    let _context = {...context};
    _context.reloadCart = true;
    _context.toast = {
      message: 'Added to cart!',
      type: 'success'
    }
    setContext({..._context});
  };

  const addToCartFn = async (e) => {
    addToCart(event).then(resp => {
      handleClose();
      let _context = {...context};
      _context.viewCart = true;
      _context.reloadCart = true;
      setContext({..._context});
    });
  };

  const addToWaitlistFn = async (e) => {
    if (!context?.sessionUser?.guid) {
      let _context = {...context};
      _context.showLogin = true;
      setContext({..._context});
      return;
    }
    addtoWaitlist(event).then(resp => {
      handleClose();
      let _context = {...context};
      _context.reloadCart = true;
      _context.toast = {
        message: 'Added to Event Wait List!',
      }
      setContext({..._context});
    });
  };

  return (

    <>
      <CustomerPageLayout>

        <CustomerHeader props={props}/>

        <Meta pageTitle={(event?.eventType.name || 'Event') + ' Registration'}/>

        <MDBox p={3}>
          <MDBox m={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={8}>
                <Card>
                  <CardContent>

                    {eventReady &&
                      <MDBox py={3}>
                        <MDTypography variant="h4" color="secondary" fontWeight="light">
                          {event.eventType.name} Registration
                        </MDTypography>
                        <Row>
                          <Col>
                            <Row>
                              <Col className={'col'} sm={12}>
                                <MDTypography variant="h5" fontWeight="medium">
                                  {event.name}
                                  {event.imageUrl && <img
                                    src={event.imageUrl}
                                    className={'pull-right m-2'}/>}
                                </MDTypography>
                              </Col>
                              <Col className={'col'} sm={12} xs={12}>
                                {event?.sessionEvent && <>
                                  <h5 htmlFor="sessionEvent"><Icon>event_repeat</Icon> Session Event</h5>
                                  <p className={'fw-light'}>
                                    This Session Event is part of an event Series.
                                  </p>
                                  <h5 htmlFor="sessionEvent">Session Events</h5>
                                  {event?.sessionEvents?.length > 0 && event?.sessionEvents?.sort((a, b) => new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime())
                                    .map((event, key) => {
                                      return (<li className={'list-group-item'} key={key}>
                                        {event.name} - {formatDateTime(event.eventDate)}
                                      </li>)
                                    })
                                  }
                                </>}
                                <h5 htmlFor="name">Details</h5>
                                <p>
                                  {event.desc}
                                </p>
                              </Col>
                              <Col className={'col'} lg={3} md={6} sm={12} xs={12}>
                                <h5 htmlFor="name">Age</h5>
                                <p>
                                  {event.ageRange?.name}
                                </p>
                              </Col>
                              <Col className={'col'} lg={3} md={6} sm={12} xs={12}>
                                <h5 htmlFor="name">Price</h5>
                                <p>
                                  {formatCurrency(event.price)}
                                </p>
                              </Col>
                              <Col className={'col'} lg={3} md={6} sm={12} xs={12}>
                                <h5 htmlFor="name">Length</h5>
                                <p>
                                  {displayDuration(event)}
                                </p>
                              </Col>
                              <Col className={'col'} lg={3} md={6} sm={12} xs={12}>
                                <h5 htmlFor="name">Available</h5>
                                <p>
                                  {inventoryAvailable(event)}
                                </p>
                              </Col>
                            </Row>

                            <Row>
                              <Col className={'col'} sm={12}>
                                <h5 htmlFor="name">Date</h5>
                                <p>
                                  {formatDateTime(event.eventDate)} - {displayDay(event.eventEndDate, 'hh:mm a')}
                                </p>
                              </Col>
                            </Row>

                            <Row className={'mt-2'}>
                              <Grid container spacing={3} justifyContent="flex-end">
                                <Grid item>
                                  {inventoryAvailable(event) > 0 &&
                                    <>
                                      <MDButton variant="gradient"
                                                size={'medium'}
                                                color="info"
                                                onClick={(e) => {
                                                  addToCartFn(e);
                                                }}>Add To Cart</MDButton>
                                    </>
                                  }
                                  {inventoryAvailable(event) <= 0 &&
                                    <>
                                      <MDButton variant="gradient"
                                                size={'medium'}
                                                color="info"
                                                onClick={(e) => {
                                                  addToWaitlistFn(e);
                                                }}>Join Wait List</MDButton>
                                    </>
                                  }
                                </Grid>

                              </Grid>
                            </Row>
                          </Col>
                        </Row>
                      </MDBox>
                    }
                  </CardContent>
                </Card>
                <Divider/>
                <Grid container spacing={3} justifyContent="flex-end">
                  <Grid item>
                    {/*<LoginOrCreateAccount*/}
                    {/*  onAccountChange={onAccountChange}*/}
                    {/*/>*/}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={4}>
                {events?.length > 0 && <>
                  <Card className={'mb-2 p-0'}>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item sm={6} xs={12}>
                          <MDTypography variant="h5" color="secondary" fontWeight="light">
                            More Events
                          </MDTypography>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <MDBox width="100%" textAlign="right" lineHeight={1}>
                            <MDTypography variant="h5" color="secondary" textAlign="right" fontWeight="light">
                              {formatDate(event.eventDate)}
                            </MDTypography>
                          </MDBox>
                        </Grid>
                      </Grid>
                      <Divider/>
                      <Grid container justifyContent={'flex-end'}>
                        <Grid item>
                          <MDButton href={'/cx/events'} variant="gradient"
                                    size={'small'}
                                    color="secondary">
                            View All
                          </MDButton>
                        </Grid>
                      </Grid>


                    </CardContent>
                  </Card>

                  {events?.sort((a, b) => new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime())
                    .map((event, index) => {
                      return (
                        <Card key={index}
                              className={'mb-2 p-0'}>
                          <CardContent>
                            <>
                              <div>
                                <h6>{event.name}</h6>
                                <p><small>{event.eventType?.name}</small><br/>
                                  <small>
                                    {displayDay(event.eventDate, 'hh:mm a')} - {displayDay(event.eventEndDate, 'hh:mm a')}
                                  </small>
                                </p>
                                <Divider/>
                                {
                                  <MDBox display="flex" justifyContent="flex-end">
                                    <MDButton variant="gradient"
                                              size={'small'}
                                              color="light"
                                              onClick={(e) => {
                                                viewEvent(event);
                                              }}>View Details</MDButton>
                                  </MDBox>
                                }
                              </div>
                            </>
                          </CardContent>
                        </Card>
                      )
                    })
                  }
                </>}
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>

        <Footer/>
      </CustomerPageLayout>
    </>
  )
    ;
}