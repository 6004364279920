import {Col, Container, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {adminSearchCompanyName, adminSearchCompanySubdomain, getStates} from "../common/api/WebApi";
import {displayPhone, handleDefaultValues} from "../common/Utils";
import {useForm} from 'react-hook-form';
import MDButton from "../../components/MDButton";
import {NativeSelect} from "@mui/material";
import useDebounce from "../common/useDebounce";
import MDInput from "../../components/MDInput";

export default function ManageCompanyModal(props) {

  const [context, setContext] = useContext(AppContext);
  const [company, setCompany] = useState({...props.company});
  const [newCompany, setNewCompany] = useState(!props?.company?.guid ? true : false);
  const [states, setStates] = useState([]);
  const [formInvalid, setFormInvalid] = useState(false);
  const [formReady, setFormReady] = useState(false);
  const [searchIndex, setSearchIndex] = useState(1);

  const [companyNameRequest, setCompanyNameRequest] = useState();
  const [companyNameResponse, setCompanyNameResponse] = useState();
  const [companyNameValid, setCompanyNameValid] = useState(props?.company?.guid ? true : false);

  const [companySubdomainRequest, setCompanySubdomainRequest] = useState();
  const [companySubdomainResponse, setCompanySubdomainResponse] = useState();
  const [companySubdomainValid, setCompanySubdomainValid] = useState(props?.company?.guid ? true : false);


  useEffect(() => {
    getStates().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setStates(result);
        setFormReady(true);
      }
    });
  }, []);

  const {register, handleSubmit, setValue} = useForm({
    values: handleDefaultValues(company)
  });

  const handleClose = () => {
    props.handleClose();
  };

  const handleSaveCompany = (data) => {
    props.handleSaveCompany(data);
  };

  const searchCompanyNameFn = async (searchCriteria) => {

    let idx = searchIndex + 1;
    setSearchIndex(idx);

    let _search = {...companyNameRequest};
    _search.companyName = searchCriteria;

    let result = await adminSearchCompanyName(_search, idx);

    setCompanyNameResponse(result?.resp);
  };

  useEffect(() => {
    if (companyNameResponse) {
      setCompanyNameValid(companyNameResponse?.valid);
      setCompanyNameRequest(companyNameResponse?.companyName);
    }
  }, [companyNameResponse]);

  const debounceCompanyNameChanges = useDebounce(function (newChanges) {
    let result = searchCompanyNameFn(newChanges);
  }, 500, []); // every .5 seconds max


  const searchCompanySubdomainFn = async (searchCriteria) => {

    let idx = searchIndex + 1;
    setSearchIndex(idx);

    let _search = {...companyNameRequest};
    _search.subdomain = searchCriteria;

    let result = await adminSearchCompanySubdomain(_search, idx);

    setCompanySubdomainResponse(result?.resp);
  };

  useEffect(() => {
    if (companySubdomainResponse) {
      setCompanySubdomainValid(companySubdomainResponse?.valid);
      setCompanySubdomainRequest(companySubdomainResponse?.subdomain);
    }
  }, [companySubdomainResponse]);

  const debounceCompanySubdomainChanges = useDebounce(function (newChanges) {
    let result = searchCompanySubdomainFn(newChanges);
  }, 500, []); // every .5 seconds max

  return (
    <Modal onHide={handleClose} size={'lg'} show={props.show}>
      {formReady && <>
        <form onSubmit={handleSubmit(handleSaveCompany)} id={'companyForm'}>
          <Modal.Header closeButton>
            <Modal.Title>{newCompany ? 'Create New' : 'Manage'} Company</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col className={'col col-main'} xs={12}>
                <Container>
                  <Row>
                    <Col className={'col'} sm={6} xs={12}>
                      <div className="form-group">
                        <label htmlFor="firstName">Company Name</label>
                        <MDInput
                          variant="outlined" type="text"
                          disabled={!newCompany}
                          {...register('companyName')}
                          autoComplete="off" required
                          className="form-control" id="companyName"
                          placeholder="Enter Company Name" onChange={(e) => {
                          debounceCompanyNameChanges(e.target.value)
                        }}/>
                        {newCompany && !companyNameValid && <span className={'text-danger'}>
                          {companyNameRequest?.length && <span>Company Name in use</span>}
                            {!companyNameRequest?.length && <span>Company Name required</span>}
                          </span>
                        }
                      </div>
                    </Col>

                    <Col className={'col'} sm={6} xs={12}>
                      <div className="form-group">
                        <label htmlFor="lastName">Subdomain</label>

                        <MDInput
                          variant="outlined" type="text"
                          disabled={!newCompany}
                          {...register('subdomain')}
                          autoComplete="off" required
                          className="form-control" id="subdomain"
                          placeholder="Enter Subdomain" onChange={(e) => {
                          debounceCompanySubdomainChanges(e.target.value)
                        }}/>
                        {newCompany && !companySubdomainValid && <span className={'text-danger'}>
                          {companySubdomainRequest?.length && <span>Company Subdomain in use</span>}
                          {!companySubdomainRequest?.length && <span>Company Subdomain required</span>}
                          </span>
                        }
                      </div>
                    </Col>
                  </Row>
                  <Row className={'pt-2'}>
                    <Col className={'col'} md={6} sm={12} xs={12}>
                      <div className="form-group">
                        <label htmlFor="email">Website</label>
                        <input type="website"
                               {...register('website')}
                               className="form-control" id="website"
                               placeholder="Enter Website"/>
                      </div>
                    </Col>

                    <Col className={'col'} md={6} sm={12} xs={12}>
                      <div className="form-group">
                        <label htmlFor="primaryPhone">Primary Phone</label>
                        <input type="text" required
                               {...register('primaryPhone', {
                                 onChange: (e) => {
                                   e.target.value = displayPhone(e.target.value);
                                 }
                               })}
                               className="form-control" id="primaryPhone"
                               placeholder="Enter Phone"/>
                      </div>
                    </Col>
                  </Row>
                  <Row className={'pt-2'}>
                    <Col className={'col'} sm={6} xs={12}>
                      <div className="form-group">
                        <label htmlFor="address1">Address</label>
                        <input type="text"
                               {...register('address1')}
                               className="form-control" id="address1"
                               placeholder="Enter Address"/>
                      </div>
                    </Col>

                    <Col className={'col'} sm={6} xs={12}>
                      <div className="form-group">
                        <label htmlFor="address2">Address 2</label>
                        <input type="text"
                               {...register('address2')}
                               className="form-control" id="address2"
                               placeholder="Enter Address 2"/>
                      </div>
                    </Col>
                  </Row>
                  <Row className={'pt-2'}>
                    <Col className={'col'} sm={4} xs={12}>
                      <div className="form-group">
                        <label htmlFor="city">City</label>
                        <input type="text"
                               {...register('city')}
                               className="form-control" id="city"
                               placeholder="Enter City"/>
                      </div>
                    </Col>

                    <Col className={'col'} sm={4} xs={12}>
                      <div className="form-group">
                        <label htmlFor="state">State</label>

                        {states?.length > 0 &&
                          <NativeSelect
                            variant="outlined"
                            id={'state'} name={'state'} className={'form-control'}
                            {...register('state.stateId', {
                              valueAsNumber: true,
                              required: true
                            })}>
                            {
                              states.map((c) =>
                                <option value={c.stateId} key={c.stateId}>
                                  {c.stateName}
                                </option>)
                            }
                          </NativeSelect>
                        }

                      </div>
                    </Col>

                    <Col className={'col'} sm={4} xs={12}>
                      <div className="form-group">
                        <label htmlFor="postalCode">Zip</label>
                        <input type="text"
                               {...register('postalCode')}
                               className="form-control" id="postalCode"
                               placeholder="Enter Zip"/>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>

          </Modal.Body>
          <Modal.Footer>
            <MDButton type="submit" color="info" disabled={!companyNameValid || !companySubdomainValid || formInvalid || props.saving}>
              {props.saving ? 'Saving...' : 'Save'}
            </MDButton>
          </Modal.Footer>
        </form>
      </>
      }
    </Modal>
  );
}