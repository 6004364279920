import {getAppSessionCookieHeader} from "../appCookie";
import api from "../../Api";

export const getCustomerCartFull = async (guid) => {
  return await api.get('cart/full/' + guid + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}

export const checkout = async (cart, client) => {
  return await api.post('cart/checkout/' + client.guid + getAppSessionCookieHeader(), cart).then((response) => {
    return response.data;
  });
}

export const lookupClientSecret = async (client) => {
  let clientEndpoint = client && client.guid ? ('billing/secret/' + client.guid) : 'cx/billing/secret';
  return await api.post(clientEndpoint + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}

export const addtoWaitlist = async (inventory) => {
  return await api.post('cart/waitlist' + getAppSessionCookieHeader(), inventory).then((response) => {
    return response.data;
  });
}

export const addClientToWaitlist = async (inventory, guid) => {
  return await api.post('cart/waitlist/' + guid + getAppSessionCookieHeader(), inventory).then((response) => {
    return response.data;
  });
}

export const clientAddToCart = async (inventory, guid) => {
  if (!guid) {
    let err = {
      error: true,
      message : 'Customer not found!'
    }
    return err;
  }
  return await api.post('cart/add/' + guid + getAppSessionCookieHeader(), inventory).then((response) => {
    return response.data;
  });
}

function handleError(error) {
  let err = {
    error: true,
    message: error.response.data.message
  }
  return err;
};
