import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../lib/AppContext";

export default function UserDisplayName(props) {

  const [context, setContext] = useContext(AppContext);
  const [user, setUser] = useState();
  const [admin, setAdmin] = useState(props.admin);

  useEffect(() => {
    if (!context?.sessionUser) {
      return;
    }
    if (admin) {
      setUser(context.sessionUser?.adminUser || context.sessionUser);
    } else {
      setUser(context.sessionUser);
    }
  }, [context?.sessionUser]);

  return (
    <span>
      {user?.firstName} {user?.lastName}
    </span>
  )
}