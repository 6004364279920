import {useLocation, useNavigate} from "react-router-dom";
import {AppContext} from "../../lib/AppContext";
import {useContext, useEffect, useState} from "react";
import {getStates, saveCompany} from "../common/api/WebApi";
import {useForm} from 'react-hook-form';
import ManageCompanyModal from "./ManageCompanyModal";

export default function NewCompany(props) {

  const [context, setContext] = useContext(AppContext);

  const {
    formData
  } = useForm();

  const navigate = useNavigate();
  const location = useLocation();

  const [saving, setSaving] = useState(false);
  const [formInvalid, setFormInvalid] = useState(false);
  const [onEdit, setOnEdit] = useState(true);
  const [company, setCompany] = useState({});
  const [states, setStates] = useState([]);

  useEffect(() => {
    getStates().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setStates(result);
      }
    });
  }, []);

  const handleSaveCompany = async (company) => {

    setSaving(true);

    let result = await saveCompany(company);

    setSaving(false);

    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      if (result.guid) {
        navigate('../company/' + result.guid);
      }
    }
  }

  const handleClose = () => {
    setOnEdit(false);
    navigate('/');
  };

  return (
    <>
      <div className={'page pl-4'}>
        <div className={"page-container"}>
          <ManageCompanyModal
            company={company}
            show={onEdit}
            saving={saving}
            handleSaveCompany={handleSaveCompany}
            handleClose={handleClose}/>
        </div>
      </div>
    </>
  );
}