import {getAppSessionCookieHeader, handleSessionId} from "../appCookie";
import api from "../../Api";

export const getMenu = () => {
  return api.get('lookup/menu/' + getAppSessionCookieHeader());
}

export const getSessionUser = async (isClient, ref) => {
  const resp = await api.get('auth/user' + (isClient ? '/cx?___ref=' + ref : '') + getAppSessionCookieHeader())
    .then((response) => {
      handleSessionId(response);
      return response.data;
    }).catch(function (error) {
      return handleError(error);
    });
  return resp;
}

export const uploadCompanyLogo = async (data) => {
  const resp = await api.post('company/logo/save' + getAppSessionCookieHeader(), data).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;

}

export const uploadTemplateFile = async (data) => {
  const resp = await api.post('company/templates/file/upload' + getAppSessionCookieHeader(), data).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;

}

export const assumeIdentity = async (guid) => {
  const resp = await api.get('admin/company/employee/assume/' + guid + getAppSessionCookieHeader()).then((response) => {
    handleSessionId(response);
    return response.data;
  });
  return resp;
}

export const dropIdentity = async (guid) => {
  const resp = await api.get('admin/company/employee/drop' + getAppSessionCookieHeader()).then((response) => {
    handleSessionId(response);
    return response.data;
  });
  return resp;
}

export const login = async (auth) => {
  const resp = await api.post('auth/login' + getAppSessionCookieHeader(), auth).then((response) => {
    handleSessionId(response);
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}


export const checkPassResetExpiration = async (tokenGuid) => {
  const resp = await api.get('auth/pass/reset/expiration/' + tokenGuid + getAppSessionCookieHeader()).then((response) => {
    handleSessionId(response);
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const resetPass = async (auth) => {
  const resp = await api.post('auth/pass/reset' + getAppSessionCookieHeader(), auth).then((response) => {
    handleSessionId(response);
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const resetPassConfirm = async (auth) => {
  const resp = await api.post('auth/pass/reset/confirm' + getAppSessionCookieHeader(), auth).then((response) => {
    handleSessionId(response);
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getCustomer = async (guid) => {
  const resp = await api.get('client/' + guid + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const processRefund = async (refundItem) => {
  const resp = await api.post('client/sales/refund' + getAppSessionCookieHeader(), refundItem).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getCompany = async (guid) => {
  const resp = await api.get('admin/company/' + guid + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}


export const getMyProfile = async () => {
  const resp = await api.get('client/my/profile' + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getMyCompany = async () => {
  const resp = await api.get('company/' + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getCompanyEmployees = async (guid) => {
  const resp = await api.get('admin/company/employees/' + guid + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getMyCompanyEmployees = async () => {
  const resp = await api.get('company/employees' + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getMyCompanyEmployee = async (guid) => {
  const resp = await api.get('company/employee/' + guid + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getEmployeeTimecard = async (timecardRequest) => {
  const resp = await api.post('company/employee/timecard' + getAppSessionCookieHeader(), timecardRequest).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const saveEmployeeTimeEntry = async (timecardRequest) => {
  const resp = await api.post('company/employee/timeentry' + getAppSessionCookieHeader(), timecardRequest).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const saveCommunicationTemplate = async (template) => {
  const resp = await api.post('company/template/save' + getAppSessionCookieHeader(), template).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const sendEmail = async (email) => {
  const resp = await api.post('company/email/send' + getAppSessionCookieHeader(), email).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}


export const saveCompanyWaiver = async (waiver) => {
  const resp = await api.post('company/waiver/save' + getAppSessionCookieHeader(), waiver).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getCompanyWaiverList = async () => {
  const resp = await api.get('company/waiver/list' + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getCompanyWaiver = async (guid) => {
  const resp = await api.get('company/waiver/' + guid + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getCommunicationTemplates = async () => {
  const resp = await api.get('company/templates/list' + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getCommunicationTemplate = async (guid) => {
  const resp = await api.get('company/templates/' + guid + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const recordCustomerVisit = async (guid, reservation) => {
  const resp = await api.post('client/visit/' + guid + getAppSessionCookieHeader(), reservation)
    .then((response) => {
      return response.data;
    }).catch(function (error) {
      return handleError(error);
    });

  return resp;
}

export const getVisits = async (guid) => {
  const resp = await api.get('client/visits/' + guid + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getReservations = async (guid) => {
  const resp = await api.get('client/reservations/' + guid + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getClientNotes = async (guid) => {
  const resp = await api.get('client/notes/' + guid + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getInventoryNotes = async (guid) => {
  const resp = await api.get('inventory/notes/' + guid + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getFamilyMemberTypes = async () => {
  const resp = await api.get('lookup/familyMemberTypes' + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;

}

export const getStates = async () => {
  const resp = await api.get('lookup/states' + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const searchUsername = async (searchParams, searchIndex) => {
  const resp = await api.post('company/username' + getAppSessionCookieHeader(), searchParams).then((response) => {
    let _resp = {searchIndex: searchIndex, resp: response.data}
    return _resp;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const adminSearchCompanyName = async (searchParams, searchIndex) => {
  const resp = await api.post('admin/company/name' + getAppSessionCookieHeader(), searchParams).then((response) => {
    let _resp = {searchIndex: searchIndex, resp: response.data}
    return _resp;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const adminSearchCompanySubdomain = async (searchParams, searchIndex) => {
  const resp = await api.post('admin/company/subdomain' + getAppSessionCookieHeader(), searchParams).then((response) => {
    let _resp = {searchIndex: searchIndex, resp: response.data}
    return _resp;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const searchContext = async (searchParams, searchIndex) => {
  const resp = await api.post('client/context' + getAppSessionCookieHeader(), searchParams).then((response) => {
    let _resp = {searchIndex: searchIndex, items: response.data}
    return _resp;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const searchCustomers = async (searchParams, searchIndex) => {
  const resp = await api.post('client/search' + getAppSessionCookieHeader(), searchParams).then((response) => {
    let _resp = {searchIndex: searchIndex, customers: response.data}
    return _resp;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const searchCompanies = async (searchParams) => {
  const resp = await api.post('admin/company/search' + getAppSessionCookieHeader(), searchParams).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}


export const removeClientTag = async (tag) => {
  return await api.post('client/tag/remove' + getAppSessionCookieHeader(), tag).then((response) => {
    return response.data;
  });
}

export const saveClientTag = async (tag) => {
  return await api.post('client/tag' + getAppSessionCookieHeader(), tag).then((response) => {
    return response.data;
  });
}

export const saveCustomer = async (customer) => {
  const resp = await api.post('client/save' + getAppSessionCookieHeader(), customer).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const saveClientBilling = async (clientBilling) => {
  const resp = await api.post('client/billing' + getAppSessionCookieHeader(), clientBilling).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const saveClientAccount = async (account) => {
  const resp = await api.post('client/account' + getAppSessionCookieHeader(), account).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}


export const saveChargeInvoice = async (invoice) => {
  const resp = await api.post('client/invoice' + getAppSessionCookieHeader(), invoice).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const saveClientNote = async (note) => {
  const resp = await api.post('client/note' + getAppSessionCookieHeader(), note).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const saveEventNote = async (note) => {
  const resp = await api.post('inventory/note' + getAppSessionCookieHeader(), note).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const saveClientReservation = async (client, reservation) => {
  const resp = await api.post('client/reservation/' + client.guid + getAppSessionCookieHeader(), reservation).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const cancelClientReservation = async (reservation) => {
  const resp = await api.post('client/reservation/cancel' + getAppSessionCookieHeader(), reservation).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const cancelClientWaitlist = async (reservation) => {
  const resp = await api.post('client/waitlist/cancel' + getAppSessionCookieHeader(), reservation).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}


export const savePrimaryClientBilling = async (client, billingGuid) => {
  const resp = await api.post('client/billing/primary/' + billingGuid + getAppSessionCookieHeader(), client)
    .then((response) => {
      return response.data;
    }).catch(function (error) {
      return handleError(error);
    });

  return resp;
}

export const deleteCustomerBilling = async (client, billing) => {
  return await api.post('client/billing/delete/' + client.guid + '/' + billing.guid);
}

export const deleteCustomerFamily = async (client, familyMember) => {
  return await api.post('client/family/delete/' + client.guid + '/' + familyMember.guid);
}
export const deleteCustomer = async (client) => {
  return await api.post('client/delete/' + client.guid);
}

export const restoreCustomer = async (client) => {
  return await api.post('client/restore/' + client.guid);
}

export const saveMyCompany = async (company) => {
  const resp = await api.post('company/save' + getAppSessionCookieHeader(), company).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const saveMyCompanyTemplates = async (template) => {
  const resp = await api.post('company/templates/save' + getAppSessionCookieHeader(), template).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const saveMyCompanyStripeSettings = async (company) => {
  const resp = await api.post('company/stripe/save' + getAppSessionCookieHeader(), company).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const saveCompany = async (company) => {
  const resp = await api.post('admin/company/save' + getAppSessionCookieHeader(), company).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getCompanyTasks = async (taskDate) => {
  const resp = await api.get('company/tasks/' + taskDate + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });


  return resp;
}

export const completeTask = async (task) => {
  const resp = await api.post('company/task/complete' + getAppSessionCookieHeader(), task).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });


  return resp;
}

export const saveEmployee = async (employee) => {
  const resp = await api.post('company/employee/save' + getAppSessionCookieHeader(), employee).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const logout = async () => {
  const resp = await api.post('auth/logout' + getAppSessionCookieHeader()).then((response) => {
    return getSessionUser();
  });
  return resp;
}

export const removeCartItem = async (cartItem) => {
  return await api.post('cart/remove' + getAppSessionCookieHeader(), cartItem).then((response) => {
    return response.data;
  });
}

export const logoutCustomer = async () => {
  const resp = await api.post('auth/logout' + getAppSessionCookieHeader()).then((response) => {
    return response;
  });
  return resp;
}

function handleError(error) {
  let err = {
    error: true,
    message: error?.response?.data?.message || JSON.stringify(error)
  }
  return err;
}
