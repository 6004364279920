import {Col, Container, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import React, {forwardRef, useContext, useEffect, useState} from "react";
import {checkFormValidity, formatCreditCard, formatExpiration, handleDefaultValues} from "../common/Utils";
import {useForm} from 'react-hook-form';
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import ReactDatePicker from "react-datepicker";
import {InputAdornment} from "@mui/material";

export default function ManageAccountModal(props) {

  const [context, setContext] = useContext(AppContext);
  const [account, setAccount] = useState(props?.account);
  const [billingEndDate, setBillingEndDate] = useState(props?.account?.billingEndDate);
  const [numberVisits, setNumberVisits] = useState(props?.account?.numberVisits);

  useEffect(() => {
    if (props?.account) {
      setAccount(props?.account);
      setBillingEndDate(props?.account?.billingEndDate);
    }
  }, [props?.account]);

  const [formInvalid, setFormInvalid] = useState(false);

  useEffect(()=> {
    let _account = {...account};
    _account.numberVisits = numberVisits;
    setAccount(_account);
  }, [numberVisits]);

  useEffect(()=> {
    let _account = {...account};
    _account.billingEndDate = billingEndDate;
    setAccount(_account);
  }, [billingEndDate]);

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(account)
  });

  const handleClose = () => {
    props.handleClose();
  };


  const handleSave = (data) => {
    props.handleSave(data);
  };

  return (
    <Modal onHide={handleClose} size={'lg'} show={props.show}>
      <form
        onChange={(e) => setFormInvalid(!!!checkFormValidity(e))}
        onSubmit={handleSubmit(handleSave)} id={'memberForm'}>
        <Modal.Header closeButton>
          <Modal.Title>Manage Member Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className={'col col-main'} xs={12}>
              {account && <Container>
                <Row>
                  <Col className={'col'} sm={6} xs={12}>
                    <MDTypography variant={'h6'} className={'mb-2'}>{account?.accountType?.name}&nbsp;</MDTypography>
                  </Col>
                </Row>
                <Row>
                  <Col className={'col'} sm={6} xs={12}>
                    <div className="form-group">
                      <label htmlFor="billingEndDate">Number Visits</label>
                      <div className={'input-group d-block'}>
                        <MDInput type="text" step="1" required
                                 onChange={(e) => {
                                   setNumberVisits(e.target.value);
                                 }}
                                 InputProps={{
                                   readOnly: true,
                                   startAdornment: <InputAdornment position="start"
                                                                   className={'cursor-pointer'}
                                                                   onClick={(e) => {
                                                                     let _q = numberVisits - 1;
                                                                     if (_q < 0) {
                                                                       _q = 0;
                                                                     }
                                                                     setNumberVisits(_q);
                                                                   }}>
                                     <span className={'fa fa-minus'}></span>
                                   </InputAdornment>,
                                   endAdornment: <InputAdornment position="end"
                                                                 className={'cursor-pointer'}
                                                                 onClick={(e) => {
                                                                   let _q = numberVisits;
                                                                   if (_q + 1 <= 999) {
                                                                     setNumberVisits(_q + 1);
                                                                   }
                                                                 }}>
                                     <span className={'fa fa-plus'}></span>
                                   </InputAdornment>,
                                 }}
                                 disabled={account?.unlimitedVisits}
                                 value={numberVisits || 0}
                                 autoComplete="off"
                                 className="form-control pull-left" id="quantity"
                                 style={{width: '125px'}}/>
                      </div>
                    </div>
                  </Col>
                  <Col className={'col'} sm={6} xs={6}>
                    <div className="form-group">
                      <label htmlFor="billingEndDate">Billing End Date</label>
                      <div className={'input-group d-block modal-picker'}>
                        <ReactDatePicker
                          popperProps={{strategy: 'fixed'}}
                          popperPlacement="top-start"
                          disabled={!account?.recurring}
                          minDate={new Date()}
                          className="form-control p-2"
                          selected={billingEndDate} onChange={(date) => setBillingEndDate(date)}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>}
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <MDButton type="submit" color="info" disabled={formInvalid || props.saving}>
            {props.saving ? 'Saving...' : 'Save'}
          </MDButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
}