import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../lib/AppContext";
import Dashboard from "./Dashboard";
import AdminDashboard from "./AdminDashboard";
import {CONSTANTS} from "../app/common/constants";

export default function DashboardSwitch(props) {

  const [context, setContext] = useContext(AppContext);


  return (
  <>
    {context?.sessionUser?.userTypeId === CONSTANTS.UTYPE_ADMIN && <AdminDashboard />}
    {context?.sessionUser?.userTypeId === CONSTANTS.UTYPE_CLIENT && <Dashboard />}
  </>
  );
}