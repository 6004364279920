import {Col, Container, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import {useContext, useState} from "react";
import {handleDefaultValues} from "../common/Utils";
import {useForm} from 'react-hook-form';
import MDButton from "../../components/MDButton";

export default function ClientNoteModal(props) {

  const [context, setContext] = useContext(AppContext);

  const [clientNote, setClientNote] = useState(props.clientNote);

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(clientNote)
  });

  const handleCloseNote = () => {
    props.handleCloseNote();
  };


  const handleSaveClientNote = (data) => {
    props.handleSaveClientNote(data);
  };

  return (
    <Modal onHide={handleCloseNote} size={'lg'} show={props.show}>
      <form
        onSubmit={handleSubmit(handleSaveClientNote)} id={'memberForm'}>
        <Modal.Header closeButton>
          <Modal.Title>{clientNote?.guid ? 'Edit' : 'Add'} Client Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className={'col col-main'} xs={12}>
              <Container>
                <Row>
                  <Col className={'col'} sm={12} xs={12}>
                    <div className="form-group">
                      <textarea required
                                rows={15}
                                {...register('note')}
                                className="form-control" id="note"
                                placeholder="Enter Client Note"/>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <MDButton type="submit" color="info" disabled={props.saving}>
            {props.saving ? 'Saving...' : 'Save'}
          </MDButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
}