import {Row} from "react-bootstrap";
import {useContext, useEffect} from "react";
import {AppContext} from "../lib/AppContext";
import {useNavigate} from "react-router-dom";
import {logout} from "./common/api/WebApi";
import DashboardLayout from "./LayoutContainers/DashboardLayout";
import MDBox from "../components/MDBox";
import LoginForm from "./common/LoginForm";
import {Card, CardContent} from "@mui/material";
import Footer from "./Footer";

export default function LoginPage(props) {
  const [context, setContext] = useContext(AppContext);
  const navigate = useNavigate();

  const logoutFn = async () => {
    let path = '/';
    let resp = await logout();
    if (resp) {
      // window.location.href = path;
    }
  }

  useEffect(() => {
    logoutFn();
  }, []);

  return (
    <>
      <DashboardLayout>
        <MDBox py={3}>
          <MDBox mb={3}>
            <Row>
              <Card>
                <CardContent>
                  <LoginForm client={true}
                  />
                </CardContent>
              </Card>
            </Row>
          </MDBox>
        </MDBox>

        <Footer mt={5}/>

      </DashboardLayout>
    </>
  );
}