import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {AppContext} from "../../lib/AppContext";

import React, {useContext, useEffect, useRef, useState} from "react";
import {
  cancelClientReservation,
  deleteCustomer,
  deleteCustomerBilling,
  deleteCustomerFamily,
  getClientNotes,
  getCustomer,
  getReservations,
  getVisits,
  recordCustomerVisit,
  removeCartItem,
  removeClientTag,
  restoreCustomer,
  saveChargeInvoice,
  saveClientAccount,
  saveClientBilling,
  saveClientNote,
  saveClientReservation,
  saveClientTag,
  saveCustomer,
  savePrimaryClientBilling
} from "../common/api/WebApi";
import {
  colorize,
  customerInitials,
  customerQrCode,
  displayPhone,
  formatAge,
  formatDate,
  formatDateTime,
  getDateNoTz
} from "../common/Utils";
import Badge from '@mui/material/Badge';
import {Card, CardContent, Chip, FormControl, InputAdornment, InputLabel, OutlinedInput} from "@mui/material";
import CustomerSaleHistory from "./graph/CustomerSaleHistory";
import ManageClientModal from "./ManageClientModal";
import QRCode from "react-qr-code";
import ManageFamilyMemberModal from "./ManageFamilyMemberModal";
import Confirm from "../common/Confirm";
import ManageClientBillingModal from "./ManageClientBillingModal";
import ClientNoteModal from "./ClientNoteModal";
import Meta from "../common/Meta";
import ContextSearchNavbar from "../navbars/ContextSearchNavbar";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Divider from "@mui/material/Divider";
import MDButton from "../../components/MDButton";
import Icon from "@mui/material/Icon";
import Footer from "../Footer";
import NotificationItem from "../Items/NotificationItem";
import Menu from "@mui/material/Menu";
import {CONSTANTS} from "../common/constants";
import ClientLookupEventsModal from "./ClientLookupEventsModal";
import {clientAddToCart, getCustomerCartFull} from "../common/api/CartApi";
import ClientViewCustomerCartModal from "../common/ClientViewCustomerCartModal";
import {saveCustomerFamily} from "../common/api/CustomerProfileApi";
import Avatar from "@mui/material/Avatar";
import ClientLookupMembershipsModal from "./ClientLookupMembershipsModal";
import ManageAccountModal from "./ManageAccountModal";
import PageLoading from "../common/PageLoading";
import {useForm} from "react-hook-form";
import ProductSearchNavbar from "../navbars/ProductSearchNavbar";

export default function Client(props) {

  const [context, setContext] = useContext(AppContext);
  const myRefs = useRef([]);

  const refHandler = (index) => {
    myRefs.current[index].visible = !!!myRefs.current[index].visible;
    setRenderIndex(renderIndex + 1);
  };

  const navigate = useNavigate();
  const location = useLocation();
  let {guid} = useParams();

  useEffect(() => {
    if (!guid) {
      setClient({});
      setNewCustomer(true);
      setOnEdit(true);
    }
  }, [guid]);


  const [refreshMember, setRefreshMember] = useState(false);
  const [saving, setSaving] = useState(false);
  const [formInvalid, setFormInvalid] = useState(false);
  const [onEdit, setOnEdit] = useState(guid == null);
  const [newCustomer, setNewCustomer] = useState(guid == null);
  const [client, setClient] = useState(null);
  const [clientCart, setClientCart] = useState(null);
  const [refreshCart, setRefreshCart] = useState(false);
  const [refreshVisits, setRefreshVisits] = useState(false);

  const [clientNotes, setClientNotes] = useState(null);
  const [familyMember, setFamilyMember] = useState(null);
  const [memberAccount, setMemberAccount] = useState(null);
  const [memberBilling, setMemberBilling] = useState(null);
  const [manageMemberBilling, setManageMemberBilling] = useState(false);
  const [addNote, setAddNote] = useState(false);
  const [clientNote, setClientNote] = useState({});
  const [manageClientNote, setManageClientNote] = useState(null);
  const [noteCount, setNoteCount] = useState(0);
  const [searchNotes, setSearchNotes] = useState(null);

  const [states, setStates] = useState([]);
  const [sysuserVisits, setSysuservisits] = useState([]);
  const [reservations, setReservations] = useState();
  const [recordingVisit, setRecordingVisit] = useState(false);
  const [lookupEvents, setLookupEvents] = useState(false);
  const [lookupMemberships, setLookupMemberships] = useState(false);

  const [manageFamilyMember, setManageFamilyMember] = useState(false);
  const [manageActiveInvoice, setManageActiveInvoice] = useState(false);
  const [activeInvoice, setActiveInvoice] = useState();

  const [confirmDeleteMember, setConfirmDeleteMember] = useState(false);
  const [confirmDeleteFamilyMember, setConfirmDeleteFamilyMember] = useState(false);
  const [confirmDeleteInvoice, setConfirmDeleteInvoice] = useState(false);
  const [confirmChargeInvoice, setConfirmChargeInvoice] = useState(false);

  const [confirmDeleteBilling, setConfirmDeleteBilling] = useState(false);
  const [confirmation, setConfirmation] = useState(null);
  const [manageClientBilling, setManageClientBilling] = useState(null);
  const [manageMemberAccount, setManageMemberAccount] = useState(false);
  const [clientBilling, setClientBilling] = useState(null);
  const [renderIndex, setRenderIndex] = useState(1);
  const [modalActive, setModalActive] = useState(false);
  const [refreshReservations, setRefreshReservations] = useState(false);
  const [viewingCart, setViewingCart] = useState(false);
  const [searchingInventory, setSearchingInventory] = useState(false);
  const [clientDisabled, setClientDisabled] = useState(false);

  const [tagName, setTagName] = useState('');
  const [tags, setTags] = useState([]);
  const [showTags, setShowTags] = useState(false);
  const toggleTagsFn = () => {
    setShowTags(!showTags);
  }

  const handleTagChange = (e) => {
    setTagName(e.target.value);
  }

  const removeTagFn = async (tag, index) => {
    removeClientTag(tag).then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setTags(tags.filter((t, i) => i !== index))
      }
    });
  }
  const saveTagFn = async () => {
    let tag = {
      name: tagName,
      entityType: CONSTANTS.ENTITY_TYPE.CUSTOMER,
      entityId: client?.id,
    }
    saveClientTag(tag).then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setTagName('');
        setTags([...tags, result]);
      }
    });
  }

  useEffect(() => {
    if (manageFamilyMember || manageClientBilling || manageClientNote || addNote) {
      setModalActive(true);
    } else {
      setModalActive(false);
    }
  }, [manageFamilyMember, manageClientBilling, manageClientNote, addNote]);

  const handleHideDropdown = (event) => {
    if (event.keyCode === 27) {
      for (let ref in myRefs.current) {
        if (!myRefs.current[ref]) {
          return;
        }
        myRefs.current[ref].visible = false;
      }
      setRenderIndex(renderIndex + 1);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleHideDropdown, true);
    return () => {
      document.removeEventListener("keydown", handleHideDropdown, true);
    };
  });

  const toggleLookupEventsFn = () => {
    setLookupEvents(!lookupEvents);
  }

  const toggleCartFn = () => {
    setViewingCart(!viewingCart);
  }

  useEffect(() => {
    if (guid) {
      getCustomerFn().then(result => {
        if (result.error) {
          setContext({...context, error: {message: result.message}});
        } else {
          let c = {...result};
          setClient({...c});
          setTags(c?.tags);
          setClientDisabled(c.statusId === CONSTANTS.USER_STATUS.INACTIVE);
          setRefreshMember(false);
          setRefreshCart(true);
        }
      });
    }
  }, [guid, refreshMember]);

  useEffect(() => {
    if (!refreshVisits) {
      return;
    }
    setRefreshVisits(false);
    getCustomVisitsFn();
  }, [refreshVisits]);

  useEffect(() => {
    if (!refreshCart) {
      return;
    }
    if (!guid) {
      return;
    }
    getCustomerCartFull(guid).then(result => {
      setClientCart(result);
      setRefreshCart(false);
    });
  }, [refreshCart]);

  useEffect(() => {
    if (!viewingCart) {
      setRefreshCart(true);
    }
  }, [viewingCart]);

  useEffect(() => {
    if (context?.reloadCart) {
      setRefreshCart(true);
    }
  }, [context?.reloadCart]);

  useEffect(() => {
    if (client?.guid) {
      setRefreshReservations(true);
    }
  }, [client?.guid]);

  useEffect(() => {
    if (!refreshReservations) {
      return;
    }
    setRefreshReservations(false);

    setRefreshVisits(true);
    getReservations(guid).then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setReservations(result);
      }
    });
  }, [refreshReservations]);

  useEffect(() => {
    if (!client?.guid) {
      return;
    }
    getClientNotesFn().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setClientNotes(result);
      }
    });
  }, [client?.guid, noteCount]);

  const getCustomerFn = async () => {
    return await getCustomer(guid);
  }

  const getCustomVisitsFn = async () => {
    getVisits(client?.guid).then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setSysuservisits(result);
      }
    });
  }

  const getClientNotesFn = async () => {
    return await getClientNotes(guid);
  }

  const viewCustomerCart = () => {
    setViewingCart(true);
  }

  const searchInventory = () => {
    setSearchingInventory(true);
  }

  const addNoteFn = () => {
    setAddNote(true);
    setClientNote(null);
  };

  const manageNoteFn = (note) => {
    setClientNote(note);
    setManageClientNote(true);
  };

  const handleSaveCustomer = async (customer) => {

    setSaving(true);

    let result = await saveCustomer(customer);

    setSaving(false);

    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setRefreshMember(true);
      setOnEdit(false);
      setNewCustomer(false);

      if (result.guid && guid !== result.guid) {
        navigate('/client/' + result.guid);
      }
    }
  }

  const handleSaveFamilyMember = async (member) => {

    setSaving(true);
    member.parentGuid = client.guid;

    let result = await saveCustomerFamily(member);
    setFamilyMember(null);
    setSaving(false);

    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setRefreshMember(true);
      setManageFamilyMember(false);
    }
  }

  const handleSaveClientBilling = async (clientBilling) => {

    setSaving(true);
    clientBilling.memberGuid = client.guid;

    let result = await saveClientBilling(clientBilling);
    setSaving(false);

    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setRefreshMember(true);
      setManageClientBilling(false);
    }
  }

  const handleSaveMemberAccount = async (account) => {

    setSaving(true);

    let result = await saveClientAccount(account);
    setSaving(false);

    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setRefreshMember(true);
      setManageMemberAccount(false);
    }
  }

  const handleCheckout = (response) => {
    setRefreshReservations(true);
    setRefreshCart(true);
    toggleCartFn();
  };

  const handleSaveEvent = async (event) => {
    let _reservation = {
      eventName: event.name,
      eventGuid: event.guid,
    };

    saveClientReservation(client, _reservation).then(result => {
      setRefreshReservations(true);
      setRefreshCart(true);
      toggleLookupEventsFn();
      viewCustomerCart();
    });
  }

  const handleSaveMembership = async (event) => {
    toggleLookupMembershipsFn();
    setRefreshCart(true);
    viewCustomerCart();
  }

  const handleSaveClientNote = async (note) => {

    setSaving(true);
    note.clientGuid = client.guid;

    let result = await saveClientNote(note);
    setSaving(false);
    setAddNote(false);
    setManageClientNote(false);

    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setNoteCount(noteCount + 1);
    }
  }

  const handleCloseNote = () => {
    setFamilyMember(null);
    setManageClientNote(false);
    setAddNote(false);
  }

  const handleClose = () => {
    setOnEdit(false);
    if (newCustomer) {
      if (window.history.length === 1) {
        navigate('/');
      } else {
        window.history.back();
      }
    }
  };

  const handleCloseFamilyMember = () => {
    setFamilyMember(null);
    setManageFamilyMember(false);
  }

  const handleCloseClientBilling = () => {
    setManageClientBilling(false);
  }

  const handleCloseManageAccount = () => {
    setManageMemberAccount(false);
  }

  const handleCancelFamilyMember = () => {
    setConfirmation(null);
    setConfirmDeleteFamilyMember(false);
  }
  const handleCancelMember = () => {
    setConfirmation(null);
    setConfirmDeleteMember(false);
  }

  const handleCancelDeleteBilling = () => {
    setConfirmation(null);
    setConfirmDeleteBilling(false);
  }

  const handleCancelDeleteInvoice = () => {
    setConfirmation(null);
    setConfirmDeleteInvoice(false);
  }

  const handleCancelChargeInvoice = () => {
    setConfirmation(null);
    setConfirmChargeInvoice(false);
  }

  const makeCardPrimaryFn = async (billing) => {
    setSaving(true);
    let result = await savePrimaryClientBilling(client, billing.guid);
    setSaving(false);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setRefreshMember(true);
    }
  };

  const handleConfirmFamilyMember = async (familyMember) => {
    setSaving(true);
    setConfirmDeleteFamilyMember(false);
    let result = await deleteCustomerFamily(client, familyMember);
    setSaving(false);
    setConfirmation(null);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setRefreshMember(true);
      setConfirmDeleteFamilyMember(false);
    }
  }

  const handleConfirmMember = async () => {
    setSaving(true);
    setConfirmDeleteFamilyMember(false);
    let result = clientDisabled ? await restoreCustomer(client) : await deleteCustomer(client);
    setSaving(false);
    setConfirmation(null);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setRefreshMember(true);
      setConfirmDeleteMember(false);
    }
  }

  const recordVisitFn = async (event, reservation) => {

    reservation.saving = true;

    setReservations([...reservations]);

    let result = await recordCustomerVisit(client.guid, reservation);

    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setRefreshReservations(true);
      setRefreshMember(true);
    }
  };

  const toggleLookupMembershipsFn = () => {
    setLookupMemberships(!lookupMemberships);
  }

  const addMembershipFn = () => {
    toggleLookupMembershipsFn();
  };

  const manageFamilyMemberFn = (member) => {
    let m = null;
    if (member) {
      m = {...member};
      if (member.dob) {
        m.dob = new Date(getDateNoTz(member.dob));
      } else {
        m.dob = null;
      }
    }
    setFamilyMember(m);
    setManageFamilyMember(true);
  };

  const manageMemberAccountFn = (account) => {
    setMemberAccount(account);
    setManageMemberAccount(true)
  };

  const chargeActiveInvoice = (invoice) => {
    let m = null;
    setActiveInvoice(invoice);
    let confirmation = {
      title: 'Change Active Invoice?',
      message: 'Confirm Charge Invoice',
      entity: invoice
    }
    setConfirmation(confirmation);
    setConfirmChargeInvoice(true);
  };

  const handleChargeInvoice = async (invoice) => {
    setSaving(true);
    setConfirmChargeInvoice(false);
    let result = await saveChargeInvoice(invoice);
    setSaving(false);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setRefreshMember(true);
      setConfirmChargeInvoice(false);
    }
  }

  const preDeleteFamilyMemberFn = (member) => {
    let confirmation = {
      title: 'Delete ' + member.firstName + '?',
      message: 'Delete ' + member.firstName + ' ' + member.lastName + ' Permanently?',
      entity: member
    }
    setConfirmation(confirmation);
    setConfirmDeleteFamilyMember(true);
  };

  const preDeleteInvoiceFn = (invoice) => {
    let confirmation = {
      title: 'Archive Invoice?',
      message: 'Archive ' + invoice.inventoryItem.name + '?',
      entity: invoice
    }
    setConfirmation(confirmation);
    setConfirmDeleteInvoice(true);
  };

  const preDeleteBillingFn = (card) => {
    let confirmation = {
      title: 'Delete Card?',
      message: 'Delete Card Permanently?',
      entity: card
    }
    setConfirmation(confirmation);
    setConfirmDeleteBilling(true);
  };

  const handleConfirmDeleteBilling = async (billing) => {
    setSaving(true);
    setConfirmDeleteBilling(false);
    let result = await deleteCustomerBilling(client, billing);
    setSaving(false);
    setConfirmation(null);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setRefreshMember(true);
      setConfirmDeleteBilling(false);
    }
  }

  const handleConfirmDeleteInvoice = async (billing) => {
    setSaving(true);
    setConfirmDeleteInvoice(false);

    let result = await deleteCustomerBilling(client, billing);
    setSaving(false);
    setConfirmation(null);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setRefreshMember(true);
      setConfirmDeleteInvoice(false);
    }
  }

  const toggleEntityActions = (entity, show, hide) => {
    if (show) {
      entity.showActions = true;
    } else if (hide) {
      entity.showActions = false;
    } else {
      entity.showActions = !!!entity.showActions;
    }
    setRenderIndex(renderIndex + 1);
  }

  const highlight = (haystack) => {
    const needle = searchNotes;
    if (!needle) {
      return haystack;
    }
    var querystr = needle;
    var result = haystack;
    var reg = new RegExp(querystr.split(' ').join('|'), 'gi');
    var final_str = result.replace(reg, function (str) {
      return '<b>' + str + '</b>'
    });

    return final_str;

  }

  const matchNotes = (note) => {
    let notes = searchNotes.split(' ');
    let match = true;
    notes.forEach(n => {
      if (!note.note.toLowerCase().includes(n.toLowerCase())) {
        match = false;
      }
    });
    return match;
  }

  const undeleteClient = () => {
    let confirmation = {
      title: 'Restore Client?',
      message: 'Restore Client',
      entity: client
    }
    setConfirmation(confirmation);
    setConfirmDeleteMember(true);
  }

  const confirmDeleteClient = () => {
    let confirmation = {
      title: 'Archive Client?',
      message: 'Confirm Archive Client?',
      entity: client
    }
    setConfirmation(confirmation);
    setConfirmDeleteMember(true);
  }

  const cancelReservationFn = async (reservation) => {
    reservation.cancelling = true;
    cancelClientReservation(reservation).then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        reservation.cancelling = false;
        setRefreshReservations(true);
      }
    });
  }

  const sendToCartFn = (item) => {
    let _item = {...item};
    _item.quantity = 1;
    clientAddToCart(_item, client?.guid).then(resp => {
      let _context = {...context};
      _context.toast = {
        message: 'Added to cart',
      }
      setContext({..._context});
      setRefreshCart(true);
    });
  }

  const [showConfirm, setShowConfirm] = useState(false);

  const preDeleteInventoryItem = (item) => {
    let confirmation = {
      title: 'Delete?',
      message: 'Remove ' + item.inventoryItem.name + ' from cart?',
      entity: item
    }
    setConfirmation(confirmation);
    setShowConfirm(true);
  };

  const deleteInventoryItem = async (item) => {
    removeCartItem(item).then((result) => {
      let _context = {...context};
      _context.reloadCart = true;
      setContext({..._context});
      handleCancelCartRemove();
      setRefreshCart(true);
    });
  };

  const handleCancelCartRemove = () => {
    setConfirmation(null);
    setShowConfirm(false);
  }

  const {register, handleSubmit, setValue} = useForm({});

  return (
    <>

      {newCustomer && <Meta pageTitle={'New Client'}/>}
      {!newCustomer && client && <Meta
        pageTitle={'View Customer - ' + client?.firstName + ' ' + client?.lastName + (clientDisabled ? ' (Disabled)' : '')}/>}

      <DashboardLayout>

        <ContextSearchNavbar title={'View Customer'}/>

        <MDBox py={3}>
          <MDBox mb={3}>
            {(!client?.guid && !newCustomer) && <>
              <PageLoading/>
            </>}

            {client?.guid && <>
              <Row>
                <Col sm={12}>
                  <Row>
                    <Col sm={6} xs={12}>
                      <Card>
                        <CardContent style={{position: 'relative'}}>

                          <h3 className={'mb-2'}>
                            <Badge className={'mr-2'}>
                              <Avatar alt={client?.email} style={{
                                backgroundColor: colorize(client?.email),
                              }}>
                                {customerInitials(client)}
                              </Avatar>
                              &nbsp;
                            </Badge>
                            {client.firstName} {client.lastName}
                            <div className={'pull-right'} ref={el => (myRefs.current['clientActions'] = el)}
                                 onClick={(e) => {
                                   refHandler('clientActions')
                                 }}>

                              <MDButton
                                variant="outlined"
                                color="secondary"
                                size="small"
                                circular
                                iconOnly
                              >
                                <Icon>more_horiz</Icon>
                              </MDButton>

                              {renderIndex && myRefs.current['clientActions']?.visible &&
                                <Menu open={true} anchorReference={null} anchorEl={myRefs.current['clientActions']}
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left",
                                      }}>
                                  <NotificationItem
                                    disabled={clientDisabled}
                                    onClick={(e) => {
                                      setOnEdit(true);
                                    }} icon={<Icon>edit</Icon>} title="Edit"/>

                                  <NotificationItem
                                    disabled={clientDisabled}
                                    onClick={(e) => {
                                      confirmDeleteClient()
                                    }} icon={<Icon>delete</Icon>} title="Archive"/>
                                  {clientDisabled && <NotificationItem onClick={(e) => {
                                    undeleteClient()
                                  }} icon={<Icon>restore</Icon>} title="Restore"/>}
                                </Menu>
                              }
                            </div>
                          </h3>
                          <h6>
                            {client.email && <>
                              {client.emailEnabled &&
                                <Link to={'/email/client/' + client.guid + '?address=' + client.email}
                                      className={'ml-2'}>
                                  {client.email}
                                </Link>
                              }
                              {!client.emailEnabled &&
                                <>
                                  <OverlayTrigger
                                    placement={'right'}
                                    delay={{show: 250, hide: 400}}
                                    overlay={
                                      <Tooltip>
                                        Email disabled
                                      </Tooltip>
                                    }>
                                    <span>{client.email}</span>
                                  </OverlayTrigger>
                                </>
                              }
                            </>
                            }
                          </h6>
                          <Divider/>
                          <Col sm={12}>
                            <div
                              style={{
                                height: "auto", margin: "0 auto",
                                padding: '0px 10px 0 0',
                                float: 'right',
                                maxWidth: 62, width: "100%"
                              }}>
                              <QRCode
                                size={256}
                                style={{height: "auto", maxWidth: "100%", width: "100%"}}
                                value={customerQrCode(client)}
                                viewBox={'0 0 256 256'}/>
                            </div>
                            Member since: {formatDate(client.createDate)}
                          </Col>
                          <p>
                            {client.address1} {client.address2}
                            {(client?.address1?.length > 0 || client?.address2?.length > 0) && <br/>}
                            {client.city}{client?.city && client?.state ? ', ' : ''}
                            {client.state.stateName} {client.zipCode}
                          </p>
                          {client.primaryPhone &&
                            <p>{displayPhone(client.primaryPhone)}</p>
                          }

                          <Divider/>

                          <Row className={'mb-4'}>
                            <Col sm={12}>
                              <MDTypography variant="h6" fontWeight="bold">Tags
                                <Chip
                                  label={!showTags ? 'Add Tag' : 'Close'}
                                  icon={!showTags ? <Icon>add_circle</Icon> : <Icon>cancel</Icon>}
                                  color="primary"
                                  className="pull-right"
                                  size="small"
                                  onClick={() => {
                                    toggleTagsFn()
                                  }}
                                /></MDTypography>
                              {showTags && <form onSubmit={handleSubmit(saveTagFn)}>
                                <FormControl variant="outlined" p={0}
                                             className="form-inline d-block mt-2 mb-2">
                                  <InputLabel htmlFor="tag-input">Tag</InputLabel>
                                  <OutlinedInput
                                    autoFocus
                                    autoComplete={'off'}
                                    id="tag-input"
                                    type={'text'}
                                    value={tagName}
                                    onChange={handleTagChange}

                                    endAdornment={
                                      <InputAdornment position="end">
                                        <MDButton
                                          color="primary"
                                          size="small"
                                          disabled={!tagName}
                                          onClick={handleSubmit(saveTagFn)}
                                          className={'btn btn-primary'}
                                        >Save</MDButton>
                                      </InputAdornment>
                                    }
                                    label="Tag"
                                  />
                                </FormControl>
                              </form>
                              }
                              {tags?.length === 0 && <ul className={'list-group'}>
                                <li className={'list-group-item'}>
                                  <center>No customer tags</center>
                                </li>
                              </ul>
                              }
                              {tags?.length > 0 && tags.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1).map((tag, index) => {
                                return (
                                  <span key={index}>
                           <Chip
                             label={tag.name}
                             onDelete={() => {
                               removeTagFn(tag, index);
                             }}
                             color="primary"
                             variant="outlined"
                           />&nbsp;
                         </span>
                                )
                              })
                              }
                            </Col>
                          </Row>

                          <div>
                            <MDTypography variant="h6" fontWeight="bold">
                              Billing Information
                            </MDTypography>
                            <Row>
                              <Col sm={12}>

                                <ul className={'list-group'}>
                                  {client?.clientBilling?.length === 0 && <li className={'list-group-item'}>
                                    <center>No billing info</center>
                                  </li>
                                  }
                                  {client?.clientBilling
                                    .sort((a, b) => a.id > b.id ? 1 : -1)
                                    .map((billing, index) => {
                                      return (<li className={'list-group-item'}
                                                  style={{
                                                    'position': 'relative',
                                                  }}
                                                  key={index}>
                                    <span
                                      className={'mx-2 fa fa-credit-card'}></span>
                                          {billing.billingName} {billing.cardNumber} ({billing.ccExpy})

                                          <div className={'pull-right px-2'}
                                               onClick={(e) => {
                                                 refHandler('clientBilling' + index)
                                               }}
                                               ref={el => (myRefs.current['clientBilling' + index] = el)}>

                                     <span
                                       className={'fa pull-right cursor-pointer p-1 mx-n1'}>
                                       <Icon>more_horiz</Icon>
                                     </span>
                                            {renderIndex && myRefs.current['clientBilling' + index]?.visible &&
                                              <Menu open={true} anchorReference={null}
                                                    anchorEl={myRefs.current['clientBilling' + index]}
                                                    anchorOrigin={{
                                                      vertical: "bottom",
                                                      horizontal: "left",
                                                    }}>

                                                <NotificationItem onClick={(e) => {
                                                  e.preventDefault();
                                                  preDeleteBillingFn(billing);
                                                }} icon={<Icon>delete</Icon>}
                                                                  title="Delete"/>
                                              </Menu>
                                            }
                                          </div>
                                          {billing.primaryCard && <>
                                            <br/><span
                                            className={'badge badge-primary bg-light-info text-black-50 fs--2 mr-2'}>
                                          Primary
                                      </span>
                                          </>}
                                        </li>
                                      );
                                    })}
                                </ul>
                              </Col>
                            </Row>
                          </div>

                        </CardContent>
                      </Card>


                      <Card className={'mt-4'}>
                        <CardContent>
                          <MDButton
                            color="secondary"
                            size="small"
                            className={'pull-right'}
                            disabled={recordingVisit || clientDisabled}
                            onClick={toggleLookupEventsFn}>
                            {!recordingVisit ? <><span className={'fa fa-calendar'}></span>&nbsp;Find</>
                              : <><span className={'fa fa-spinner fa-spin'}></span>&nbsp;Recording</>}
                          </MDButton>
                          <MDTypography variant="h5" fontWeight="regular">
                            Upcoming Reservations
                          </MDTypography>
                          <Divider/>
                          <ul className={'list-group'}>
                            {reservations?.length === 0 && <li className={'list-group-item'}>
                              <center>No upcoming reservations</center>
                            </li>
                            }
                            {reservations?.length > 0 && reservations?.sort((a, b) => new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime()).map((reservation, index) => {
                                return (
                                  <li
                                    className={'list-group-item'}
                                    key={index}>
                                    {reservation?.cancelling && <span className={'fa fa-spinner fa-spin'}></span>}
                                    <Link to={'/event/view/' + reservation.eventGuid}>
                                      {reservation.eventName}
                                    </Link>

                                    <div className={'pull-right'}
                                         ref={el => (myRefs.current['reservationsActions-' + index] = el)}
                                         onClick={(e) => {
                                           refHandler('reservationsActions-' + index)
                                         }}>

                                      <MDButton
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        circular
                                        iconOnly
                                      >
                                        <Icon>more_horiz</Icon>
                                      </MDButton>
                                      {renderIndex && myRefs.current['reservationsActions-' + index]?.visible &&
                                        <Menu open={true} anchorReference={null}
                                              anchorEl={myRefs.current['reservationsActions-' + index]}
                                              anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "left",
                                              }}>
                                          <NotificationItem
                                            disabled={reservation.cancelling}
                                            onClick={(e) => {
                                              cancelReservationFn(reservation)
                                            }} icon={<Icon>cancel</Icon>} title="Cancel Reservation"/>
                                        </Menu>
                                      }
                                    </div>

                                    <br/>

                                    <p style={{
                                      'clear': 'both',
                                      'display': 'block',
                                      'marginTop': '10px'
                                    }}>

                                      <MDButton
                                        color="info"
                                        size="small"
                                        className="pull-right"
                                        disabled={recordingVisit || reservation?.reservationStatus?.id === CONSTANTS.RESERVATION_STATUS.CHECKED_IN || clientDisabled}
                                        onClick={(e) => {
                                          recordVisitFn(e, reservation);
                                        }}>
                                        {!reservation.saving ? <><span
                                            className={'fa fa-check'}></span>&nbsp;Check{reservation?.reservationStatus?.id === CONSTANTS.RESERVATION_STATUS.CHECKED_IN ? 'ed' : ''}</>
                                          : <><span className={'fa fa-spinner fa-spin'}></span>&nbsp;Checking</>}&nbsp;In
                                      </MDButton>

                                      <span
                                        className={'badge badge-primary bg-light-info text-black-50 fs--2'}>{reservation?.reservationStatus?.name}</span>
                                      <small> {formatDateTime(reservation.eventDate)}</small>
                                    </p>

                                  </li>
                                )
                              }
                            )}
                          </ul>
                        </CardContent>
                      </Card>

                      {client?.completedWaivers && client?.completedWaivers.length > 0 &&
                        <>
                          <Card className={'mt-4'}>
                            <CardContent>
                              <MDTypography variant="h5" fontWeight="regular">
                                Signed Waivers
                              </MDTypography>
                              <Divider/>
                              <ul className={'list-group'}>
                                {client?.completedWaivers?.sort((a, b) => a.id - b.id).map((item, index) => {
                                    return (
                                      <li
                                        className={'list-group-item'}
                                        key={index}>
                                        {item.waiverName} - Completed on: {formatDate(item.waiverDate)}
                                        <br/> <Chip
                                        label={'Version: ' + item.version}
                                        variant="outlined"
                                        color="secondary"/>
                                      </li>
                                    )
                                  }
                                )}
                              </ul>
                            </CardContent>
                          </Card>
                        </>
                      }

                      <Card className={'mt-4'}>
                        <CardContent>
                          <MDTypography variant="h5" fontWeight="regular">
                            Visit History
                          </MDTypography>
                          <Divider/>
                          <ul className={'list-group'}>
                            {sysuserVisits?.length === 0 && <li className={'list-group-item'}>
                              <center>No recent visits</center>
                            </li>
                            }
                            {sysuserVisits.sort((a, b) => new Date(b.visitDate).getTime() - new Date(a.visitDate).getTime()).map((visit, index) => {
                                return (
                                  <li
                                    className={'list-group-item'}
                                    key={index}>
                                    <span
                                      className={'pull-right'}>
                                      <Chip
                                        label={visit?.accountType?.name || 'General'}
                                        variant="outlined"
                                        color="secondary"/>
                                    </span>
                                    {visit?.event?.inventory?.name || 'General Visit'}
                                    <br/>
                                    <small>{formatDateTime(visit.visitDate)}</small>

                                  </li>
                                )
                              }
                            )}
                          </ul>
                        </CardContent>
                      </Card>

                    </Col>
                    <Col sm={6} xs={12} className={'pt-4 py-md-0'}>

                      <Card className={'mb-4'}>
                        <CardContent>

                          <MDButton
                            color="secondary"
                            size="small"
                            sx={{
                              marginLeft: '5px'
                            }}
                            className={'pull-right'}
                            disabled={clientDisabled}
                            onClick={searchInventory}>
                            <span className={'fa fa-search'}></span>&nbsp;Search Inventory
                          </MDButton>

                          <MDButton
                            color="secondary"
                            size="small"
                            className={'pull-right mr-2'}
                            disabled={recordingVisit || clientDisabled || !(clientCart?.items?.length > 0)}
                            onClick={viewCustomerCart}>
                            <span className={'fa fa-shopping-cart'}></span>&nbsp;View
                          </MDButton>
                          <MDTypography variant="h5" fontWeight="regular">
                            Customer Cart
                          </MDTypography>
                          <Divider/>
                          <ul className={'list-group'}>
                            {clientCart?.items?.length === 0 && <li className={'list-group-item'}>
                              <center>No cart items</center>
                            </li>
                            }
                            {clientCart?.items?.sort((a, b) => a.cartItemId - b.cartItemId).map((item, index) => {
                                return (
                                  <li
                                    className={'list-group-item'}
                                    key={index}>
                                    <Avatar alt={item.inventoryItem?.name}
                                            className={'pull-left mr-3'}
                                            sx={{width: 32, height: 32}}>
                                      <img src={item.inventoryItem?.imageThumbUrl || item.inventoryItem?.imageUrl}
                                           style={{width: '200%', height: 'auto'}}
                                           alt={item.inventoryItem?.name}/>
                                    </Avatar>
                                    &nbsp;{item.inventoryItem.name}
                                    <MDButton
                                      color="info"
                                      size="small"
                                      className="pull-right"
                                      onClick={(e) => {
                                      preDeleteInventoryItem(item)
                                    }}>
                                      <span className={'fa fa-times'}></span>&nbsp;Remove
                                    </MDButton>
                                    <br/>
                                    <span
                                      className={'badge badge-primary bg-light-info text-black-50 fs--2'}>{item?.reservationStatus?.name}</span>

                                    {item.inventoryItem?.eventDate && <small>
                                      {formatDateTime(item.inventoryItem.eventDate, 'hh:mm a')} - {formatDateTime(item.inventoryItem.eventEndDate, 'hh:mm a')}
                                    </small>
                                    }
                                  </li>
                                )
                              }
                            )}
                          </ul>
                        </CardContent>
                      </Card>

                      {client?.activeInvoices && client?.activeInvoices.length > 0 &&
                        <>
                          <Card className={'my-4'}>
                            <CardContent>
                              <MDTypography variant="h5" fontWeight="regular">
                                Open Invoices
                              </MDTypography>
                              <Divider/>
                              <ul className={'list-group'}>
                                {client?.activeInvoices?.sort((a, b) => a.id - b.id).map((item, index) => {
                                    return (
                                      <li
                                        className={'list-group-item'}
                                        key={index}>
                                        <Avatar alt={item.inventoryItem?.name}
                                                className={'pull-left mr-3'}
                                                sx={{width: 32, height: 32}}>
                                          {(item.inventoryItem?.imageThumbUrl || item.inventoryItem?.imageUrl) ?
                                            <img src={item.inventoryItem?.imageThumbUrl || item.inventoryItem?.imageUrl}
                                                 style={{width: '200%', height: 'auto'}}
                                                 alt={item.inventoryItem?.name}/> : <Icon>event</Icon>
                                          }
                                        </Avatar>
                                        <div className={'pull-right px-2'}
                                             onClick={(e) => {
                                               refHandler('activeInvoice' + index)
                                             }}
                                             ref={el => (myRefs.current['activeInvoice' + index] = el)}>

                                     <span
                                       className={'fa pull-right cursor-pointer p-1 mx-n1'}>
                                       <Icon>more_horiz</Icon>
                                     </span>

                                          {renderIndex && myRefs.current['activeInvoice' + index]?.visible &&
                                            <Menu open={true} anchorReference={null}
                                                  anchorEl={myRefs.current['activeInvoice' + index]}
                                                  anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                  }}>
                                              <NotificationItem
                                                onClick={(e) => {
                                                  chargeActiveInvoice(item)
                                                }} icon={<Icon>credit_card</Icon>} title="Charge Now"/>
                                              <NotificationItem onClick={(e) => {
                                                preDeleteInvoiceFn(item)
                                              }} icon={<Icon>delete</Icon>} title="Archive"/>
                                            </Menu>
                                          }
                                        </div>

                                        &nbsp;{item.inventoryItem.name} - Due: {formatDate(item.dueDate)}
                                        <br/>
                                        <span
                                          className={'badge badge-primary bg-light-info text-black-50 fs--2'}>{item?.reservationStatus?.name}</span>

                                        {item.inventoryItem?.eventDate && <small>
                                          {formatDateTime(item.inventoryItem.eventDate, 'hh:mm a')} - {formatDateTime(item.inventoryItem.eventEndDate, 'hh:mm a')}
                                        </small>
                                        }
                                      </li>
                                    )
                                  }
                                )}
                              </ul>
                            </CardContent>
                          </Card>
                        </>
                      }

                      <Card>
                        <CardContent>
                          <MDButton
                            color="secondary"
                            size="small"
                            disabled={clientDisabled}
                            className={'pull-right'}
                            onClick={(e) => {
                              e.preventDefault();
                              manageFamilyMemberFn(null)
                            }}>
                            <span className={'fa fa-user-plus'}></span>&nbsp;Add
                          </MDButton>

                          <MDTypography variant="h5" fontWeight="regular">
                            Family Members
                          </MDTypography>
                          <Divider/>
                          <Row>
                            <Col sm={12}>
                              <ul className={'list-group'}>
                                {client?.familyMembers?.length === 0 && <li className={'list-group-item'}>
                                  <center>No family members</center>
                                </li>
                                }
                                {renderIndex && client?.familyMembers
                                  .sort((a, b) => a.id > b.id ? 1 : -1)
                                  .map((familyMember, index) => {
                                    return (<li className={'list-group-item'}
                                                style={{
                                                  'position': 'relative',
                                                }}
                                                key={index}>
                                  <span className={'cursor-pointer'} onClick={(e) => {
                                    manageFamilyMemberFn(familyMember)
                                  }}>{familyMember.firstName} {familyMember.lastName}
                                    <span className={'font-smaller'}>
                                      {familyMember?.dob && ' (' + formatAge(familyMember) + ')'}
                                    </span>
                                  </span>

                                      <div className={'pull-right px-2'}
                                           onClick={(e) => {
                                             refHandler('familyMember' + index)
                                           }}
                                           ref={el => (myRefs.current['familyMember' + index] = el)}>

                                     <span
                                       className={'fa pull-right cursor-pointer p-1 mx-n1'}>
                                       <Icon>more_horiz</Icon>
                                     </span>

                                        {renderIndex && myRefs.current['familyMember' + index]?.visible &&
                                          <Menu open={true} anchorReference={null}
                                                anchorEl={myRefs.current['familyMember' + index]}
                                                anchorOrigin={{
                                                  vertical: "bottom",
                                                  horizontal: "left",
                                                }}>
                                            <NotificationItem
                                              disabled={clientDisabled}
                                              onClick={(e) => {
                                                manageFamilyMemberFn(familyMember)
                                              }} icon={<Icon>edit</Icon>} title="Edit"/>
                                            <NotificationItem
                                              disabled={clientDisabled}
                                              onClick={(e) => {
                                                preDeleteFamilyMemberFn(familyMember)
                                              }} icon={<Icon>delete</Icon>} title="Delete"/>
                                          </Menu>
                                        }
                                      </div>
                                      <br/>
                                      <Chip
                                        color="secondary"
                                        variant="outlined"
                                        label={familyMember.familyMemberType.name}/>&nbsp;
                                      {familyMember.emergencyContact &&
                                        <Chip
                                          color="secondary"
                                          variant="outlined"
                                          label={'Emergency Contact' + displayPhone(familyMember.phoneNumber)}/>
                                      }
                                    </li>);
                                  })}
                              </ul>
                            </Col>
                          </Row>
                        </CardContent>
                      </Card>

                      <Card className={'mt-4'}>
                        <CardContent>
                          <MDButton
                            color="secondary"
                            size="small"
                            disabled={clientDisabled}
                            className={'pull-right'}
                            onClick={(e) => {
                              addMembershipFn()
                            }}>
                            <span className={'fa fa-user-plus'}></span>&nbsp;Add Membership
                          </MDButton>

                          <MDTypography variant="h5" fontWeight="regular">
                            Memberships
                          </MDTypography>
                          <Divider/>
                          <Row>
                            <Col sm={12}>
                              <ul className={'list-group'}>
                                {client?.accounts?.length === 0 && <li className={'list-group-item'}>
                                  <center>No memberships</center>
                                </li>
                                }
                                {renderIndex && client?.accounts
                                  .sort((a, b) => a.accountId > b.accountId ? 1 : -1)
                                  .map((account, index) => {
                                    return (<li className={'list-group-item'}
                                                style={{
                                                  'position': 'relative',
                                                }}
                                                key={index}>
                                  <span>{account.accountType?.name}
                                    <div className={'pull-right px-2'}
                                         onClick={(e) => {
                                           refHandler('memberAccount' + index)
                                         }}
                                         ref={el => (myRefs.current['memberAccount' + index] = el)}>

                                     <span
                                       className={'fa pull-right cursor-pointer p-1 mx-n1'}>
                                       <Icon>more_horiz</Icon>
                                     </span>

                                      {renderIndex && myRefs.current['memberAccount' + index]?.visible &&
                                        <Menu open={true} anchorReference={null}
                                              anchorEl={myRefs.current['memberAccount' + index]}
                                              anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "left",
                                              }}>
                                          <NotificationItem
                                            onClick={(e) => {
                                              manageMemberAccountFn(account)
                                            }} icon={<Icon>edit</Icon>} title="Edit"/>
                                          <NotificationItem onClick={(e) => {
                                            preDeleteFamilyMemberFn(account)
                                          }} icon={<Icon>delete</Icon>} title="Delete"/>
                                        </Menu>
                                      }
                                      </div>
                                    <br/>
                                    {account.billingEndDate && <><Chip
                                      color="secondary"
                                      variant="outlined"
                                      label={'Billing End Date: ' + (formatDate(account.billingEndDate))}
                                    />&nbsp;</>}
                                    <Chip
                                      color="secondary"
                                      variant="outlined"
                                      label={'Created on: ' + (formatDate(account.createDate))}
                                    />&nbsp;
                                    <Chip
                                      color="secondary"
                                      variant="outlined"
                                      label={'Remaining Visits: ' + (account?.accountType?.unlimitedVisits ? 'Unlimited' : account?.numberVisits)}
                                    />&nbsp;
                                    {account?.recurring && <Chip
                                      color="secondary"
                                      variant="outlined"
                                      label={'Recurring'}
                                    />
                                    }
                                  </span>
                                    </li>);
                                  })}
                              </ul>
                            </Col>
                          </Row>
                        </CardContent>
                      </Card>

                      <Card className={'mt-4'}>
                        <CardContent>
                          <CustomerSaleHistory
                            customer={client}
                            modalActive={() => {
                            setModalActive(true);
                          }}
                                               modalInactive={() => {
                                                 setModalActive(false);
                                               }}
                          />
                        </CardContent>
                      </Card>

                    </Col>
                  </Row>

                  <Card className={'mt-4'}>
                    <CardContent>
                      <MDTypography variant="h5" fontWeight="regular">
                        Customer Notes
                        <MDButton
                          color="secondary"
                          size="small"
                          disabled={clientDisabled}
                          className={'pull-right'}
                          onClick={addNoteFn}>
                          <span className={'fa fa-plus'}></span>&nbsp;Add note
                        </MDButton>
                        <input type={'text'} className={'form-control form-control-sm my-n1 mx-2 pull-right w-25'}
                               placeholder={'Search Notes'}
                               onFocus={(e) => {
                                 setModalActive(true);
                               }}
                               onBlur={(e) => {
                                 setModalActive(false);
                               }}
                               onChange={(e) => {
                                 setSearchNotes(e.target.value);
                               }}/>
                      </MDTypography>
                      <Divider/>
                      <Row style={{
                        'clear': 'both',
                        'maxHeight': '500px',
                        'overflow': 'auto'
                      }}>
                        <Col sm={12}>

                          <ul className={'list-group'}>
                            {clientNotes?.length === 0 && <li className={'list-group-item'}>
                              <center>No customer notes</center>
                            </li>
                            }

                            {clientNotes?.map((note, index) => {
                                return (
                                  <li
                                    className={'list-group-item hover-bg-soft ' + (searchNotes && !matchNotes(note) ? 'd-none' : '')}
                                    key={index}>
                                    <p>
                                    <span
                                      dangerouslySetInnerHTML={{__html: highlight(note.note)}}/>
                                    </p>

                                    <small>By: {note.createUser.firstName + ' ' + note.createUser.lastName} On: {formatDateTime(note.createDate)}</small>
                                    <Chip
                                      className={'pull-right'}
                                      label="Edit"
                                      variant="outlined"
                                      onClick={(e) => {
                                        manageNoteFn(note);
                                      }}/>
                                  </li>
                                )
                              }
                            )}
                          </ul>

                        </Col>
                      </Row>
                    </CardContent>
                  </Card>

                </Col>

              </Row>
            </>
            }

            {
              newCustomer && <>
                <ManageClientModal
                  customer={null}
                  show={onEdit}
                  saving={saving}
                  handleSaveCustomer={handleSaveCustomer}
                  handleClose={handleClose}/>
              </>
            }

            {
              client?.guid && <>
                <ManageClientModal
                  customer={newCustomer ? null : client}
                  show={onEdit}
                  saving={saving}
                  handleSaveCustomer={handleSaveCustomer}
                  handleClose={handleClose}/>


                {manageFamilyMember && <ManageFamilyMemberModal
                  familyMember={familyMember}
                  show={manageFamilyMember}
                  saving={saving}
                  handleSaveFamilyMember={handleSaveFamilyMember}
                  handleCloseFamilyMember={handleCloseFamilyMember}/>
                }
              </>
            }
            {
              confirmation && <Confirm confirmation={confirmation}
                                       show={confirmDeleteFamilyMember}
                                       saving={saving}
                                       handleConfirm={handleConfirmFamilyMember}
                                       handleClose={handleCancelFamilyMember}/>
            }
            {
              confirmation && <Confirm confirmation={confirmation}
                                       show={confirmDeleteMember}
                                       saving={saving}
                                       handleConfirm={handleConfirmMember}
                                       handleClose={handleCancelMember}/>
            }
            {
              confirmation && <Confirm confirmation={confirmation}
                                       show={confirmDeleteBilling}
                                       saving={saving}
                                       handleConfirm={handleConfirmDeleteBilling}
                                       handleClose={handleCancelDeleteBilling}/>
            }

            {
              confirmation && <Confirm confirmation={confirmation}
                                       show={confirmDeleteInvoice}
                                       saving={saving}
                                       handleConfirm={handleConfirmDeleteInvoice}
                                       handleClose={handleCancelDeleteInvoice}/>
            }

            {
              confirmation && <Confirm confirmation={confirmation}
                                       show={confirmChargeInvoice}
                                       saving={saving}
                                       handleConfirm={handleChargeInvoice}
                                       handleClose={handleCancelChargeInvoice}/>
            }

            {
              manageClientBilling && <ManageClientBillingModal
                clientBilling={clientBilling}
                show={manageClientBilling}
                saving={saving}
                handleSaveClientBilling={handleSaveClientBilling}
                handleCloseClientBilling={handleCloseClientBilling}/>
            }

            {
              memberAccount && <ManageAccountModal
                account={memberAccount}
                billingEndDate={memberAccount?.billingEndDate}
                show={manageMemberAccount}
                saving={saving}
                handleSave={handleSaveMemberAccount}
                handleClose={handleCloseManageAccount}/>
            }

            {
              (addNote || manageClientNote) && <ClientNoteModal
                clientNote={clientNote}
                show={manageClientNote || addNote}
                saving={saving}
                handleCloseNote={handleCloseNote}
                handleSaveClientNote={handleSaveClientNote}/>
            }

            {
              lookupEvents && <ClientLookupEventsModal
                show={true}
                client={client}
                saving={saving}
                handleClose={toggleLookupEventsFn}
                handleSave={handleSaveEvent}/>
            }

            {
              lookupMemberships && <ClientLookupMembershipsModal
                show={true}
                client={client}
                saving={saving}
                handleClose={toggleLookupMembershipsFn}
                handleSave={handleSaveMembership}/>
            }

            {
              client && <ClientViewCustomerCartModal
                show={viewingCart}
                handleCartRefresh={()=>{
                  setRefreshCart(true);
                }}
                client={client}
                saving={saving}
                handleClose={toggleCartFn}
                handleSave={handleCheckout}/>
            }

            {client && <ProductSearchNavbar
              client={client}
              open={searchingInventory}
              handleClose={()=> {
                setSearchingInventory(false);
              }}
              handleSave={()=> {
                setRefreshCart(true);
              }}
            /> }

            {
              confirmation && <Confirm confirmation={confirmation}
                                       show={showConfirm}
                                       saving={saving}
                                       handleSave={()=> {
                                         setShowConfirm(false);
                                       }}
                                       handleConfirm={deleteInventoryItem}
                                       handleClose={handleCancelCartRemove}/>
            }
          </MDBox>
        </MDBox>

        <Footer mt={5}/>

      </DashboardLayout>
    </>
  )
}