import {useNavigate, useParams} from "react-router-dom";
import {AppContext} from "../../lib/AppContext";
import {useContext, useEffect, useState} from "react";
import {setAppGuidCookie} from "../common/appCookie";
import {Card, CardContent} from "@mui/material";
import MDBox from "../../components/MDBox";
import CustomerHeader from "../common/CustomerHeader";
import Footer from "../Footer";
import CustomerPageLayout from "../Layout/CustomerPageLayout";
import Grid from "@mui/material/Grid";
import MDTypography from "../../components/MDTypography";
import Divider from "@mui/material/Divider";
import EventsMiniPane from "./pane/EventsMiniPane";
import InventoryMiniPane from "./pane/InventoryMiniPane";
import CompanyCard from "./pane/CompanyCard";

export default function CustomerDashboard(props) {
  const [context, setContext] = useContext(AppContext);
  const [company, setCompany] = useState();

  const navigate = useNavigate();
  let {guid} = useParams();

  useEffect(() => {
    if (guid) {
      setAppGuidCookie(guid);
    }
  }, [guid]);

  useEffect(() => {
    if (context.sessionUser?.companyGk) {
      setPageReady(true);
      setCompany(context.sessionUser.company);
    }
  }, [context?.sessionUser]);

  const [pageReady, setPageReady] = useState(false);


  return (
    <>
      <CustomerPageLayout>

        <CustomerHeader props={props}/>

        {pageReady && <MDBox p={3}>
          <MDBox m={3}>
            <Grid container cols={3} spacing={3} direction="row">
              <Grid item sm={3} xs={12}>
                <CompanyCard/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card>
                  <CardContent>
                    <MDTypography variant="h5" color="secondary" fontWeight="light">
                      Today's Events
                    </MDTypography>
                    <EventsMiniPane />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item sm={3} xs={12}>
                <Card>
                  <CardContent>
                    <MDTypography variant="h5" color="secondary" fontWeight="light">
                      Inventory
                      <Divider/>
                      <InventoryMiniPane />
                    </MDTypography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        }

        <Footer/>
      </CustomerPageLayout>
    </>
  )
}