import {Helmet} from "react-helmet";
import {AppContext} from "../../lib/AppContext";
import {useContext} from "react";

export default function Meta(props) {

  const [context, setContext] = useContext(AppContext);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>Play Space Brain - {props?.pageTitle || ''}</title>
        <link rel="canonical" href="http://mysite.com/example"/>
      </Helmet>
      {/*<MDTypography fontWeight="light" variant="h2">{props?.pageTitle}</MDTypography>*/}
    </>
  );

}