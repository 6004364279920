import {useNavigate} from "react-router-dom";
import {useContext} from "react";

import Meta from "../common/Meta";
import {AppContext} from "../../lib/AppContext";
import ContextSearchNavbar from "../navbars/ContextSearchNavbar";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import ListCustomersResults from "./ListCustomersResults";
import Footer from "../Footer";

export default function ListCustomers(props) {

  const [context, setContext] = useContext(AppContext);
  const navigate = useNavigate();

  const handleViewCustomer = (row) => {
    navigate('/client/' + row.guid);
  }

  return (

    <DashboardLayout>

      <Meta pageTitle={'List Customers'}/>

      <ContextSearchNavbar title="List Customers"/>

      <MDBox py={3}>
        <MDBox mb={3}>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
               <ListCustomersResults action={handleViewCustomer}/>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>

      <Footer mt={5}/>

    </DashboardLayout>
  );
}