import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";
import {Card} from "@mui/material";
import Meta from "./common/Meta";
import ContextSearchNavbar from "./navbars/ContextSearchNavbar";

import DashboardLayout from "./LayoutContainers/DashboardLayout";
import MDBox from "../components/MDBox";
import MDTypography from "../components/MDTypography";
import Footer from "./Footer";
import {AppContext} from "../lib/AppContext";

export default function AdminDashboard(props) {

  const navigate = useNavigate();

  const [context, setContext] = useContext(AppContext);

  return (
    <DashboardLayout>

      <Meta pageTitle={'Dashboard'}/>

      <ContextSearchNavbar title="Dashboard"/>

      <MDBox py={3}>

        <MDBox mb={3}>
          <Card>
            <MDBox p={2}>
              <MDBox display={'block'} alignItems={'left'}>
                <MDTypography variant={'h6'}>Admin Dashboard</MDTypography>
                <MDTypography variant="p" fontWeight="light">
                  Keep an eye on this space for more info coming soon.
                </MDTypography>
              </MDBox>
            </MDBox>
          </Card>
        </MDBox>

      </MDBox>
      <Footer/>
    </DashboardLayout>
  );
}