import {Col, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import {useContext, useState} from "react";
import ClientInventoryPanel from "./ClientInventoryPanel";

export default function ClientLookupMembershipsModal(props) {

  const [context, setContext] = useContext(AppContext);

  const handleClose = () => {
    props.handleClose();
  };

  const handleMembershipSave = (event) => {
    props.handleSave(event);
  }
  const rotate = {
    'transform': 'rotate(270deg)'
  };

  return (
    <Modal onHide={handleClose} size={'lg'} show={true}>
      <Modal.Header closeButton>
        <Modal.Title>Memberships
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <ClientInventoryPanel
            client={props.client}
            onSave={handleMembershipSave}
          />
        </Row>
      </Modal.Body>
    </Modal>
  );
}