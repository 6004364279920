import {Card, CardContent} from "@mui/material";
import MDBox from "../../../components/MDBox";
import Divider from "@mui/material/Divider";
import MDTypography from "../../../components/MDTypography";
import Icon from "@mui/material/Icon";
import React, {useContext, useState} from "react";
import {AppContext} from "../../../lib/AppContext";
import {useNavigate} from "react-router-dom";

export default function CompanyCard(props) {
  const [context, setContext] = useContext(AppContext);
  const [company, setCompany] = useState(context?.sessionUser?.company);
  const navigate = useNavigate();

  return (
    <Card>
      {company && <CardContent>

        <MDBox display="flex" justifyContent="center">
          {company.logoUrl && <img
            onClick={() => {
              navigate('/cx/')
            }}
            src={(company.logoUrl)}
            className={'img-thumbnail m-2 ' + props.className} style={{width: '90%'}}/>}
        </MDBox>

        <Divider/>
        <MDTypography variant="h5" color="secondary" fontWeight="light">
          {company?.companyName}
        </MDTypography>
        <MDTypography variant="p" color="secondary" fontWeight="light">
          {company?.defaultLocation}
          <br/>
          <Icon>phone</Icon> {company?.contactPhone}
        </MDTypography>

        <Divider/>
        <MDTypography variant="h5" color="secondary" fontWeight="light">
          About
        </MDTypography>

        <MDTypography variant="p" color="primary" fontWeight="regular">
          {company?.aboutUs && <>
          <span
            dangerouslySetInnerHTML={{__html: company?.aboutUs}}/>
          </>}
          <pre></pre>
        </MDTypography>
      </CardContent>}
    </Card>
  )
}