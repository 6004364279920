import {Col, Container, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {formatCurrency, formatDate, formatDateTime, handleDefaultValues} from "../common/Utils";
import {useForm} from 'react-hook-form';
import MDButton from "../../components/MDButton";
import MDInput from "../../components/MDInput";
import MDTypography from "../../components/MDTypography";
import {InputAdornment} from "@mui/material";
import {processRefund} from "../common/api/WebApi";

export default function ViewSaleItemModal(props) {

  const [context, setContext] = useContext(AppContext);
  const [saleItem, setSaleItem] = useState(props.saleItem || {});
  const [startRefund, setStartRefund] = useState(false);
  const [formInvalid, setFormInvalid] = useState(false);
  const [refundInvalid, setRefundInvalid] = useState(false);
  const [saving, setSaving] = useState(false);
  const [maxRefund, setMaxRefund] = useState(0);

  useEffect(() => {
    setRefundInvalid(parseFloat(saleItem?.refundAmount) > maxRefund);
  }, [saleItem?.refundAmount]);

  useEffect(() => {
    let _item = {...props?.saleItem};
    let _total = props?.saleItem?.refundBalance;
    _item.refundAmount = _total;
    setSaleItem(_item);
    setMaxRefund(props?.saleItem?.refundBalance);

  }, [props?.saleItem]);

  const [isNew, setIsNew] = useState(!!!props.inventoryType?.guid);

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(saleItem)
  });

  const handleClose = () => {
    props.handleClose();
  };

  const handleSave = async (data) => {
    setSaving(true);
    let _d = {...data};
    _d.refundAmount = parseFloat(_d.refundAmount);
    let promise = processRefund(_d);
    promise.then((resp) => {
      setSaving(false);
      props.handleSave(data);
    });
  };


  return (
    <Modal onHide={handleClose} size={'lg'} show={props.show}>
      <form onSubmit={handleSubmit(handleSave)} id={'salesItemForm'}>
        <Modal.Header closeButton>
          <Modal.Title>View Sale</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className={'col col-main'} xs={12}>
              {saleItem && <Container>
                <dl className={'row'}>
                  <dd className={'col'} sm={3} xs={12}>
                    <MDTypography variant={'h6'} className={'mb-2'}>
                      Sale Item Name
                    </MDTypography>
                  </dd>
                  <dt className={'col'} sm={9} xs={12}>
                    <MDTypography variant={'h6'} fontWeight={'regular'} className={'mb-2'}>
                      {saleItem?.itemName}
                    </MDTypography>
                  </dt>
                </dl>
                <dl className={'row'}>
                  <dd className={'col'} sm={3} xs={12}>
                    <MDTypography variant={'h6'} className={'mb-2'}>
                      Purchase Date
                    </MDTypography>
                  </dd>
                  <dt className={'col'} sm={9} xs={12}>
                    <MDTypography variant={'h6'} fontWeight={'regular'} className={'mb-2 text-right'}>
                      {formatDate(saleItem?.purchaseDate)}
                    </MDTypography>
                  </dt>
                </dl>
                <dl className={'row'}>
                  <dd className={'col'} sm={3} xs={12}>
                    <MDTypography variant={'h6'} className={'mb-2'}>
                      Item Price
                    </MDTypography>
                  </dd>
                  <dt className={'col'} sm={9} xs={12}>
                    <MDTypography variant={'h6'} fontWeight={'regular'} className={'mb-2 text-right'}>
                      {formatCurrency(saleItem?.price)}
                    </MDTypography>
                  </dt>
                </dl>
                <dl className={'row'}>
                  <dd className={'col'} sm={3} xs={12}>
                    <MDTypography variant={'h6'} className={'mb-2'}>
                      Discount Amount
                    </MDTypography>
                  </dd>
                  <dt className={'col'} sm={9} xs={12}>
                    <MDTypography variant={'h6'} fontWeight={'regular'} className={'mb-2 text-right'}>
                      {formatCurrency(saleItem?.discount)}
                    </MDTypography>
                  </dt>
                </dl>
                <dl className={'row'}>
                  <dd className={'col'} sm={3} xs={12}>
                    <MDTypography variant={'h6'} className={'mb-2'}>
                      Sales Tax
                    </MDTypography>
                  </dd>
                  <dt className={'col'} sm={9} xs={12}>
                    <MDTypography variant={'h6'} fontWeight={'regular'} className={'mb-2 text-right'}>
                      {formatCurrency(saleItem?.salesTax)}
                    </MDTypography>
                  </dt>
                </dl>
                <dl className={'row'}>
                  <dd className={'col'} sm={3} xs={12}>
                    <MDTypography variant={'h6'} className={'mb-2'}>
                      Purchase Price
                    </MDTypography>
                  </dd>
                  <dt className={'col'} sm={9} xs={12}>
                    <MDTypography variant={'h6'} fontWeight={'regular'} className={'mb-2 text-right'}>
                      {formatCurrency(saleItem?.chargeAmount)}
                    </MDTypography>
                  </dt>
                </dl>

                <ul className="list-group">
                  {saleItem?.refundItems?.length > 0 && saleItem?.refundItems?.sort((a, b) => a.refundDate > b.refundDate).map((refundItem, index) => {
                    return (
                      <li className="list-inline-item mb-2">
                        <MDTypography variant={'p'} className={'mb-2'}>
                          <b>{formatDateTime(refundItem?.refundDate)}&nbsp;</b>
                          {refundItem?.createUser?.firstName + ' ' + refundItem?.createUser?.lastName} issued refund
                          amount of {formatCurrency(refundItem?.refundAmount)}&nbsp;
                          for {refundItem?.refundReason}&nbsp;
                        </MDTypography>
                      </li>
                    )
                  })
                  }</ul>

                <dl className={'row'}>
                  <dd className={'col'} sm={9} xs={12}>
                    <MDTypography variant={'h6'} className={'mb-2'}>
                      &nbsp;

                    </MDTypography>
                  </dd>
                  <dt className={'col'} sm={3} xs={12}>

                    {startRefund && <>
                      <div className="form-group">
                        <MDInput type="text" step="0.01" required
                                 {...register('refundAmount', {
                                   valueAsDouble: true
                                 })}
                                 onChange={(e) => {
                                   let _amount = e.target.value;
                                   _amount = e.target.value.replace(/[^0-9.]/g, '');
                                   e.target.value = _amount;
                                   let _item = {...saleItem}
                                   _item.refundAmount = e.target.value;
                                   setSaleItem(_item);
                                 }}
                                 InputProps={{
                                   startAdornment: <InputAdornment position="start">
                                     <span className={'fa fa-dollar'}></span>
                                   </InputAdornment>,
                                 }}
                                 min={0}
                                 max={maxRefund}
                                 disabled={!startRefund}
                                 defaultValue={'0.00'}
                                 autoComplete="off"
                                 className="form-control" id="refundAmount"
                                 label="Refund Amount"/>
                        <MDTypography
                          variant="p"
                          fontWeight="light">Note: Maximum refund amount is {formatCurrency(maxRefund)}
                        </MDTypography>
                      </div>
                      <div className="form-group mt-4">
                        <MDInput type="text" required
                                 {...register('refundReason')}
                                 autoComplete="off"
                                 variant="outlined"
                                 className="form-control" id="refundReason"
                                 label="Enter Refund reason"/>
                      </div>
                    </>}
                  </dt>
                </dl>

              </Container>}
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>

          {!startRefund && maxRefund > 0 && <MDButton type="button"
                                                      onClick={() => {
                                                        setStartRefund(true);
                                                      }}
                                                      color="info" className={'mb-2 text-right'}
                                                      disabled={formInvalid || props.saving}>
            Issue Refund
          </MDButton>}

          {startRefund && <MDButton type="submit" color="info" disabled={refundInvalid || formInvalid || saving}>
            {saving ? 'Saving...' : 'Save Refund'}
          </MDButton>}
        </Modal.Footer>
      </form>
    </Modal>
  );
}