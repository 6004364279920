import {Col, Container, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {handleDefaultValues} from "../common/Utils";
import {useForm} from 'react-hook-form';
import MDButton from "../../components/MDButton";
import {Chip } from "@mui/material";

export default function ManageCompanyDetailsModal(props) {

  const [context, setContext] = useContext(AppContext);
  const [companyDetails, setCompanyDetails] = useState(props?.companyDetails);

  const [logoImage, setLogoImage] = useState(null);
  const [logoImageName, setLogoImageName] = useState(null);
  const [logoImageType, setLogoImageType] = useState(null);

  const [preview, setPreview] = useState(null);
  const [formInvalid, setFormInvalid] = useState(false);

  useEffect(() => {
    setCompanyDetails(props?.companyDetails);
  }, [props?.companyDetails]);

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(companyDetails)
  });

  const handleClose = () => {
    props.handleClose();
  };

  const handleSave = (data) => {
    let d = {...data};
    d.data = logoImage;
    d.fileName = logoImageName;
    d.fileType = logoImageType;
    props.handleSave(d);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setPreview(null);

      setLogoImageName(null);
      setLogoImageType(null);
      setLogoImage(null);
      return;
    }
    setLogoImageName(file.name);
    setLogoImageType(file.type);

    const reader = new FileReader();

    reader.onload = function (evt) {
      setLogoImage(btoa(evt.target.result));
    }
    reader.readAsBinaryString(file);

    setPreview(URL.createObjectURL(file));

    let _inv = {...companyDetails};
    _inv.removeImage = false;
    setCompanyDetails(_inv);
  }

  return (
    <>
      <Modal onHide={handleClose} size={'lg'} show={props.show}>
        <form onSubmit={handleSubmit(handleSave)} id={'detailsForm'}>
          <Modal.Header closeButton>
            <Modal.Title>Manage Company Portal Logo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col className={'col col-main'} xs={12}>
                <Container>
                  <Row>
                    <Col className={'col'} sm={6} xs={12}>
                      <div className="form-group">
                        <label htmlFor="logoImage">Logo Image</label>
                        <input type="file"
                               accept="image/*"
                               {...register('logoImage', {
                                 onChange: (e) => {
                                   handleFileChange(e);
                                 }
                               })}
                               autoComplete="off"
                               className="form-control" id="logoImage"/>

                        {preview && <>
                          <img className={'mt-2 img-thumbnail'} src={preview} alt="img"/>
                          <div className="d-block mt-2">
                          <Chip label="Remove"
                                className={'cls clear-both'}
                                variant="outlined" onDelete={()=>{
                            let _inv = {...companyDetails}
                            _inv.imageUrl = null;
                            _inv.removeImage = true;
                            setCompanyDetails(_inv);
                            setPreview(null);
                          }} />
                          </div>
                        </>}
                        {!preview && companyDetails?.imageUrl &&
                          <><img className={'mt-2 img-thumbnail'} src={companyDetails.imageUrl} alt="img"/>
                            <div className="d-block mt-2">
                              <Chip label="Remove"
                                    className={'cls clear-both'}
                                    variant="outlined" onDelete={() => {
                                let _inv = {...companyDetails}
                                _inv.imageUrl = null;
                                _inv.removeImage = true;
                                setCompanyDetails(_inv);
                                setPreview(null);
                              }}/>
                            </div>
                          </>}
                      </div>
                    </Col>
                  </Row>
                  <Row className={'mt-2'}>
                  </Row>
                </Container>
              </Col>
            </Row>

          </Modal.Body>
          <Modal.Footer>
            <MDButton type="submit" color="info" disabled={formInvalid || props.saving}>
              {props.saving ? 'Saving...' : 'Save'}
            </MDButton>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}