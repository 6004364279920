import {Col, Container, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import {forwardRef, useContext, useEffect, useState} from "react";
import {displayPhone, handleDefaultValues} from "../common/Utils";
import {Controller, useForm} from 'react-hook-form';
import {getFamilyMemberTypes} from "../common/api/WebApi";
import ReactDatePicker from "react-datepicker";
import {addDays} from "@fullcalendar/core/internal";
import MDInput from "../../components/MDInput";
import {NativeSelect} from "@mui/material";
import MDButton from "../../components/MDButton";

export default function ReservationSpecialRequest(props) {

  const [context, setContext] = useContext(AppContext);
  const [reservation, setReservation] = useState(props.reservation);

  useEffect(() => {
    setReservation(props?.reservation);
  }, [props?.reservation]);

  const [formInvalid, setFormInvalid] = useState(false);

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(reservation)
  });

  const handleClose = () => {
    props.handleClose();
    setReservation(null);
  };

  const handleSave = (data) => {
    props.handleSave(data);
    setReservation(null);
  };

  return (
    <Modal onHide={handleClose} size={'lg'} show={props.show}>
      <form onSubmit={handleSubmit(handleSave)} id={'specialRequestForm'}>
        <Modal.Header closeButton>
          <Modal.Title>Event Special Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className={'col col-main'} xs={12}>
              <Container>
                <Row>
                  <Col className={'col'} sm={12} xs={12}>
                    <div className="form-group">
                      <label htmlFor="specialRequestInput">Special Request</label>
                      <MDInput
                        variant="outlined" type="text" required
                             {...register('specialRequestInput')}
                             className="form-control" id="specialRequestInput"
                        label="Enter Request"/>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <MDButton type="submit" color="info" disabled={formInvalid || props.saving}>
            {props.saving ? 'Saving...' : 'Save'}
          </MDButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
}