import {useNavigate, useParams} from "react-router-dom";
import {AppContext} from "../../lib/AppContext";
import {useContext, useEffect, useState} from "react";
import Meta from "../common/Meta";
import {setAppGuidCookie} from "../common/appCookie";
import CustomerCalendarPane from "./pane/CustomerCalendarPane";
import {Card, CardContent} from "@mui/material";
import MDBox from "../../components/MDBox";
import CustomerHeader from "../common/CustomerHeader";
import Footer from "../Footer";
import CustomerPageLayout from "../Layout/CustomerPageLayout";

export default function CustomerCalendar(props) {
  const [context, setContext] = useContext(AppContext);

  const navigate = useNavigate();
  let {guid} = useParams();
  const [pageReady, setPageReady] = useState(false);

  useEffect(() => {
    if (guid) {
      setAppGuidCookie(guid);
    }
  }, [guid]);

  useEffect(() => {
    if (context.sessionUser?.companyGk) {
      setPageReady(true);
    }
  }, [context?.sessionUser]);


  return (
    <>
      <CustomerPageLayout >

        <CustomerHeader props={props}/>

        <MDBox p={3}>
          <MDBox m={3}>
            <Card>
              <CardContent>
                <Meta pageTitle={'Client Dashboard'}/>
                {pageReady && <CustomerCalendarPane/>}
              </CardContent>
            </Card>
          </MDBox>
        </MDBox>

        <Footer/>
      </CustomerPageLayout>
    </>
  )
}