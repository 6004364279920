import {useNavigate} from "react-router-dom";
import {AppContext} from "../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {Card, CardContent, Chip} from "@mui/material";
import MDBox from "../../components/MDBox";
import CustomerHeader from "../common/CustomerHeader";
import Footer from "../Footer";
import CustomerPageLayout from "../Layout/CustomerPageLayout";
import Grid from "@mui/material/Grid";
import MDTypography from "../../components/MDTypography";
import Divider from "@mui/material/Divider";
import {formatCurrency, formatDateTime,} from "../common/Utils";
import MDButton from "../../components/MDButton";
import {getCustomerSales} from "../common/api/PortalCustomerApi";

export default function CustomerPurchases(props) {
  const navigate = useNavigate();
  const [context, setContext] = useContext(AppContext);
  const [salesResp, setSalesResp] = useState();
  const [salesReady, setSalesReady] = useState(false);

  useEffect(() => {
    getSalesFn();
  }, [context?.sessionUser?.guid]);

  const getSalesFn = async () => {
    let result = await getCustomerSales(context?.sessionUser?.guid);
    setSalesResp(result);
    setSalesReady(true);
  }

  return (
    <CustomerPageLayout>

      <CustomerHeader props={props}/>

      {salesReady && <MDBox p={3}>
        <MDBox m={3}>
          <Grid container cols={1} spacing={3} direction="row">
            <Grid item xs={12} sm={12}>
              <Card>
                <CardContent>
                  <MDTypography variant="h5" color="secondary" fontWeight="light">
                    My Purchases
                    <MDButton
                      color="info"
                      size="small"
                      className={'pull-right'}
                      onClick={(e) => {
                        navigate('/cx/my-profile');
//                        setShowPurchaseHistory(true);
                      }}>
                      <span className={'fa fa-person'}></span>&nbsp;My Profile
                    </MDButton>
                    <Divider/>
                  </MDTypography>
                </CardContent>

                <MDBox p={3}>
                  <MDTypography variant={'h6'}>Total
                    Purchases: {salesReady && formatCurrency(salesResp?.totalSales)}</MDTypography>

                  <ul className={'list-group'}>
                    {salesReady && salesResp?.salesItems.sort((a, b) => b.id - a.id).map((item, index) => {
                        return (
                          <li
                            className={'list-group-item'}
                            key={index}>
                                    <span
                                      className={'pull-right'}>
                                      <Chip
                                        label={formatCurrency(item.total - item.discount)}
                                        variant="outlined"
                                        color="secondary"/>
                                    </span>
                            {item?.itemName}
                            <br/>
                            <small>{formatDateTime(item.purchaseDate)}</small>

                          </li>
                        )
                      }
                    )}
                  </ul>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      }
      <Footer/>
    </CustomerPageLayout>
  )
}