import {Col, Container, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {handleDefaultValues} from "../common/Utils";
import {useForm} from 'react-hook-form';
import MDButton from "../../components/MDButton";
import MDInput from "../../components/MDInput";
import {Chip, IconButton, InputAdornment, NativeSelect} from "@mui/material";
import {getInventoryByCategory, getInventoryTypes} from "../common/api/InventoryApi";
import Avatar from "@mui/material/Avatar";
import {ExpandCircleDown, ExpandCircleDownOutlined} from "@mui/icons-material";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";

export default function ManageInventoryEventTypeModal(props) {

  const rotate = {
    'transform': 'rotate(270deg)'
  };
  const [context, setContext] = useContext(AppContext);
  const [inventoryEventType, setInventoryEventType] = useState(props.inventoryEventType);
  const [initialDeposit, setInitialDeposit] = useState(!!props.inventoryEventType?.initialDeposit);

  const [formInvalid, setFormInvalid] = useState(false);
  const [inventories, setInventories] = useState();
  const [expandInventoryOptions, setExpandInventoryOptions] = useState(!!props.inventoryEventType?.guid);
  const [inventoryCategories, setInventoryCategories] = useState();
  const [inventoryCategory, setInventoryCategory] = useState();

  useEffect(() => {
    setInventoryEventType(props?.inventoryEventType);
  }, [props?.inventoryEventType]);

  useEffect(() => {
    if (!inventoryCategory) {
      return;
    }
    loadInventoryByCategory(inventoryCategory);
  }, [inventoryCategory]);

  useEffect(() => {
    loadInventoryFn();
  }, [expandInventoryOptions]);


  const [isNew, setIsNew] = useState(!!!props.inventoryEventType?.guid);

  const loadInventoryFn = async () => {
    if (!expandInventoryOptions) {
      return;
    }
    if (inventories?.length >= 0) {
      return;
    }
    getInventoryTypes().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setInventoryCategories(result);
        setInventoryCategory(result[0].id);
      }
    });
  }

  const loadInventoryByCategory = async (category) => {
    getInventoryByCategory(category).then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setInventories(result);
      }
    });
  }
  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(inventoryEventType)
  });

  const handleClose = () => {
    props.handleClose();
  };

  const handleSave = (data) => {
    props.handleSave(data);
  };

  const addOns = [{
    'name': 'Cake',
    'desc': 'A delicious cake for the birthday party',
    id: 'a'
  }, {
    'name': 'Costumes',
    'desc': 'Costumes for the birthday party',
    id: 'b'
  }, {
    'name': 'Balloons',
    'desc': 'Balloons for the birthday party',
    id: 'c'
  }]

  const assignInventory = (inventory) => {
    let _c = {...inventoryEventType};
    let _group = {
      inventory: inventory,
    }

    for (var j in _c.eventInventoryGroups) {
      if (_c.eventInventoryGroups[j].inventory.id == inventory.id) {
        return;
      }
    }
    if (!_c.eventInventoryGroups) {
      _c.eventInventoryGroups = [];
    }

    _c.eventInventoryGroups.push(_group);

    setInventoryEventType(_c);
  }

  const handleRemoveItem = (item) => {
    let _c = {...inventoryEventType};
    let idx;
    for (let j in _c.eventInventoryGroups) {
      if (_c.eventInventoryGroups[j].inventory.id == item.inventory.id) {
        idx = j;
        break;
      }
    }

    _c.eventInventoryGroups.splice(idx, 1);
    setInventoryEventType(_c);
  }

  const toggleDeposit = (e) => {
    setInitialDeposit(e.target.checked);
    let _inventoryEventType = {...inventoryEventType};
    _inventoryEventType.initialDeposit = e.target.checked;
    if (!e.target.checked) {
      _inventoryEventType.depositPercent = '';
    }
    setInventoryEventType(_inventoryEventType);
  }

  const [specialEvent, setSpecialEvent] = useState(!!props.inventoryEventType?.specialEvent);
  const [specialRequest, setSpecialRequest] = useState(!!props.inventoryEventType?.specialRequest);
  const [privateAccess, setPrivateAccess] = useState(!!props.inventoryEventType?.privateAccess);

  const toggleSpecialEvent = (e) => {
    setSpecialEvent(e.target.checked);
    let _inventoryEventType = {...inventoryEventType};
    _inventoryEventType.specialEvent = e.target.checked;
    setInventoryEventType(_inventoryEventType);
  }

  const toggleSpecialRequest = (e) => {
    setSpecialRequest(e.target.checked);
    let _inventoryEventType = {...inventoryEventType};
    _inventoryEventType.specialRequest = e.target.checked;
    setInventoryEventType(_inventoryEventType);
  }

  const togglePrivateAccess = (e) => {
    setPrivateAccess(e.target.checked);
    let _inventoryEventType = {...inventoryEventType};
    _inventoryEventType.privateAccess = e.target.checked;
    setInventoryEventType(_inventoryEventType);
  }


  return (
    <Modal onHide={handleClose} size={'lg'} show={props.show}>
      <form onSubmit={handleSubmit(handleSave)} id={'inventoryTypeForm'}>
        <Modal.Header closeButton>
          <Modal.Title>{isNew ? 'Create New' : 'Manage'} Inventory Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className={'col col-main'} xs={12}>
              <Container>
                <Row>
                  <Col className={'col'} sm={12} xs={12}>
                    <div className="form-group">
                      <MDInput type="text" required
                               {...register('name')}
                               autoComplete="off"
                               className="form-control" id="name"
                               label="Inventory Category Name"/>
                    </div>
                  </Col>
                </Row>
                <Row className={'mt-2'}>
                  <Col className={'col'} sm={12} xs={12}>
                    <div className="form-group">
                      <MDInput type="text"
                               {...register('desc')}
                               autoComplete="off"
                               maxLength={255}
                               className="form-control" id="desc"
                               label="Inventory Category Description"/>
                    </div>
                  </Col>
                </Row>

                <Row className={'mt-3'}>
                  <h5>Event Deposit</h5>
                  <p className={'py-2 fw-light'}>
                    Initial Deposit is a percentage of the cost of the inventory item that is required to be
                    paid at the time of booking. For example, if the initial deposit is 50%, then 50% of the total
                    cost of the inventory item is required to be paid at the time of booking. If the initial deposit
                    is 0%, then the full item amount will be required at the time of booking.
                  </p>

                  <Col className={'col'} md={6} sm={12}>
                    <div className="form-group">
                      <label htmlFor="name">Accept Deposit</label>
                      <Switch
                        name={'initialDeposit'}
                        id={'initialDeposit'}
                        checked={initialDeposit}
                        style={{marginRight: '5px'}}
                        onChange={toggleDeposit}
                      />
                    </div>
                  </Col>

                  <Col className={'col'} md={6} sm={12}>

                    <div className="form-group">
                      <div className={'input-group'}>
                        <MDInput type="number" step="1" required={inventoryEventType?.initialDeposit}
                                 {...register('depositPercent', {
                                   valueAsNumber: true,
                                 })}
                                 style={{
                                   borderTopRightRadius: '0px !important',
                                   borderBottomRightRadius: '0',
                                   borderRight: '0',
                                 }}
                                 disabled={!inventoryEventType?.initialDeposit}
                                 defaultValue={'0'}
                                 step={5}
                                 min={0}
                                 max={100}
                                 autoComplete="off"
                                 InputProps={{
                                   endAdornment: <InputAdornment position="end">
                                     <span className={'fa fa-percent'}></span>
                                   </InputAdornment>
                                 }}
                                 className="form-control" id="initialDeposit"
                                 label="Deposit Percent"/>

                      </div>
                    </div>
                  </Col>
                </Row>

                <Row className={'mt-2'}>
                  <Col className={'col'} sm={12} xs={12}>
                    <div className="form-group">
                      <label htmlFor="name">Special Event</label>
                      <Switch
                        name={'specialEvent'}
                        id={'specialEvent'}
                        checked={specialEvent}
                        style={{marginRight: '5px'}}
                        onChange={toggleSpecialEvent}
                      />
                      <p className={'py-2 fw-light'}>
                        Special Events will display additionally in the "Special Events" calendar.
                        Use Special Events for "Birthday Parties" or events that require additional
                        attention.
                      </p>
                    </div>
                  </Col>
                </Row>

                <Row className={'mt-2'}>
                  <Col className={'col'} sm={12} xs={12}>
                    <div className="form-group">
                      <label htmlFor="name">Accept Special Request</label>
                      <Switch
                        name={'specialRequest'}
                        id={'specialRequest'}
                        checked={specialRequest}
                        style={{marginRight: '5px'}}
                        onChange={toggleSpecialRequest}
                      />
                      <p className={'py-2 fw-light'}>
                        Special Requests will display additionally in the "Reservations" section of Events.
                        Use Special Requests for "Birthday Parties" or events that require additional attention.
                      </p>
                    </div>
                  </Col>
                </Row>

                <Row className={'mt-2'}>
                  <Col className={'col'} sm={12} xs={12}>
                    <div className="form-group">
                      <label htmlFor="name">Private Event</label>
                      <Switch
                        name={'privateAccess'}
                        id={'privateAccess'}
                        checked={privateAccess}
                        style={{marginRight: '5px'}}
                        onChange={togglePrivateAccess}
                      />
                      <p className={'py-2 fw-light'}>
                        Private Events will not display on the public calendar. Use Private Events for
                        "Private Parties" or events that are not open to the public.
                      </p>
                    </div>
                  </Col>
                </Row>

                <Row className={'mt-2'}>
                  <Col className={'col'} sm={12} xs={12}>
                    <h5>Inventory Add-ons <IconButton
                      onClick={(e) => {
                        setExpandInventoryOptions(!expandInventoryOptions);
                      }}
                      size={'small'} className={'pull-right btn btn-sm'} color={'primary'}>
                      {expandInventoryOptions && <ExpandCircleDown sx={rotate}/>}
                      {!expandInventoryOptions && <ExpandCircleDownOutlined/>}
                    </IconButton></h5>
                    <p className={'py-2 fw-light'}>
                      Add-ons are additional items that can be added to an inventory item. For example, a
                      "Birthday Party" inventory item might have an add-on for "Cake" or "Balloons".
                    </p>

                    {expandInventoryOptions && <Row>

                      <Col className={'col'} sm={6} xs={12}>
                        <div className="form-group">
                          <h6>Available Items</h6>
                          {inventoryCategories && <NativeSelect
                            variant="outlined"
                            id={'inventoryCategory'}
                            name={'inventoryCategory'}
                            className={'form-control'}
                            onChange={(e) => {
                              setInventoryCategory(e.target.value);
                            }}>
                            {
                              inventoryCategories.map((c) =>
                                <option value={c.id} key={c.id}>
                                  {c.name}
                                </option>)
                            }
                          </NativeSelect>}
                        </div>

                        <ul className={'list-group'}>
                          {inventories?.length > 0 && inventories?.sort((a, b) => b.id - a.id).map((inventory, index) => {
                            return (
                              <li index={index} key={index}
                                  className={'list-group-item'}>
                                <Avatar alt={inventory.name}
                                        className={'pull-left mr-2'}
                                        sx={{width: 32, height: 32}}>
                                  <img src={inventory.imageUrl}
                                       style={{width: '200%', height: 'auto'}}
                                       alt={inventory.name}/>
                                </Avatar> &nbsp;{inventory.name}
                                <IconButton aria-label="Add" className={'pull-right mt-n1'} onClick={() => {
                                  assignInventory(inventory);
                                }}>
                                  <Icon>add_circle</Icon>
                                </IconButton>
                              </li>
                            )
                          })
                          }
                        </ul>
                      </Col>

                      <Col className={'col'} sm={6} xs={12}>
                        <h6>Assigned Items</h6>
                        {inventoryEventType?.eventInventoryGroups?.map((group, index) => {
                          return (
                            <Chip
                              key={index}
                              className={'m-1'}
                              index={index}
                              avatar={<Avatar src={group?.inventory.imageThumbUrl || group?.inventory.imageUrl}/>}
                              label={group?.inventory.name}
                              // onClick={handleClick}
                              onDelete={() => {
                                handleRemoveItem(group)
                              }}
                              variant="outlined"
                              color="secondary"/>
                          )
                        })
                        }
                      </Col>


                    </Row>
                    }

                  </Col>
                </Row>

              </Container>
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <MDButton type="submit" color="info" disabled={formInvalid || props.saving}>
            {props.saving ? 'Saving...' : 'Save'}
          </MDButton>
        </Modal.Footer>
      </form>
    </Modal>
  )
    ;
}