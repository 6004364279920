import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../lib/AppContext";
import {checkout, getCart, getCartFull, removeCartItem} from "./api/PortalCustomerApi";
import {
  capitalize,
  formatCurrency,
  formatDateTime,
  handleDefaultValues,
  isAuthenticatedCustomer,
  isSessionCustomer
} from "./Utils";
import {useNavigate} from "react-router-dom";
import {Col, Modal, Row} from "react-bootstrap";
import {Divider} from "@mui/material";
import LoginForm from "./LoginForm";
import CreateAccount from "./CreateAccount";
import Confirm from "./Confirm";
import MDBadge from "../../components/MDBadge";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import MDTypography from "../../components/MDTypography";
import MDBox from "../../components/MDBox";
import {useForm} from "react-hook-form";
import CheckoutPage from "../CheckoutPage";
import MDButton from "../../components/MDButton";
import Avatar from "@mui/material/Avatar";

export default function CustomerCart(props) {

  const navigate = useNavigate();

  const [context, setContext] = useContext(AppContext);
  const [cartItems, setCartItems] = useState([]);
  const [cartActive, setCartActive] = useState(false);
  const [cart, setCart] = useState();
  const [cartReady, setCartReady] = useState(false);
  const [checkoutActive, setCheckoutActive] = useState(false);
  const [checkoutDisabled, setCheckoutDisabled] = useState(true);
  const [checkoutFinal, setCheckoutFinal] = useState(false);

  const [showCart, setShowCart] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [confirmation, setConfirmation] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);

  const [addPaymentMethod, setAddPaymentMethod] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState({});

  const [saving, setSaving] = useState(false);


  useEffect(() => {
    setCheckoutDisabled(!isAuthenticatedCustomer(context?.sessionUser));
  }, []);

  useEffect(() => {
    setCheckoutDisabled(!isAuthenticatedCustomer(context?.sessionUser));
  }, [context?.sessionUser]);

  useEffect(() => {
    if (context?.viewCart) {
      let _context = {...context};
      _context.viewCart = false;
      _context.reloadCart = false;
      setContext({..._context});
      viewCart();
    }
  }, [context?.viewCart]);

  useEffect(() => {
    setShowLogin(true);
    setCheckoutActive(false);
    setAddPaymentMethod(false);

  }, [cartActive]);

  const getCartFn = () => {
    if (!isSessionCustomer(context?.sessionUser)) {
      return false;
    }
    getCart().then(resp => {
      setCartItems(resp?.items);
    });
  };

  const getCartFullFn = async () => {
    getCartFull().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setCart(result);
        setCartReady(true);
      }
    });
  }

  useEffect(() => {
    if (!cartActive) {
      return;
    }
    getCartFullFn();
  }, [cartActive])

  useEffect(() => {
    getCartFn();
  }, [context?.sessionUser]);

  useEffect(() => {
    if (context?.reloadCart) {
      let c = {...context};
      c.reloadCart = false;
      c.viewCart = false;
      setContext({...c});
      getCartFn();
    }
  }, [context?.reloadCart]);

  const deleteInventoryItem = async (item) => {
    removeCartItem(item).then((result) => {
      let _context = {...context};
      _context.reloadCart = true;
      setContext({..._context});
      handleCancelCartRemove();
      getCartFullFn();
    });

  };

  const handleCancelCartRemove = () => {
    setConfirmation(null);
    setShowConfirm(false);
  }

  const preDeleteInventoryItem = async (item) => {
    let confirmation = {
      title: 'Delete?',
      message: 'Remove ' + item.inventoryItem.name + ' from cart?',
      entity: item
    }
    setConfirmation(confirmation);
    setShowConfirm(true);
  };

  const viewCart = () => {
    setCart(null);
    setCartActive(true);
  };

  const checkoutFn = async () => {

    setCheckoutDisabled(true);
    setCheckoutActive(true);

    const resp = await checkout(cart);
    if (resp) {
      setCheckoutActive(false);
      setCheckoutDisabled(false);
      navigate('/cx/checkout/success?payment_intent=' + resp.paymentId);
      handleClose();
      let _context = {...context};
      _context.reloadCart = true;
      setContext({..._context});
    }
  };

  const handleClose = () => {
    setCartActive(false);
    setShowCart(false);
    setShowLogin(false);
    setCheckoutFinal(false);
    setShowCreateAccount(false);
  };

  const handleLogin = () => {
    setShowLogin(false);
    setShowCreateAccount(false);
    getCartFullFn();
  }

  const updatePaymentMethod = (item) => {
    setAddPaymentMethod(false);
    let _cart = {...cart};
    _cart.paymentMethod = item;
    setCart(_cart);
  };

  const [card, setCard] = useState(null);
  const [cardInvalid, setCardInvalid] = useState(true);
  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(paymentMethod)
  });

  useEffect(() => {
    if (!context?.sessionUser?.guid) {
      return;
    }
    if (cart?.billings?.length === 0) {
      addPaymentMethodFn();
    }
  }, [context?.sessionUser?.guid, cart?.billings]);

  const addPaymentMethodFn = () => {
    setCard(null);
    setAddPaymentMethod(true);
  };

  const handleCardChange = (card) => {
    if (!card) {
      return;
    }
    if (!cart) {
      return;
    }
    setCard(card);
    setCardInvalid(!card?.card?.isValid);

    let _paymentMethod = {...card};
    let _cart = cart;
    _cart.paymentMethod = _paymentMethod;
    setCart(_cart);
  };

  const [transparentNavbar, absolute, light, isMini, darkMode] = [false, true, true, false, false];

  return (
    <>
      {isSessionCustomer(context?.sessionUser) &&
        <div>
          <IconButton
            size="small"
            disableRipple
            color="primary"
            aria-controls="notification-menu"
            aria-haspopup="true"
            variant="contained"
            onClick={() => {
              viewCart()
            }}
          >
            {cartItems?.length > 0 && <MDBadge badgeContent={cartItems?.length} color="error" size="xs" circular>
              <Icon>shopping_cart</Icon>
            </MDBadge>}
          </IconButton>
        </div>
      }

      <Modal size={'xl'} onHide={handleClose}
             show={cartActive}>

        {!showCart && <>

          {
            confirmation && <Confirm confirmation={confirmation}
                                     show={showConfirm}
                                     saving={saving}
                                     handleSave={()=> {
                                       setShowConfirm(false);
                                     }}
                                     handleConfirm={deleteInventoryItem}
                                     handleClose={handleCancelCartRemove}/>
          }

          <Modal.Header closeButton>
            <Modal.Title>Your Shopping Cart</Modal.Title>
          </Modal.Header>
          <Modal.Body className={'modal-overflow'}>
            {cart && cart.items.length === 0 &&
              <Col xs={12}>
                <h5 onClick={() => {
                  handleClose();
                }}>Your cart is empty. Continue shopping?</h5>
              </Col>
            }
            {!checkoutFinal && cart && cart?.items.length > 0 &&
              <Row p={2}>
                <Col xs={12} md={6} p={2}>
                  <h5 className={'text-right'}>
                    Your {cart.items.length > 1 ? ' items ' : ' item '} ({cart.items.length})
                  </h5>
                  <Divider/>
                  {cart && cart?.items
                    .sort((a, b) => a.cartItemId > b.cartItemId ? 1 : -1)
                    .map((item, index) => {
                      return (
                        <dl key={index}
                            className={'row'}>
                          <dd className={'col col-sm col-sm-2'}>
                            <Avatar alt={item.inventoryItem?.name}
                                    className={'pull-left mr-3'}
                                    sx={{width: 64, height: 64}}>
                              {(item.inventoryItem?.imageThumbUrl || item.inventoryItem?.imageUrl) ?
                                <img src={item.inventoryItem?.imageThumbUrl || item.inventoryItem?.imageUrl}
                                     style={{width: '200%', height: 'auto'}}
                                     alt={item.inventoryItem?.name}/> : <Icon>event</Icon>
                              }
                            </Avatar>
                          </dd>
                          <dd className={'col col-sm col-sm-10'}>
                            {item.inventoryItem.name}
                            <p className={'my-0'}>
                              {item.inventoryItem?.eventType?.id && <>
                                <small>
                                  {formatDateTime(item.inventoryItem.eventDate, 'hh:mm a')} - {formatDateTime(item.inventoryItem.eventEndDate, 'hh:mm a')}
                                  <br/>
                                </small>
                              </>
                              }
                            </p>
                            <p className={'my-0'}>
                               <span
                                 className={'text-primary ' + (item.discountItem ? 'text-decoration-line-through' : '')}>
                                {formatCurrency(item.inventoryItem.price)}
                              </span>
                              {item.discountItem && <>
                                <br/>
                                <span className={'text-primary'}>
                                {formatCurrency(item.inventoryItem.price - item.discountItem.discountAmount)}
                                  <br/>
                                  {item.discountItem.discountName} - {item.discountItem.discountReason}
                              </span>
                              </>
                              }

                              {item.inventoryItem?.eventType?.specialRequest && <div>

                                <label htmlFor="specialRequestInput">Note:&nbsp;</label>
                                <MDTypography variant="p" fontWeight="light">
                                  This event supports a special request. Your request may be
                                  submitted from the Reservations section of your profile.
                                </MDTypography>
                              </div>}

                              <a className={'p-1 m-0 pull-right cursor-pointer'}
                                 onClick={(e) => {
                                   preDeleteInventoryItem(item)
                                 }}>
                                <small><span className={'fa fa-times'}></span> Remove</small>
                              </a>
                            </p>
                            <small>
                              Qty: {item.quantity}
                            </small>

                          </dd>

                          <Divider/>
                        </dl>
                      )
                    })
                  }

                </Col>

                <Col xs={12} md={6}>
                  <dl className={'row'}>
                    <dt className={'col col-sm col-sm-6'}>
                      Items Total
                    </dt>
                    <dd className={'col col-sm col-sm-6 text-right'}>
                      {formatCurrency(cart.itemsTotal)}
                    </dd>
                  </dl>
                  <dl className={'row'}>
                    <dt className={'col col-sm col-sm-6'}>
                      Sales Tax:
                    </dt>
                    <dd className={'col col-sm col-sm-6 text-right'}>
                      {formatCurrency(cart.totalTax)}
                    </dd>
                  </dl>
                  <dl className={'row'}>
                    <dt className={'col col-sm col-sm-6'}>
                      Order Total:
                    </dt>
                    <dd className={'col col-sm col-sm-6 text-right'}>
                      {formatCurrency(cart.cartTotal)}
                    </dd>
                  </dl>
                </Col>
              </Row>
            }

            {checkoutFinal &&
              <Row>
                <Col className={'col'} xs={12} md={6}>
                  {(context?.sessionUser && context?.sessionUser?.guid) && <>
                    Welcome, {context.sessionUser?.firstName}
                    <MDBox mt={2}>

                      <MDTypography variant="h4" color="secondary" fontWeight="light">
                        Choose Payment Method
                      </MDTypography>

                      {cart && cart?.billings?.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1)
                        .map((item, index) => {
                          return (
                            <dl className={'row'} key={index}>
                              <dt className={'col col-sm col-sm-6'}>
                                <label>
                                  <input className={'mx-2'} type={'radio'}
                                         defaultChecked={item.primaryCard}
                                         name={'paymentMethod'}
                                         onChange={(e) => {
                                           updatePaymentMethod(item);
                                         }}
                                  />
                                  {capitalize(item.cardType)} ending in **** {item.cardNumber}
                                </label>
                              </dt>
                              <dd className={'col col-sm col-sm-6 text-right'}>
                                ({item.ccExpy})
                              </dd>
                            </dl>
                          )
                        })}

                      <dl className={'row'}>
                        <dt className={'col col-sm col-12'}>
                          <label>
                            <input className={'mx-2'} type={'radio'}
                                   name={'paymentMethod'}
                                   defaultChecked={cart?.billings?.length === 0}
                                   onChange={(e) => {
                                     addPaymentMethodFn(true);
                                   }}
                            />New Payment Method</label>
                        </dt>
                      </dl>
                    </MDBox>

                  </>
                  }
                  {!(context?.sessionUser && context?.sessionUser?.guid) && <>

                    <h5>
          <span
            onClick={() => {
              setShowLogin(true);
            }}
            className={'mx-2 cursor-pointer ' + (showLogin ? 'text-decoration-underline' : '')}>Login</span>
                      or
                      <span
                        onClick={() => {
                          setShowLogin(false);
                        }}
                        className={'mx-2 cursor-pointer ' + (!showLogin ? 'text-decoration-underline' : '')}>Create Account</span>
                    </h5>

                    <Divider/>

                    {!showLogin && <CreateAccount/>}
                    {showLogin && <LoginForm handleLogin={handleLogin}/>}
                  </>}

                </Col>
                <Col xs={12} md={6}>
                  <dl className={'row'}>
                    <dt className={'col col-sm col-sm-6'}>
                      Items Total
                    </dt>
                    <dd className={'col col-sm col-sm-6 text-right'}>
                      {formatCurrency(cart.itemsTotal)}
                    </dd>
                  </dl>
                  <dl className={'row'}>
                    <dt className={'col col-sm col-sm-6'}>
                      Sales Tax:
                    </dt>
                    <dd className={'col col-sm col-sm-6 text-right'}>
                      {formatCurrency(cart.totalTax)}
                    </dd>
                  </dl>
                  <dl className={'row'}>
                    <dt className={'col col-sm col-sm-6'}>
                      Order Total:
                    </dt>
                    <dd className={'col col-sm col-sm-6 text-right'}>
                      {formatCurrency(cart.cartTotal)}
                    </dd>
                  </dl>
                </Col>
              </Row>
            }


          </Modal.Body>

          {cart && cart.items.length > 0 &&
            <>

              {addPaymentMethod &&
                <CheckoutPage props={props}/>
              }

              {!addPaymentMethod &&
                <Modal.Footer>
                  {checkoutFinal && <>
                    <MDButton variant="gradient"
                              color="info"
                              disabled={checkoutDisabled}
                              onClick={() => {
                                checkoutFn();
                              }}>
                      {checkoutActive ? 'Checking out...' : 'Complete Purchase'}
                    </MDButton>
                  </>

                  }
                  {cart?.quickCheckout && <>
                    <MDButton variant="gradient"
                              color="info"
                              disabled={checkoutDisabled}
                              onClick={() => {
                                checkoutFn();
                              }}>
                      {checkoutActive ? 'Checking out...' : 'Complete Purchase'}
                    </MDButton>
                  </>}

                  {!checkoutFinal && !cart?.quickCheckout && <>
                    <MDButton variant="gradient"
                              color="info"
                              onClick={() => {
                                setCheckoutFinal(true);
                              }}>
                      Continue to Checkout
                    </MDButton>
                  </>}

                </Modal.Footer>
              }
            </>
          }
        </>
        }
      </Modal>
    </>
  );
}