import {Col, Container, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import {useContext} from "react";
import MDButton from "../../components/MDButton";
import ListCustomersResults from "./ListCustomersResults";

export default function ListCustomersModal(props) {

  const [context, setContext] = useContext(AppContext);


  const handleCloseListCustomersModal = (data) => {
    props.handleCloseListCustomersModal(data);
  };


  return (
    <Modal onHide={handleCloseListCustomersModal} size={'xl'} show={props.show}>
        <Modal.Header closeButton>
          <Modal.Title>List Customers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className={'col col-main'} xs={12}>
              <Container>
                <Row>
                  <Col className={'col'} sm={12} xs={12}>
                    <ListCustomersResults
                      actionLabel={props?.actionLabel}
                      embedded={props?.embedded} action={props?.action}
                    />
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <MDButton type="submit" color="info" disabled={props.saving}>
            {props.saving ? 'Saving...' : 'Save'}
          </MDButton>
        </Modal.Footer>
    </Modal>
  );
}