import {useContext, useEffect, useMemo, useState} from "react";

// react-router components
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {AppContext} from "./lib/AppContext";

// @mui material components
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React examples
import Sidenav from "./app/Sidenav";

// Material Dashboard 2 PRO React themes
import theme from "./assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "./assets/theme-dark";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";

// Material Dashboard 2 PRO React routes
import routes from "./routes";

// Material Dashboard 2 PRO React contexts
import {setMiniSidenav, setOpenConfigurator, useMaterialUIController} from "./context";

// Images
import brandWhite from "./assets/images/logo-ct.png";
import brandDark from "./assets/images/logo-ct-dark.png";
import {CONSTANTS} from "./app/common/constants";
import Loading from "./app/common/Loading";
import Client from "./app/client/Client";
import Company from "./app/companies/Company";
import NewCompany from "./app/companies/NewCompany";
import Dashboard from "./app/Dashboard";
import CustomerDashboard from "./app/cx/CustomerDashboard";
import CustomerViewEvent from "./app/cx/event/CustomerViewEvent";
import CustomerViewCart from "./app/cx/CustomerViewCart";
import Logout from "./app/Logout";
import Init from "./app/Init";
import LoginPage from "./app/LoginPage";
import {isSessionClient, isSessionCustomer, isSessionPending, isSessionSuperAdmin} from "./app/common/Utils";
import CheckoutPage from "./app/CheckoutPage";
import CheckoutFinal from "./app/cx/CheckoutFinal";
import CustomerCalendar from "./app/cx/CustomerCalendar";
import CustomerMemberships from "./app/cx/CustomerMemberships";
import CustomerProfile from "./app/cx/CustomerProfile";
import ViewEmployee from "./app/employee/ViewEmployee";
import CommunicationTemplates from "./app/company/CommunicationTemplates";
import ComposeEmail from "./app/email/ComposeEmail";
import ManageInventoryEvent from "./app/inventory/ManageInventoryEvent";
import {Modal} from "react-bootstrap";
import MDButton from "./components/MDButton";
import CustomerCancelEvent from "./app/cx/CustomerCancelEvent";
import WaiverManager from "./app/company/WaiverManager";
import Toast from "./app/common/Toast";
import CustomerWaivers from "./app/cx/CustomerWaivers";
import CustomerWaiver from "./app/cx/CustomerWaiver";
import ResetPass from "./app/ResetPass";
import CustomerResetPass from "./app/cx/CustomerResetPass";
import ViewInventoryEvent from "./app/inventory/ViewInventoryEvent";
import CustomerConfirmReservation from "./app/cx/CustomerConfirmReservation";
import CustomerStore from "./app/cx/CustomerStore";
import ViewInventory from "./app/inventory/ViewInventory";
import CustomerPurchases from "./app/cx/CustomerPurchases";
import AdminDashboard from "./app/AdminDashboard";
import logoSm from "./app/assets/images/psb-logo-sm.png";
import TermsOfService from "./app/company/TermsOfService";
import ReportDetails from "./app/reports/ReportDetails";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    miniWindow,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const {pathname} = useLocation();

  const [context, setContext] = useContext(AppContext);
  const navigate = useNavigate();

  const [sessionLoggedIn, setSessionLoggedIn] = useState(false);
  useEffect(() => {
    if (!context?.sessionUser) {
      return;
    }
    setSessionLoggedIn(context?.sessionUser?.guid?.length > 0);
  }, [context?.sessionUser]);

  const [collapse, setCollapse] = useState(false);
  const [hasSidebar, setHasSidebar] = useState(false);
  const [isCustomer, setIsCustomer] = useState(false);
  const [appReady, setAppReady] = useState(false);
  const [noSessionSidenav, setNoSessionSidenav] = useState(false);

  useEffect(() => {

    if (!context?.sessionUser) {
      return;
    }
    setNoSessionSidenav(isSessionClient(context?.sessionUser) && !context?.sessionUser?.guid);
    setIsCustomer(context?.sessionUser?.userTypeId === CONSTANTS.UTYPE_CUSTOMER);
    setHasSidebar(isSessionClient(context?.sessionUser) || isSessionSuperAdmin(context?.sessionUser));


    window.setTimeout(function () {
      setAppReady(true);
    }, 1);

  }, [context?.sessionUser]);

  useEffect(() => {
    // setNoSessionSidenav(hasSidebar);
  }, [appReady]);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) => {
    return allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key}/>;
      }

      return null;
    });
  }

  return (
    <>
      <ThemeProvider theme={darkMode ? themeDark : theme}>

        <div>
          {miniWindow && miniSidenav && <div style={{
            width: '100%',
            display: 'block'
          }}>
            <MDButton style={{
              margin: '2px',
              display: 'inline-block',
              background: 'transparent'
            }}>
              <img
                onClick={(e) => {
                  navigate('/');
                }}
                style={{
                  width: '35px',
                  height: 'auto',
                }}
                src={logoSm} className={'logo-sm logo'}/>
              {sessionLoggedIn && <span className={'fa fa-bars'}
                                        onClick={(e) => {
                                          setMiniSidenav(dispatch, false);
                                        }}
                                        style={{
                                          fontSize: '16px',
                                          marginLeft: '5px'
                                        }}></span>}
            </MDButton>
          </div>
          }
        </div>

        <Toast/>

        <Modal show={context?.error} onHide={() => setContext({...context, error: null})}>
          <Modal.Header closeButton>
            <Modal.Title>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>{context?.error && context.error.message}</Modal.Body>
          <Modal.Footer>
            <MDButton color="info" onClick={() => setContext({...context, error: null})}>
              Close
            </MDButton>
          </Modal.Footer>
        </Modal>

        <CssBaseline/>

        {appReady && <>
          {layout === "dashboard" && (
            <>
              {hasSidebar && <>
                <Sidenav
                  color={sidenavColor}
                  brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                  brandName="PSB"
                  routes={routes}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
              </>
              }

              {noSessionSidenav && <>
                <Sidenav
                  color={sidenavColor}
                  brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                  brandName="PSB"
                  routes={[]}
                  onMouseEnter={handleOnMouseEnter}
                  onMouseLeave={handleOnMouseLeave}
                />
              </>
              }

            </>
          )}

          <Routes>

            {getRoutes(routes)}

            <Route path="/pass/reset/:guid" element={<ResetPass/>}/>

            {/*<Route path="*" element={<Navigate to="/login"/>}/>*/}

            <Route path="/init" element={<Init/>}/>

            {isSessionPending(context?.sessionUser) && <Route path="/" element={<Loading/>}/>}
            {isSessionClient(context?.sessionUser) && <Route path="/" element={<Dashboard/>}/>}
            {isSessionSuperAdmin(context?.sessionUser) && <Route path="/" element={<AdminDashboard/>}/>}
            {isSessionCustomer(context?.sessionUser) && <Route path="/" element={<CustomerDashboard/>}/>}

            <Route path="/login" element={<LoginPage/>}/>

            <Route path="/client/:guid" element={<Client/>}/>
            <Route exact path="/company/:guid" element={<Company/>}/>
            <Route exact path="/company/new" element={<NewCompany/>}/>

            <Route path="/event/manage/:guid" element={<ManageInventoryEvent/>}/>
            <Route path="/event/view/:guid" element={<ViewInventoryEvent/>}/>
            <Route path="/inventory/view/:guid" element={<ViewInventory/>}/>
            <Route path="/sales/create/event" element={<ManageInventoryEvent/>}/>

            <Route path="/communication/templates/:guid" element={<CommunicationTemplates/>}/>

            <Route path="/communication/waivers/:guid" element={<WaiverManager/>}/>
            <Route exact path="/company/employees/view/:guid" element={<ViewEmployee/>}/>
            <Route exact path="/company/terms-of-service" element={<TermsOfService/>}/>
            <Route exact path="/email/client/:guid" element={<ComposeEmail/>}/>

            <Route path="/sales/report/:reportYear/:reportMonth" element={<ReportDetails/>}/>

            <Route exact path="/cx" element={<CustomerDashboard/>}/>
            <Route exact path="/cx/" element={<CustomerDashboard/>}/>
            <Route exact path="/cx/events" element={<CustomerCalendar/>}/>
            <Route exact path="/cx/store" element={<CustomerStore/>}/>
            <Route exact path="/cx/waivers" element={<CustomerWaivers/>}/>
            <Route exact path="/cx/waiver/:guid" element={<CustomerWaiver/>}/>

            <Route exact path="/cx/:guid/event/cancel/:eventGuid/:customerGuid" element={<CustomerCancelEvent/>}/>

            <Route exact path="/cx/:guid/event/waitlist/:reservationGuid/:customerGuid"
                   element={<CustomerConfirmReservation/>}/>

            <Route exact path="/cx/memberships" element={<CustomerMemberships/>}/>
            <Route exact path="/cx/:guid" element={<CustomerDashboard/>}/>
            <Route exact path="/cx/:guid/pass/reset/:resetId" element={<CustomerResetPass/>}/>

            <Route exact path="/cx/my-profile" element={<CustomerProfile/>}/>
            <Route exact path="/cx/my-purchases" element={<CustomerPurchases/>}/>
            <Route exact path="/cx/register/:eventId" element={<CustomerViewEvent/>}/>
            <Route exact path="/cx/cart" element={<CustomerViewCart/>}/>

            <Route exact path="/cx/checkout" element={<CheckoutPage/>}/>
            <Route exact path="/cx/checkout/success" element={<CheckoutFinal/>}/>
            <Route exact path="/cx/checkout/cancel" element={<CheckoutFinal isCancel={true}/>}/>

            <Route path="/logout" element={<Logout/>}/>

          </Routes>
        </>
        }
      </ThemeProvider>
    </>
  );
}
