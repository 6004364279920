import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {AppContext} from "../../lib/AppContext";
import {useContext, useEffect, useState} from "react";
import {searchCompanies} from "../common/api/WebApi";

import * as pagination from "../common/pagination";
import DataTable from "react-data-table-component";
import BootyPagination from "../common/bootyPagination";
import ContextSearchNavbar from "../navbars/ContextSearchNavbar";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import MDTypography from "../../components/MDTypography";
import Footer from "../Footer";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import Card from "@mui/material/Card";
import Meta from "../common/Meta";
import MDButton from "../../components/MDButton";
import {formatDate} from "../common/Utils";
import PageLoading from "../common/PageLoading";
import MDInput from "../../components/MDInput";

export default function ListCompanies(props) {

  const [context, setContext] = useContext(AppContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [PaginationOptions, setPaginationOptions] = useState(pagination._PaginationOptions);

  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [render, setRender] = useState(0);
  const [sortDir, setSortDir] = useState(searchParams.get('sortDir') || 'asc');
  const [sortOrder, setSortOrder] = useState(searchParams.get('sortOrder') || 1);
  const [pageSize, setPageSize] = useState(searchParams.get('pageSize') || 20);
  const [startPage, setStartPage] = useState(searchParams.get('startPage') || 0);
  const [searchResults, setSearchResults] = useState([]);

  const filteredResults = (items) => {
    if (!searchCriteria || searchCriteria.length === 0) {
      return items;
    }
    let _items = [];
    for (let j in items) {
      let crit = items[j]?.companyName.toLowerCase();
      let match = crit.match(searchCriteria.toLowerCase());
      if (match && match.length > 0) {
        _items.push(items[j])
      }
    }
    return _items;
  }
  const _columns = [
    {
      name: <span className='th'>Name</span>,
      id: 1,
      selector: row => row.companyName,
      cell: row => {
        let link = '/company/' + row.guid;
        return <Link to={link} className="link"> {row.companyName} </Link>;
      },
      sortable: true,
    },
    {
      name: <span className='th'>Location</span>,
      id: 2,
      selector: row => row.title,
      cell: row => {
        return <span> {row.address1 + ' ' + row.city + ', ' + (row.state?.stateName || '') + row.postalCode} </span>;
      },
      sortable: false,
    },
    {
      name: <span className='th'>Phone</span>,
      id: 3,
      selector: row => row.title,
      cell: row => {
        return <span> {row.primaryPhone} </span>;
      },
      sortable: false,
    },
    {
      name: <span className='th'>Created On</span>,
      id: 4,
      selector: row => row.createDate,
      cell: row => {
        return <span> {formatDate(row.createDate)} </span>;
      },
      sortable: true,
    },
    {
      name: <span className='th'>Subdomain</span>,
      id: 5,
      selector: row => row.subdomain,
      cell: row => {
        return <span> {row.subdomain} </span>;
      },
      sortable: false,
    },
    {
      name: <span className='th'>Website</span>,
      id: 6,
      selector: row => row.website,
      cell: row => {
        return <span> {row.website} </span>;
      },
      sortable: false,
    },
    {
      name: <span className='th'>View</span>,
      id: 9,
      right: true,
      selector: row => row.guid,
      cell: row => {
        let link = '/company/' + row.guid;
        return <div><MDButton onClick={() => {
          navigate(link)
        }} size="small" color="primary">View</MDButton></div>
      },
      sortable: false,
    }
  ];

  const [columns, setColumns] = useState(_columns);

  const handleSort = (column, sortDirection) => {
    setSortOrder(column.id);
    setSortDir(sortDirection);
    setRender(render + 1);
  };

  const handlePageChange = (selector, page) => {
    setStartPage(selector);
  };

  const searchCompaniesFn = async () => {
    const searchParams = {
      sortDir: sortDir,
      sortOrder: sortOrder,
      pageSize: pageSize,
      startPage: startPage
    }
    let result = await searchCompanies(searchParams);
    if (result.error) {
      // todo: handle error
    } else {
      setSearchResults(result.data);
      setLoading(false);
    }
  };
  const [searchCriteria, setSearchCriteria] = useState();

  const handleSearchChange = (e) => {
    setSearchCriteria(e.target.value);
  }

  useEffect(() => {
    searchCompaniesFn();
  }, []);

  return (
    <>
      <DashboardLayout>
        <Meta pageTitle={'List Companies'}/>
        <ContextSearchNavbar title={'List Companies'}/>
        <MDBox mt={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <MDBox pb={3}>
                <MDBox mb={3}>
                  <Card>
                    <MDBox p={3} lineHeight={1}>

                      <MDButton
                        onClick={() => {
                          navigate('/companies/new');
                        }}
                        color="info"
                        size="small"
                        className="pull-right"
                        variant="contained">Add Company</MDButton>

                      <MDTypography variant="h5" fontWeight="regular">
                        List Companies
                      </MDTypography>
                      <Grid container justifyContent={'flex-end'} mt={2}>
                        <Grid item xs={12} sm={4}>
                          <MDInput type="text"
                                   variant="outlined"
                                   value={searchCriteria || ''}
                                   onChange={handleSearchChange}
                                   className="form-control mb-2" id="searchCriteria" aria-describedby="searchCriteria"
                                   label="Search"/>
                        </Grid>
                      </Grid>
                    </MDBox>
                    {!loading && <DataTable
                      striped
                      columns={columns}
                      keyField={'guid'}
                      data={filteredResults(searchResults)}
                      onSort={handleSort}
                      highlightOnHover
                      pagination
                      paginationPerPage={20}
                      paginationComponent={BootyPagination}
                      paginationTotalRows={totalRecords}
                      onChangePage={handlePageChange}
                      progressPending={loading}
                      defaultSortFieldId={1}
                      defaultSortAsc
                      paginationComponentOptions={PaginationOptions}
                    />}
                  </Card>
                </MDBox>

                {loading && <PageLoading/>}

              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <Footer mt={5}/>

      </DashboardLayout>
    </>
  );
}