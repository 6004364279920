import {AppContext} from "../../../lib/AppContext";
import {useContext, useEffect, useState} from "react";
import {displayDay, formatDate, handleDefaultValues} from "../../common/Utils";
import {useForm} from 'react-hook-form';
import {useNavigate} from "react-router-dom";
import {getInventoryEvent, getInventoryEvents} from "../../common/api/PortalCustomerApi";
import {Divider, Grid} from "@mui/material";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";

export default function EventsMiniPane(props) {
  const navigate = useNavigate();
  const [context, setContext] = useContext(AppContext);

  const [eventReady, setEventReady] = useState(false);
  const [event, setEvent] = useState();
  const [events, setEvents] = useState([]);
  const [eventsReady, setEventsReady] = useState(false);
  const [inventoryRender, setInventoryRender] = useState(1);


  useEffect(() => {

    let startDate = new Date();
    startDate.setHours(0, 0, 0, 0);

    let endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 1);

    let req = {
      start: startDate,
      end: endDate
    }
    getInventoryEventsFn(req)
  }, []);

  const getInventoryEventFn = async (eventId) => {
    getInventoryEvent(eventId).then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setEvent(result);
        setEventReady(true);
      }
    });
  }

  const [inventoryType, setInventoryType] = useState();
  const [formInvalid, setFormInvalid] = useState(false);

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(inventoryType)
  });

  const handleClose = () => {
    props.handleSave();
  };

  const handleSave = (data) => {
    props.handleSave(data);
  };

  const getInventoryEventsFn = async (req) => {
    setEventsReady(false);
    let result = await getInventoryEvents(req, inventoryRender);
    setEvents(result.events);
    setEventsReady(true);
  }

  const viewEvent = (e) => {
    navigate('/cx/register/' + e.guid);
  };

  const loadCartFn = () => {

    let _context = {...context};
    _context.reloadCart = true;
    _context.toast = {
      message: 'Added to cart!',
      type: 'success'
    }
    setContext({..._context});
  };

  return (
    <>
      <MDBox>
        <MDBox>
          {eventsReady && events?.length === 0 && <MDBox>
            <MDTypography variant="h5" color="secondary" textAlign="center" fontWeight="light">
              No Events Today
            </MDTypography>
          </MDBox>
          }
          {events?.length > 0 && <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MDBox width="100%" lineHeight={1}>
                  <MDTypography variant="h5" color="secondary" textAlign="right" fontWeight="light">
                    {formatDate(new Date())}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
            <Divider/>
            <Grid container justifyContent={'flex-end'}>
              <Grid item>
                <MDButton href={'/cx/events'} variant="gradient"
                          size={'small'}
                          color="secondary">
                  View All
                </MDButton>
              </Grid>
            </Grid>

            {events?.sort((a, b) => new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime())
              // .filter(item => showEvent(item))
              .map((event, index) => {
                return (
                  <div key={index}>
                    <div>
                      <h6>{event.name}</h6>
                      <p><small>{event.eventType?.name}
                        {event?.sessionEvent && <b> Session</b>}
                      </small>
                        <br/>
                        <small>
                          {displayDay(event.eventDate, 'hh:mm a')} - {displayDay(event.eventEndDate, 'hh:mm a')}
                        </small>
                      </p>
                      {
                        <MDBox display="flex" justifyContent="flex-end">
                          <MDButton variant="gradient"
                                    size={'small'}
                                    color="light"
                                    onClick={(e) => {
                                      viewEvent(event);
                                    }}>View Details</MDButton>
                        </MDBox>
                      }
                      <Divider/>
                    </div>
                  </div>
                )
              })
            }
          </>}
        </MDBox>
      </MDBox>
    </>
  )
}