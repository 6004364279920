import {Col, Container, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import {useContext, useEffect, useState} from "react";
import {handleDefaultValues} from "../common/Utils";
import {useForm} from 'react-hook-form';
import MDButton from "../../components/MDButton";
import MDInput from "../../components/MDInput";

export default function ManageAgeRangeTypeModal(props) {

  const [context, setContext] = useContext(AppContext);
  const [ageRangeType, setAgeRangeType] = useState(props.ageRangeType || {});
  const [formInvalid, setFormInvalid] = useState(false);

  useEffect(() => {
    setAgeRangeType(props?.ageRangeType);
  }, [props?.ageRangeType]);

  const handleFormChange = (e) => {
    setValue(e.target.name, e.target.value);
    let _obj = {...ageRangeType};
    _obj[e.target.name] = e.target.value;
    setAgeRangeType({..._obj});
  };

  const toggleMembershipType = (e) => {
    setValue(e.target.name, e.target.checked);
    let _obj = {...ageRangeType};
    _obj[e.target.name] = e.target.checked;
    setAgeRangeType({..._obj});
  }

  const [isNew, setIsNew] = useState(!!!props.ageRangeType?.guid);

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(ageRangeType)
  });

  const handleClose = () => {
    props.handleClose();
  };

  const handleSave = (data) => {
    props.handleSave(data);
  };


  return (
    <Modal onHide={handleClose} size={'lg'} show={props.show}>
      <form onSubmit={handleSubmit(handleSave)} id={'ageRangeTypeForm'}>
        <Modal.Header closeButton>
          <Modal.Title>{isNew ? 'Create New' : 'Manage'} Age Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className={'col col-main'} xs={12}>
              <Container>
                <Row>
                  <Col className={'col'} sm={12} xs={12}>
                    <div className="form-group">
                      <MDInput
                        type="text"
                        {...register('name')}
                        autoComplete="off" required
                        className="form-control" id="name"
                        label="Enter Age Type Name"/>

                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <MDButton type="submit" color="info" disabled={formInvalid || props.saving}>
            {props.saving ? 'Saving...' : 'Save'}
          </MDButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
}