import {useNavigate, useParams} from "react-router-dom";
import {AppContext} from "../../lib/AppContext";
import {useContext, useEffect, useRef, useState} from "react";
import {Card} from "@mui/material";
import Meta from "../common/Meta";
import ContextSearchNavbar from "../navbars/ContextSearchNavbar";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import Icon from "@mui/material/Icon";
import Footer from "../Footer";
import NotificationItem from "../Items/NotificationItem";
import Menu from "@mui/material/Menu";
import {getEmployeeTimecard, getMyCompanyEmployee, saveEmployee, saveEmployeeTimeEntry} from "../common/api/WebApi";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import moment from "moment";
import {formatDate, formatTime} from "../common/Utils";
import ManageTimeEntryModal from "./ManageTimeEntryModal";
import ManageEmployeeModal from "./ManageEmployee";

export default function ViewEmployee(props) {

  const myProfile = useState(props?.myProfile || false);

  const navigate = useNavigate();
  let {guid} = useParams();

  const DISPLAY_DATE_FORMAT = 'MM/DD/YYYY';

  const [context, setContext] = useContext(AppContext);

  const [saving, setSaving] = useState(false);
  const [manageEmployee, setManageEmployee] = useState(false);
  const [employee, setEmployee] = useState();

  const [editEmployee, setEditEmployee] = useState();


  const [timecard, setTimecard] = useState();
  const [timeEntry, setTimeEntry] = useState();
  const [manageTimeEntry, setManageTimeEntry] = useState(false);
  const [timeRequest, setTimeRequest] = useState();

  const [timeEntryOptions, setTimeEntryOptions] = useState([]);

  useEffect(() => {
    let _options = [];
    let day = new Date();
    day.setHours(0, 0, 0, 0);

    for (let i = 0; i < 4; i++) {
      let currentDate = moment(day);
      let weekStart = currentDate.clone().startOf('week');
      weekStart.add(-7 * i, 'days');
      _options.push(
        {
          label: 'Week of ' + weekStart.format(DISPLAY_DATE_FORMAT),
          value: weekStart.format('YYYY-MM-DD'),
          type: 'week'
        }
      )
    }

    for (let i = 0; i < 12; i++) {
      let currentDate = moment(day);
      let weekStart = currentDate.clone().startOf('month');
      weekStart.add(-1 * i, 'months');
      _options.push(
        {
          label: 'Month of ' + weekStart.format(DISPLAY_DATE_FORMAT),
          value: weekStart.format('YYYY-MM-DD'),
          type: 'month'
        }
      )
    }

    setTimeEntryOptions(_options);
    setTimeRequest(_options[0]);

  }, []);

  useEffect(() => {
    getEmployeeTimecardFn();
  }, [timeRequest]);

  const startManageEmployeeFn = (e) => {
    setEditEmployee({...e});
    setManageEmployee(true);
  };

  const handleSaveTimeEntry = (timeEntry) => {
    setSaving(true);
    let _timeEntry = {...timeEntry};
    _timeEntry.guid = myProfile ? context.sessionUser?.guid : guid;

    saveEmployeeTimeEntry(_timeEntry).then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setManageTimeEntry(false);
        getEmployeeTimecardFn();
      }
      setSaving(false);
    });

  }

  const handleCloseTimeEntry = () => {
    setManageTimeEntry(false);
  }

  useEffect(() => {
    getEmployeeFn();
  }, [guid]);

  const getEmployeeFn = () => {
    let employeeId = myProfile ? context.sessionUser?.guid : guid;
    getMyCompanyEmployee(employeeId).then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setEmployee(result);
      }
    });
  }

  const getEmployeeTimecardFn = () => {
    let _req = {};

    if (!timeRequest?.type) {
      return;
    }

    if (timeRequest.type === 'week') {
      let startOfWeek = moment(timeRequest.value).startOf('week');
      let endOfWeek = moment(timeRequest.value).endOf('week');
      _req.startDate = startOfWeek.toDate();
      _req.endDate = endOfWeek.toDate();
    } else if (timeRequest.type === 'month') {
      let startOfMonth = moment(timeRequest.value).startOf('month');
      let endOfMonth = moment(timeRequest.value).endOf('month');
      _req.startDate = startOfMonth.toDate();
      _req.endDate = endOfMonth.toDate();
    }

    _req.guid = myProfile ? context.sessionUser?.guid : guid;

    getEmployeeTimecard(_req).then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setTimecard(result);
      }
    });
  }

  const myRefs = useRef([]);
  const [renderIndex, setRenderIndex] = useState(1);

  const refHandler = (index) => {
    myRefs.current[index].visible = !!!myRefs.current[index].visible;
    setRenderIndex(renderIndex + 1);
  };


  const addTimeEntry = (e) => {
    setTimeEntry({
      startDate: new Date(),
      endDate: new Date(),
      timeEntryId: null
    });
    setManageTimeEntry(true);
  }

  const handleSaveEmployee = async (employee) => {
    setSaving(true);

    let result = await saveEmployee(employee);
    setSaving(false);

    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setManageEmployee(false);
      getEmployeeFn();
    }
  };

  const canEdit = (entry) => {
    const now = new Date();
    const start = new Date(entry.startDate);
    const startTime = now.getTime() - (7 * 60 * 60 * 24 * 1000);

    return start.getTime() > startTime;

  }

  const handleCloseEmployee = () => {
    setEditEmployee({});
    setManageEmployee(false);
  };

  return (
    <DashboardLayout>

      <Meta
        pageTitle={'View Employee' + (employee?.firstName ? ' - ' : '') + (employee?.firstName || '') + ' ' + (employee?.lastName || '')}/>

      <ContextSearchNavbar title={'View Employee'}/>
      {employee &&
        <MDBox py={3}>
          <Grid container spacing={3} cols={2}>
            <Grid item xs={12} sm={6}>
              <Card>
                <MDBox p={3}>
                  <MDTypography variant="h5" fontWeight="regular" className={'mb-3'}>
                    {employee?.firstName} {employee?.lastName}
                    <MDButton
                      size="small"
                      color="secondary"
                      className={'pull-right'}
                      onClick={(e) => {
                        startManageEmployeeFn(employee);
                      }}><Icon>edit</Icon>&nbsp; Edit</MDButton>

                  </MDTypography>
                  <p>{employee.address1} {employee.address2}
                    <br/>{employee.city}, {employee?.state?.stateName} {employee.zipCode}
                    <br/>{employee.primaryPhone}
                  </p>

                  {employee?.employeeStartDate && <p>
                    <small className={'ml-2'}>Hired: {formatDate(employee?.employeeStartDate)}</small>
                  </p>
                  }

                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Card>
                <MDBox p={3}>
                  <MDTypography variant="h5" fontWeight="regular" className={'mb-3'}>
                    <MDButton
                      size="small"
                      color="secondary"
                      className={'pull-right'}
                      onClick={(e) => {
                        addTimeEntry(e);
                      }}><Icon>add_circle</Icon>&nbsp; Add Time</MDButton>
                    Time Clock
                  </MDTypography>

                  <MDButton
                    variant="outlined"
                    color="secondary"
                    size="small"
                    ref={el => (myRefs.current['timeEntryOptions'] = el)}
                    onClick={(e) => {
                      refHandler('timeEntryOptions')
                    }}>

                    {timeRequest?.label}

                    &nbsp;<span className={'fa fa-caret-down ml-2 mt-n1'}/>

                    {renderIndex && myRefs.current['timeEntryOptions']?.visible &&
                      <Menu open={true} anchorReference={null} anchorEl={myRefs.current['timeEntryOptions']}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}>
                        {timeEntryOptions.map((option, index) => {
                          return (
                            <NotificationItem key={index}
                                              onClick={(e) => {
                                                setTimeRequest(option)
                                              }} icon={
                              <Icon>{option.type === 'month' ? 'calendar_month' : 'access_time'}</Icon>}
                                              title={option.label}
                            />
                          )
                        })}
                      </Menu>
                    }

                  </MDButton>
                  <Divider/>
                  <ul className={'list-group'}>
                    {timecard?.timecardEntryList?.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()).map((entry, index) => {
                      return (
                        <li
                          className={'list-group-item'}
                          key={index}>
                          {formatDate(entry.startDate) + ' ' + formatTime(entry.startDate)} - {formatTime(entry?.endDate)}

                          {canEdit(entry) && <MDButton variant="gradient"
                                                       size={'small'}
                                                       className={'pull-right'}
                                                       color="info"
                                                       onClick={(e) => {
                                                         setTimeEntry(entry);
                                                         setManageTimeEntry(true);
                                                       }}>Edit</MDButton>}
                        </li>
                      );
                    })}
                  </ul>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      }

      {manageTimeEntry && <ManageTimeEntryModal
        timeEntry={timeEntry}
        show={manageTimeEntry}
        saving={saving}
        handleSave={handleSaveTimeEntry}
        handleClose={handleCloseTimeEntry}/>
      }

      {
        <>

          {editEmployee && <ManageEmployeeModal
            employee={editEmployee}
            show={manageEmployee}
            saving={saving}
            handleSaveEmployee={handleSaveEmployee}
            handleClose={handleCloseEmployee}/>
          }

        </>
      }

      <Footer mt={5}/>

    </DashboardLayout>
  );
}