import config from "../config";

export const CONSTANTS = {
  HOST: '',
  UTYPE_CLIENT : 1,
  UTYPE_CUSTOMER : 2,
  UTYPE_ADMIN : 4,
  SESSION_USER: 'SESSION_USER',
  MENU: 'MENU',
  PANES: 'PANES',
  X_APP_HEADER: 'x-app-header',
  VISITOR_ID: 'visitor-id',
  TOAST_TIMEOUT: 2500,
  ENVIRONMENT: config.ENVIRONMENT,
  ENTITY_TYPE : {
    CUSTOMER: 1,
    INVENTORY: 2
  },
  USER_STATUS: {
    NONE : -1,
    ACTIVE : 1,
    INACTIVE : 2,
    PENDING : 3,
    DELETED : 4
  },
  CHARGE_TYPE: {
    CARD : 1,
    CASH : 2,
    GIFT_CARD : 3,
    UNSPECIFIED : 4
  },
  STATUS: {
    NONE : -1,
    ACTIVE : 1,
    INACTIVE : 2,
  },
  RESERVATION_STATUS: {
    NONE : -1,
    RESERVED : 1,
    CONFIRMED : 2,
    WAIT_LIST : 3,
    CHECKED_IN : 4,
    ARCHIVED : 5,
    CANCELLED: 6,
    EVENT_CANCELLED: 7
  },
  WAITLIST_STATUS: {
    NONE : -1,
    PENDING : 1,
    ACTIVE : 2,
    INACTIVE : 3
  },
};