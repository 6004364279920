import {useNavigate} from "react-router-dom";
import {AppContext} from "../../lib/AppContext";
import {useContext, useEffect, useState} from "react";
import {Card, CardContent} from "@mui/material";
import MDBox from "../../components/MDBox";
import CustomerHeader from "../common/CustomerHeader";
import Footer from "../Footer";
import CustomerPageLayout from "../Layout/CustomerPageLayout";
import Grid from "@mui/material/Grid";
import MDTypography from "../../components/MDTypography";
import Divider from "@mui/material/Divider";
import InventoryMiniPane from "./pane/InventoryMiniPane";
import CompanyCard from "./pane/CompanyCard";

export default function CustomerMemberships(props) {
  const [context, setContext] = useContext(AppContext);
  const [company, setCompany] = useState(context?.sessionUser?.company);

  const navigate = useNavigate();

  useEffect(() => {
    if (context.sessionUser?.companyGk) {
      setPageReady(true);
      setCompany(context.sessionUser.company);
    }
  }, [context?.sessionUser]);

  const [pageReady, setPageReady] = useState(false);

  const handleClose = () => {
    // setShowMembershipModal(false);
  }
  const handleSave = (data) => {
    //  setSavingMembership(true);
  }

  return (
    <>
      <CustomerPageLayout>

        <CustomerHeader props={props}/>

        {pageReady && <MDBox p={3}>
          <MDBox m={3}>
            <Grid container cols={3} spacing={3} direction="row">
              <Grid item sm={3} xs={12}>
                <CompanyCard/>
              </Grid>

              <Grid item sm={9} xs={12}>
                <Card>
                  <CardContent>
                    <MDTypography variant="h5" color="secondary" fontWeight="light">
                      Memberships
                      <Divider/>
                      <InventoryMiniPane/>
                    </MDTypography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        }

        <Footer/>
      </CustomerPageLayout>
    </>
  )
}