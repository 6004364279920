import {Button, Col, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {AppContext} from "../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {
  deleteAccountType,
  deleteAgeType,
  deleteCompanyTaskTemoplate,
  deleteEventCategory,
  deleteInventoryType,
  deleteLocation,
  getAccountTypes,
  getAgeRangeTypes,
  getEventLocations,
  getInventoryEventTypes,
  getInventoryTypes,
  getTaskLists,
  saveAccountType,
  saveAgeRangeType,
  saveEventLocation,
  saveInventoryEventType,
  saveInventoryType
} from "../common/api/InventoryApi";
import {Card, Chip, NativeSelect} from "@mui/material";
import Meta from "../common/Meta";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Footer from "../Footer";
import ManageInventoryEventTypeModal from "../inventory/ManageInventoryEventTypeModal";
import ManageInventoryTypeModal from "../inventory/ManageInventoryTypeModal";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import {useMaterialUIController} from "../../context";
import ManageAccountTypeModal from "../inventory/ManageAccountTypeModal";
import {displayPhone, handleDefaultValues} from "../common/Utils";
import {useForm} from "react-hook-form";
import {
  getCommunicationTemplates,
  getMyCompany,
  getStates,
  saveMyCompany,
  saveMyCompanyStripeSettings,
  saveMyCompanyTemplates,
  uploadCompanyLogo,
  uploadTemplateFile
} from "../common/api/WebApi";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import ContextSearchNavbar from "../navbars/ContextSearchNavbar";
import ManageEventLocationModal from "../inventory/ManageEventLocationModal";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import Menu from "@mui/material/Menu";
import NotificationItem from "../Items/NotificationItem";
import Confirm from "../common/Confirm";
import Avatar from "@mui/material/Avatar";
import ManageAgeRangeTypeModal from "../inventory/ManageAgeRangeTypeModal";
import ManageTaskListModal from "../inventory/ManageTaskListModal";
import SortEventTypes from "../inventory/SortEventTypes";
import SortInventoryTypes from "../inventory/SortInventoryTypes";
import SortAgeRangeTypes from "../inventory/SortAgeRangeTypes";
import SortAccountTypes from "../inventory/SortAccountTypes";
import SortLocations from "../inventory/SortLocations";
import ManageCompanyDetailsModal from "./ManageCompanyDetailsModal";

import {CKEditor} from '@ckeditor/ckeditor5-react';
import {ClassicEditor,} from 'ckeditor5';

import 'ckeditor5/ckeditor5.css';
import {CONFIG} from "../common/EditorConfig";


export default function CompanyCustomization(props) {
  const [controller] = useMaterialUIController();
  const {darkMode} = controller;

  const navigate = useNavigate();

  const [context, setContext] = useContext(AppContext);
  const [initialLoad, setInitialLoad] = useState(true);

  const [inventoryTypes, setInventoryTypes] = useState([]);
  const [taskLists, setTaskLists] = useState([]);

  const [inventoryEventTypes, setInventoryEventTypes] = useState([]);
  const [inventoryType, setInventoryType] = useState(null);
  const [taskList, setTaskList] = useState(null);

  const [accountTypes, setAccountTypes] = useState([]);
  const [ageRangeTypes, setAgeRangeTypes] = useState([]);
  const [eventLocations, setEventLocations] = useState([]);

  const [savingCompany, setSavingCompany] = useState(false);
  const [manageInventoryType, setManageInventoryType] = useState(false);
  const [manageTaskList, setManageTaskList] = useState(false);
  const [sortEventTypes, setSortEventTypes] = useState(false);
  const [sortLocations, setSortLocations] = useState(false);
  const [sortAgeRangeTypes, setSortAgeRangeTypes] = useState(false);
  const [sortAccountTypes, setSortAccountTypes] = useState(false);
  const [sortInventoryTypes, setSortInventoryTypes] = useState(false);
  const [isManageCompanyDetailsModal, setIsManageCompanyDetailsModal] = useState(false);

  const [manageInventoryEventType, setManageInventoryEventType] = useState(false);
  const [manageEventLocation, setManageEventLocation] = useState(false);

  const [inventoryEventType, setInventoryEventType] = useState(null);
  const [eventLocation, setEventLocation] = useState(null);
  const [confirmation, setConfirmation] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmDeleteAgeType, setConfirmDeleteAgeType] = useState(false);
  const [confirmDeleteInventoryType, setConfirmDeleteInventoryType] = useState(false);
  const [confirmDeleteTaskTemplate, setConfirmDeleteTaskTemplate] = useState(false);

  const [confirmDeleteAccountType, setConfirmDeleteAccountType] = useState(false);
  const [confirmDeleteLocation, setConfirmDeleteLocation] = useState(false);

  const [accountType, setAccountType] = useState(null);
  const [ageRangeType, setAgeRangeType] = useState(null);
  const [manageAccountType, setManageAccountType] = useState(false);
  const [manageAgeRangeType, setManageAgeRangeType] = useState(false);

  const [isInventoryEvent, setIsInventoryEvent] = useState(false);

  const [eventsLoading, setEventsLoading] = useState(true);

  const [init, setInit] = useState(false);

  const [company, setCompany] = useState();
  const [states, setStates] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [templatesReady, setTemplatesReady] = useState();
  const [saving, setSaving] = useState(false);

  const [reloadInventory, setReloadInventory] = useState(false);

  useEffect(() => {
    if (!init) {
      return;
    }
    setInit(false);
    loadInventoryTypes();
    loadInventoryEventTypes();
    loadAccountTypes();
    loadCompany();
    loadStates();
    getCompanyTemplates();
    loadEventLocations();
    loadAgeRangeTypes();
    loadTaskLists();

  }, [init]);

  useEffect(() => {
    setInit(true);
  }, []);

  const getCompanyTemplates = () => {
    getCommunicationTemplates().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {

        let defaultTemplate = {
          id: -1,
          templateName: "None"
        }
        result.unshift(defaultTemplate);
        setTemplates(result);
        setTemplatesReady(true);
      }
    });
  }

  const loadStates = () => {
    getStates().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setStates(result);
      }
    });
  }

  const addAccountTypeFn = () => {
    setAccountType(null);
    setManageAccountType(true);
  }

  const addAgeRangeTypFn = () => {
    setAgeRangeType(null);
    setManageAgeRangeType(true);
  }

  const manageAgeRangeTypFn = (type) => {
    setAgeRangeType(type);
    setManageAgeRangeType(true);
  }

  const loadAccountTypes = () => {
    getAccountTypes().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setAccountTypes(result);
      }
    });
  }

  const loadAgeRangeTypes = () => {
    getAgeRangeTypes().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setAgeRangeTypes(result);
      }
    });
  }

  const loadEventLocations = () => {
    getEventLocations().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setEventLocations(result);
      }
    });
  }

  const loadCompany = () => {
    getMyCompany().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setCompany(result);
      }
    });
  }

  const loadInventoryTypes = () => {
    getInventoryTypes().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setInventoryTypes(result);
      }
    });
  }

  const loadInventoryEventTypes = () => {
    getInventoryEventTypes().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setInventoryEventTypes(result);
      }
    });
  }

  const loadTaskLists = () => {
    getTaskLists().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setTaskLists(result);
      }
    });
  }

  const saveCompanyDetailsFn = async (file) => {
    let result = await uploadCompanyLogo(file);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      closeManageCompanyDetailsFn();
      loadCompany();
    }
  }

  const saveInventoryTypeFn = async (inventoryType) => {
    let result = await saveInventoryType(inventoryType);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      closeInventoryTypeFn();
      loadInventoryTypes();
    }
  }

  const handleSaveTask = async () => {
    closeTaskListFn();
    loadTaskLists();
  }

  const handleSaveEventTypesSort = async () => {
    setSortEventTypes(false);
    loadInventoryEventTypes();
  }

  const handleSaveAgeRangeTypesSort = async () => {
    setSortAgeRangeTypes(false);
    loadAgeRangeTypes();
  }

  const handleSaveLocationsSort = async () => {
    setSortLocations(false);
    loadEventLocations();
  }

  const handleSaveAccountTypesSort = async () => {
    setSortAccountTypes(false);
    loadAccountTypes();
  }

  const handleSaveInventoryTypesSort = async () => {
    setSortInventoryTypes(false);
    loadInventoryTypes();
  }


  const saveAgeRangeTypeFn = async (ageType) => {
    let result = await saveAgeRangeType(ageType);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      closeAgeRangeTypeFn();
      loadAgeRangeTypes();
    }
  }


  const closeAccountTypeFn = () => {
    setAccountType(null);
    setManageAccountType(false);
  }

  const closeEventLocationFn = () => {
    setEventLocation(null);
    setManageEventLocation(false);
  }


  const saveInventoryEventTypeFn = async (inventoryType) => {
    let result = await saveInventoryEventType(inventoryType);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      closeInventoryEventTypeFn();
      loadInventoryEventTypes();
    }
  }

  const saveAccountTypeFn = async (accountType) => {
    let result = await saveAccountType(accountType);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      closeAccountTypeFn();
      loadAccountTypes();
    }
  }

  const saveEventLocationFn = async (location) => {
    let result = await saveEventLocation(location);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      closeEventLocationFn();
      loadEventLocations();
    }
  }

  const addInventoryTypeFn = () => {
    setInventoryType(null);
    setManageInventoryType(true);
  }

  const manageTaskListFn = (taskList, isSort) => {
    let e = {...taskList};
    e.isSort = isSort;
    setTaskList({...e});
    setManageTaskList(true);
  }

  const addTaskListFn = () => {
    setTaskList(null);
    setManageTaskList(true);
  }

  const addInventoryEventTypeFn = () => {
    manageInventoryEventTypeFn();
  }

  const addEventLocationFn = () => {
    manageEventLocationFn();
  }

  const manageAccountTypeFn = (accountType) => {
    let t = null;
    if (accountType) {
      t = {...accountType};
    }
    t.recurrencePattern = t.recurrencePattern + '';

    setAccountType(t);
    setManageAccountType(true);
  }

  const manageInventoryEventTypeFn = (inventory) => {
    let t = null;
    if (inventory) {
      t = {...inventory};
    }
    setInventoryEventType(t);
    setManageInventoryEventType(true);
  }

  const manageEventLocationFn = (location) => {
    let t = null;
    if (location) {
      t = {...location};
    }
    setEventLocation(t);
    setManageEventLocation(true);
  }

  const manageInventoryTypeFn = (type) => {
    let t = null;
    if (type) {
      t = {...type};
    }
    setInventoryType(t);
    setManageInventoryType(true);
  }

  const closeInventoryEventTypeFn = () => {
    setInventoryType(null);
    setManageInventoryEventType(false);
  }

  const closeManageCompanyDetailsFn = () => {
    setIsManageCompanyDetailsModal(false);
  }

  const closeInventoryTypeFn = () => {
    setInventoryType(null);
    setManageInventoryType(false);
  }

  const closeTaskListFn = () => {
    setTaskList(null);
    setManageTaskList(false);
  }

  const closeInventoryEventTypeSortFn = () => {
    setSortEventTypes(false);
  }

  const closeInventoryTypeSortFn = () => {
    setSortInventoryTypes(false);
  }

  const closeAgeRangeTypeSortFn = () => {
    setSortAgeRangeTypes(false);
  }

  const closeLocationsSortFn = () => {
    setSortLocations(false);
  }

  const closeAccountTypeSortFn = () => {
    setSortAccountTypes(false);
  }

  const closeAgeRangeTypeFn = () => {
    setAgeRangeType(null);
    setManageAgeRangeType(false);
  }

  const handleArchiveEventCategory = async (inventory) => {
    let result = await deleteEventCategory(inventory.guid);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setConfirmDelete(false);
      loadInventoryEventTypes();
    }
  }

  const handleArchiveInventoryType = async (inventory) => {
    let result = await deleteInventoryType(inventory.guid);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setConfirmDeleteInventoryType(false);
      loadInventoryTypes();
    }
  }

  const handleArchiveTaskTemplate = async (inventory) => {
    let result = await deleteCompanyTaskTemoplate(inventory.guid);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setConfirmDeleteTaskTemplate(false);
      loadTaskLists();
    }
  }

  const handleArchiveAccountType = async (inventory) => {
    let result = await deleteAccountType(inventory.guid);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setConfirmDeleteAccountType(false);
      loadAccountTypes();
    }
  }
  const handleArchiveAgeType = async (inventory) => {
    let result = await deleteAgeType(inventory.guid);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setConfirmDeleteAgeType(false);
      loadAgeRangeTypes();
    }
  }
  const handleDeleteLocation = async (inventory) => {
    let result = await deleteLocation(inventory.guid);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setConfirmDeleteLocation(false);
      loadEventLocations();
    }
  }

  const handleCloseDeleteInventory = () => {
    setConfirmDelete(false);
  };

  const handleCloseAgeType = () => {
    setConfirmDeleteAgeType(false);
  }
  const handleCloseInventoryType = () => {
    setConfirmDeleteInventoryType(false);
  };

  const handleCloseTaskTemplate = () => {
    setConfirmDeleteTaskTemplate(false);
  };

  const handleCloseAccountType = () => {
    setConfirmDeleteAccountType(false);
  };

  const handleCloseLocation = () => {
    setConfirmDeleteLocation(false);
  }

  const handleSaveCompanyAboutUs = async (company) => {

    let _c = {...company};
    _c.aboutUs = aboutUsBody;
    setSavingCompany(true);

    setCompany({...company});

    let result = await saveMyCompany(_c);

    setSavingCompany(false);

    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      // setRefreshCompany(true);
      // setOnEdit(false);
    }
  }

  const handleSaveCompany = async (company) => {

    setSavingCompany(true);

    let result = await saveMyCompany(company);

    setSavingCompany(false);

    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      // setRefreshCompany(true);
      // setOnEdit(false);
    }
  }

  const handleSaveStripeSettings = async (company) => {

    setSavingCompany(true);

    let result = await saveMyCompanyStripeSettings(company);

    setSavingCompany(false);

    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      // setRefreshCompany(true);
      // setOnEdit(false);
    }
  }

  const handleSaveCompanyTemplates = async (company) => {

    setSavingCompany(true);

    let result = await saveMyCompanyTemplates(company);

    setSavingCompany(false);

    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      // setRefreshCompany(true);
      // setOnEdit(false);
    }
  }

  const defaultvalues = {
    eventRegistrationTemplate: {
      id: -1
    }
  }

  const {register, handleSubmit, setValue} = useForm({
    values: handleDefaultValues(company)
  });

  const sidenavItems = [
    {icon: "store", label: "Company Info", href: "company-info"},
    {icon: "storefront", label: "About Us", href: "about-us-settings"},
    {icon: "payments", label: "Stripe Settings", href: "stripe-settings"},
    {icon: "email", label: "Communication Templates", href: "communication-templates"},
    {icon: "check", label: "Task Lists", href: "task-lists"},
    {icon: "sell", label: "Inventory Types", href: "inventory-types"},
    {icon: "event", label: "Event Categories", href: "event-categories"},
    {icon: "room", label: "Event Locations", href: "event-locations"},
    {icon: "apps", label: "Account Types", href: "account-types"},
    {icon: "margin", label: "Age Ranges", href: "age-ranges"},
  ];

  const confirmDeleteInventory = (inventory) => {
    let confirmation = {
      title: 'Archive ' + inventory.name + '?',
      message: 'Confirm archive ' + inventory.name + '?',
      entity: inventory
    }
    setConfirmation(confirmation);
    setConfirmDelete(true);
  }

  const confirmDeleteEventLocation = (inventory) => {
    let confirmation = {
      title: 'Archive ' + inventory.name + '?',
      message: 'Confirm archive ' + inventory.name + '?',
      entity: inventory
    }
    setConfirmation(confirmation);
    setConfirmDeleteLocation(true);
  }

  const confirmDeleteInventoryTypeFn = (inventory) => {
    let confirmation = {
      title: 'Archive ' + inventory.name + '?',
      message: 'Confirm archive ' + inventory.name + '?',
      entity: inventory
    }
    setConfirmation(confirmation);
    setConfirmDeleteInventoryType(true);
  }

  const confirmDeleteTaskListFn = (taskTemplate) => {
    let confirmation = {
      title: 'Archive ' + taskTemplate.name + '?',
      message: 'Confirm archive ' + taskTemplate.name + '?',
      entity: taskTemplate
    }
    setConfirmation(confirmation);
    setConfirmDeleteTaskTemplate(true);
  }

  const confirmDeleteAccountTypeFn = (inventory) => {
    let confirmation = {
      title: 'Archive ' + inventory.name + '?',
      message: 'Confirm archive ' + inventory.name + '?',
      entity: inventory
    }
    setConfirmation(confirmation);
    setConfirmDeleteAccountType(true);
  }

  const confirmDeleteAgeTypeFn = (inventory) => {
    let confirmation = {
      title: 'Archive ' + inventory.name + '?',
      message: 'Confirm archive ' + inventory.name + '?',
      entity: inventory
    }
    setConfirmation(confirmation);
    setConfirmDeleteAgeType(true);
  }

  const EventCategoryActions = ({item}) => {
    return (<PopupState variant="popover" popupId={'inventory-popup-menu-' + item.id}>
      {(popupState) => (
        <>
          <span variant="contained" {...bindTrigger(popupState)}>
            <Icon>more_horiz</Icon>
          </span>
          <Menu {...bindMenu(popupState)}>
            <NotificationItem
              onClick={(e) => {
                popupState.close();
                manageInventoryEventTypeFn(item)
              }} icon={<Icon>edit</Icon>} title="Edit"/>
            <NotificationItem onClick={(e) => {
              popupState.close();
              confirmDeleteInventory(item)
            }} icon={<Icon>delete</Icon>} title="Archive"/>
          </Menu>
        </>
      )}
    </PopupState>);
  }

  const EventLocationActions = ({item}) => {
    return (<PopupState variant="popover" popupId={'inventory-popup-menu-' + item.id}>
      {(popupState) => (
        <>
          <Button variant="contained" {...bindTrigger(popupState)}>
            <Icon>more_horiz</Icon>
          </Button>
          <Menu {...bindMenu(popupState)}>
            <NotificationItem
              onClick={(e) => {
                popupState.close();
                manageEventLocationFn(item)
              }} icon={<Icon>edit</Icon>} title="Edit"/>
            <NotificationItem onClick={(e) => {
              popupState.close();
              confirmDeleteEventLocation(item)
            }} icon={<Icon>delete</Icon>} title="Archive"/>
          </Menu>
        </>
      )}
    </PopupState>);
  }

  const AccountTypeActions = ({item}) => {
    return (<PopupState variant="popover" popupId={'inventory-popup-menu-' + item.id}>
      {(popupState) => (
        <>
          <Button variant="contained" {...bindTrigger(popupState)}>
            <Icon>more_horiz</Icon>
          </Button>
          <Menu {...bindMenu(popupState)}>
            <NotificationItem
              onClick={(e) => {
                popupState.close();
                manageAccountTypeFn(item)
              }} icon={<Icon>edit</Icon>} title="Edit"/>
            <NotificationItem onClick={(e) => {
              popupState.close();
              confirmDeleteAccountTypeFn(item)
            }} icon={<Icon>delete</Icon>} title="Archive"/>
          </Menu>
        </>
      )}
    </PopupState>);
  }

  const AgeTypeActions = ({item}) => {
    return (<PopupState variant="popover" popupId={'inventory-popup-menu-' + item.id}>
      {(popupState) => (
        <>
          <Button variant="contained" {...bindTrigger(popupState)}>
            <Icon>more_horiz</Icon>
          </Button>
          <Menu {...bindMenu(popupState)}>
            <NotificationItem
              onClick={(e) => {
                popupState.close();
                manageAgeRangeTypFn(item)
              }} icon={<Icon>edit</Icon>} title="Edit"/>
            <NotificationItem onClick={(e) => {
              popupState.close();
              confirmDeleteAgeTypeFn(item)
            }} icon={<Icon>delete</Icon>} title="Archive"/>
          </Menu>
        </>
      )}
    </PopupState>);
  }

  const EventTypeActions = ({item}) => {
    return (<PopupState variant="popover" popupId={'inventory-popup-menu-' + item.id}>
      {(popupState) => (
        <>
          <Button variant="contained" {...bindTrigger(popupState)}>
            <Icon>more_horiz</Icon>
          </Button>
          <Menu {...bindMenu(popupState)}>
            <NotificationItem
              onClick={(e) => {
                popupState.close();
                manageInventoryTypeFn(item)
              }} icon={<Icon>edit</Icon>} title="Edit"/>
            <NotificationItem onClick={(e) => {
              popupState.close();
              confirmDeleteInventoryTypeFn(item)
            }} icon={<Icon>delete</Icon>} title="Archive"/>
          </Menu>
        </>
      )}
    </PopupState>);
  }

  const TaskListActions = ({item}) => {
    return (<PopupState variant="popover" popupId={'inventory-popup-menu-' + item.id}>
      {(popupState) => (
        <>
          <Button variant="contained" {...bindTrigger(popupState)}>
            <Icon>more_horiz</Icon>
          </Button>
          <Menu {...bindMenu(popupState)}>
            <NotificationItem
              onClick={(e) => {
                popupState.close();
                manageTaskListFn(item)
              }} icon={<Icon>edit</Icon>} title="Edit"/>
            <NotificationItem
              onClick={(e) => {
                popupState.close();
                manageTaskListFn(item, true)
              }} icon={<Icon>sort</Icon>} title="Sort"/>
            <NotificationItem onClick={(e) => {
              popupState.close();
              confirmDeleteTaskListFn(item)
            }} icon={<Icon>delete</Icon>} title="Archive"/>
          </Menu>
        </>
      )}
    </PopupState>);
  }

  const displayRepeats = (item) => {
    if (item.repeatExpression) {
      let arr = item.repeatExpression.split(',');
      let days = [];
      arr.forEach((a, i) => {
        days.push(a.replace('MON', 'Monday') //
          .replace('TUE', 'Tuesday') //
          .replace('WED', 'Wednesday') //
          .replace('THU', 'Thursday') //
          .replace('FRI', 'Friday') //
          .replace('SAT', 'Saturday') //
          .replace('SUN', 'Sunday'));
      });
      if (days.length === 7) {
        return 'Repeats Daily';
      }
      return 'Repeats ' + days.join(', ');
    }
    return '';
  }

  const [stripeFormValid, setStripeFormValid] = useState(false);
  const [stripeForm, setStripeForm] = useState({
    stripePublicKey: '',
    stripeSecretKey: '',
    stripeWebhookSecret: ''
  });

  useEffect(() => {
    if (company) {
      setStripeForm({
        stripePublicKey: company.stripePublicKey || '',
        stripeSecretKey: company.stripeSecretKey || '',
        stripeWebhookSecret: company.stripeWebhookSecret || ''
      });
    }
  }, [company]);

  useEffect(() => {
    let _stripeForm = {...stripeForm};
    let isValid = _stripeForm.stripePublicKey.length > 55 &&
      _stripeForm.stripeSecretKey.length > 55 &&
      _stripeForm.stripeWebhookSecret.length > 35;

    setStripeFormValid(isValid);

  }, [stripeForm]);

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {

          const body = new FormData();
          loader.file.then((file) => {
            const fileType = file.type;
            const fileName = file.name;
            const reader = new FileReader();

            reader.onload = function (evt) {
              let fileRequest = {
                fileType: fileType,
                fileName: fileName,
                data: btoa(evt.target.result)
              }

              uploadTemplateFile(fileRequest).then(result => {
                if (result.error) {
                  setContext({...context, error: {message: result.message}});
                } else {
                  loader.uploaded = true;
                  loader.uploadUrl = result;
                }
                resolve({
                  default: result.fileLocation
                })
              });
            }
            reader.readAsBinaryString(file);
          });
        })
      }
    }
  }

  const [aboutUsBody, setAboutUsBody] = useState(company?.aboutUs);
  const [blurEditor, setBlurEditor] = useState();

  const insertText = (text) => {
    if (!blurEditor) {
      return;
    }
    blurEditor.model.change(writer => {
      writer.insertText(text + " ", blurEditor.model.document.selection.getFirstPosition());
      writer.setSelection(writer.createPositionAt(blurEditor.model.document.getRoot(), 'end'));
    });
  };

  useEffect(() => {
    setAboutUsBody(company?.aboutUs);
  }, [company?.aboutUs]);

  const handleChange = (e, editor) => {
    setAboutUsBody(editor.getData());
  }

  const handleReady = (editor) => {
    setBlurEditor(editor);
  };

  const handleBlur = (event, editor) => {
    setBlurEditor(editor);
  };

  const renderSidenavItems = sidenavItems.map(({icon, label, href}, key) => {
    const itemKey = `item-${key}`;

    return (
      <MDBox key={itemKey} component="li" pt={key === 0 ? 0 : 1}>
        <MDTypography
          component="a"
          href={`#${href}`}
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          sx={({
                 borders: {borderRadius},
                 functions: {pxToRem},
                 palette: {light},
                 transitions,
               }) => ({
            display: "flex",
            alignItems: "center",
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            "&:hover": {
              backgroundColor: light.main,
            },
          })}
        >
          <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}>
            <Icon fontSize="small">{icon}</Icon>
          </MDBox>
          {label}
        </MDTypography>
      </MDBox>
    );
  });

  return (
    <DashboardLayout>
      <Meta pageTitle={'Company Customization'}/>
      <ContextSearchNavbar title="Company Customization"/>

      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Card
              sx={{
                borderRadius: ({borders: {borderRadius}}) => borderRadius.lg,
                position: "sticky",
                top: "95px",
              }}
            >
              <MDBox
                component="ul"
                display="flex"
                flexDirection="column"
                p={2}
                m={0}
                sx={{listStyle: "none"}}
              >
                {renderSidenavItems}
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>

              <Grid container spacing={3}>
                <div id="company-info" style={{
                  position: 'relative',
                  left: '-999em',
                  top: '-100px'
                }}></div>
                <Grid item xs={12}>
                  <Card>
                    <MDBox p={3}>
                      <MDTypography variant="h5" fontWeight="regular" className={'mb-2'}>
                        Company Info
                      </MDTypography>
                      {company && <>
                        <form onSubmit={handleSubmit(handleSaveCompany)} id={'companyForm'}>
                          <Row>
                            <Col className={'col'} sm={6} xs={12}>
                              <div className="form-group">
                                <MDInput
                                  variant="standard" type="text"
                                  {...register('companyName')}
                                  autoComplete="off" disabled
                                  className="form-control" id="companyName"
                                  label="Enter Company Name"/>
                              </div>
                            </Col>

                            <Col className={'col'} sm={6} xs={12}>
                              <div className="form-group">

                                <MDInput
                                  variant="standard" type="text"
                                  {...register('subdomain')}
                                  autoComplete="off"
                                  className="form-control" id="subdomain"
                                  label="Subdomain"/>
                              </div>
                            </Col>
                          </Row>
                          <Row className={'pt-2'}>
                            <Col className={'col'} md={6} sm={12} xs={12}>
                              <div className="form-group">
                                <MDInput
                                  variant="standard" type="text"
                                  {...register('website')}
                                  autoComplete="off"
                                  className="form-control" id="website"
                                  label="Website"/>
                              </div>
                            </Col>

                            <Col className={'col'} md={6} sm={12} xs={12}>
                              <div className="form-group">
                                <MDInput
                                  variant="standard" type="text"
                                  {...register('primaryPhone', {
                                    onChange: (e) => {
                                      e.target.value = displayPhone(e.target.value);
                                    }
                                  })}
                                  autoComplete="off"
                                  className="form-control" id="primaryPhone"
                                  label="Enter Phone"/>
                              </div>
                            </Col>
                          </Row>
                          <Row className={'pt-2'}>
                            <Col className={'col'} sm={6} xs={12}>
                              <div className="form-group">

                                <MDInput
                                  variant="standard" type="text"
                                  {...register('address1')}
                                  autoComplete="off"
                                  className="form-control" id="address1"
                                  label="Enter Address"/>

                              </div>
                            </Col>

                            <Col className={'col'} sm={6} xs={12}>
                              <div className="form-group">
                                <MDInput
                                  variant="standard" type="text"
                                  {...register('address2')}
                                  autoComplete="off"
                                  className="form-control" id="address2"
                                  label="Enter Address 2"/>
                              </div>
                            </Col>
                          </Row>
                          <Row className={'pt-2'}>
                            <Col className={'col'} sm={4} xs={12}>
                              <div className="form-group">
                                <MDInput
                                  variant="standard" type="text"
                                  {...register('city')}
                                  autoComplete="off"
                                  className="form-control" id="city"
                                  label="Enter City"/>
                              </div>
                            </Col>

                            <Col className={'col'} sm={4} xs={12}>
                              <div className="form-group">
                                {states?.length && <NativeSelect
                                  variant="outlined"
                                  id={'state'} name={'state'} className={'form-control'}
                                  {...register('state.stateId')}>
                                  {
                                    states.map((c) =>
                                      <option value={c.stateId} key={c.stateId}>
                                        {c.stateName}
                                      </option>)
                                  }
                                </NativeSelect>}

                              </div>
                            </Col>

                            <Col className={'col'} sm={4} xs={12}>
                              <div className="form-group">
                                <MDInput
                                  variant="standard" type="text"
                                  {...register('postalCode')}
                                  autoComplete="off"
                                  className="form-control" id="postalCode"
                                  label="Enter Zip"/>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <MDButton className={'mt-2 pull-right'}
                                        type="submit"
                                        variant="gradient"
                                        size={'small'}
                                        disabled={savingCompany}
                                        color="info">
                                {savingCompany ? 'Saving' : 'Save'}
                              </MDButton>
                            </Col>
                          </Row>

                          <Row>
                            <Col className={'col'} sm={4} xs={12}>
                              <div className="form-group">
                                <MDInput
                                  variant="standard" step="1" required
                                  {...register('defaultSalesTaxPercentage', {
                                    valueAsNumber: true,
                                  })}
                                  defaultValue={'0'}
                                  autoComplete="off"
                                  className="form-control" id="defaultSalesTaxPercentage"
                                  label="Enter Default Sale Tax"/>
                              </div>
                            </Col>
                          </Row>

                        </form>

                        <Row className={'pt-2'}>
                          <Col className={'col'} md={12} sm={12} xs={12}>
                            <div className="form-group">
                              <label>Public Member Portal</label>
                              <MDTypography variant="p" fontWeight="medium">
                                <br/>
                                <a href={company?.portalUrl} target="_blank">
                                  <Icon>launch</Icon>{company?.portalUrl}
                                </a>
                              </MDTypography>
                            </div>

                            {company?.logoUrl &&
                              <>
                                <img
                                  className={'my-2 d-block img-thumbnail'} src={company?.logoUrl} alt="img"/>
                              </>
                            }
                            <MDButton
                              color="secondary"
                              size="small"
                              className="d-block p-2"
                              onClick={() => {
                                setIsManageCompanyDetailsModal(true);
                              }}>Upload Logo</MDButton>

                          </Col>
                        </Row>
                      </>
                      }
                    </MDBox>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <div id="about-us-settings" style={{
                    position: 'relative',
                    left: '-999em',
                    zIndex: '-999',
                    top: '-100px'
                  }}/>
                  <Card>
                    <MDBox p={3}>
                      {company &&
                        <form onSubmit={handleSubmit(handleSaveCompanyAboutUs)} id={'companyAboutUsForm'}>
                          <MDTypography variant="h5" fontWeight="medium">
                            About Us
                          </MDTypography>
                          <p className={'py-2 fw-light'}>
                            Company About Us. This will be the display company details on the public portal.
                          </p>
                          <CKEditor
                            editor={ClassicEditor}
                            config={{
                              htmlSupport: {
                                allow: [
                                  {
                                    name: /.*/,
                                    attributes: true,
                                    classes: true,
                                    styles: true
                                  }
                                ]
                              },
                              placeholder: 'About Us',
                              toolbar: CONFIG.TOOLBAR,
                              plugins: CONFIG.PLUGINS,
                              extraPlugins: [
                                uploadPlugin
                              ]
                            }}
                            data={company?.aboutUs || ''}
                            onReady={editor => {
                              handleReady(editor);
                            }}
                            onChange={(event, editor) => {
                              handleChange(event, editor);
                            }}
                            onBlur={(event, editor) => {
                              handleBlur(event, editor);
                            }}
                            onFocus={(event, editor) => {
                            }}
                          />

                          <Row>
                            <Col>
                              <MDTypography variant="h5"
                                            sx={{
                                              marginTop: '20px'
                                            }}
                                            fontWeight="medium">
                                Company Details
                              </MDTypography>
                              <p className={'py-2 fw-light'}>
                                Use these fields for other public customer-facing information.
                              </p>

                              <MDInput
                                className="mt-2"
                                variant="standard" type="text"
                                {...register('defaultLocation')}
                                autoComplete="off"
                                className="form-control" id="defaultLocation"
                                label="Enter Display Location"/>

                              <MDInput
                                sx={{
                                  marginTop: '20px'
                                }}
                                variant="standard" type="text"
                                {...register('contactPhone', {
                                  onChange: (e) => {
                                    e.target.value = displayPhone(e.target.value);
                                  }
                                })}
                                autoComplete="off"
                                className="form-control" id="contactPhone"
                                label="Enter Contact Phone"/>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <MDButton className={'mt-2 pull-right'}
                                        type="submit"
                                        variant="gradient"
                                        size={'small'}
                                        disabled={savingCompany}
                                        color="info">
                                {savingCompany ? 'Saving' : 'Save'}
                              </MDButton>
                            </Col>
                          </Row>

                        </form>
                      }
                    </MDBox>

                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <div id="stripe-settings" style={{
                    position: 'relative',
                    left: '-999em',
                    zIndex: '-999',
                    top: '-100px'
                  }}/>
                  <Card>
                    <MDBox p={3}>
                      <MDTypography variant="h5" fontWeight="medium">
                        Stripe Settings
                      </MDTypography>

                      <p className={'py-2 fw-light'}>
                        Stripe Settings are required to process payments and manage subscriptions.
                        You can find your Stripe keys in your Stripe account.
                      </p>
                      {company && <form onSubmit={handleSubmit(handleSaveStripeSettings)} id={'companyStripeForm'}>

                        <Row className={'pt-2'}>
                          <Col className={'col'} md={12} sm={12} xs={12}>
                            <div className="form-group">
                              <MDInput
                                variant="standard" type="text"
                                {...register('stripePublicKey', {
                                  onChange: (e) => {
                                    setStripeForm({
                                      ...stripeForm,
                                      stripePublicKey: e.target.value
                                    });
                                  }
                                })}
                                placeholder="pk_************"
                                autoComplete="off"
                                className="form-control" id="stripePublicKey"
                                label="Enter Publishable Key"/>
                            </div>
                          </Col>

                          <Col className={'col'} md={12} sm={12} xs={12}>
                            <div className="form-group">
                              <MDInput
                                variant="standard" type="password"
                                {...register('stripeSecretKey', {
                                  onChange: (e) => {
                                    setStripeForm({
                                      ...stripeForm,
                                      stripeSecretKey: e.target.value
                                    });
                                  }
                                })}
                                placeholder="sk_************"
                                autoComplete="off"
                                className="form-control" id="stripeSecretKey"
                                label="Enter Secret Key"/>
                            </div>
                          </Col>

                          <Col className={'col'} md={12} sm={12} xs={12}>
                            <div className="form-group">
                              <MDInput
                                variant="standard" type="text"

                                {...register('stripeWebhookSecret', {
                                  onChange: (e) => {
                                    setStripeForm({
                                      ...stripeForm,
                                      stripeWebhookSecret: e.target.value
                                    });
                                  }
                                })}
                                placeholder="whsec_************"
                                autoComplete="off"
                                className="form-control" id="stripeWebhookSecret"
                                label="Enter Webhook Signing Secret"/>
                            </div>
                          </Col>

                        </Row>

                        <Row>
                          <Col>
                            <MDButton className={'mt-2 pull-right'}
                                      type="submit"
                                      variant="gradient"
                                      size={'small'}
                                      disabled={!stripeFormValid || savingCompany}
                                      color="info">
                              {savingCompany ? 'Saving' : 'Save'}
                            </MDButton>
                          </Col>
                        </Row>

                      </form>}
                    </MDBox>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <div id="communication-templates" style={{
                    position: 'relative',
                    left: '-999em',
                    zIndex: '-999',
                    top: '-100px'
                  }}/>
                  <Card>
                    <MDBox p={3}>
                      <MDTypography variant="h5" fontWeight="medium">
                        Communication Templates
                        <MDButton
                          color="secondary"
                          size="small"
                          className={'pull-right'}
                          onClick={(e) => {
                            navigate('/communication/templates')
                          }}>
                          <Icon>email</Icon>&nbsp;Add Communication Template
                        </MDButton>
                      </MDTypography>
                      <p className={'py-2 fw-light'}>
                        Use communication templates to define the content of emails and messages sent to customers
                        in automated messages and actions.
                      </p>
                      {company && <form onSubmit={handleSubmit(handleSaveCompanyTemplates)} id={'companyTemplatesForm'}>
                        <Row className={'mt-2'}>
                          <Col className={'col'} sm={6} xs={12}>
                            <MDTypography variant="p" fontWeight="medium">
                              New Account Created
                            </MDTypography>
                            <div className="form-group">
                              {templates?.length && <NativeSelect
                                variant="outlined"
                                id={'newAccount'} name={'newAccount'} className={'form-control'}
                                {...register('newAccountTemplate.id', {
                                  valueAsNumber: true,
                                })}>
                                {
                                  templates.map((c) =>
                                    <option value={c.id} key={c.id}>
                                      {c.templateName}
                                    </option>)
                                }
                              </NativeSelect>}

                            </div>
                          </Col>

                          <Col className={'col'} sm={6} xs={12}>
                            <MDTypography variant="p" fontWeight="medium">
                              Event Registration Confirmation
                            </MDTypography>
                            <div className="form-group">
                              {templates?.length && <NativeSelect
                                variant="outlined"
                                id={'eventRegistrationTemplate'} name={'eventRegistrationTemplate'}
                                className={'form-control'}
                                {...register('eventRegistrationTemplate.id', {
                                  valueAsNumber: true
                                })}>
                                {
                                  templates.map((c) =>
                                    <option value={c.id} key={c.id}>
                                      {c.templateName}
                                    </option>)
                                }
                              </NativeSelect>}

                            </div>
                          </Col>

                        </Row>
                        <Row className={'mt-2'}>
                          <Col className={'col'} sm={6} xs={12}>
                            <MDTypography variant="p" fontWeight="medium">
                              Purchase Receipt
                            </MDTypography>
                            <div className="form-group">
                              {templates?.length && <NativeSelect
                                variant="outlined"
                                id={'purchaseReceiptTemplate'} name={'purchaseReceiptTemplate'}
                                className={'form-control'}
                                {...register('purchaseReceiptTemplate.id', {
                                  valueAsNumber: true
                                })}>
                                {
                                  templates.map((c) =>
                                    <option value={c.id} key={c.id}>
                                      {c.templateName}
                                    </option>)
                                }
                              </NativeSelect>}

                            </div>
                          </Col>

                          <Col className={'col'} sm={6} xs={12}>
                            <MDTypography variant="p" fontWeight="medium">
                              Password Reset
                            </MDTypography>
                            <div className="form-group">
                              {templates?.length && <NativeSelect
                                variant="outlined"
                                id={'passwdResetTemplate'} name={'passwdResetTemplate'}
                                className={'form-control'}
                                {...register('passwdResetTemplate.id', {
                                  valueAsNumber: true
                                })}>
                                {
                                  templates.map((c) =>
                                    <option value={c.id} key={c.id}>
                                      {c.templateName}
                                    </option>)
                                }
                              </NativeSelect>}

                            </div>
                          </Col>

                        </Row>
                        <Row className={'mt-2'}>
                          <Col className={'col'} sm={6} xs={12}>
                            <MDTypography variant="p" fontWeight="medium">
                              Event Cancellation
                            </MDTypography>
                            <div className="form-group">
                              {templates?.length && <NativeSelect
                                variant="outlined"
                                id={'eventCancellationTemplate'} name={'eventCancellationTemplate'}
                                className={'form-control'}
                                {...register('eventCancellationTemplate.id', {
                                  valueAsNumber: true
                                })}>
                                {
                                  templates.map((c) =>
                                    <option value={c.id} key={c.id}>
                                      {c.templateName}
                                    </option>)
                                }
                              </NativeSelect>}

                            </div>
                          </Col>

                          <Col className={'col'} sm={6} xs={12}>
                            <MDTypography variant="p" fontWeight="medium">
                              Wait List Notification
                            </MDTypography>
                            <div className="form-group">
                              {templates?.length && <NativeSelect
                                variant="outlined"
                                id={'eventWaitlistTemplate'} name={'eventWaitlistTemplate'}
                                className={'form-control'}
                                {...register('eventWaitlistTemplate.id', {
                                  valueAsNumber: true
                                })}>
                                {
                                  templates.map((c) =>
                                    <option value={c.id} key={c.id}>
                                      {c.templateName}
                                    </option>)
                                }
                              </NativeSelect>}

                            </div>
                          </Col>

                        </Row>

                        <Row className={'mt-2'}>
                          <Col className={'col'} sm={6} xs={12}>
                            <MDTypography variant="p" fontWeight="medium">
                              New Invoice Notification
                            </MDTypography>
                            <div className="form-group">
                              {templates?.length && <NativeSelect
                                variant="outlined"
                                id={'invoiceCreationTemplate'} name={'invoiceCreationTemplate'}
                                className={'form-control'}
                                {...register('invoiceCreationTemplate.id', {
                                  valueAsNumber: true
                                })}>
                                {
                                  templates.map((c) =>
                                    <option value={c.id} key={c.id}>
                                      {c.templateName}
                                    </option>)
                                }
                              </NativeSelect>}

                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <MDButton className={'mt-2 pull-right'}
                                      type="submit"
                                      variant="gradient"
                                      size={'small'}
                                      disabled={savingCompany}
                                      color="info">
                              {savingCompany ? 'Saving' : 'Save'}
                            </MDButton>
                          </Col>
                        </Row>
                      </form>}
                    </MDBox>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <div id="task-lists" style={{
                    position: 'relative',
                    left: '-999em',
                    zIndex: '-999',
                    top: '-100px'
                  }}/>
                  <Card>
                    <MDBox p={3}>
                      <MDTypography variant="h5" fontWeight="medium">
                        Task Lists
                        <MDButton
                          color="secondary"
                          size="small"
                          className={'pull-right'}
                          onClick={(e) => {
                            addTaskListFn();
                          }}>
                          <Icon>check</Icon>&nbsp;Add Task List
                        </MDButton>
                      </MDTypography>
                      <p className={'py-2 fw-light'}>
                        Use task lists to define daily tasks for your company and employees.
                        For example, "clean the play space", "check inventory", "open the facility", etc.
                        <br/> Tasks will be created each day from the defined templates.
                      </p>

                      <ul className={'list-group'}>
                        {taskLists.map((type, index) => {
                          return (
                            <li
                              className={'list-group-item'}
                              key={index}>
                              <>
                                <span className="d-block pull-right">
                                    <TaskListActions item={type}/>
                                  </span>
                                <h5>
                                  <a
                                    className={'link cursor-pointer'}
                                    onClick={(e) => {
                                      manageTaskListFn(type);
                                    }}>{type.name}</a>
                                </h5>
                              </>
                              {type.desc && <>
                                <small className={'text-small mt-n2'}>{type.desc}</small>
                              </>}

                              {type.items &&
                                <ul
                                  className={'list-group'}> {type.items.sort((a, b) => a.sortOrder - b.sortOrder).map((task, index) => {
                                  return (
                                    <li key={index} className={'list-group-item'}>
                                      {task.name}
                                      {task.description && <small
                                        className={'text-small my-n2'}><br/>{task.description}
                                        <br/>
                                        {displayRepeats(task)}
                                      </small>}
                                    </li>
                                  );
                                })
                                }</ul>
                              }
                            </li>
                          );
                        })}
                      </ul>
                    </MDBox>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <div id="inventory-types" style={{
                    position: 'relative',
                    left: '-999em',
                    zIndex: '-999',
                    top: '-100px'
                  }}/>
                  <Card>
                    <MDBox p={3}>
                      <MDTypography variant="h5" fontWeight="medium">
                        Inventory Types
                        <MDButton
                          color="secondary"
                          size="small"
                          className={'pull-right'}
                          onClick={(e) => {
                            addInventoryTypeFn();
                          }}>
                          <Icon>sell</Icon>&nbsp;Add Inventory Type
                        </MDButton>

                        <MDButton
                          color="secondary"
                          size="small"
                          className={'pull-right mx-2'}
                          onClick={(e) => {
                            setSortInventoryTypes(true);
                          }}>
                          <Icon>sort</Icon>&nbsp;Sort Inventory Types
                        </MDButton>

                      </MDTypography>
                      <p className={'py-2 fw-light'}>
                        Use inventory types to define general categories of inventory. For example, you might have
                        inventory types for "Food", "Beverage", "Equipment", "Supplies", etc.
                      </p>
                      <ul className={'list-group'}>
                        {inventoryTypes
                          .map((type, index) => {
                            return (
                              <li
                                className={'list-group-item'}
                                key={index}>
                                {type.id &&
                                  <>
                                <span className="d-block pull-right">
                                    <EventTypeActions item={type}/>
                                  </span>
                                    <a
                                      className={'link cursor-pointer'}
                                      onClick={(e) => {
                                        manageInventoryTypeFn(type);
                                      }}>{type.name}</a>
                                  </>
                                }
                                {type.desc && <>
                                  <small className={'text-small my-n2'}><br/>{type.desc}</small>
                                </>}
                              </li>
                            );
                          })}
                      </ul>
                    </MDBox>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <div id="event-categories" style={{
                    position: 'relative',
                    left: '-999em',
                    zIndex: '-999',
                    top: '-100px'
                  }}/>
                  <Card>
                    <MDBox p={3}>
                      <MDTypography variant="h5" fontWeight="medium">
                        Event Categories
                        <MDButton
                          color="secondary"
                          size="small"
                          className={'pull-right'}
                          onClick={(e) => {
                            addInventoryEventTypeFn();
                          }}>
                          <Icon>event</Icon>&nbsp;Add Event Category
                        </MDButton>

                        <MDButton
                          color="secondary"
                          size="small"
                          className={'pull-right mx-2'}
                          onClick={(e) => {
                            setSortEventTypes(true);
                          }}>
                          <Icon>sort</Icon>&nbsp;Sort Categories
                        </MDButton>

                      </MDTypography>
                      <p className={'py-2 fw-light'}>
                        Use event categories to define general categories of inventory events. For example, you might
                        have
                        event categories for "Open Play", "Classes", "Member-Only Events", "Birthday Parties", etc.
                      </p>
                      <ul className={'list-group'}>
                        {inventoryEventTypes.map((type, index) => {
                          return (
                            <li
                              className={'list-group-item '}
                              key={index}>
                              {type.id && type.name &&
                                <>
                                  <span className="d-block pull-right">
                                    <EventCategoryActions item={type}/>
                                  </span>
                                  <a
                                    className={'link cursor-pointer'}
                                    onClick={(e) => {
                                      manageInventoryEventTypeFn(type);
                                    }}>{type.name}
                                  </a>
                                </>
                              }
                              <small className={'text-small my-n2 d-block'}>
                                {type.desc}

                                {type.privateAccess &&
                                  <Chip
                                    size="small"
                                    className={'m-1 mb-n1'}
                                    label="Private"
                                    color="info"/>
                                }

                                {type.specialEvent &&
                                  <Chip
                                    size="small"
                                    className={'m-1 mb-n1'}
                                    label="Special Event"
                                    color="success"/>
                                }

                                {type.specialRequest &&
                                  <Chip
                                    avatar={<Avatar><Icon>check</Icon></Avatar>}
                                    // deleteIcon={<Icon>check</Icon>}
                                    size="small"
                                    className={'m-1 mb-n1'}
                                    label="Special Requests"
                                    color="info"/>
                                }

                                {type.initialDeposit &&
                                  <Chip
                                    size="small"
                                    className={'m-2'}
                                    label={type.depositPercent + '%'}
                                    color="secondary"/>
                                }
                              </small>

                              {
                                type.eventInventoryGroups && <span className="d-block">

                                {type.eventInventoryGroups?.sort((a, b) => a.id - b.id).map((group, index) => {
                                  return (
                                    <Chip
                                      key={index}
                                      size="small"
                                      className={'m-1 mb-n1'}
                                      index={index}
                                      avatar={<Avatar
                                        src={group?.inventory.imageThumbUrl || group?.inventory.imageUrl}/>}
                                      label={group?.inventory.name}
                                      variant="outlined"
                                      color="secondary"/>
                                  )
                                })
                                }
                              </span>
                              }

                            </li>
                          )
                        })}
                      </ul>
                    </MDBox>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <div id="event-locations" style={{
                    position: 'relative',
                    left: '-999em',
                    zIndex: '-999',
                    top: '-100px'
                  }}/>
                  <Card>
                    <MDBox p={3}>
                      <MDTypography variant="h5" fontWeight="medium">
                        Event Locations
                        <MDButton
                          color="secondary"
                          size="small"
                          className={'pull-right'}
                          onClick={(e) => {
                            addEventLocationFn();
                          }}>
                          <Icon>room</Icon>&nbsp;Add Event Location
                        </MDButton>


                        <MDButton
                          color="secondary"
                          size="small"
                          className={'pull-right mx-2'}
                          onClick={(e) => {
                            setSortLocations(true);
                          }}>
                          <Icon>sort</Icon>&nbsp;Sort Locations
                        </MDButton>

                      </MDTypography>
                      <p className={'py-2 fw-light'}>
                        Use event locations to separate party rooms or event spaces. For example, you might have
                        event locations for "Main Room", "Party Room A", "Party Room B", "Outdoor Space", etc.
                      </p>
                      <ul className={'list-group'}>
                        {eventLocations?.map((location, index) => {
                          return (
                            <li
                              className={'list-group-item'}
                              key={index}>
                              {location.id <= -1 && location.name}
                              {location.id > -1 && location.name &&
                                <>
                                 <span className="d-block pull-right">
                                    <EventLocationActions item={location}/>
                                  </span>
                                  <a
                                    className={'link cursor-pointer'}
                                    onClick={(e) => {
                                      manageEventLocationFn(location);
                                    }}>{location?.parentLocation ? <span className="mr-1"><Icon>star</Icon></span> : ''}
                                    {location.name}
                                  </a>
                                </>
                              }
                              {location.desc &&
                                <span className={'d-block'}>
                                  <small>{location.desc}</small>
                                </span>
                              }
                              {location.address && <>
                                <span className={'d-block'}><Icon>room</Icon><small>{location.address}</small></span>
                              </>}

                            </li>
                          );
                        })}
                      </ul>
                    </MDBox>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <div id="account-types" style={{
                    position: 'relative',
                    left: '-999em',
                    zIndex: '-999',
                    top: '-100px'
                  }}/>
                  <Card>
                    <MDBox p={3}>
                      <MDTypography variant="h5" fontWeight="medium">
                        Account Types
                        <MDButton
                          color="secondary"
                          size="small"
                          className={'pull-right'}
                          onClick={(e) => {
                            addAccountTypeFn();
                          }}>
                          <Icon>apps</Icon>&nbsp;Add Account Type
                        </MDButton>

                        <MDButton
                          color="secondary"
                          size="small"
                          className={'pull-right mx-2'}
                          onClick={(e) => {
                            setSortAccountTypes(true);
                          }}>
                          <Icon>sort</Icon>&nbsp;Sort Account Types
                        </MDButton>
                      </MDTypography>
                      <p className={'py-2 fw-light'}>
                        Use account types to define membership account types and their access to event categories. For
                        example, you might have
                        account types for "Annual Member", "Recurring Monthly Member", "Daily Drop In Customer",
                        "Special Event Account type", etc.
                        <br/><b>Note</b>: Multiple Account types may be mapped to multiple Event Categories.
                      </p>
                      <ul className={'list-group'}>
                        {accountTypes.map((type, index) => {
                          return (
                            <li
                              className={'list-group-item '}
                              key={index}>
                              {type.id <= -1 && type.name}
                              {type.id > -1 && type.name &&
                                <>
                                <span className="d-block pull-right">
                                    <AccountTypeActions item={type}/>
                                  </span>
                                  <a
                                    className={'link'}
                                    onClick={(e) => {
                                      manageAccountTypeFn(type);
                                    }}>{type.name}</a>

                                </>
                              }
                              {type.desc && <>
                                <small className={'text-small my-n2'}><br/>{type.desc}</small>
                              </>}
                            </li>
                          );
                        })}
                      </ul>
                    </MDBox>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <div id="age-ranges" style={{
                    position: 'relative',
                    left: '-999em',
                    zIndex: '-999',
                    top: '-100px'
                  }}/>
                  <Card>
                    <MDBox p={3}>
                      <MDTypography variant="h5" fontWeight="medium">
                        Age Ranges
                        <MDButton
                          color="secondary"
                          size="small"
                          className={'pull-right'}
                          onClick={(e) => {
                            addAgeRangeTypFn();
                          }}>
                          <Icon>margin</Icon>&nbsp;Add Age Range
                        </MDButton>

                        <MDButton
                          color="secondary"
                          size="small"
                          className={'pull-right mx-2'}
                          onClick={(e) => {
                            setSortAgeRangeTypes(true);
                          }}>
                          <Icon>sort</Icon>&nbsp;Sort Age Ranges
                        </MDButton>

                      </MDTypography>
                      <p className={'py-2 fw-light'}>
                        Use age ranges to customize events tailored to specific age groups. For
                        example, you might have age ranges for "0-2", "3-5", "5-10", "All Ages"
                      </p>
                      <ul className={'list-group'}>
                        {ageRangeTypes
                          .map((type, index) => {
                            return (
                              <li
                                className={'list-group-item '}
                                key={index}>
                                {type.id <= -1 && type.name}
                                {type.id > -1 && type.name &&
                                  <>
                                <span className="d-block pull-right">
                                    <AgeTypeActions item={type}/>
                                  </span>
                                    <a
                                      className={'link'}
                                      onClick={(e) => {
                                        manageAgeRangeTypFn(type);
                                      }}>{type.name}</a>

                                  </>
                                }
                              </li>
                            );
                          })}
                      </ul>
                    </MDBox>
                  </Card>
                </Grid>

              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>


      {isManageCompanyDetailsModal
        && <ManageCompanyDetailsModal
          show={isManageCompanyDetailsModal}
          saving={savingCompany}
          handleSave={saveCompanyDetailsFn}
          handleClose={closeManageCompanyDetailsFn}
        />
      }
      {manageInventoryType && <ManageInventoryTypeModal
        inventoryType={inventoryType}
        show={manageInventoryType}
        saving={savingCompany}
        handleSave={saveInventoryTypeFn}
        handleClose={closeInventoryTypeFn}/>}

      {sortInventoryTypes && <SortInventoryTypes
        inventoryTypes={inventoryTypes}
        show={sortInventoryTypes}
        saving={savingCompany}
        handleSave={handleSaveInventoryTypesSort}
        handleClose={closeInventoryTypeSortFn}/>}

      {sortAccountTypes && <SortAccountTypes
        accountTypes={accountTypes}
        show={sortAccountTypes}
        saving={savingCompany}
        handleSave={handleSaveAccountTypesSort}
        handleClose={closeAccountTypeSortFn}/>
      }

      {sortAgeRangeTypes && <SortAgeRangeTypes
        ageRangeTypes={ageRangeTypes}
        show={sortAgeRangeTypes}
        saving={savingCompany}
        handleSave={handleSaveAgeRangeTypesSort}
        handleClose={closeAgeRangeTypeSortFn}/>
      }

      {sortLocations && <SortLocations
        locations={eventLocations}
        show={sortLocations}
        saving={savingCompany}
        handleSave={handleSaveLocationsSort}
        handleClose={closeLocationsSortFn}/>
      }

      {sortEventTypes && <SortEventTypes
        eventTypes={inventoryEventTypes}
        show={sortEventTypes}
        saving={savingCompany}
        handleSave={handleSaveEventTypesSort}
        handleClose={closeInventoryEventTypeSortFn}/>}

      {manageTaskList && <ManageTaskListModal
        taskList={taskList}
        show={manageTaskList}
        saving={savingCompany}
        handleSave={handleSaveTask}
        handleClose={closeTaskListFn}/>}

      {manageInventoryEventType && <ManageInventoryEventTypeModal
        inventoryEventType={inventoryEventType}
        show={manageInventoryEventType}
        saving={savingCompany}
        handleSave={saveInventoryEventTypeFn}
        handleClose={closeInventoryEventTypeFn}/>}

      {manageAccountType && <ManageAccountTypeModal
        accountType={accountType}
        show={manageAccountType}
        saving={savingCompany}
        handleSave={saveAccountTypeFn}
        handleClose={closeAccountTypeFn}/>}

      {manageAgeRangeType && <ManageAgeRangeTypeModal
        ageRangeType={ageRangeType}
        show={manageAgeRangeType}
        saving={savingCompany}
        handleSave={saveAgeRangeTypeFn}
        handleClose={closeAgeRangeTypeFn}/>}

      {manageEventLocation && <ManageEventLocationModal
        eventLocation={eventLocation}
        show={manageEventLocation}
        saving={savingCompany}
        handleSave={saveEventLocationFn}
        handleClose={closeEventLocationFn}/>}

      {confirmDelete && <Confirm confirmation={confirmation}
                                 show={confirmDelete}
                                 saving={saving}
                                 handleConfirm={handleArchiveEventCategory}
                                 handleClose={handleCloseDeleteInventory}/>}

      {confirmDeleteInventoryType && <Confirm confirmation={confirmation}
                                              show={confirmDeleteInventoryType}
                                              saving={saving}
                                              handleConfirm={handleArchiveInventoryType}
                                              handleClose={handleCloseInventoryType}/>}

      {confirmDeleteAccountType && <Confirm confirmation={confirmation}
                                            show={confirmDeleteAccountType}
                                            saving={saving}
                                            handleConfirm={handleArchiveAccountType}
                                            handleClose={handleCloseAccountType}/>}

      {confirmDeleteTaskTemplate && <Confirm confirmation={confirmation}
                                             show={confirmDeleteTaskTemplate}
                                             saving={saving}
                                             handleConfirm={handleArchiveTaskTemplate}
                                             handleClose={handleCloseTaskTemplate}/>}

      {confirmDeleteAgeType && <Confirm confirmation={confirmation}
                                        show={confirmDeleteAgeType}
                                        saving={saving}
                                        handleConfirm={handleArchiveAgeType}
                                        handleClose={handleCloseAgeType}/>}

      {confirmDeleteLocation && <Confirm confirmation={confirmation}
                                         show={confirmDeleteLocation}
                                         saving={saving}
                                         handleConfirm={handleDeleteLocation}
                                         handleClose={handleCloseLocation}/>}

      <Footer mt={5}/>

    </DashboardLayout>
  );
}