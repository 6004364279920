import moment from "moment";
import cardValidator from "card-validator";
import {CONSTANTS} from "./constants";

export const checkFormValidity = (event) => {
  let elements = event.target.form.elements;
  let valid = true;
  for (let el in elements) {
    if (elements[el].checkValidity) {
      valid = valid && elements[el].checkValidity();
    }
  }
  return valid;
}

export const isAuthenticatedCustomer = (sessionUser) => {
  return isSessionCustomer(sessionUser) && sessionUser.guid;
}

export const isSessionPending = (sessionUser) => {
  if (!sessionUser) {
    return true;
  }
  if (sessionUser?.userTypeId === null) {
    return true;
  }
  return false;
};

export const isSessionCustomer = (sessionUser) => {
  if (!sessionUser) {
    return false;
  }
  if (sessionUser?.userTypeId === CONSTANTS.UTYPE_CUSTOMER) {
    return true;
  }
  return false;
};

export const isSessionClient = (sessionUser) => {
  if (!sessionUser) {
    return false;
  }
  if (sessionUser?.userTypeId === CONSTANTS.UTYPE_CLIENT) {
    return true;
  }
  return false;
};

export const isSessionSuperAdmin = (sessionUser) => {
  if (!sessionUser) {
    return false;
  }
  if (sessionUser?.userTypeId === CONSTANTS.UTYPE_ADMIN) {
    return true;
  }
  return false;
};

export const formatDateNoTz = (jsDate) => {
  let d = new Date(jsDate.split('-'));
  return d.getMonth() + 1 + '/' + d.getDate() + '/' + d.getFullYear();
}

export const formatAge = (familyMember) => {
  let dob = familyMember.dob;
  if (!dob) {
    return;
  }
  let d = new Date(dob.split('-'));
  let e6age = Math.floor((new Date() - new Date(d).getTime()) / 3.15576e+10);

  if (e6age > 0) {
    return (e6age) + ' years';
  } else {
    return (Math.floor((new Date().getTime() - new Date(d).getTime()) / 1000 / 60 / 60 / 24 / 30)) + ' months';
  }
}

export const getDateNoTz = (jsDate) => {
  return new Date(jsDate.split('-'));
}

export const formatDate = (jsDate) => {
  let d = new Date(jsDate);
  return d.getMonth() + 1 + '/' + d.getDate() + '/' + d.getFullYear();
}

function addPadding(value) {
  return value < 10 ? '0' + value : value;
}

export const formatTime = (jsDate) => {
  let d = new Date(jsDate);
  const hours = d.getHours();
  const amPm = hours >= 12 ? 'PM' : 'AM';
  return (hours > 12 ? hours - 12 : hours) + ":" + addPadding(d.getMinutes()) + ' ' + amPm;
}

export const addDate = (date, numberDays) => {
  let result = new Date(date);
  result.setDate(result.getDate() + numberDays);
  return new Date(result);
}

export const inventoryAvailable = (inventory) => {
  if (!inventory) {
    return 0;
  }
  return inventory?.publicAvailableQuantity || 0;
}

export const displayDuration = (inventory) => {
  if (inventory?.allDay) {
    return 'All Day';
  }
  let duration = inventory?.duration || 0;
  if (duration < 60) {
    return duration + ' minutes';
  }
  let hours = Math.floor(duration / 60);
  let minutes = duration % 60;

  let displayHours = hours > 1 ? ' hours' : ' hour';

  return hours + displayHours + (minutes > 0 ? ' ' + minutes + ' minutes' : '');
}

export const formatDateTime = (jsDate) => {
  return moment(jsDate).format('MM/DD/yyyy hh:mm a');
}

export const formatDateMask = (jsDate, mask) => {
  return moment(jsDate).format(mask);
}

export const displayDay = (jsDate, format) => {
  return moment(jsDate).format(format);
}
export const formatCurrency = (price) => {
  if (isNaN(price)) {
    return '';
  }

  return '$' + parseFloat(Math.round(100 * price) / 100)
    .toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});
}

export const customerQrCode = (customer) => {
  return 'https://psb.com/qr/cx/' + customer.id + '/' + customer.guid;
}

export const handleDefaultValues = (entity) => {
  if (!entity) {
    return entity;
  }

  if (entity.primaryPhone) {
    entity.primaryPhone = displayPhone(entity.primaryPhone);
  }

  return entity;
};

export const displayPhone = (phoneNumberString) => {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    let intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return phoneNumberString;
}

export const capitalize = (s) => {
  if (!s) {
    return s;
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export const formatExpiration = (expiration) => {
  let cleaned = ('' + expiration).replace(/\D/g, '');

  let expy = cardValidator.expirationDate(expiration);

  // format expiration into MM/YY format
  let match = cleaned.match(/^(0[1-9]|1[0-2])\/?([0-9]{0,4})$/);
  if (match) {
    expiration = [match[1], '/', match[2]].join('');
  }

  expy.expy = expiration;

  return expy;
}


export const formatCreditCard = (cardNumber) => {
  let cleaned = ('' + cardNumber).replace(/\D/g, '');

  let card = cardValidator.number(cardNumber);

  if (card.isPotentiallyValid) {
    cardNumber = cardNumber.replace(/\D/g, '');
    cardNumber = cardNumber.replace(/(\d{4})/g, '$1 ');
    cardNumber = cardNumber.trim();
  }
  card.cardNumber = cardNumber;

  return card;
}

export const customerInitials = (customer) => {
  let s = '';
  s += customer && customer.firstName ? customer.firstName.split('')[0] + '' : '';
  s += customer && customer.lastName ? customer.lastName.split('')[0] + '' : '';
  return s.toUpperCase();
}

export const invert = (hex, bw) => {

  if (!hex) {
    return '#000';
  }

  function padZero(str, len) {
    len = len || 2;
    let zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
  }


  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  let r = parseInt(hex.slice(0, 2), 16),
    g = parseInt(hex.slice(2, 4), 16),
    b = parseInt(hex.slice(4, 6), 16);
  if (bw) {
    // https://stackoverflow.com/a/3943023/112731
    return (r * 0.299 + g * 0.587 + b * 0.114) > 186
      ? '#000000'
      : '#FFFFFF';
  }
  // invert color components
  r = (255 - r).toString(16);
  g = (255 - g).toString(16);
  b = (255 - b).toString(16);
  // pad each with zeros and return
  return "#" + padZero(r) + padZero(g) + padZero(b);
}

export const colorize = (str) => {
  if (!str) {
    return '#efefef';
  }
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let j = 0; j < 3; j++) {
    let value = (hash >> (j * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}