import React, {useState} from 'react';
import {PaymentElement, useElements, useStripe,} from '@stripe/react-stripe-js';
import MDButton from "../../../components/MDButton";
import {Modal} from "react-bootstrap";

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const clientSecret = props.clientSecret;
  const isClientCart = props?.client?.guid?.length > 0;

  const clientSuccessPart = isClientCart ? '/client/' + props.client.guid : '';
  const customerSuccessPart = '/cx/checkout/success';

  const successUrl = window.location.protocol + '//' + window.location.host + (isClientCart ? clientSuccessPart : customerSuccessPart);

  const [errorMessage, setErrorMessage] = useState(null);
  const [checkoutActive, setCheckoutActive] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    setCheckoutActive(true);

    const {error} = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: successUrl,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
    setCheckoutActive(false);

  };

  return (
    <>

      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <PaymentElement/>
          {errorMessage && <div>{errorMessage}</div>}
        </Modal.Body>
        <Modal.Footer>

          <MDButton variant="gradient"
                  disabled={!stripe || !elements || checkoutActive}
                  type="submit"
                  color="info">
            Complete Purchase
          </MDButton>
        </Modal.Footer>
      </form>
    </>
  );
}

