import {Col, Container, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {handleDefaultValues} from "../common/Utils";
import {useForm} from 'react-hook-form';
import MDButton from "../../components/MDButton";
import MDInput from "../../components/MDInput";
import Switch from "@mui/material/Switch";
import MDTypography from "../../components/MDTypography";
import {getEventLocations} from "../common/api/InventoryApi";

export default function ManageEventLocationModal(props) {

  const [context, setContext] = useContext(AppContext);
  const [eventLocation, setEventLocation] = useState(props.eventLocation || {});
  const [formInvalid, setFormInvalid] = useState(false);
  const [childLocations, setChildLocations] = useState(props?.eventLocation?.childLocations || []);
  const [eventLocations, setEventLocations] = useState();
  const [parentLocations, setParentLocations] = useState();

  useEffect(() => {
    setEventLocation(props?.eventLocation);
  }, [props?.eventLocation]);

  const isChildLocation = (location) => {
    for (let loc in eventLocation?.childLocations) {
      if (eventLocation?.childLocations[loc].guid === location.guid) {
        return true;
      }
    }
  }

  const toggleChildLocation = (location) => {
    let _locations = eventLocation?.childLocations || [];
    if (isChildLocation(location)) {
      _locations = _locations.filter(loc => loc.guid !== location.guid);
    } else {
      _locations.push(location);
    }
    let _obj = {...eventLocation};
    _obj.childLocations = _locations;
    setEventLocation({..._obj});
  }

  useEffect(() => {
    getEventLocations().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {

        let _r = [];
        for (let location in result) {
          if (result[location].guid !== eventLocation.guid) {
            _r.push(result[location])
          }
        }

        let _locations = [];
        for (let location in _r) {
          if (_r[location].parentLocation) {
            _locations.push(_r[location])
          }
        }
        setParentLocations(_locations);
        setEventLocations(_r);
      }
    });
  }, []);

  const handleFormChange = (e) => {
    setValue(e.target.name, e.target.value);
    let _obj = {...eventLocation};
    _obj[e.target.name] = e.target.value;
    setEventLocation({..._obj});
  };

  const [isNew, setIsNew] = useState(!!!props.eventLocation?.guid);

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(eventLocation)
  });

  const handleClose = () => {
    props.handleClose();
  };

  const toggleParentLocation = (e) => {
    let _obj = {...eventLocation};
    _obj.parentLocation = e.target.checked;
    setEventLocation({..._obj});
  }

  const handleSave = (data) => {
    props.handleSave(data);
  };

  return (
    <Modal onHide={handleClose} size={'lg'} show={props.show}>
      <form onSubmit={handleSubmit(handleSave)} id={'eventLocationForm'}>
        <Modal.Header closeButton>
          <Modal.Title>{isNew ? 'Create New' : 'Manage'} Event Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className={'col col-main'} xs={12}>
              <Container>
                <Row>
                  <Col className={'col'} sm={12} xs={12}>
                    <div className="form-group">
                      <MDInput
                        type="text"
                        {...register('name')}
                        autoComplete="off" required
                        className="form-control" id="name"
                        label="Location Name"/>

                    </div>
                  </Col>
                </Row>

                <Row className={'mt-2'}>
                  <Col className={'col'} sm={12} xs={12}>
                    <div className="form-group">

                      <MDInput
                        type="text"
                        {...register('desc')}
                        autoComplete="off" required
                        className="form-control" id="desc"
                        label="Location Description"/>

                    </div>
                  </Col>
                </Row>

                <Row className={'mt-2'}>
                  <Col className={'col'} sm={12} xs={12}>
                    <div className="form-group">

                      <MDInput
                        type="text"
                        {...register('address')}
                        autoComplete="off"
                        className="form-control" id="desc"
                        label="Location Address"/>

                    </div>
                  </Col>
                </Row>

                <Row className={'mt-2'}>
                  <Col className={'col'} sm={12} xs={12}>
                    <label className={'cursor-pointer'} htmlFor={'parentLocation'}>
                      <Switch name={'parentLocation'}
                              id={'parentLocation'}
                              style={{marginRight: '5px'}}
                              defaultChecked={props?.eventLocation?.parentLocation}
                              onChange={(e) => {
                                toggleParentLocation(e);
                              }}
                      />
                      Parent Location
                    </label>
                  </Col>
                </Row>

                {eventLocation?.parentLocation && <Row className={'mt-2'}>
                  <Col className={'col'} sm={12} xs={12}>
                    <MDTypography variant="h5" fontWeight="regular">
                      Child Locations
                    </MDTypography>
                    <div className="form-group">
                      {eventLocations?.sort((a, b) => a.id > b.id ? 1 : -1).map((location, index) => {
                        return (<div key={index} className={'form-check'}>
                          <label className={'form-check-label cursor-pointer'} htmlFor={location.guid}>
                            <input type="checkbox"
                                   role={'switch'}
                                   id={location.guid}
                                   className={'form-check-input'}
                                   name={location.guid}
                                   defaultChecked={isChildLocation(location)}
                                   onChange={() => {
                                     toggleChildLocation(location);
                                   }}/>
                            &nbsp;{location.name}
                          </label>
                        </div>)
                      })}

                    </div>
                  </Col>
                </Row>
                }

              </Container>
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <MDButton type="submit" color="info" disabled={formInvalid || props.saving}>
            {props.saving ? 'Saving...' : 'Save'}
          </MDButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
}