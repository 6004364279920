import {useContext, useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import CustomerPageLayout from "../Layout/CustomerPageLayout";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import {Card, CardContent, Chip} from "@mui/material";
import MDTypography from "../../components/MDTypography";
import CustomerHeader from "../common/CustomerHeader";
import Divider from "@mui/material/Divider";
import {getCustomerIntentSuccess} from "../common/api/PortalCustomerApi"
import {formatCurrency, formatDateTime} from "../common/Utils";
import Footer from "../Footer";
import MDButton from "../../components/MDButton";
import Loading from "../common/Loading";
import {AppContext} from "../../lib/AppContext";

export default function CheckoutFinal(props) {

  const [context, setContext] = useContext(AppContext);

  const navigate = useNavigate();

  const [sessionId, setSessionId] = useState();
  const [isCancel, setIsCancel] = useState(props?.isCancel);
  const [searchParams, setSearchParams] = useSearchParams();
  const [paymentIntent, setPaymentIntent] = useState();
  const [salesReady, setSalesReady] = useState(false);
  const [salesResp, setSalesResp] = useState();
  const [searchTimer, setSearchTimer] = useState(1);

  useEffect(()=>{
    if (salesReady) {
      let _c = {...context};
      _c.reloadCart = true;
      setContext({..._c});
    }
  }, [salesReady]);

  useEffect(()=>{
    let intent =   searchParams.get("payment_intent");
    if (intent) {
      intent = intent;
      setPaymentIntent(intent);
      getSalesFn(intent);
    }
  }, [searchParams]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (paymentIntent) {
        if (searchTimer > 1) {
          if (searchTimer < 30) {
            getSalesFn(paymentIntent);
          } else {
            navigate('/cx/my-purchases');
          }
        }
      }
    }, 1000);
    return () => clearTimeout(searchTimer);
  }, [searchTimer]);
  
  const getSalesFn = async (paymentIntent) => {
    let result = await getCustomerIntentSuccess(paymentIntent);
    if (result) {
      if (result?.salesItems?.length > 0) {
        setSalesResp(result);
        setSalesReady(true);
      } else {
        setSearchTimer(searchTimer + 1);
      }
    }
  }

  return (
    <CustomerPageLayout>

      <CustomerHeader props={props}/>

      {!salesReady && <Loading/> }
      {salesReady && <MDBox p={3}>
        <MDBox m={3}>
          <Grid container cols={1} spacing={3} direction="row">
            <Grid item xs={12} sm={12}>
              <Card>
                <CardContent>
                  <MDTypography variant="h5" color="secondary" fontWeight="light">
                    My Purchase
                    <MDButton
                      color="info"
                      size="small"
                      className={'pull-right'}
                      onClick={(e) => {
                        navigate('/cx/my-profile');
                      }}>
                      <span className={'fa fa-person'}></span>&nbsp;My Profile
                    </MDButton>
                    <Divider/>
                  </MDTypography>
                </CardContent>

                <MDBox p={3}>
                  <MDTypography variant={'h6'}>Total
                    Purchases: {salesReady && formatCurrency(salesResp?.totalSales)}</MDTypography>

                  <ul className={'list-group'}>
                    {salesReady && salesResp?.salesItems.sort((a, b) => a.itemName.toUpperCase() > b.itemName.toUpperCase() ? 1 : -1).map((item, index) => {
                        return (
                          <li
                            className={'list-group-item'}
                            key={index}>
                                    <span
                                      className={'pull-right'}>
                                      <Chip
                                        label={formatCurrency(item.total - item.discount)}
                                        variant="outlined"
                                        color="secondary"/>
                                    </span>
                            {item?.itemName}
                            <br/>
                            <small>{formatDateTime(item.purchaseDate)}</small>

                          </li>
                        )
                      }
                    )}
                  </ul>
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      }
      <Footer/>
    </CustomerPageLayout>
  );
}