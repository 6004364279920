import {Col, Container, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {formatCurrency, handleDefaultValues} from "../../common/Utils";
import {useForm} from 'react-hook-form';
import {clientAddToCart} from "../../common/api/CartApi";
import MDButton from "../../../components/MDButton";
import Loading from "../../common/Loading";
import Avatar from "@mui/material/Avatar";
import {Card, CardContent} from "@mui/material";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

export default function ClientViewEventRegistrationModal(props) {
  const [context, setContext] = useContext(AppContext);

  const [clientGuid, setClientGuid] = useState(props?.reservation?.memberGuid);
  const [event, setEvent] = useState();
  const [addingToCart, setAddingToCart] = useState(false);
  const [eventLoading, setEventLoading] = useState(false);

  const [inventoryType, setInventoryType] = useState(props.inventoryType);
  const [formInvalid, setFormInvalid] = useState(false);

  useEffect(()=> {
    setEvent(props?.reservation?.event);
  }, [props?.reservation?.event]);

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(inventoryType)
  });

  const addOnToCartFn = async (item) => {
    let _item = {...item};
    _item.parentEventGuid = event.parentEventGuid;

    item.addingToCart = true;
    _item.quantity = 1;
    clientAddToCart(_item, clientGuid).then((result) => {
      item.addingToCart = false;
      item.addedToCart = true;
      let _context = {...context};
      _context.reloadCart = true;
      setContext({..._context});
    });
  };

  const handleClose = () => {
    props.handleClose();
  };

  return (
    <Modal onHide={handleClose} size={'xl'} show={props.show}>
      {eventLoading && <Modal.Body>
        <Loading/>
      </Modal.Body>}
      {event &&
        <form>
          <Modal.Header closeButton>
            <Modal.Title>{event?.eventType?.name} Registration</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col className={'col col-main'} xs={12}>
                <Container>
                  <Row>
                    <Col className={'col'} sm={12}>
                      <h5 htmlFor="name">Event Name !!!
                        {event.imageUrl && <img
                          src={(event.imageThumbUrl ? event.imageThumbUrl : event.imageUrl) + '?d=' + new Date()}
                          className={'img-thumbnail pull-right m-2'} style={{width: '120px'}}/>}
                      </h5>
                      <p>
                        {event.name}
                      </p>
                    </Col>
                  </Row>

                  {event?.addOns && event?.addOns.length > 0 &&
                    <>
                      <Row>
                        <Col className={'col'} sm={12}>
                          <Divider/>
                          <h5 htmlFor="name">Add-Ons</h5>
                        </Col>
                      </Row>
                      <Grid container spacing={4} alignItems="stretch">
                        {event?.addOns.map((addOn, index) => {
                          return (
                            <Grid item sm={12} md={4} index={index} key={index}>
                              <Card p={1}>
                                <CardContent>
                                  <h6><Avatar alt={addOn.name}
                                              className={'pull-right mx-2'}
                                              sx={{width: 55, height: 55}}>
                                    <img src={addOn.imageUrl}
                                         style={{width: '200%', height: 'auto'}}
                                         alt={addOn.name}/>
                                  </Avatar>{addOn.name}</h6>
                                  <p>{addOn.desc}</p>
                                  <p>Price: {formatCurrency(addOn.price)}</p>

                                  <div className={'d-block'}>
                                    <MDButton variant="gradient"
                                              color="secondary"
                                              size="small"
                                              className={'pull-right mt-n1'}
                                              onClick={(e) => {
                                                addOnToCartFn(addOn)
                                              }}
                                              disabled={addOn.addingToCart || addOn.addedToCart}>
                                      &nbsp;{addOn.addingToCart && 'Adding to Cart...'}
                                      {!addOn.addingToCart && <>
                                        {addOn.addedToCart ? (<><span
                                          className={'fa fa-check-circle-o ml-n2'}></span> Added to
                                          Cart</>) : 'Add to Cart'}
                                      </>}

                                    </MDButton>
                                  </div>
                                </CardContent>
                              </Card>
                            </Grid>
                          )
                        })}

                      </Grid>
                    </>
                  }

                </Container>
              </Col>
            </Row>

            {event?.reservationStatus?.id > -1 &&
              <p className={'text-center text-info'}>
                <span className={'fa fa-thumbs-o-up'}></span>
                &nbsp;You are already registered for this event.</p>
            }
          </Modal.Body>
          <Modal.Footer>
            <MDButton color="info" onClick={() => props.toggleCheckout(props?.reservation)}>
              Continue to Checkout
            </MDButton>
          </Modal.Footer>
        </form>
      }
    </Modal>
  )
}