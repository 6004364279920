import React from "react";
import MDPagination from "../../components/MDPagination";
import Icon from "@mui/material/Icon";
import MDTypography from "../../components/MDTypography";
import MDBox from "../../components/MDBox";

const ellipsis = '...';

function toPages(m, c) {
  let current = c,
    last = m,
    delta = 5,
    left = current - delta,
    right = current + delta + 1,
    range = [],
    rangeWithDots = [],
    idx;

  for (let i = 1; i <= last; i++) {
    if (i === 1 || i === last || (i >= left && i < right)) {
      range.push(i);
    }
  }

  for (let i of range) {
    if (idx) {
      if (i - idx === delta) {
        rangeWithDots.push(idx + 1);
      } else if (i - idx !== 1) {
        rangeWithDots.push('...');
      }
    }
    rangeWithDots.push(i);
    idx = i;
  }

  return rangeWithDots;
}


const BootyPagination = ({
                           rowsPerPage,
                           rowCount,
                           onChangePage,
                           onChangeRowsPerPage, // available but not used here
                           currentPage,
                           totalFiltered
                         }) => {
  const handleBackButtonClick = () => {
    onChangePage(currentPage - 1);
  };

  const handleNextButtonClick = () => {
    onChangePage(currentPage + 1);
  };

  const handlePageNumber = (e) => {
    onChangePage(Number(e.target.value));
  };

  const pages = Math.ceil(rowCount / rowsPerPage);
  const pageItems = toPages(pages, currentPage);
  const lastPage = pageItems[pageItems.length - 1];
  const nextDisabled = currentPage === lastPage;
  const previousDisabled = currentPage === 1;
  return (
    <MDBox p={2}
           display="flex"
           flexDirection={{xs: "column", sm: "row"}}
           justifyContent="space-between"
           alignItems={{xs: "flex-start", sm: "center"}}
    >
      <MDBox mb={{xs: 3, sm: 0}}>
        <MDTypography variant="button" color="secondary" fontWeight="regular">
          Showing {currentPage === 1 ? 1 :
          ((currentPage - 1) * rowsPerPage) + 1} to {rowCount < (currentPage * rowsPerPage) ? rowCount : currentPage * rowsPerPage} of {rowCount}
        </MDTypography>
      </MDBox>

      <MDPagination>

        <MDPagination item
                      onClick={handleBackButtonClick}
                      disabled={previousDisabled}
                      aria-disabled={previousDisabled}
                      aria-label="previous page"
        >
          <Icon>keyboard_arrow_left</Icon>
        </MDPagination>
        {pageItems.map((page, idx) => {
          const className = page === currentPage ? "page-item active" : "page-item";
          return (
            <MDPagination item
                          key={idx}
                          className="page-link"
                          active={page === currentPage}
                          onClick={handlePageNumber}
                          disabled={page === ellipsis}
                          aria-disabled={page === ellipsis}
                          value={page}
            > {page}
            </MDPagination>
          );
        })}
        <MDPagination item
                      onClick={handleNextButtonClick}
                      disabled={nextDisabled}
                      aria-disabled={nextDisabled}
                      aria-label="next page"
        >
          <Icon>keyboard_arrow_right</Icon>
        </MDPagination>
      </MDPagination>
    </MDBox>
  );
};

export default BootyPagination;