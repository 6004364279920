import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../lib/AppContext";
import {login, resetPass} from "./api/PortalCustomerApi";
import {useNavigate} from "react-router-dom";
import {Grid} from "@mui/material";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import Divider from "@mui/material/Divider";
import CreateAccount from "./CreateAccount";
import {Modal} from "react-bootstrap";

export default function CustomerLoginForm(props) {
  const navigate = useNavigate();
  const [context, setContext] = useContext(AppContext);
  const [showLogin, setShowLogin] = useState(props?.show);
  const [usernameEmail, setUsernameEmail] = useState('');
  const [email, setEmail] = useState('');
  const [passwd, setPasswd] = useState('');
  const [loggingIn, setLoggingIn] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [createAccount, setCreateAccount] = useState(props?.createAccount || false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showForgotPasswordMessage, setShowForgotPasswordMessage] = useState(false);

  useEffect(()=>{
    if (props?.createAccount) {
      setCreateAccount(props?.createAccount);
    }
  },[props?.createAccount]);


  useEffect(()=>{
    setShowLogin(props?.show);
  },[props?.show]);

  const toggleShowForgotPassword = () => {
    setShowForgotPassword(!showForgotPassword);
  }


  const toggleShowCreateAccount = () => {
    setCreateAccount(!createAccount);
  }

  const handleCreateAccount = () => {
    setShowLogin(false);
    props.handleClose();
  }

  const handleChange = (event) => {
    if (event.target.id === 'usernameEmail') {
      setUsernameEmail(event.target.value);
    }
    if (event.target.id === 'passwd') {
      setPasswd(event.target.value);
    }
    if (event.target.id === 'email') {
      setEmail(event.target.value);
    }
  };

  const forgotPassFn = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const auth = {
      email: email
    };

    setLoginError(null);
    setLoggingIn(true);
    let result = await resetPass(auth);

    if (result.error) {
      setLoginError(result.message);
    } else {
      setShowForgotPassword(false);
      setShowForgotPasswordMessage(true);

      setContext({sessionUser: result});
      setShowLogin(false);
      setUsernameEmail('');
      setPasswd('');
    }
    setLoggingIn(false);
  }

  const loginFn = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const auth = {
      email: usernameEmail,
      passwd: passwd
    };

    setLoginError(null);
    setLoggingIn(true);
    let result = await login(auth);
    if (result.error) {
      setLoginError(result.message);
    } else {
      setContext({sessionUser: result});
      setShowLogin(false);
      setUsernameEmail('');
      setPasswd('');
      if (props.handleLogin) {
        props.handleLogin(result);
      } else {
        window.location.href = '/';
      }
    }
    setLoggingIn(false);
  }
  return (
    <>
      <Modal show={showLogin} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{createAccount ? 'Create Account' : 'Login'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {createAccount && <CreateAccount handleSucess={handleCreateAccount} />}
            {!createAccount && <>
            {showForgotPassword && <form onSubmit={forgotPassFn}>
              <Grid container>
                <Grid item xs={12}>
                  <MDBox>
                    <Grid container p={1}>
                      <Grid item xs={12} sm={12}>
                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                          <MDInput type="email"
                                   variant="outlined"
                                   value={email || ''}
                                   onChange={handleChange}
                                   className="form-control" id="email" aria-describedby="emailHelp"
                                   label="Enter email"/>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3} mt={1}>
                      <Grid item xs={12} sm={12}>
                        <MDTypography variant="h5" fontWeight="medium">
                          {loginError && <p className={'text-center text-danger'}>{loginError}</p>}

                          <MDButton type="submit" className={'pull-right'} color="info" disabled={loggingIn}>
                            {loggingIn ? 'Resetting Password...' : 'Reset Password'}
                          </MDButton>

                          {props.handleCancel &&
                            <MDButton className={'pull-right mr-2'} color="info"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        props.handleCancel()
                                      }}>
                              Cancel
                            </MDButton>
                          }
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <MDTypography variant="p" fontWeight="light">
                          <Divider/>
                          <p className={'text-right'}><a className={'link-underline-info cursor-pointer'}
                                                         onClick={() => {
                                                           toggleShowForgotPassword();
                                                         }}>Back</a>
                          </p>
                        </MDTypography>
                      </Grid>
                    </Grid>
                  </MDBox>
                </Grid>
              </Grid>
            </form>
            }

            {!showForgotPassword && <form onSubmit={loginFn}>
              <Grid container>
                <Grid item xs={12}>
                  <MDBox>
                    <Grid container p={1}>
                      <Grid item xs={12} sm={12}>
                        <div className="form-group">
                          <label htmlFor="usernameEmail">Email</label>
                          <MDInput type="email"
                                   variant="outlined"
                                   value={usernameEmail || ''}
                                   onChange={handleChange}
                                   className="form-control" id="usernameEmail" aria-describedby="usernameHelp"
                                   label="Enter Email"/>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container p={1}>
                      <Grid item xs={12} sm={12}>
                        <div className="form-group">
                          <label htmlFor="passwd">Password</label>
                          <MDInput
                            variant="outlined"
                            value={passwd}
                            onChange={handleChange}
                            type="password" className="form-control" id="passwd" label="Enter Password"/>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={3} mt={1}>
                      <Grid item xs={12} sm={12}>
                        <MDTypography variant="h5" fontWeight="medium">
                          {loginError && <p className={'text-center text-danger'}>{loginError}</p>}

                          <MDButton type="submit" className={'pull-right'} color="info" disabled={loggingIn}>
                            {loggingIn ? 'Logging in...' : 'Login'}
                          </MDButton>

                          {props.handleCancel &&
                            <MDButton className={'pull-right mr-2'} color="info"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        props.handleCancel()
                                      }}>
                              Cancel
                            </MDButton>
                          }
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <MDTypography variant="p" fontWeight="light">
                          <Divider/>
                          {showForgotPasswordMessage &&
                            <p className={'text-center'}>Password reset instructions have been emailed to you</p>
                          }
                          <p className={'d-block'}>
                            <a className={'link-underline-info cursor-pointer pull-left'} onClick={() => {
                              toggleShowCreateAccount();
                            }}>Create Account</a>

                            <a className={'link-underline-info cursor-pointer pull-right'} onClick={() => {
                              toggleShowForgotPassword();
                            }}>Forgot Password?</a>
                          </p>
                        </MDTypography>

                      </Grid>
                    </Grid>
                  </MDBox>
                </Grid>
              </Grid>
            </form>}
            </> }
          </div>

        </Modal.Body>
      </Modal>

    </>
  )
}