import React, {createContext, useEffect, useState} from 'react';
import {getSessionUser} from "../app/common/api/WebApi";
import {useLocation, useNavigate} from "react-router-dom";
import {CONSTANTS} from "../app/common/constants";

export const AppContext = createContext({
  sessionUser: {},
  authenticated: false,
  showLogin: false,
  refreshIdentity: false,
  reloadCart: false,
  toast: {},
  pageTitle: '',
  contextSearchActive: false,
  viewCart: false,
});

export const Provider = (props) => {
  const [context, setContext] = useState([]);
  const [pingTimer, setPingTimer] = useState(new Date().getTime());
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const cx = pathname.startsWith('/cx');

  useEffect(() => {
    getSessionUser(cx, pathname).then((user) => {
      if (!user) {
        setContext({showLogin: true});
        navigate('/login');
      } else if (JSON.stringify(context.sessionUser) !== JSON.stringify(user)) {
        let _c = {...context};
        _c.sessionUser = user;

        if (user.userTypeId === CONSTANTS.UTYPE_CLIENT && !user.guid) {
          _c.showLogin = true;
          setContext({..._c});
          if (!pathname.match('/pass/reset/')) {
            navigate('/login');
          }
        }
        setContext({..._c});

      }
    });

    let interval = null;
    interval = setInterval(() => {
      setPingTimer(new Date().getTime());
      clearInterval(interval);
    }, 60000);
  }, [pingTimer, context.refreshIdentity]);

  return (
    <AppContext.Provider value={[context, setContext]}>
      {props.children}
    </AppContext.Provider>
  )
}