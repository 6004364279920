import {Col, Container, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {handleDefaultValues} from "../common/Utils";
import {useForm} from 'react-hook-form';
import MDButton from "../../components/MDButton";
import MDInput from "../../components/MDInput";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import MDTypography from "../../components/MDTypography";
import {saveTaskList} from "../common/api/InventoryApi";
import {Card} from "@mui/material";

export default function ManageTaskListModal(props) {

  const [context, setContext] = useContext(AppContext);
  const [taskList, setTaskList] = useState(props.taskList || {});
  const [repeatWeekdays, setRepeatWeekdays] = useState(props.taskList?.repeatWeekdays || false);
  const [formInvalid, setFormInvalid] = useState(false);
  const [renderIndex, setRenderIndex] = useState(1);

  useEffect(() => {
    setTaskList(props?.taskList);
  }, [props?.taskList]);

  const [isNew, setIsNew] = useState(!!!props.taskList?.guid);

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(taskList)
  });

  const handleClose = () => {
    props.handleClose();
  };

  const handleSave = async (data) => {
    let result = await saveTaskList(data);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      props.handleSave(result);
    }
  };

  const DAYS = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

  const addTask = () => {
    let _obj = {...taskList};
    if (!_obj.items) {
      _obj.items = [];
    }
    let sortOrder = 0;
    for (let i in _obj.items) {
      if (_obj.items[i].sortOrder > sortOrder) {
        sortOrder = _obj.items[i].sortOrder;
      }
    }
    _obj.items.push({sortOrder: sortOrder + 1, repeatExpression: DAYS.join(',')});
    setTaskList({..._obj});
  }

  const moveUp = (item) => {
    let _obj = {...taskList};
    for (let i = 0; i < _obj.items.length; i++) {
      _obj.items[i].sortOrder = i;
    }
    for (let i = 0; i < _obj.items.length; i++) {
      if (_obj.items[i].id === item.id) {
        _obj.items[i].sortOrder = _obj.items[i].sortOrder - 1;
      } else {
        _obj.items[i].sortOrder = _obj.items[i].sortOrder + 1;
      }
    }
    setTaskList({..._obj});
    setRenderIndex(renderIndex + 1);
  }

  const moveDown = (item) => {
    let _obj = {...taskList};
    for (let i = 0; i < _obj.items.length; i++) {
      _obj.items[i].sortOrder = i;
    }
    for (let i = 0; i < _obj.items.length; i++) {
      if (_obj.items[i].id === item.id) {
        _obj.items[i].sortOrder = _obj.items[i].sortOrder + 1;
      } else {
        _obj.items[i].sortOrder = _obj.items[i].sortOrder - 1;
      }
    }
    setTaskList({..._obj});
    setRenderIndex(renderIndex + 1);
  }


  const taskActive = (row, val) => {
    let _obj = {...taskList};
    return _obj.items[row]?.repeatExpression?.split(',').indexOf(val) > -1;
  }

  const toggleTaskActive = (row, val, checked) => {
    let _obj = {...taskList};
    if (!taskActive(row, val)) {
      if (!_obj.items[row].repeatExpression) {
        _obj.items[row].repeatExpression = val;
      } else {
        _obj.items[row].repeatExpression += ',' + val;
      }
    } else {
      let _arr = _obj.items[row].repeatExpression.split(',');
      _arr.splice(_arr.indexOf(val), 1);
      _obj.items[row].repeatExpression = _arr.join(',');
    }
    setTaskList({..._obj});
  }

  return (
    <Modal onHide={handleClose} size={'lg'} show={props.show}>
      <form onSubmit={handleSubmit(handleSave)} id={'taskListForm'}>
        <Modal.Header closeButton>
          <Modal.Title>{isNew ? 'Create New' : 'Manage'} Task List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {taskList?.isSort &&
            <Row>
              <Col className={'col col-main'} xs={12}>
                <Container>
                  <MDTypography variant="h5" fontWeight="medium" pt={2}>
                    {taskList?.name} Tasks
                  </MDTypography>

                  {renderIndex && taskList?.items?.sort((a, b) => a.sortOrder - b.sortOrder).map((item, index) => {
                    return (
                      <ul className={'mt-2 list-group'} key={index + renderIndex}>
                        <li
                          className={'list-group-item'}>
                          {index !== 0 && <MDButton color="primary"
                                                    className="pull-right mx-1"
                                                    size="small"
                                                    onClick={() => {
                                                      moveUp(item);
                                                    }}>
                           <span
                             style={{
                               fontSize: '16px'
                             }}
                             className={'fa fa-arrow-circle-up'}></span>
                          </MDButton>
                          }
                          <MDTypography variant="h6" fontWeight="regular" pt={2}>
                            {item.name}
                          </MDTypography>
                          <br/> {item.description}


                          {index !== taskList.items.length - 1 && <MDButton color="primary"
                                                                            className="pull-right mx-1"
                                                                            size="small"
                                                                            onClick={() => {
                                                                              moveDown(item);
                                                                            }}>
                            <span
                              style={{
                                fontSize: '16px'
                              }}
                              className={'fa fa-arrow-circle-down'}></span>
                          </MDButton>
                          }
                        </li>
                      </ul>
                    )
                  })}

                </Container>
              </Col>
            </Row>
          }
          {!taskList?.isSort && <Row>
            <Col className={'col col-main'} xs={12}>
              <Container>
                <Row>
                  <Col className={'col'} sm={12} xs={12}>
                    <div className="form-group">
                      <MDInput
                        type="text"
                        {...register('name')}
                        autoComplete="off" required
                        className="form-control" id="name"
                        label="Enter Task List Name"/>
                    </div>
                  </Col>
                  <Col className={'col mt-2'} sm={12} xs={12}>
                    <div className="form-group">
                      <MDInput
                        type="text"
                        {...register('desc')}
                        autoComplete="off" required
                        className="form-control" id="desc"
                        label="Enter Task List Description"/>

                    </div>
                  </Col>
                </Row>
                <MDTypography variant="h5" fontWeight="medium" pt={2}>
                  Tasks
                </MDTypography>
                <p className={'fw-light'}>
                  Define individual tasks that will be part of this task list. Each task can be repeated on specific
                  days.
                </p>
                {renderIndex && taskList?.items?.sort((a, b) => a.sortOrder - b.sortOrder).map((item, index) => {
                  return (
                    <Row className={'mt-2'} key={index + renderIndex}>
                      <Card style={{
                        padding: '10px',
                        marginBottom: '5px'
                      }}>
                        <Col className={'col'} sm={12} xs={12}>

                          <div className="form-group">
                            <MDInput
                              type="text"
                              {...register('items[' + index + '].name', {
                                onChange: (e) => {
                                  let _t = {...item};
                                  _t.name = e.target.value;
                                  let _obj = {...taskList};
                                  _obj.items[index] = _t;
                                  setTaskList({..._obj});
                                }
                              })}
                              autoComplete="off" required
                              className="form-control" id={'name[' + index + ']'}
                              label="Enter Task Name"/>
                          </div>
                        </Col>
                        <Col className={'col mt-2'} sm={12} xs={12}>
                          <div className="form-group">
                            <MDInput
                              type="text"
                              {...register('items[' + index + '].description', {
                                onChange: (e) => {
                                  let _t = {...item};
                                  _t.description = e.target.value;
                                  let _obj = {...taskList};
                                  _obj.items[index] = _t;
                                  setTaskList({..._obj});
                                }
                              })}
                              autoComplete="off" required
                              className="form-control" id={'desc[' + index + ']'}
                              label="Enter Task Description"/>
                          </div>
                        </Col>

                        <Col className={'col mt-2'} sm={12} xs={12}>
                          Repeat:<br/>
                          <label className="form-group cursor-pointer" htmlFor={'repeatSun-' + index}>
                            <Switch
                              name={'repeatSun-' + index}
                              id={'repeatSun-' + index}
                              checked={taskActive(index, 'SUN')}
                              onChange={(e) => {
                                toggleTaskActive(index, 'SUN', e.target.checked);
                              }}
                            />Sunday
                          </label>
                          <label className="form-group cursor-pointer" htmlFor={'repeatMon-' + index}>
                            <Switch
                              name={'repeatMon-' + index}
                              id={'repeatMon-' + index}
                              checked={taskActive(index, 'MON')}
                              onChange={(e) => {
                                toggleTaskActive(index, 'MON', e.target.checked);
                              }}
                            />Monday
                          </label>

                          <label className="form-group cursor-pointer" htmlFor={'repeatTue-' + index}>
                            <Switch
                              name={'repeatTue-' + index}
                              id={'repeatTue-' + index}
                              checked={taskActive(index, 'TUE')}
                              onChange={(e) => {
                                toggleTaskActive(index, 'TUE', e.target.checked);
                              }}
                            />Tuesday
                          </label>

                          <label className="form-group cursor-pointer" htmlFor={'repeatWed-' + index}>
                            <Switch
                              name={'repeatWed-' + index}
                              id={'repeatWed-' + index}
                              checked={taskActive(index, 'WED')}
                              onChange={(e) => {
                                toggleTaskActive(index, 'WED', e.target.checked);
                              }}
                            />Wednesday
                          </label>

                          <label className="form-group cursor-pointer" htmlFor={'repeatThu-' + index}>
                            <Switch
                              name={'repeatThu-' + index}
                              id={'repeatThu-' + index}
                              checked={taskActive(index, 'THU')}
                              onChange={(e) => {
                                toggleTaskActive(index, 'THU', e.target.checked);
                              }}
                            />Thursday
                          </label>

                          <label className="form-group cursor-pointer" htmlFor={'repeatFri-' + index}>
                            <Switch
                              name={'repeatFri-' + index}
                              id={'repeatFri-' + index}
                              checked={taskActive(index, 'FRI')}
                              onChange={(e) => {
                                toggleTaskActive(index, 'FRI', e.target.checked);
                              }}
                            />Friday
                          </label>

                          <label className="form-group cursor-pointer" htmlFor={'repeatSat-' + index}>
                            <Switch
                              name={'repeatSat-' + index}
                              id={'repeatSat-' + index}
                              checked={taskActive(index, 'SAT')}
                              onChange={(e) => {
                                toggleTaskActive(index, 'SAT', e.target.checked);
                              }}
                            />Saturday
                          </label>

                          <Divider/>

                          <MDButton color="info"
                                    className="pull-right"
                                    size="small"
                                    onClick={() => {
                                      let _obj = {...taskList};
                                      _obj.items.splice(index, 1);
                                      setTaskList({..._obj});
                                    }}>Remove Task</MDButton>
                        </Col>

                      </Card>
                    </Row>
                  )
                })}
                <Row className={'mt-2'}>
                  <Col className={'col'} sm={12} xs={12}>
                    <div className="form-group">
                      <MDButton
                        color="primary"
                        className="pull-right"
                        onClick={() => {
                          addTask();
                        }}>Add Task</MDButton>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          }
        </Modal.Body>
        <Modal.Footer>
          <MDButton type="submit" color="info" disabled={formInvalid || props.saving}>
            {props.saving ? 'Saving...' : 'Save'}
          </MDButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
}