import {AppContext} from "../../lib/AppContext";
import React, {forwardRef, useContext, useEffect, useState} from "react";
import {getCompanyTasks} from "../common/api/WebApi";
import {Card, Chip, Grid} from "@mui/material";
import ContextSearchNavbar from "../navbars/ContextSearchNavbar";

import Meta from "../common/Meta";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import Footer from "../Footer";
import MDTypography from "../../components/MDTypography";
import Icon from "@mui/material/Icon";
import {useNavigate} from "react-router-dom";
import {formatDate, formatDateMask, formatDateTime, handleDefaultValues} from "../common/Utils";
import {Controller, useForm} from "react-hook-form";
import ReactDatePicker from "react-datepicker";

export default function TaskHistory(props) {
  const navigate = useNavigate();

  const [context, setContext] = useContext(AppContext);

  const [saving, setSaving] = useState(false);
  const [manageEmployee, setManageEmployee] = useState(false);
  const [tasks, setTasks] = useState();
 const [taskHistoryDate, setTaskHistoryDate] = useState(new Date());

  useEffect(() => {
    getCompanyTasksByDate();
  }, [taskHistoryDate]);

  const updateEventDates = (date) => {
    setTaskHistoryDate(date);
  }

  const getCompanyTasksByDate = async () => {
    let taskDate = formatDateMask(taskHistoryDate, 'yyyy-MM-DD');
    getCompanyTasks(taskDate).then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setTasks(result);
      }
    });
  }

  const FormattedDateInput = forwardRef(({value, onClick}, ref) => (
    <div className={'cursor-pointer'} onClick={onClick} ref={ref}>
      <Icon sx={{
        marginTop: '2px',
      }}>event</Icon>{formatDate(taskHistoryDate)}
    </div>
  ));

  const [inventoryType, setInventoryType] = useState();


  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(inventoryType)
  });

  return (
    <DashboardLayout>

      <Meta pageTitle={'Task History'}/>
      <ContextSearchNavbar title={'Task History'}/>
      <MDBox py={3}>
        <Card>
          <MDBox p={3}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <MDBox width="100%" lineHeight={1}>
                  <MDTypography variant="h5" fontWeight="regular" className={'mb-3'}>
                    Task History
                  </MDTypography>
                </MDBox>
              </Grid>

              <Grid item xs={6}>
                <MDBox width="100%" lineHeight={1}>
                  <MDTypography variant="h5" color="secondary" textAlign="right" fontWeight="light">

                    <Controller
                      className={'d-block'}
                      control={control}
                      name="eventDate"
                      id="eventDate"
                      render={({field: {onChange, onBlur, value, ref}}) => (
                        <ReactDatePicker
                          todayButton="Today"
                          maxDate={new Date()}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          calendarClassName="psb-form-control"
                          // showTimeSelect
                          dateFormat="MMMM d, yyyy h:mm aa"
                          className={'form-control'}
                          onChange={(date) => updateEventDates(date)}
                          onBlur={onBlur}
                          selected={taskHistoryDate}
                          customInput={<FormattedDateInput/>}
                        />
                      )}
                    />

                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>

            <ul className={'list-group'}>
              {tasks?.length > 0 && tasks.sort((a, b) => a.sortOrder - b.sortOrder).map((task, index) => {
                return (
                  <li index={index} key={index}
                      className={'list-group-item'}>
                    {task.name}
                    <span className={'text-small d-block'}>
                              {task.description}
                            </span>
                    {task.completed &&
                      <Chip
                        className={'pull-right'}
                        icon={<Icon>check</Icon>}
                        variant="outlined"
                        label={'Completed ' + formatDateTime(task.completedDate)}/>
                    }

                  </li>
                )
              })
              }
            </ul>
          </MDBox>
        </Card>
      </MDBox>

      <Footer mt={5}/>

    </DashboardLayout>
  );
}