import {Col, Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {AppContext} from "../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {Card} from "@mui/material";
import Meta from "../common/Meta";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import Footer from "../Footer";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import {useMaterialUIController} from "../../context";
import {handleDefaultValues} from "../common/Utils";
import {useForm} from "react-hook-form";
import {getCommunicationTemplate, saveCommunicationTemplate, uploadTemplateFile,} from "../common/api/WebApi";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";

import {CKEditor} from '@ckeditor/ckeditor5-react';
import {ClassicEditor} from 'ckeditor5';
import Divider from "@mui/material/Divider";
import ContextSearchNavbar from "../navbars/ContextSearchNavbar";
import {CONFIG} from "../common/EditorConfig";


export default function CommunicationTemplates(props) {

  let {guid} = useParams();

  const [isNew, setIsNew] = useState(true);

  const [controller] = useMaterialUIController();
  const {darkMode} = controller;

  const navigate = useNavigate();

  const [context, setContext] = useContext(AppContext);
  const [saving, setSaving] = useState(false);
  const [templateReady, setTemplateReady] = useState(false);
  const [template, setTemplate] = useState();
  const [templateBody, setTemplateBody] = useState();
  const [templates, setTemplates] = useState([]);
  const [templateIndex, setTemplateIndex] = useState(0);

  const [editorState, setEditorState] = useState();

  useEffect(() => {
    reset();
  }, []);

  const {register, handleSubmit, setValue, reset} = useForm({
    values: handleDefaultValues(template)
  });

  useEffect(() => {
    if (guid && 'new' !== guid) {
      setIsNew(false);
      setTemplateReady(false);
      getCommunicationTemplate(guid).then(result => {
        if (result.error) {
          setContext({...context, error: {message: result.message}});
        } else {
          setTemplate(result);
          setTemplateBody(result.templateBody);
          setTemplateReady(true);
        }
      });
    } else {
      reset();
      setTemplate({
        templateName: null,
        id: null,
        guid: null,
        templateSubject: null,
        templateBody: '',
      });
      setIsNew(true);
      setTemplateReady(true);
    }
  }, [guid])

  const sidenavItems = [
    {icon: "person", label: "First Name", replacement: "{firstName}"},
    {icon: "person", label: "Last Name", replacement: "{lastName}"},
    {icon: "person_outline", label: "Child Name", replacement: "{childName}"},

    {icon: "apps", label: "Account", replacement: "{membership}"},

    {icon: "", type: "divider"},

    {icon: "store", label: "Company Address", replacement: "{companyAddress}"},
    {icon: "store", label: "Company Contact", replacement: "{companyContact}"},

    {icon: "", type: "divider"},

    {icon: "sell", label: "Formatted Receipt", replacement: "{formattedReceipt}"},
    {icon: "receipt", label: "Formatted Invoice", replacement: "{formattedInvoice}"},
    {icon: "event", label: "Event Name", replacement: "{eventName}"},
    {icon: "event", label: "Event Date", replacement: "{eventDate}"},
    {icon: "event_busy", label: "Event Cancellation", replacement: "{eventCancellation}"},
    {icon: "subject", label: "Wait List Notification", replacement: "{eventWaitlistConfirmationLink}"},

    {icon: "", type: "divider"},
    {icon: "sell", label: "Forgot Password", replacement: "{forgotPasswordLink}"},

  ];

  const handleChange = (e, editor) => {
    setTemplateBody(editor.getData());
  }

  const handleSaveTemplate = async (data) => {
    setSaving(true);
    let d ={...data};
    d.templateBody = templateBody;
    let promise = saveCommunicationTemplate(d);
    promise.then(result => {
      setSaving(false);
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setTemplate(result);
        setTemplateBody(result.templateBody);
        if (isNew) {
          navigate(`/communication/templates/${result.guid}`);
        }
      }
    });
  }

  const renderSidenavItems = sidenavItems.map(({icon, label, href, replacement, type}, key) => {
    const itemKey = `item-${key}`;

    return (

      type === "divider" ? <Divider key={itemKey} sx={{my: 1}}/> :
      <MDBox key={itemKey} component="li" pt={0}>
        <MDTypography
          component="a"
          href={`#${href}`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            insertText(replacement);
          }}
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          sx={({
                 borders: {borderRadius},
                 functions: {pxToRem},
                 palette: {light},
                 transitions,
               }) => ({
            display: "flex",
            alignItems: "center",
            borderRadius: borderRadius.md,
            padding: `${pxToRem(10)} ${pxToRem(16)}`,
            transition: transitions.create("background-color", {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.shorter,
            }),

            "&:hover": {
              backgroundColor: light.main,
            },
          })}
        >
          <MDBox mr={1.5} lineHeight={1} color={darkMode ? "white" : "dark"}>
            <Icon fontSize="small">{icon}</Icon>
          </MDBox>
          {label}
        </MDTypography>
      </MDBox>
    );
  });


  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {

          const body = new FormData();
          loader.file.then((file) => {
            const fileType = file.type;
            const fileName = file.name;
            const reader = new FileReader();

            reader.onload = function (evt) {
              let fileRequest = {
                fileType: fileType,
                fileName: fileName,
                data: btoa(evt.target.result)
              }

              uploadTemplateFile(fileRequest).then(result => {
                if (result.error) {
                  setContext({...context, error: {message: result.message}});
                } else {
                  loader.uploaded = true;
                  loader.uploadUrl = result;
                }
                resolve({
                  default: result.fileLocation
                })
              });
            }
            reader.readAsBinaryString(file);
          });
        })
      }
    }
  }

  const [blurEditor, setBlurEditor] = useState();

  const insertText = (text) => {
    if (!blurEditor) {
      return;
    }

    blurEditor.model.change(writer => {
      writer.insertText(text + " ", blurEditor.model.document.selection.getFirstPosition());
      writer.setSelection(writer.createPositionAt(blurEditor.model.document.getRoot(), 'end'));
    });
  };

  const handleReady = (editor) => {
    setBlurEditor(editor);
  };

  const handleBlur = (event, editor) => {
    setBlurEditor(editor);
  };

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <DashboardLayout>
      <Meta pageTitle={'Communication Templates'}/>
      <ContextSearchNavbar title="Communication Templates"/>

      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <MDBox
              sx={{
                position: "sticky",
                top: "95px",
              }}>
              <Card
                sx={{
                  borderRadius: ({borders: {borderRadius}}) => borderRadius.lg,
                }}
              >
                <MDTypography variant="h5" fontWeight="regular" p={2} pb={0}>
                  Replacement Variables
                </MDTypography>
                <Divider/>
                <MDBox
                  component="ul"
                  display="flex"
                  flexDirection="column"
                  p={2}
                  pt={0}
                  m={0}
                  sx={{listStyle: "none"}}
                >
                  {renderSidenavItems}
                </MDBox>
              </Card>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>

              <Grid container spacing={3}>
                <Grid item xs={12} id="company-info">
                  <Card>
                    <MDBox p={3}>
                      <MDTypography variant="h5" fontWeight="regular" className={'mb-2'}>
                        Communication Template
                      </MDTypography>
                      <MDTypography variant="body2" fontWeight="regular" className={'mb-2'}>
                        Customize your company communication templates.
                      </MDTypography>
                      {templateReady && <form onSubmit={handleSubmit(handleSaveTemplate)} id={'templateForm'}>
                        <Row>
                          <Col className={'col'} sm={6} xs={12}>

                            <div className="form-group">
                              <MDInput
                                variant="standard" type="text"
                                {...register('templateName')}
                                autoComplete="off" required
                                className="form-control" id="templateName"
                                label="Enter Template Name"/>
                            </div>
                          </Col>
                          <Col className={'col'} sm={6} xs={12}>
                            <div className="form-group">
                              <MDInput
                                variant="standard" type="text"
                                {...register('templateSubject')}
                                autoComplete="off" required
                                className="form-control" id="templateSubject"
                                label="Enter Subject Name"/>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col className={'col'} sm={12} xs={12}>

                            <CKEditor
                              editor={ClassicEditor}
                              config={{
                                htmlSupport: {
                                  allow: [
                                    {
                                      name: /.*/,
                                      attributes: true,
                                      classes: true,
                                      styles: true
                                    }
                                  ]
                                },
                                toolbar: CONFIG.TOOLBAR,
                                plugins: CONFIG.PLUGINS,
                                extraPlugins: [
                                  uploadPlugin
                                ],
                                placeholder: 'Enter Template Body',
                              }}
                              data={templateBody || ''}
                              onReady={editor => {
                                handleReady(editor);
                              }}
                              onChange={(event, editor) => {
                                handleChange(event, editor);
                              }}
                              onBlur={(event, editor) => {
                                handleBlur(event, editor);
                              }}
                              onFocus={(event, editor) => {
                              }}
                            />
                            <Divider/>
                          </Col>
                        </Row>
                        <Row>
                          <Col className={'col'} sm={12} xs={12}>
                            <MDButton
                              type="submit"
                              variant="gradient"
                              size={'medium'}
                              disabled={saving}
                              color="info"
                              className={'pull-right'}>
                              <span className={'fa fa-save'}></span>&nbsp;
                              Save Template</MDButton>
                          </Col>
                        </Row>

                      </form>
                      }
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>

      <Footer mt={5}/>

    </DashboardLayout>
  );
}