import {Col, Container, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import {useContext, useState} from "react";
import MDButton from "../../components/MDButton";

export default function Confirm(props) {

  const [context, setContext] = useContext(AppContext);
  const [confirmation, setConfirmation] = useState(props.confirmation);

  const handleClose = () => {
    props.handleSave();
  };

  const handleConfirm = (data) => {
    props.handleConfirm(confirmation?.entity);
  };

  return (
    <Modal onHide={handleClose} size={'md'} show={props.show}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm {confirmation?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className={'col col-main'} xs={12}>
              <Container>
                <Row>
                  <Col className={'col'} xs={12}>
                    {confirmation?.message}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>

        </Modal.Body>

        <Modal.Footer>
          <MDButton type="submit"
                  onClick={() => {
                    handleClose(confirmation);
                  }}
                    color="secondary" disabled={props.saving}>
            {props.saving ? 'Saving...' : 'Cancel'}
          </MDButton>
          <MDButton
            style={{marginLeft: '10px'}}
            type="submit"
                  onClick={() => {
                    handleConfirm(confirmation);
                  }}
                  color="info"  disabled={props.saving}>
            {props.saving ? 'Saving...' : 'Confirm'}
          </MDButton>
        </Modal.Footer>
    </Modal>
  );
}