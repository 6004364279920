import {Col, Container, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {handleDefaultValues} from "../common/Utils";
import {useForm} from 'react-hook-form';
import MDButton from "../../components/MDButton";
import MDInput from "../../components/MDInput";
import {InputAdornment} from "@mui/material";

export default function ManageInventoryTypeModal(props) {

  const [context, setContext] = useContext(AppContext);
  const [inventoryType, setInventoryType] = useState(props.inventoryType || {});
  const [formInvalid, setFormInvalid] = useState(false);

  useEffect(() => {
    setInventoryType(props?.inventoryType);
  }, [props?.inventoryType]);

  const handleFormChange = (e) => {
    setValue(e.target.name, e.target.value);
    let _obj = {...inventoryType};
    _obj[e.target.name] = e.target.value;
    setInventoryType({..._obj});
  };

  const toggleMembershipType = (e) => {
    setValue(e.target.name, e.target.checked);
    let _obj = {...inventoryType};
    _obj[e.target.name] = e.target.checked;
    setInventoryType({..._obj});
  }

  const [isNew, setIsNew] = useState(!!!props.inventoryType?.guid);

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(inventoryType)
  });

  const handleClose = () => {
    props.handleClose();
  };

  const handleSave = (data) => {
    props.handleSave(data);
  };


  return (
    <Modal onHide={handleClose} size={'lg'} show={props.show}>
      <form onSubmit={handleSubmit(handleSave)} id={'inventoryTypeForm'}>
        <Modal.Header closeButton>
          <Modal.Title>{isNew ? 'Create New' : 'Manage'} Inventory Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className={'col col-main'} xs={12}>
              <Container>
                <Row>
                  <Col className={'col'} sm={12} xs={12}>
                    <div className="form-group">
                      <MDInput
                        type="text"
                        {...register('name')}
                        autoComplete="off" required
                        className="form-control" id="name"
                        label="Enter Inventory Type Name"/>

                    </div>
                  </Col>
                </Row>

                <Row className={'mt-2'}>
                  <Col className={'col'} sm={12} xs={12}>
                    <div className="form-group">

                      <MDInput
                        type="text"
                        {...register('desc')}
                        autoComplete="off" required
                        className="form-control" id="desc"
                        label="Enter Inventory Type Description"/>

                    </div>
                  </Col>
                </Row>

                <Row className={'mt-2'}>
                  <Col className={'col'} sm={12} xs={12}>
                    <div className="form-group">
                      <MDInput type="text" step="0.01" required
                               {...register('salesTax', {
                                 valueAsDouble: true
                               })}
                               onChange={(e) => {
                                 e.target.value = e.target.value.replace(/[^0-9.]/g, '');
                               }}
                               InputProps={{
                                 endAdornment: <InputAdornment position="end">
                                   <span className={'fa fa-percentage'}></span>
                                 </InputAdornment>,
                               }}
                               defaultValue={'0.00'}
                               autoComplete="off"
                               className="form-control" id="price"
                               label="Sales Tax"/>

                    </div>
                  </Col>
                </Row>

              </Container>
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <MDButton type="submit" color="info" disabled={formInvalid || props.saving}>
            {props.saving ? 'Saving...' : 'Save'}
          </MDButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
}