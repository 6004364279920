import {useContext} from "react";
import {AppContext} from "../../lib/AppContext";
import logo from '../assets/images/psb-logo-2024-full.png';
import logoSm from '../assets/images/psb-logo-2024-sm.png';
import {useNavigate} from "react-router-dom";

export default function Logo(props) {
  const [context, setContext] = useContext(AppContext);
  const navigate = useNavigate();
  const _props = props;

  const handleClick = (e) => {
    navigate('/');
  };

  return (
    <>
      <img
        style={{
          width: '100%',
          height: 'auto'
        }}
        onClick={handleClick}
        src={_props && _props.sm ? logoSm : logo} className={
         _props && _props.sm ? 'logo-sm sidebarCollapse' : 'logo'
       }/>
    </>
  );

}