import {useNavigate} from "react-router-dom";
import {AppContext} from "../../lib/AppContext";
import React, {useContext, useEffect, useRef, useState} from "react";
import {Card, CardContent, Chip, NativeSelect} from "@mui/material";
import MDBox from "../../components/MDBox";
import CustomerHeader from "../common/CustomerHeader";
import Footer from "../Footer";
import CustomerPageLayout from "../Layout/CustomerPageLayout";
import Grid from "@mui/material/Grid";
import MDTypography from "../../components/MDTypography";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import {Col, Container, Row} from "react-bootstrap";
import MDInput from "../../components/MDInput";
import {displayPhone, formatAge, formatDateTime, getDateNoTz, handleDefaultValues} from "../common/Utils";
import {useForm} from "react-hook-form";
import {
  customerSaveClientAccount,
  deleteMyCustomerFamily,
  getMyProfile,
  getMyReservations,
  getStates,
  saveInventoryEventCancellation,
  saveMyCustomerFamily,
  saveMyProfile,
  saveSpecialRequest
} from "../common/api/PortalCustomerApi";
import MDButton from "../../components/MDButton";
import Menu from "@mui/material/Menu";
import NotificationItem from "../Items/NotificationItem";
import ManageFamilyMemberModal from "../client/ManageFamilyMemberModal";
import Confirm from "../common/Confirm";
import ClientMembershipsModal from "./modal/ClientMembershipsModal";
import ClientPurchaseHistoryModal from "./modal/ClientPurchaseHistoryModal";
import ReservationSpecialRequest from "../client/ReservationSpecialRequest";
import ClientManageAccountModal from "./modal/ClientManageAccountModal";
import PageLoading from "../common/PageLoading";

export default function CustomerProfile(props) {
  const [context, setContext] = useContext(AppContext);
  const [company, setCompany] = useState();
  const [states, setStates] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [statesReady, setStatesReady] = useState(false);
  const [renderIndex, setRenderIndex] = useState(1);
  const [familyMember, setFamilyMember] = useState(null);
  const [manageFamilyMember, setManageFamilyMember] = useState(false);

  const [reservationSpecialRequest, setReservationSpecialRequest] = useState(null);
  const [showReservationSpecialRequest, setShowReservationSpecialRequest] = useState(false);

  const [confirmation, setConfirmation] = useState(null);
  const [confirmDeleteFamilyMember, setConfirmDeleteFamilyMember] = useState(false);
  const [saving, setSaving] = useState(false);
  const [refreshMember, setRefreshMember] = useState(false);
  const [showMembershipModal, setShowMembershipModal] = useState(false);
  const [showPurchaseHistory, setShowPurchaseHistory] = useState(false);
  const [reservations, setReservations] = useState([]);

  const [inventory, setInventory] = useState();

  const [memberAccount, setMemberAccount] = useState(null);
  const [manageMemberAccount, setManageMemberAccount] = useState(false);


  const navigate = useNavigate();
  const myRefs = useRef([]);

  const manageMemberAccountFn = (account) => {
    setMemberAccount(account);
    setManageMemberAccount(true)
  };

  const refHandler = (index) => {
    myRefs.current[index].visible = !!!myRefs.current[index].visible;
    setRenderIndex(renderIndex + 1);
  };
  const manageFamilyMemberFn = (member) => {
    let m = null;
    if (member) {
      m = {...member};
      if (member.dob) {
        m.dob = new Date(getDateNoTz(member.dob));
      } else {
        m.dob = null;
      }
    }
    setFamilyMember(m);
    setManageFamilyMember(true);
  };

  const handleCloseInventoryMembershipModal = () => {
    setShowMembershipModal(false);
  }

  const handleClosePurchaseHistory = () => {
    setShowPurchaseHistory(false);
  }

  const handleEventCancel = (inventoryEvent) => {
    saveInventoryEventCancellation(inventoryEvent).then(result => {
      setConfirmCancel(false);
      setConfirmation(null);
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        getMyUpcomingReservations();
      }
    });
  }

  const handleCloseManageAccount = () => {
    setManageMemberAccount(false);
  }

  const handleSaveMemberAccount = async (account) => {

    setSaving(true);

    let result = await customerSaveClientAccount(account);
    setSaving(false);

    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setRefreshMember(true);
      setManageMemberAccount(false);
    }
  }

  const handleSaveInventoryMembership = (inventory) => {
  }

  const manageMembershipFn = (member) => {
    setShowMembershipModal(true);
  };

  const handleCloseConfirm = () => {
    setConfirmation(null);
    setConfirmCancel(false);
  };

  const handleCancelFamilyMember = () => {
    setConfirmation(null);
    setConfirmDeleteFamilyMember(false);
  }

  const handleConfirmFamilyMember = async (familyMember) => {
    setSaving(true);
    setConfirmDeleteFamilyMember(false);
    let result = await deleteMyCustomerFamily(familyMember);
    setSaving(false);
    setConfirmation(null);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setRefreshMember(true);
      setConfirmDeleteFamilyMember(false);
    }
  }

  const preDeleteFamilyMemberFn = (member) => {
    let confirmation = {
      title: 'Delete ' + member.firstName + '?',
      message: 'Delete ' + member.firstName + ' ' + member.lastName + ' Permanently?',
      entity: member
    }
    setConfirmation(confirmation);
    setConfirmDeleteFamilyMember(true);
  };
  const [pageReady, setPageReady] = useState(false);
  const {register, handleSubmit, setValue, control, reset} = useForm({
    values: handleDefaultValues(customer),
  });

  const [confirmCancel, setConfirmCancel] = useState(false);

  const addSpecialRequest = (reservation) => {

    setReservationSpecialRequest(reservation);
    setShowReservationSpecialRequest(true);
  }

  const handleConfirmCancel = (inventoryEvent) => {
    let confirmation = {
      title: 'Event Cancellation',
      message: 'Confirm ' + inventoryEvent.eventName + ' cancellation.',
      entity: inventoryEvent
    }
    setConfirmation(confirmation);
    setConfirmCancel(true);
  }

  const saveProfile = (profile) => {
    setSaving(true);
    saveMyProfile(profile).then(result => {
      getMyProfile().then(result => {
        setCustomer(result);
        setPageReady(true);
        setSaving(false);
      });
    });
  }
  useEffect(() => {
    getStates().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setStates(result);
        setStatesReady(true);
      }
    });
    setRefreshMember(true);
    getMyUpcomingReservations();
  }, []);

  const getMyUpcomingReservations = () => {
    getMyReservations().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setReservations(result);
      }
    });
  }

  const handleCloseSpecialRequest = () => {
    setReservationSpecialRequest(null);
    setShowReservationSpecialRequest(false);
  }


  const handleCloseFamilyMember = () => {
    setFamilyMember(null);
    setManageFamilyMember(false);
  }

  useEffect(() => {
    if (!refreshMember) {
      return;
    }
    setRefreshMember(false);
    getMyProfile().then(result => {
      setCustomer(result);
    });
  }, [refreshMember]);

  const handleSaveReservationRequest = async (reservation) => {

    setSaving(true);

    let result = await saveSpecialRequest(reservation);

    setSaving(false);

    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setShowReservationSpecialRequest(false);
      setReservationSpecialRequest(null);

      let _context = {...context};
      _context.toast = {
        message: 'Special Request Saved',
      }
      setContext({..._context});
      getMyUpcomingReservations();
    }
  }

  const handleSaveFamilyMember = async (member) => {

    setSaving(true);

    let result = await saveMyCustomerFamily(member);
    setFamilyMember(null);
    setSaving(false);

    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      setRefreshMember(true);
      setManageFamilyMember(false);
    }
  }

  useEffect(() => {
    if (context.sessionUser?.companyGk) {
      setPageReady(true);
      setCompany(context.sessionUser.company);
    }
  }, [context?.sessionUser]);

  return (
    <CustomerPageLayout>

      <CustomerHeader props={props}/>

      {pageReady && <MDBox p={3}>
        <MDBox m={3}>
          <Grid container cols={2} spacing={3} direction="row">
            <Grid item xs={12} sm={8}>
              <Card>
                <CardContent>
                  <MDTypography variant="h5" color="secondary" fontWeight="light">
                    My Profile
                    <MDButton
                      color="info"
                      size="small"
                      className={'pull-right'}
                      onClick={(e) => {
                        navigate('/cx/my-purchases');
//                        setShowPurchaseHistory(true);
                      }}>
                      <span className={'fa fa-dollar'}></span>&nbsp;Purchase History
                    </MDButton>
                    <Divider/>
                  </MDTypography>

                  {!customer && <PageLoading/>}
                  {customer &&
                    <form onSubmit={handleSubmit(saveProfile)} id={'myProfileForm'}>
                      <Row>
                        <Col xs={12}>
                          <Container>
                            <Row>
                              <Col className={'col'} sm={6} xs={12}>
                                <div className="form-group">
                                  <MDInput type="text" required
                                           {...register('firstName')}
                                           autoComplete="off"
                                           variant="outlined"
                                           className="form-control" id="firstName"
                                           label="Enter First name"/>
                                </div>
                              </Col>

                              <Col className={'col'} sm={6} xs={12}>
                                <div className="form-group">
                                  <MDInput type="text" required
                                           {...register('lastName')}
                                           variant="outlined"
                                           className="form-control" id="lastName"
                                           label="Enter Last name"/>
                                </div>
                              </Col>
                            </Row>
                            <Row className={'my-4'}>
                              <Col className={'col'} md={6} sm={12} xs={12}>
                                <div className="form-group">
                                  <MDInput
                                    variant="outlined"
                                    type="email" required
                                    {...register('email')}
                                    autoComplete="off"
                                    variant="outlined"
                                    className="form-control" id="email"
                                    label="Enter Email"/>

                                  <label htmlFor="emailEnabled" className={'my-4'}>
                                    <input type="checkbox"
                                           {...register('emailEnabled')}
                                           autoComplete="off"
                                           id="emailEnabled"/>
                                    &nbsp;Email Allowed
                                  </label>
                                </div>
                              </Col>

                              <Col className={'col'} md={6} sm={12} xs={12}>
                                <div className="form-group">
                                  <MDInput
                                    variant="outlined" type="text"
                                    {...register('primaryPhone', {
                                      onChange: (e) => {
                                        e.target.value = displayPhone(e.target.value);
                                      }
                                    })}
                                    autoComplete="off"
                                    className="form-control" id="primaryPhone"
                                    label="Enter Phone"/>

                                  <label htmlFor="smsEnabled" className={'my-4'}>
                                    <input type="checkbox"
                                           {...register('smsEnabled')}
                                           id="smsEnabled"/>
                                    &nbsp;SMS Allowed
                                  </label>
                                </div>
                              </Col>

                              <Col className={'col'} sm={6} xs={12}>
                                <div className="form-group">
                                  <MDInput
                                    variant="outlined" type="text"
                                    {...register('address1')}
                                    autoComplete="off"
                                    className="form-control" id="address1"
                                    label="Enter Address"/>
                                </div>
                              </Col>

                              <Col className={'col'} sm={6} xs={12}>
                                <div className="form-group">
                                  <MDInput
                                    variant="outlined" type="text"
                                    {...register('address2')}
                                    autoComplete="off"
                                    className="form-control" id="address2"
                                    label="Enter Address 2"/>
                                </div>
                              </Col>

                            </Row>

                            <Row className={'my-4'}>
                              <Col className={'col'} sm={4} xs={12}>
                                <div className="form-group">
                                  <MDInput
                                    variant="outlined" type="text"
                                    {...register('city')}
                                    autoComplete="off"
                                    className="form-control" id="city"
                                    label="Enter City"/>
                                </div>
                              </Col>

                              <Col className={'col'} sm={4} xs={12}>
                                <div className="form-group">
                                  {states && <NativeSelect
                                    variant="outlined"
                                    id={'state'} name={'state'} className={'form-control'}
                                    {...register('state.stateId')}>
                                    {
                                      states.map((c) =>
                                        <option value={c.stateId} key={c.stateId}>
                                          {c.stateName}
                                        </option>)
                                    }
                                  </NativeSelect>}
                                </div>
                              </Col>

                              <Col className={'col'} sm={4} xs={12}>
                                <div className="form-group">
                                  <MDInput
                                    variant="outlined" type="text"
                                    {...register('zipCode')}
                                    autoComplete="off"
                                    className="form-control" id="zipCode"
                                    label="Enter Zip"/>
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </Col>
                        <Col className={'col col-side'} xs={12}>
                          <Row>

                            <Divider/>
                            <Grid container justifyContent={'flex-end'}>
                              <Grid item>
                                <MDButton
                                  type="submit"
                                  variant="gradient"
                                  size={'medium'}
                                  disabled={saving}
                                  color="secondary">
                                  {saving ? 'Saving Profile' : 'Save Profile'}
                                </MDButton>
                              </Grid>
                            </Grid>

                          </Row>
                        </Col>
                      </Row>
                    </form>
                  }
                </CardContent>
              </Card>
            </Grid>


            <Grid item sm={4} xs={12}>
              <MDBox>
                <Grid container cols={2} spacing={3} direction="row">

                  {renderIndex && reservations.length > 0 && <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <MDTypography variant="h5" color="secondary" fontWeight="light">
                          Upcoming Events
                          <Divider/>
                        </MDTypography>

                        <MDTypography variant="p" color="text" fontWeight="regular">
                          <ul className={'list-group'}>
                            {renderIndex && reservations
                              .sort((a, b) => new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime())
                              .map((rr, index) => {
                                return (<li className={'list-group-item'}
                                            style={{
                                              'position': 'relative',
                                            }}
                                            key={index}>
                                  {rr.eventName} - {rr.eventDesc}
                                  {rr.specialRequestInput && <>

                                    <MDBox>
                                      <MDTypography variant="p" color="text" fontWeight="bold">
                                        Special Request:&nbsp;
                                      </MDTypography>
                                      <MDTypography variant="p" color="text" fontWeight="regular">
                                        {rr.specialRequestInput}
                                      </MDTypography>
                                    </MDBox>
                                  </>}
                                  <div className={'pull-right px-2'}
                                       onClick={(e) => {
                                         refHandler('reservation' + index)
                                       }}
                                       ref={el => (myRefs.current['reservation' + index] = el)}>

                                     <span
                                       className={'fa pull-right cursor-pointer p-1 mx-n1'}>
                                       <Icon>more_horiz</Icon>
                                     </span>

                                    {renderIndex && myRefs.current['reservation' + index]?.visible &&
                                      <Menu open={true} anchorReference={null}
                                            anchorEl={myRefs.current['reservation' + index]}
                                            anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "left",
                                            }}>
                                        {rr.specialRequest && <NotificationItem onClick={(e) => {
                                          addSpecialRequest(rr)
                                        }} icon={<Icon>add_comment</Icon>} title="Special Request"/>}
                                        <NotificationItem onClick={(e) => {
                                          handleConfirmCancel(rr)
                                        }} icon={<Icon>cancel</Icon>} title="Cancel"/>
                                      </Menu>
                                    }
                                  </div>
                                  <br/>
                                  <Chip label={formatDateTime(rr.eventDate)}/>
                                </li>)
                              })}
                          </ul>
                        </MDTypography>
                      </CardContent>
                    </Card>
                  </Grid>}

                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <MDTypography variant="h5" color="secondary" fontWeight="light">
                          Memberships
                          <MDButton
                            color="secondary"
                            size="small"
                            className={'pull-right'}
                            onClick={(e) => {
                              e.preventDefault();
                              manageMembershipFn(null)
                            }}>
                            <span className={'fa fa-user-plus'}></span>&nbsp;Add Membership
                          </MDButton>
                          <Divider/>
                        </MDTypography>
                        <MDTypography variant="p" color="text" fontWeight="regular">
                          <ul className={'list-group'}>
                            {renderIndex && customer?.accounts?.sort((a, b) => a.accountId > b.accountId ? 1 : -1)
                              .map((account, index) => {
                                return (<li className={'list-group-item'}
                                            style={{
                                              'position': 'relative',
                                            }}
                                            key={index}>
                                  {account.accountType.name}
                                  <br/>
                                  {account?.accountType?.recurring &&
                                    <>
                                      <Chip
                                        variant="outlined"
                                        color="secondary"
                                        label="Recurring"
                                      />&nbsp;
                                    </>
                                  }
                                  <>
                                    <Chip
                                      variant="outlined"
                                      color="secondary"
                                      label={account?.accountType?.unlimitedVisits ? 'Unlimited Visits' : 'Visits Remaining: ' + account?.numberVisits}
                                    />&nbsp;
                                  </>

                                  <div className={'pull-right px-2'}
                                       onClick={(e) => {
                                         refHandler('memberAccount' + index)
                                       }}
                                       ref={el => (myRefs.current['memberAccount' + index] = el)}>

                                    {account?.recurring &&  <span
                                       className={'fa pull-right cursor-pointer p-1 mx-n1'}>
                                       <Icon>more_horiz</Icon>
                                     </span> }

                                    {renderIndex && account?.recurring && myRefs.current['memberAccount' + index]?.visible &&
                                      <Menu open={true} anchorReference={null}
                                            anchorEl={myRefs.current['memberAccount' + index]}
                                            anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "left",
                                            }}>
                                        <NotificationItem
                                          onClick={(e) => {
                                            manageMemberAccountFn(account)
                                          }} icon={<Icon>pause_circle_outline</Icon>} title="Pause Billing"/>
                                      </Menu>
                                    }
                                  </div>

                                </li>);
                              })}
                          </ul>
                        </MDTypography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <MDTypography variant="h5" color="secondary" fontWeight="light">
                          Family Members
                          <MDButton
                            color="secondary"
                            size="small"
                            className={'pull-right'}
                            onClick={(e) => {
                              e.preventDefault();
                              manageFamilyMemberFn(null)
                            }}>
                            <span className={'fa fa-user-plus'}></span>&nbsp;Add Family
                          </MDButton>
                          <Divider/>
                        </MDTypography>

                        <MDTypography variant="p" color="text" fontWeight="regular">
                          <ul className={'list-group'}>
                            {renderIndex && customer?.familyMembers?.sort((a, b) => a.id > b.id ? 1 : -1)
                              .map((familyMember, index) => {
                                return (<li className={'list-group-item'}
                                            style={{
                                              'position': 'relative',
                                            }}
                                            key={index}>
                                  <span className={'cursor-pointer'} onClick={(e) => {
                                    manageFamilyMemberFn(familyMember)
                                  }}>{familyMember.firstName} {familyMember.lastName}

                                    <span className={'font-smaller'}>
                                      {familyMember?.dob && ' (' + formatAge(familyMember) + ')'}
                                    </span>
                                  </span>

                                  <div className={'pull-right px-2'}
                                       onClick={(e) => {
                                         refHandler('familyMember' + index)
                                       }}
                                       ref={el => (myRefs.current['familyMember' + index] = el)}>

                                     <span
                                       className={'fa pull-right cursor-pointer p-1 mx-n1'}>
                                       <Icon>more_horiz</Icon>
                                     </span>

                                    {renderIndex && myRefs.current['familyMember' + index]?.visible &&
                                      <Menu open={true} anchorReference={null}
                                            anchorEl={myRefs.current['familyMember' + index]}
                                            anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "left",
                                            }}>
                                        <NotificationItem
                                          onClick={(e) => {
                                            manageFamilyMemberFn(familyMember)
                                          }} icon={<Icon>edit</Icon>} title="Edit"/>
                                        <NotificationItem onClick={(e) => {
                                          preDeleteFamilyMemberFn(familyMember)
                                        }} icon={<Icon>delete</Icon>} title="Delete"/>
                                      </Menu>
                                    }
                                  </div>
                                  <br/>
                                  <Chip
                                    variant="outlined"
                                    color="secondary"
                                    label={familyMember.familyMemberType.name}/>&nbsp;

                                  {familyMember.emergencyContact &&
                                    <>
                                      <Chip
                                        variant="outlined"
                                        color="primary"
                                        label={'Emergency Contact ' + displayPhone(familyMember.phoneNumber)}
                                      />&nbsp;
                                    </>
                                  }
                                </li>)
                              })}
                          </ul>
                        </MDTypography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      }

      {showPurchaseHistory && <ClientPurchaseHistoryModal
        handleClose={handleClosePurchaseHistory}
        show={showPurchaseHistory}/>
      }
      {showMembershipModal && <ClientMembershipsModal
        handleClose={handleCloseInventoryMembershipModal}
        handleSave={handleSaveInventoryMembership}
        show={showMembershipModal}
      />
      }

      {confirmCancel && <Confirm confirmation={confirmation}
                                 show={confirmCancel}
                                 saving={saving}
                                 handleConfirm={handleEventCancel}
                                 handleClose={handleCloseConfirm}/>}

      {
        confirmation && <Confirm confirmation={confirmation}
                                 show={confirmDeleteFamilyMember}
                                 saving={saving}
                                 handleConfirm={handleConfirmFamilyMember}
                                 handleClose={handleCancelFamilyMember}/>
      }


      {
        showReservationSpecialRequest && <ReservationSpecialRequest
          reservation={reservationSpecialRequest}
          show={showReservationSpecialRequest}
          saving={saving}
          handleSave={handleSaveReservationRequest}
          handleClose={handleCloseSpecialRequest}/>
      }

      {
        manageFamilyMember && <ManageFamilyMemberModal
          familyMember={familyMember}
          show={manageFamilyMember}
          saving={saving}
          handleSaveFamilyMember={handleSaveFamilyMember}
          handleCloseFamilyMember={handleCloseFamilyMember}/>
      }
      {
        memberAccount && <ClientManageAccountModal
          account={memberAccount}
          show={manageMemberAccount}
          saving={saving}
          handleSave={handleSaveMemberAccount}
          handleClose={handleCloseManageAccount}/>
      }

      <Footer/>
    </CustomerPageLayout>
  )
}