import {Col, Container, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import {useContext, useEffect, useState} from "react";
import {formatCreditCard, handleDefaultValues} from "../common/Utils";
import {useForm} from 'react-hook-form';
import MDTypography from "../../components/MDTypography";
import {Collapse, Divider, IconButton} from "@mui/material";
import {ExpandCircleDown, ExpandCircleDownOutlined} from "@mui/icons-material";
import {getInventoryEventTypes} from "../common/api/InventoryApi";
import MDButton from "../../components/MDButton";
import MDInput from "../../components/MDInput";

export default function ManageAccountTypeModal(props) {

  const rotate = {
    'transform': 'rotate(270deg)'
  };

  const RECURRENCES_WEEKLY = 'WEEKLY';
  const RECURRENCES_MONTHLY = 'MONTHLY';
  const RECURRENCES_ANNUALLY = 'ANNUALLY';

  const [recurrenceType, setRecurrenceType] = useState();

  const [context, setContext] = useContext(AppContext);
  const [accountType, setAccountType] = useState(props.accountType || {});

  const [eventTypesFilter, setEventTypesFilter] = useState(props.accountType?.eventTypes || []);
  const [formInvalid, setFormInvalid] = useState(false);

  const [isNew, setIsNew] = useState(!!!props.accountType?.guid);
  const [expandMemberOptions, setExpandMemberOptions] = useState(!!props.accountType?.guid);
  const [expandLinkedEventTypes, setExpandLinkedEventTypes] = useState(!!props.accountType?.guid);
  const [eventTypes, setEventTypes] = useState([]);

  useEffect(() => {
      if (props?.accountType) {
        setRecurrenceType(props?.accountType?.recurrencePattern);
      }
  }, [props?.accountType]);

  useEffect(()=> {
    let _accountType = {...accountType};
    _accountType.recurrencePattern = recurrenceType;
    setAccountType(_accountType);
  }, [recurrenceType]);

  const loadInventoryEventTypesFn = async () => {
    getInventoryEventTypes().then((result) => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setEventTypes(result);
      }
    });
  }

  useEffect(() => {
    loadInventoryEventTypesFn();
  }, [])

  useEffect(() => {
    setAccountType(props?.accountType);
  }, [props?.accountType]);

  const handleFormChange = (e) => {
    setValue(e.target.name, e.target.value);
    let _obj = {...accountType};
    _obj[e.target.name] = e.target.value;
    setAccountType({..._obj});
  };

  const toggleRecurring = (e) => {
    setValue(e.target.name, e.target.checked);
    let _obj = {...accountType};
    _obj[e.target.name] = e.target.checked;
    setAccountType({..._obj});
    if (e.target.checked) {
      setValue('recurrencePattern', RECURRENCES_WEEKLY);
    } else {
      setValue('recurrencePattern', null);
    }
  }

  const toggleUnlimited = (e) => {
    setValue(e.target.name, e.target.checked);
    let _obj = {...accountType};
    _obj[e.target.name] = e.target.checked;
    setAccountType({..._obj});
  }

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(accountType)
  });

  const handleClose = () => {
    props.handleClose();
  };

  const handleSave = (data) => {
    let d = {...data};
    d.eventTypes = eventTypesFilter;
    props.handleSave(d);
  };


  const handleLinkedEventTypeChange = (e) => {
    let _obj = [...eventTypesFilter];
    if (e.target.checked) {
      _obj = [...eventTypesFilter, parseInt(e.target.value, 10)];
    } else {
      _obj.splice(eventTypesFilter.indexOf(parseInt(e.target.value, 10)), 1);
    }
    setEventTypesFilter(_obj);
  }

  return (
    <Modal onHide={handleClose} size={'lg'} show={props.show}>
      <form onSubmit={handleSubmit(handleSave)} id={'accountTypeForm'}>
        <Modal.Header closeButton>
          <Modal.Title>{isNew ? 'Create New' : 'Manage'} Account Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className={'col col-main'} xs={12}>
              <Container>
                <Row>
                  <Col className={'col'} sm={12} xs={12}>
                    <div className="form-group">
                      <MDInput type="text" required
                               {...register('name')}
                               autoComplete="off"
                               className="form-control" id="name"
                               label="Enter Account Type Name"/>
                    </div>
                  </Col>
                </Row>

                <Row className={'mt-2'}>
                  <Col className={'col'} sm={12} xs={12}>
                    <div className="form-group">
                      <label htmlFor="name">Account Type Description</label>
                      <MDInput type="text"
                               {...register('desc')}
                               autoComplete="off"
                               maxLength={255}
                               className="form-control" id="desc"
                               label="Enter Account Type Description"/>
                    </div>
                  </Col>
                </Row>

                <Row className={'mt-2'}>
                  <MDTypography variant={'h6'}>Member Options
                    <IconButton
                      onClick={(e) => {
                        setExpandMemberOptions(!expandMemberOptions);
                      }}
                      size={'small'} className={'pull-right btn btn-sm'} color={'primary'}>
                      {expandMemberOptions && <ExpandCircleDown sx={rotate}/>}
                      {!expandMemberOptions && <ExpandCircleDownOutlined/>}
                    </IconButton>
                    <Divider/>
                  </MDTypography>
                  <Row>
                    <Collapse collapsedSize={0}
                              in={expandMemberOptions}>
                      <Row>
                        <Col className={'col'} sm={6} xs={12}>
                          <div className="form-group">
                            <label htmlFor="recurring">
                              <input type="checkbox"
                                     {...register('recurring', {
                                       onChange: (e) => {
                                         toggleRecurring(e);
                                       }
                                     })}
                                     autoComplete="off"
                                     id="recurring"/>
                              &nbsp;Recurring (auto-renews)
                            </label>
                          </div>
                        </Col>

                        <Col className={'col'} sm={6} xs={12}>
                          <div className="form-group">
                            <label htmlFor="accountType">
                              Auto Renew Every
                            </label>
                            <label className={'d-block'}>
                              <input type={'radio'} disabled={!accountType?.recurring}
                                     value={RECURRENCES_WEEKLY}
                                     name="recurrenceType"
                                     defaultChecked={accountType?.recurrencePattern === RECURRENCES_WEEKLY}
                                     {...register('recurrenceType', {
                                       onChange: (e) => {
                                         setRecurrenceType(e.target.value)
                                       }
                                     })}
                              /> Repeat Weekly
                            </label>
                            <label className={'d-block'}>
                              <input type={'radio'} disabled={!accountType?.recurring}
                                     name="recurrenceType"
                                     value={RECURRENCES_MONTHLY}
                                     defaultChecked={accountType?.recurrencePattern === RECURRENCES_MONTHLY}
                                     {...register('recurrenceType', {
                                       onChange: (e) => {
                                         setRecurrenceType(e.target.value)
                                       }
                                     })}
                              /> Repeat Monthly
                            </label>
                            <label className={'d-block'}>
                              <input type={'radio'} disabled={!accountType?.recurring}
                                     name="recurrenceType"
                                     value={RECURRENCES_ANNUALLY}
                                     defaultChecked={accountType?.recurrencePattern === RECURRENCES_ANNUALLY}
                                     {...register('recurrenceType', {
                                       onChange: (e) => {
                                         setRecurrenceType(e.target.value)
                                       }
                                     })}
                              /> Repeat Annually
                            </label>
                          </div>
                        </Col>

                      </Row>

                      <Row className={'mt-2'}>
                        <Col className={'col'} sm={6} xs={12}>
                          <div className="form-group">
                            <label htmlFor="decrementVisits">
                              <input type="checkbox"
                                     disabled={accountType?.unlimitedVisits}
                                     {...register('decrementVisits', {
                                       onChange: (e) => {
                                         toggleRecurring(e);
                                       }
                                     })}
                                     autoComplete="off"
                                     id="decrementVisits"/>
                              &nbsp;Decrement Visits
                            </label>
                          </div>
                        </Col>

                        <Col className={'col'} sm={6} xs={12}>
                          <div className="form-group">
                            <label htmlFor="unlimitedVisits">
                              <input type="checkbox"
                                     {...register('unlimitedVisits', {
                                       onChange: (e) => {
                                         toggleUnlimited(e);
                                       }
                                     })}
                                     autoComplete="off"
                                     id="unlimitedVisits"/>
                              &nbsp;Unlimited Visits
                            </label>
                          </div>
                        </Col>

                        <Col className={'col'} sm={6} xs={12}>
                          <div className="form-group">
                            <label htmlFor="numberVisits">Number Visits</label>
                            <input type="number"
                                   disabled={accountType?.unlimitedVisits}
                                   {...register('numberVisits', {
                                     valueAsNumber: true
                                   })}
                                   className="form-control"
                                   autoComplete="off"
                                   id="numberVisits"/>
                          </div>
                        </Col>

                      </Row>
                    </Collapse>
                  </Row>

                </Row>

                <Row className={'mt-2'}>
                  <MDTypography variant={'h6'}>Linked Event Categories
                    <IconButton
                      onClick={(e) => {
                        setExpandLinkedEventTypes(!expandLinkedEventTypes);
                      }}
                      size={'small'} className={'pull-right btn btn-sm'} color={'primary'}>
                      {expandLinkedEventTypes && <ExpandCircleDown sx={rotate}/>}
                      {!expandLinkedEventTypes && <ExpandCircleDownOutlined/>}
                    </IconButton>
                    <Divider/>
                  </MDTypography>
                  <Row>
                    <Collapse collapsedSize={0}
                              in={expandLinkedEventTypes}>
                      <Row>
                        <Col className={'col'} sm={6} xs={12}>
                          {eventTypes.map((eventType, index) => {
                            return (
                              <div key={index} className="form-group">
                                <label htmlFor={'linkedEventTypes-' + eventType.id}>
                                  <input type={'checkbox'}
                                         name={'linkedEventTypes-' + eventType.id}
                                         id={'linkedEventTypes-' + eventType.id}
                                         value={eventType.id}
                                         defaultChecked={eventTypesFilter.indexOf(eventType.id) > -1}
                                         onChange={(e) => {
                                           handleLinkedEventTypeChange(e);
                                         }}
                                  />
                                  &nbsp;{eventType.name}
                                </label>
                              </div>
                            )
                          })}

                        </Col>
                      </Row>
                    </Collapse>
                  </Row>
                </Row>

              </Container>
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <MDButton type="submit" color="info" disabled={formInvalid || props.saving}>
            {props.saving ? 'Saving...' : 'Save'}
          </MDButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
}