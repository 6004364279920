import {useContext} from "react";
import {AppContext} from "../../lib/AppContext";
import logo from '../../assets/images/psb-logo-animated.gif';

export default function PageLoading(props) {
  const [context, setContext] = useContext(AppContext);

  return (
    <div className={'loading mt-5'}>
      <p className={'text-center'}>
        <img
          style={{
            width: '65px',
            height: '60px',
            margin: '-15px 25px 10px 20px',
            position: 'absolute',
            top: '40vh',
          }}
          src={logo} className={'logo-sm logo'}/>
      {/*<span className={'fa fa-circle-o-notch fa-spin'}></span> Loading...*/}
    </p>
</div>
)
  ;

}