import {useNavigate, useParams} from "react-router-dom";
import {AppContext} from "../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {Card, CardContent} from "@mui/material";
import MDBox from "../../components/MDBox";
import CustomerHeader from "../common/CustomerHeader";
import Footer from "../Footer";
import CustomerPageLayout from "../Layout/CustomerPageLayout";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import {getPublicWaiver, submitPublicWaiver} from "../common/api/PortalCustomerApi";
import {CONSTANTS} from "../common/constants";

export default function CustomerWaiver(props) {

  let {guid} = useParams();

  const [context, setContext] = useContext(AppContext);
  const [company, setCompany] = useState(context?.sessionUser?.company);

  const [waiverReady, setWaiverReady] = useState(false);
  const [waiver, setWaiver] = useState();
  const [waiverBody, setWaiverBody] = useState();
  const [formattedWaiverBody, setFormattedWaiverBody] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (context.sessionUser?.companyGk) {
      setCompany(context.sessionUser.company);
    }
  }, [context?.sessionUser]);

  useEffect(() => {
    if (guid) {
      setWaiverReady(false);
      getPublicWaiver(guid).then(result => {
        if (result.error) {
          setContext({...context, error: {message: result.message}});
        } else {
          setWaiver(result);
          setWaiverBody(result.waiverBody);
          setWaiverReady(true);
        }
      });
    }
  }, [guid]);

  const [pageReady, setPageReady] = useState(false);

  const handleClose = () => {
    // setShowMembershipModal(false);
  }
  const handleSave = (data) => {
    //  setSavingMembership(true);
  }

  const handleWaiverSubmit = async (e) => {

    const formData = {};
    const form = document.getElementById('waiverForm');

    const elements = form.elements;
    for (let j in elements) {
      if (!elements[j].className?.match('replacement')) {
        continue;
      }
      if (!elements[j].value) {
        continue;
      }
      formData[elements[j].name] = elements[j].value;
    }

    const saveData = {
      waiver : waiver,
      formData: formData
    }

    submitPublicWaiver(saveData).then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        let _c = {...context};
        _c.refreshIdentity = true;
        _c.toast = {message: 'Waiver submitted successfully'};
        setContext({..._c});
        setTimeout(() => {
          setContext({...context, toast: {}});
        }, CONSTANTS.TOAST_TIMEOUT);
        navigate('/cx/waivers');
      }

    });

  }

  const formatReplacements = (html, replacement, placeholder, type) => {
    let _html = html;
    const pattern = '{' + replacement + '}';

    const regex = new RegExp(pattern, 'g');

    let matches = _html.match(regex);

    matches.forEach((item, index) => {
      _html = _html.replace(item, '<input required placeholder="'
        + placeholder + '" type="' + type + '" name="' + replacement + '-' + index + '" class="replacement" />');
    });

    return _html;
  }

  const formItems = [
    {icon: "person", label: "Parent / Guardian Name", replacement: "legal-name", type: "input"},
    {icon: "person_add", label: "Child", replacement: "child-name", type: "input"},
    {icon: "check_box", label: "Agree", replacement: "checkbox-agree", type: "checkbox"},
    {icon: "edit", label: "Signature", replacement: "signature", type: "text"},
    {icon: "event", label: "Date", replacement: "date", type: "date"},
  ];

  const formatPlaceholders = (html, replacement, placeholder, type) => {
    let _html = html;
    const pattern = '{' + replacement + '}';

    const regex = new RegExp(pattern, 'g');

    let matches = _html.match(regex);

    matches.forEach((item, index) => {
      _html = _html.replace(item, '{' + replacement + '-' + index + '}');
    });

    return _html;
  }

  const toForm = (html) => {
    let _html = html;
    for (let j in formItems) {
      let item = formItems[j];
      _html = formatReplacements(_html, item.replacement, item.label, item.type);
    }

    return _html;

  }

  return (
    <>
      <CustomerPageLayout>

        <CustomerHeader props={props}/>

        {waiverReady && <MDBox p={3}>
          <MDBox m={3}>
            <Grid container cols={3} spacing={3} direction="row">
              <Grid item sm={3} xs={12}>
                <Card>
                  <CardContent>

                    <MDBox display="flex" justifyContent="center">
                      {company.logoUrl && <img
                        onClick={() => {
                          navigate('/cx/')
                        }}
                        src={(company.logoUrl)}
                        className={'img-thumbnail m-2 ' + props.className} style={{width: '90%'}}/>}
                    </MDBox>

                    <Divider/>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Card>
                  <CardContent>
                    <MDBox mt={3}>
                      {waiverBody && <form id="waiverForm" onSubmit={(e) => {
                        e.preventDefault();
                        handleWaiverSubmit(e);
                      }}><span
                        dangerouslySetInnerHTML={{__html: toForm(waiverBody)}}></span>
                        <div className="row">
                          <div className="col col-12">
                            <input type="submit" value="Save" className={'btn btn-primary pull-right'}/>
                          </div>
                        </div>
                      </form>}
                      <hr/>
                      {formattedWaiverBody && <div dangerouslySetInnerHTML={{__html: formattedWaiverBody}}></div>}
                    </MDBox>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        }

        <Footer/>
      </CustomerPageLayout>
    </>
  )
}