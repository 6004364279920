import {AppContext} from "../../lib/AppContext";
import {useContext, useEffect, useRef, useState} from "react";
import {Card, CardContent, Chip, FormControl, InputAdornment, InputLabel, OutlinedInput} from "@mui/material";
import ContextSearchNavbar from "../navbars/ContextSearchNavbar";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import Footer from "../Footer";
import {getInventoryItem, removeTag, saveInventory, saveTag} from "../common/api/InventoryApi";
import {useNavigate, useParams} from "react-router-dom";
import MDTypography from "../../components/MDTypography";
import Grid from "@mui/material/Grid";
import MDButton from "../../components/MDButton";
import Divider from "@mui/material/Divider";
import {Col, Row} from "react-bootstrap";
import {formatCurrency} from "../common/Utils";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import NotificationItem from "../Items/NotificationItem";
import {CONSTANTS} from "../common/constants";
import {useForm} from "react-hook-form";
import PageLoading from "../common/PageLoading";
import ManageInventoryModal from "./ManageInventoryModal";

export default function ViewInventory(props) {

  const navigate = useNavigate();
  const [context, setContext] = useContext(AppContext);
  let {guid} = useParams();

  const [reloadInventory, setReloadInventory] = useState(false);
  const [inventory, setInventory] = useState();
  const [modalInventory, setModalInventory] = useState();

  const [inventoryReady, setInventoryReady] = useState(false);
  const [renderIndex, setRenderIndex] = useState(1);

  const [refreshCart, setRefreshCart] = useState(false);
  const [refreshReservations, setRefreshReservations] = useState(false);
  const [viewingCart, setViewingCart] = useState(false);
  const [saving, setSaving] = useState(false);

  const [client, setClient] = useState(null);

  const myRefs = useRef([]);

  const [listCustomersModalOpen, setListCustomersModalOpen] = useState(false);
  const [listCustomersWaitlistModalOpen, setListCustomersWaitlistModalOpen] = useState(false);

  const handleCloseListCustomersModal = () => {
    setListCustomersModalOpen(false);
  }

  const handleCloseListWaitlistCustomersModal = () => {
    setListCustomersWaitlistModalOpen(false);
  }

  const startAddCustomerReservation = () => {
    setListCustomersModalOpen(true);
  }

  const startAddCustomerWaitlist = () => {
    setListCustomersWaitlistModalOpen(true);
  }

  const getInventoryFn = async (guid) => {
    return await getInventoryItem(guid);
  }
  const refHandler = (index) => {
    myRefs.current[index].visible = !!!myRefs.current[index].visible;
    setRenderIndex(renderIndex + 1);
  };

  const toggleCartFn = () => {
    setViewingCart(!viewingCart);
  }

  const [tagName, setTagName] = useState('');
  const [tags, setTags] = useState([]);
  const [showTags, setShowTags] = useState(false);
  const toggleTagsFn = () => {
    setShowTags(!showTags);
  }

  const handleTagChange = (e) => {
    setTagName(e.target.value);
  }

  const removeTagFn = async (tag, index) => {
    removeTag(tag).then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setTags(tags.filter((t, i) => i !== index))
      }
    });
  }
  const saveTagFn = async () => {
    let tag = {
      name: tagName,
      entityType: CONSTANTS.ENTITY_TYPE.INVENTORY,
      entityId: inventory.id,
    }
    saveTag(tag).then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setTagName('');
        setTags([...tags, result]);
      }
    });
  }

  const handleEditInventory = () => {
    let _inventory = {...inventory};
    setModalInventory(_inventory);
    setManageInventory(true);
  }

  const {register, handleSubmit, setValue} = useForm({});

  const closeInventoryFn = () => {
    setInventory(null);
    setManageInventory(false);
  }

  const handleSaveInventory = async (inventory) => {
    const inv = {...inventory};
    let result = await saveInventory(inv);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      closeInventoryFn();
      setReloadInventory(true);
    }
  }

  const [manageInventory, setManageInventory] = useState(false);

  const handleCloseInventory = () => {
    setManageInventory(false);
  }

  useEffect(() => {
    if (!guid) {
      return;
    }
    getInventoryFn(guid).then(result => {
      setInventory(null);
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setInventory(result);
        setTags(result.tags);
        setInventoryReady(true);
      }
    });
  }, [guid]);

  useEffect(() => {
    if (!reloadInventory) {
      return;
    }
    setReloadInventory(false);
    getInventoryFn(guid).then(result => {
      setInventory(null);
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setInventory(result);
        setTags(result.tags);
        setInventoryReady(true);
      }
    });
  }, [reloadInventory]);

  return (
    <DashboardLayout>

      <ContextSearchNavbar title={'View Inventory'}/>
      {!inventoryReady && <PageLoading/>}
      {inventoryReady && <MDBox py={3}>
        <MDBox mb={3}>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <div className={'pull-right'} ref={el => (myRefs.current['inventoryActions'] = el)}
                       onClick={(e) => {
                         refHandler('inventoryActions')
                       }}>

                    <MDButton
                      variant="outlined"
                      color="secondary"
                      size="small"
                      circular
                      iconOnly
                    >
                      <Icon>more_horiz</Icon>
                    </MDButton>
                    {renderIndex && myRefs.current['inventoryActions']?.visible &&
                      <Menu open={true} anchorReference={null} anchorEl={myRefs.current['inventoryActions']}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}>
                        <NotificationItem
                          disabled={inventory?.status?.id === CONSTANTS.STATUS.INACTIVE}
                          onClick={(e) => {
                            handleEditInventory()
                          }} icon={<Icon>edit</Icon>} title="Edit"/>

                      </Menu>
                    }
                  </div>
                  {inventory?.name &&
                    <>
                      <MDTypography fontWeight={'regular'} variant={'h5'}>{inventory?.name}</MDTypography>
                    </>
                  }

                  <Row>
                    <Col sm={12} md={6}>
                      {inventory?.inventoryType?.name && <>
                        <MDTypography fontWeight={'bold'} variant={'h6'}>Inventory Type</MDTypography>
                        <MDTypography fontWeight={'regular'}
                                      variant={'h6'}>{inventory?.inventoryType?.name}</MDTypography>
                      </>
                      }
                    </Col>
                    <Col sm={12} md={6}>
                      {inventory?.brand?.name && <>
                        <MDTypography fontWeight={'bold'} variant={'h6'}>Brand</MDTypography>
                        <MDTypography fontWeight={'regular'}
                                      variant={'h6'}>{inventory?.brand?.name}</MDTypography>
                      </>
                      }
                    </Col>
                  </Row>
                  <Divider/>

                  <MDTypography fontWeight={'bold'} variant={'h6'}>Description</MDTypography>
                  <p>
                    {inventory?.desc}
                  </p>

                  <Row>
                    <Col sm={4}>
                      <MDTypography fontWeight={'bold'} variant={'h6'}>Price</MDTypography>
                      <p>
                        {formatCurrency(inventory?.price)}
                      </p>

                      <MDTypography fontWeight={'bold'} variant={'h6'}>Available</MDTypography>
                      <p>
                        {inventory?.unlimited ? 'Unlimited' : inventory?.quantity}
                      </p>

                    </Col>
                    <Col sm={4}>

                      <MDTypography fontWeight={'bold'} variant={'h6'}>Wholesale Price</MDTypography>
                      <p>
                        {formatCurrency(inventory?.wholesalePrice)}
                      </p>

                    </Col>

                  </Row>
                  <Divider/>

                  <Row>
                    <Col sm={12}>
                      <MDTypography fontWeight={'bold'} variant={'h6'}>Tags
                        <Chip
                          label={!showTags ? 'Add Tag' : 'Close'}
                          icon={!showTags ? <Icon>add_circle</Icon> : <Icon>cancel</Icon>}
                          color="primary"
                          className="pull-right"
                          size="small"
                          onClick={() => {
                            toggleTagsFn()
                          }}
                        /></MDTypography>
                      {tags?.length > 0 && tags.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1).map((tag, index) => {
                        return (
                          <span key={index}>
                           <Chip
                             label={tag.name}
                             onDelete={() => {
                               removeTagFn(tag, index);
                             }}
                             color="primary"
                             variant="outlined"
                           />&nbsp;
                         </span>
                        )
                      })
                      }

                      {showTags && <form onSubmit={handleSubmit(saveTagFn)}>
                        <FormControl variant="outlined" p={0}
                                     className="form-inline d-block mt-4">
                          <InputLabel htmlFor="tag-input">Tag</InputLabel>
                          <OutlinedInput
                            autoFocus
                            id="tag-input"
                            type={'text'}
                            value={tagName}
                            onChange={handleTagChange}

                            endAdornment={
                              <InputAdornment position="end">
                                <MDButton
                                  color="primary"
                                  size="small"
                                  disabled={!tagName}
                                  onClick={handleSubmit(saveTagFn)}
                                  className={'btn btn-primary'}
                                >Save</MDButton>
                              </InputAdornment>
                            }
                            label="Tag"
                          />
                        </FormControl>
                      </form>
                      }
                    </Col>
                  </Row>

                  <Divider/>

                  <Row>
                    <Col sm={12}>
                      <MDTypography fontWeight={'bold'} variant={'h6'}>Public Image</MDTypography>
                      {inventory?.imageUrl && <img
                        className={'mt-2 img-thumbnail'} src={inventory?.imageUrl} alt="img"/>}
                    </Col>
                  </Row>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>

            </Grid>
          </Grid>


        </MDBox>
      </MDBox>
      }

      {manageInventory && <ManageInventoryModal
        inventory={inventory}
        show={modalInventory}
        saving={saving}
        handleSave={handleSaveInventory}
        handleClose={handleCloseInventory}/>}

      <Footer mt={5}/>
    </DashboardLayout>
  );
}