import {Col, Container, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {handleDefaultValues} from "../common/Utils";
import {useForm} from 'react-hook-form';
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";
import {saveLocationSortOrders} from "../common/api/InventoryApi";
import Icon from "@mui/material/Icon";

export default function SortLocations(props) {
  const [context, setContext] = useContext(AppContext);
  const [locations, setLocations] = useState({});
  const [formInvalid, setFormInvalid] = useState(false);
  const [renderIndex, setRenderIndex] = useState(1);

  useEffect(() => {
    let _types = {
      items: props?.locations
    }
    setLocations(_types);
  }, [props?.locations]);


  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(locations)
  });

  const handleClose = () => {
    props.handleClose();
  };

  const handleSave = async (data) => {
    let result = await saveLocationSortOrders(data);
    if (result.error) {
      setContext({...context, error: {message: result.message}});
    } else {
      props.handleSave(result);
    }
  };

  const moveUp = (item) => {
    let _obj = {...locations};
    for (let i = 0; i < _obj.items.length; i++) {
      _obj.items[i].sortOrder = i;
    }
    for (let i = 0; i < _obj.items.length; i++) {
      if (_obj.items[i].id === item.id) {
        _obj.items[i].sortOrder = _obj.items[i].sortOrder - 1;
      } else {
        _obj.items[i].sortOrder = _obj.items[i].sortOrder + 1;
      }
    }
    setLocations({..._obj});
    setRenderIndex(renderIndex + 1);
  }

  const moveDown = (item) => {
    let _obj = {...locations};
    for (let i = 0; i < _obj.items.length; i++) {
      _obj.items[i].sortOrder = i;
    }
    for (let i = 0; i < _obj.items.length; i++) {
      if (_obj.items[i].id === item.id) {
        _obj.items[i].sortOrder = _obj.items[i].sortOrder + 1;
      } else {
        _obj.items[i].sortOrder = _obj.items[i].sortOrder - 1;
      }
    }
    setLocations({..._obj});
    setRenderIndex(renderIndex + 1);
  }

  return (
    <Modal onHide={handleClose} size={'lg'} show={props.show}>
      <form onSubmit={handleSubmit(handleSave)} id={'sortForm'}>
        <Modal.Header closeButton>
          <Modal.Title>Sort Age Range</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {locations?.items?.length > 0 &&
            <Row>
              <Col className={'col col-main'} xs={12}>
                <Container>
                  {renderIndex && locations?.items?.sort((a, b) => a.sortOrder - b.sortOrder).map((item, index) => {
                    return (
                      <ul className={'mt-2 list-group'} key={index + renderIndex}>
                        <li
                          className={'list-group-item'}>
                          {index !== 0 && <MDButton color="primary"
                                                    className="pull-right mx-1"
                                                    size="small"
                                                    onClick={() => {
                                                      moveUp(item);
                                                    }}>
                           <span
                             style={{
                               fontSize: '16px'
                             }}
                             className={'fa fa-arrow-circle-up'}></span>
                          </MDButton>
                          }
                          <MDTypography variant="h6" fontWeight="regular" pt={2}>
                            {item?.parentLocation ? <span className="mr-1"><Icon>star</Icon></span> : ''}
                            {item.name}
                          </MDTypography>
                          <br/> {item.desc}
                          {item?.address && <>
                            <span className={'d-block'}><Icon>room</Icon><small>{item.address}</small></span>
                          </>}
                          {index !== locations.items.length - 1 && <MDButton color="primary"
                                                                             className="pull-right mx-1"
                                                                             size="small"
                                                                             onClick={() => {
                                                                               moveDown(item);
                                                                             }}>
                            <span
                              style={{
                                fontSize: '16px'
                              }}
                              className={'fa fa-arrow-circle-down'}></span>
                          </MDButton>
                          }
                        </li>
                      </ul>
                    )
                  })}

                </Container>
              </Col>
            </Row>
          }
        </Modal.Body>
        <Modal.Footer>
          <MDButton type="submit" color="info" disabled={formInvalid || props.saving}>
            {props.saving ? 'Saving...' : 'Save'}
          </MDButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
}