import {AppContext} from "../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {formatCurrency, handleDefaultValues} from "../common/Utils";
import {useForm} from 'react-hook-form';
import {getInventoryEvent, getInventoryMemberships} from "../common/api/InventoryApi";
import {Divider, Grid} from "@mui/material";
import {clientAddToCart} from "../common/api/CartApi";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import Avatar from "@mui/material/Avatar";

export default function ClientInventoryPanel(props) {
  const [context, setContext] = useContext(AppContext);

  const [eventReady, setEventReady] = useState(false);
  const [event, setEvent] = useState();
  const [memberships, setMemberships] = useState([]);

  useEffect(() => {
    getInventoryMembershipsFn()
  }, []);

  const [inventory, setInventory] = useState();
  const [showMembershipModal, setShowMembershipModal] = useState(false);
  const [savingMembership, setSavingMembership] = useState(false);

  const getInventoryEventFn = async (eventId) => {
    getInventoryEvent(eventId).then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setEvent(result);
        setEventReady(true);
      }
    });
  }

  const [inventoryType, setInventoryType] = useState();
  const [formInvalid, setFormInvalid] = useState(false);

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(inventoryType)
  });

  const handleClose = () => {
    setShowMembershipModal(false);
  };

  const handleSave = (data) => {
    props.handleSave(data);
  };

  const getInventoryMembershipsFn = async () => {
    let result = await getInventoryMemberships();
    setMemberships(result);
  }

  const loadCartFn = () => {

    let _context = {...context};
    _context.reloadCart = true;
    _context.toast = {
      message: 'Added to cart!',
      type: 'success'
    }
    setContext({..._context});
  };

  const addToCartFn = async (inventory) => {
    clientAddToCart(inventory, props?.client?.guid).then(resp => {
      props.onSave();
    });
  };

  return (
    <>
      <MDBox p={3}>
        <MDBox>
          {memberships?.length > 0 && <>
            {memberships?.sort((a, b) => new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime())
              // .filter(item => showEvent(item))
              .map((inventory, index) => {
                return (
                  <div key={index}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={9}>
                        <h6>{inventory.name}</h6>
                        <p><small>
                          {inventory.accountType?.name}
                        </small><br/>
                          <small>
                            {formatCurrency(inventory?.price)}
                          </small>
                        </p>
                        <p>
                          <small>
                            {inventory.desc}
                          </small>
                        </p>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <MDBox display="flex" justifyContent="flex-end">
                          <Avatar alt={inventory?.name}
                                  className={'pull-left m-3'}
                                  sx={{width: 96, height: 96}}>
                            {inventory?.imageUrl && <img src={inventory?.imageThumbUrl || inventory?.imageUrl}
                                 style={{width: '200%', height: 'auto'}}
                                 alt={inventory?.name}/> }
                          </Avatar>
                        </MDBox>
                        {
                          <MDBox display="flex" justifyContent="flex-end">
                            <MDButton variant="gradient"
                                      size={'small'}
                                      color="light"
                                      onClick={(e) => {
                                        addToCartFn(inventory);
                                      }}>Add To Cart</MDButton>
                          </MDBox>
                        }
                      </Grid>
                    </Grid>
                    <Divider/>
                  </div>
                )
              })
            }
          </>}
        </MDBox>
      </MDBox>
    </>
  )
}