export const _PaginationOptions = {
  noRowsPerPage: true,
  rowsPerPageText: '',
  rowsPerPage: 10,
  rangeSeparatorText: 'of',
  selectAllRowsItem: false,
  selectAllRowsItemText: false,
  numberFiltered : 0
}

export const PAGE_NUM = 10;