import {Col, Row} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {AppContext} from "../../../lib/AppContext";
import React, {forwardRef, useContext, useEffect, useState} from "react";
import {Divider, Grid, IconButton} from "@mui/material";
import {setAppGuidCookie} from "../../common/appCookie";
import {displayDay, formatCurrency, formatDate, inventoryAvailable} from "../../common/Utils";
import moment from "moment";
import RegisterEventModal from "../modal/RegisterEventModal";
import Loading from "../../common/Loading";
import MDTypography from "../../../components/MDTypography";
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import Icon from "@mui/material/Icon";
import Switch from "@mui/material/Switch";
import {
  addtoWaitlist,
  getAgeRangeTypes,
  getInventoryEvents,
  getInventoryEventTypes
} from "../../common/api/PortalCustomerApi";
import {Controller, useForm} from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import {addDays} from "@fullcalendar/core/internal";
import Avatar from "@mui/material/Avatar";

export default function CustomerCalendarPane(props) {

  const navigate = useNavigate();
  const [context, setContext] = useContext(AppContext);
  const [calendarLoading, setCalendarLoading] = useState(true);

  const [calendarDate, setCalendarDate] = useState(new Date());

  const [showLogin, setShowLogin] = useState(false);
  const [username, setUsername] = useState('');
  const [passwd, setPasswd] = useState('');
  const [loggingIn, setLoggingIn] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const [inventoryRender, setInventoryRender] = useState(1);
  const [events, setEvents] = useState([]);
  const [inventoryEventTypes, setInventoryEventTypes] = useState([]);
  const [ageRangeTypes, setAgeRangeTypes] = useState([]);

  const [inventoryEvent, setInventoryEvent] = useState();
  const [showRegister, setShowRegister] = useState(false);
  const [savingRegisterEvent, setSavingRegisterEvent] = useState(false);
  const WEEK_VIEW = 'weekView';

  useEffect(() => {
    setCalendarLoading(false);
  }, [events])
  const loadInventoryEventTypes = () => {
    getInventoryEventTypes().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setInventoryEventTypes(result);
      }
    });
  }

  const getAgeRangeTypesFn = () => {
    getAgeRangeTypes().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setAgeRangeTypes(result);
      }
    });
  }

  useEffect(() => {
    loadInventoryEventTypes();
    getAgeRangeTypesFn();
  }, []);

  let {guid} = useParams();

  useEffect(() => {
    if (guid) {
      setAppGuidCookie(guid);
    }
  }, [guid]);

  const currentDay = (day) => {
    let today = new Date(calendarDate);
    today.setHours(0, 0, 0, 0);
    return day.getTime() === today.getTime();
  }

  const [daysOfWeek, setDaysOfWeek] = useState([]);
  const [calendarStartDay, setCalendarStartDay] = useState(new Date());

  const [calendarView, setCalendarView] = useState(WEEK_VIEW);
  const [calendarViewLabel, setCalendarViewLabel] = useState('Week');

  const showEvent = (_event) => {
    let show = (eventTypeFilter.length === 0 || eventTypeFilter.indexOf(_event.eventType.id) > -1);
    show = show && (ageRangeTypeFilter.length === 0 || ageRangeTypeFilter.indexOf(_event.ageRange.id) > -1);
    return show;
  };

  const getInventoryEventsDayFn = async (day) => {

    let startDate = day;
    let endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + 1);
    let req = {
      start: startDate,
      end: endDate
    };

    setCalendarLoading(true);

    let renderNum = inventoryRender + 1;
    let result = await getInventoryEvents(req, inventoryRender);

    if ((result?.inventoryRender + 1) == renderNum) {
      setEvents(result.events);
      setCalendarLoading(false);
    }
  }

  const setWeek = (day) => {
    let currentDate = moment(day);
    let weekStart = currentDate.clone().startOf('week');
    let weekEnd = currentDate.clone().endOf('week');

    const dayOfWeek = weekStart.toDate();

    const _daysOfWeek = [];
    for (let i = 0; i < 7; i++) {

      let day = moment(dayOfWeek).add(i, 'days').toDate();
      day.setHours(0, 0, 0, 0);
      _daysOfWeek.push({
        day: day,
        idx: day.getDay(),
        events: []
      });
    }
    setDaysOfWeek(_daysOfWeek);
  }

  const validEvent = (event) => {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let validAllDayEvent = event.allDay && new Date(event.eventDate).getTime() >= today.getTime();
    return validAllDayEvent || new Date(event.eventDate).getTime() >= new Date().getTime();
  }

  useEffect(() => {
    let day = new Date();
    day.setHours(0, 0, 0, 0);
    setWeek(day);
    setInventoryRender(inventoryRender + 1);
    getInventoryEventsDayFn(day);
  }, []);

  const [eventTypeFilter, setEventTypeFilter] = useState([]);
  const [ageRangeTypeFilter, setAgeRangeTypeFilter] = useState([]);

  const preRegisterEvent = (event) => {
    let _e = {...event};
    _e.actionDate = new Date();
    setInventoryEvent(_e);
    setShowRegister(true);
  }

  const registerMembershipEvent = (event, memberAccount) => {
    let _e = {...event};
    _e.memberAccount = {...memberAccount};
    _e.actionDate = new Date();
    setInventoryEvent(_e);
    setShowRegister(true);
  }

  const handleCloseRegisterEventModal = () => {
    setShowRegister(false);
  }

  const handleSaveRegisterEvent = (data) => {
    setSavingRegisterEvent(true);
  }

  const toggleEventTypeFilter = (e) => {
    let _eventTypeFilter = [...eventTypeFilter];
    if (e.target.checked) {
      _eventTypeFilter = [...eventTypeFilter, parseInt(e.target.value, 10)];
    } else {
      _eventTypeFilter.splice(eventTypeFilter.indexOf(parseInt(e.target.value, 10)), 1);
    }
    setEventTypeFilter(_eventTypeFilter);
  }

  const toggleAgeRangeTypeFilter = (e) => {
    let _ageTypeFilter = [...ageRangeTypeFilter];
    if (e.target.checked) {
      _ageTypeFilter = [...ageRangeTypeFilter, parseInt(e.target.value, 10)];
    } else {
      _ageTypeFilter.splice(ageRangeTypeFilter.indexOf(parseInt(e.target.value, 10)), 1);
    }
    setAgeRangeTypeFilter(_ageTypeFilter);
  }

  const toggleDay = (_day) => {
    let day = new Date(_day.day);
    day.setHours(0, 0, 0, 0);

    setCalendarStartDay(day);
    setWeek(day);
    setCalendarDate(day);
    setInventoryRender(inventoryRender + 1);
    getInventoryEventsDayFn(day);
  }

  const previousWeek = () => {
    let day = calendarStartDay;
    day.setHours(0, 0, 0, 0);
    day.setDate(day.getDate() - 7);

    setCalendarStartDay(day);
    setWeek(day);
    setCalendarDate(day);
    setInventoryRender(inventoryRender + 1);
    getInventoryEventsDayFn(day);
  }

  const nextWeek = () => {
    let day = calendarStartDay;
    let dateCheck = moment(day);
    let weekStart = dateCheck.clone().startOf('week');
    let weekEnd = dateCheck.clone().endOf('week');
    let _weekEnd = new Date(weekEnd);
    _weekEnd.setHours(0, 0, 0, 0);

    if (dateCheck.isSame(moment(_weekEnd))) {
      day.setDate(day.getDate() + 1);
    } else {
      day.setDate(day.getDate() + 7);
    }

    day.setHours(0, 0, 0, 0);

    setCalendarStartDay(day);
    setWeek(day);
    setCalendarDate(day);
    setInventoryRender(inventoryRender + 1);
    getInventoryEventsDayFn(day);
  }

  const jumpToDate = (date) => {
    let day = new Date(date);
    day.setHours(0, 0, 0, 0);

    let currentDate = moment(day);
    let weekStart = currentDate.clone().startOf('week');
    let weekEnd = currentDate.clone().endOf('week');

    const dayOfWeek = weekStart.toDate();

    const _daysOfWeek = [];
    for (let i = 0; i < 7; i++) {

      let day = moment(dayOfWeek).add(i, 'days').toDate();
      day.setHours(0, 0, 0, 0);
      _daysOfWeek.push({
        day: day,
        idx: day.getDay(),
        events: []
      });
    }
    setDaysOfWeek(_daysOfWeek);

  }

  const monthLabel = (day) => {
    if (day.getDate() === 1) {
      return displayDay(day, 'MMM');
    }
    return '';
  }

  const addToWaitlistFn = async (e) => {
    if (!context?.sessionUser?.guid) {
      let _context = {...context};
      _context.showLogin = true;
      setContext({..._context});
      return;
    }
    addtoWaitlist(e).then(resp => {
      let _context = {...context};
      _context.reloadCart = true;
      _context.toast = {
        message: 'Added to Event Wait List!',
      }
      setContext({..._context});
    });
  };

  const [eventDate, setEventDate] = useState(new Date());
  const [eventEndDate, setEventEndDate] = useState(new Date());

  const updateEventDates = (date) => {
    setCalendarDate(date);
    let day = new Date(date);
    day.setHours(0, 0, 0, 0);

    let dayfound = false;
    for (let j in daysOfWeek) {
      if (daysOfWeek[j].day.getTime() === day.getTime()) {
        toggleDay(daysOfWeek[j]);
        dayfound = true;
      }
    }
    if (!dayfound) {
      jumpToDate(date);
    }
    setInventoryRender(inventoryRender + 1);
    getInventoryEventsDayFn(day);
  };

  const {register, handleSubmit, setValue, control} = useForm({});

  const FormattedDateInput = forwardRef(({value, onClick}, ref) => (
    <div className={'d-block cursor-pointer'} onClick={onClick} ref={ref}>
      <Icon sx={{
        marginTop: '2px',
      }}>event</Icon>{formatDate(calendarDate)}
    </div>
  ));

  return (
    <div>

      {context && context.sessionUser && <div>

        <MDBox py={3}>

          <MDTypography variant="h2" fontWeight="light">
            <center>{context?.sessionUser?.companyName}&nbsp;Calendar</center>
            <Divider/>
          </MDTypography>

          <Row>
            <Col xs={12} sm={2} className={'mt-4'}>

              <MDBox width="100%" lineHeight={1}>
                <MDTypography variant="h5" color="secondary" textAlign="left" fontWeight="light">

                  <Controller
                    className={'d-block'}
                    control={control}
                    name="eventDate"
                    id="eventDate"
                    render={({field: {onChange, onBlur, value, ref}}) => (
                      <ReactDatePicker
                        todayButton="Today"
                        peekNextMonth
                        minDate={new Date()}
                        maxDate={addDays(new Date(), 60)}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        calendarClassName="psb-form-control"
                        // showTimeSelect
                        dateFormat="MMMM d, yyyy h:mm aa"
                        className={'form-control'}
                        onChange={(date) => updateEventDates(date)}
                        onBlur={onBlur}
                        selected={calendarDate}
                        customInput={<FormattedDateInput/>}
                      />
                    )}
                  />

                </MDTypography>
              </MDBox>

              <Divider/>
              <div className={'d-none d-sm-block d-md-block d-lg-block'}>

                <h6>Event Types</h6>
                {inventoryEventTypes.sort((a, b) => a.sortOrder - b.sortOrder).map((type, index) => {
                  return (
                    <div key={index}>
                      <label className={'cursor-pointer'} htmlFor={'eventTypeFilter-' + type.id}>
                        <Switch name={'eventTypeFilter'}
                                id={'eventTypeFilter-' + type.id}
                                style={{marginRight: '5px'}}
                                value={type.id}
                                checked={eventTypeFilter.indexOf(type.id) > -1}
                                onChange={(e) => {
                                  toggleEventTypeFilter(e);
                                }}
                        />
                        {type.name}
                      </label>
                    </div>
                  )
                })}

                <hr/>

                <h6>Ages</h6>
                {ageRangeTypes
                  .sort((a, b) => a.id > b.id ? 1 : -1)
                  .map((type, index) => {
                    return (
                      <div key={index}>
                        <label className={'cursor-pointer'} htmlFor={'ageTypeFilter-' + type.id}>
                          <Switch name={'ageTypeFilter'}
                                  id={'ageTypeFilter-' + type.id}
                                  value={type.id}
                                  style={{marginRight: '5px'}}
                                  checked={ageRangeTypeFilter.indexOf(type.id) > -1}
                                  onChange={(e) => {
                                    toggleAgeRangeTypeFilter(e);
                                  }}
                          />
                          {type.name}
                        </label>
                      </div>
                    )
                  })}
              </div>

            </Col>

            <Col xs={12} sm={10}>
              <div className={'d-none d-sm-block d-md-block d-lg-block'}>
                <Row>
                  <Col
                    className={'pt-3'}
                    style={{
                      width: '1%',
                    }}>
                    <IconButton onClick={(e) => {
                      previousWeek();
                    }}>
                      <Icon>arrow_back_ios</Icon>
                    </IconButton>
                  </Col>
                  {daysOfWeek.map((day, index) => {
                    return (
                      <Col
                        key={index}
                        className={'p-1'}
                        style={{
                          width: '12.85%',
                        }}>
                        {
                          <MDBox display="block" pt={2} px={2}>
                            <Grid item className={'cursor-pointer'} onClick={(e) => {
                              toggleDay(day)
                            }}>
                              <MDTypography fontWeight={currentDay(day.day) ? 'bold' : 'light'}
                                            textTransform="uppercase"
                                            variant={'h5'} color={'secondary'}>
                                <span className={'d-block text-center'}>{displayDay(day.day, 'ddd')}</span>
                              </MDTypography>
                              <MDTypography fontWeight={currentDay(day.day) ? 'bold' : 'light'} variant={'h6'}
                                            color={'secondary'}>
                       <span className={'d-block text-center'}>
                        {monthLabel(day.day)} {displayDay(day.day, 'DD')}
                       </span>
                              </MDTypography>
                            </Grid>
                          </MDBox>
                        }
                      </Col>
                    );
                  })
                  }
                  <Col className={'pt-3'}
                       style={{
                         width: '5%',
                         textAlign: 'right'
                       }}>
                    <IconButton onClick={(e) => {
                      nextWeek();
                    }}>
                      <Icon>arrow_forward_ios</Icon>
                    </IconButton>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col xs={12}>
                  <Row>
                    {!calendarLoading && events && events?.length === 0 && <Col sm={12}>
                      <MDBox justifyContent="top">
                        <MDTypography variant="h5" fontWeight="light" color="secondary">
                          <center>No Events found for {displayDay(calendarDate, 'MMMM DD, YYYY')}</center>
                          <Divider/>
                        </MDTypography>
                      </MDBox>
                    </Col>}

                    {calendarLoading && <Col sm={12}><Loading/></Col>}

                    {!calendarLoading && events && events?.sort((a, b) => new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime())
                      .filter(item => showEvent(item))
                      .map((event, index) => {
                        return (
                          <div key={index}
                               className={(validEvent(event) ? ' hover-bg-200' : ' event-disabled bg-100')}>
                            <Row>
                              <Col xs={12} sm={6}>

                                <Avatar alt={event?.name}
                                        className={'pull-left m-3'}
                                        sx={{width: 126, height: 126}}>
                                  {event?.imageUrl && <img src={event?.imageThumbUrl || event?.imageUrl}
                                                           style={{width: '200%', height: 'auto'}}
                                                           alt={event?.name}/>}
                                </Avatar>

                                <MDTypography variant="h5" fontWeight="bold" color="secondary">
                                  {event.name}
                                </MDTypography>
                                {event?.sessionEvent && <MDTypography variant="h6" fontWeight="light" color="secondary">
                                  Session
                                </MDTypography>
                                }

                                <blockquote>{event.desc}</blockquote>
                              </Col>
                              <Col xs={12} sm={6}>
                                <Divider orientation="vertical" sx={{
                                  float: 'left'
                                }}/>
                                <p>
                                  <small>Type: <b>{event.eventType?.name}</b></small><br/>
                                  <small>
                                    Price: <b>{formatCurrency(event.price)}</b>
                                    <br/>
                                  </small>
                                  <small>
                                    Age(s): <b>{event.ageRange?.name}</b>
                                    <br/>
                                  </small>
                                  <small>
                                    Available: <b>{inventoryAvailable(event)}</b>
                                    <br/>
                                  </small>
                                  {event?.sessionEvent && <small>
                                    Session Event: <b>Yes</b>
                                    <br/>
                                  </small>}
                                  <small>
                                    {displayDay(event.eventDate, 'MM/DD/YYYY hh:mm a')} - {displayDay(event.eventEndDate, 'hh:mm a')}
                                  </small>
                                </p>
                                {validEvent(event) && <p>

                                  {inventoryAvailable(event) <= 0 &&
                                    <>
                                      <MDButton variant="gradient"
                                                size={'small'}
                                                color="info"
                                                onClick={(e) => {
                                                  addToWaitlistFn(event);
                                                }}>Join Wait List</MDButton>
                                    </>
                                  }

                                  {inventoryAvailable(event) > 0 && <>

                                    {event.memberAccountTypes?.length > 0 && <>
                                      {event.memberAccountTypes.map((_row, index) => {
                                        return (<MDButton onClick={() => registerMembershipEvent(event, _row)}
                                                          variant="gradient"
                                                          sx={{'marginRight': '5px', 'marginBottom': '5px'}}
                                                          size={'small'}
                                                          key={index}
                                                          color="info">
                                          Register ({_row.name})
                                        </MDButton>)
                                      })
                                      }
                                    </>}

                                    {!event.memberAccountTypes?.length > 0 && <>
                                      <MDButton variant="gradient"
                                                size={'small'}
                                                color="info"
                                                onClick={(e) => {
                                                  preRegisterEvent(event);
                                                }}>Register</MDButton>
                                    </>}
                                  </>
                                  }
                                </p>
                                }
                              </Col>
                              <Divider/>
                            </Row>
                          </div>
                        )
                      })
                    }
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>


          {
            inventoryEvent && <RegisterEventModal
              handleClose={handleCloseRegisterEventModal}
              handleSave={handleSaveRegisterEvent}
              show={showRegister}
              event={inventoryEvent}
            />
          }
        </MDBox>
      </div>
      }
    </div>
  )
}