import {getAppSessionCookieHeader} from "../appCookie";
import api from "../../Api";

export const getInventoryTypes = async () => {
  return await api.get('inventory/types' + getAppSessionCookieHeader()).then((response) => {
    return response.data?.sort((a, b) => a.sortOrder - b.sortOrder);
  });
}

export const getBrands = async () => {
  return await api.get('inventory/brands' + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}

export const getAccountTypes = async () => {
  return await api.get('inventory/account/types' + getAppSessionCookieHeader()).then((response) => {
    return response.data?.sort((a, b) => a.sortOrder - b.sortOrder);
  });
}

export const getEventLocations = async () => {
  return await api.get('inventory/location/types' + getAppSessionCookieHeader()).then((response) => {
    return response.data?.sort((a, b) => a.sortOrder - b.sortOrder);
  });
}

export const getInventoryEventTypes = async () => {
  return await api.get('inventory/event/types' + getAppSessionCookieHeader()).then((response) => {
    return response.data?.sort((a, b) => a.sortOrder - b.sortOrder);
  });
}

export const getTaskLists = async () => {
  return await api.get('company/tasklists' + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}

export const saveTaskList = async (taskList) => {
  return await api.post('company/tasklist/save' + getAppSessionCookieHeader(), taskList).then((response) => {
    return response.data;
  });
}

export const getAgeRangeTypes = async () => {
  return await api.get('inventory/age/types' + getAppSessionCookieHeader()).then((response) => {
    return response.data?.sort((a, b) => a.sortOrder - b.sortOrder);
  });
}
export const deleteEventCategory = async (guid) => {
  return await api.post('inventory/event/type/delete/' + guid + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}

export const deleteInventoryType = async (guid) => {
  return await api.post('inventory/type/delete/' + guid + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}

export const deleteCompanyTaskTemoplate = async (guid) => {
  return await api.post('company/task/template/delete/' + guid + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}

export const deleteAccountType = async (guid) => {
  return await api.post('inventory/account/type/delete/' + guid + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}

export const deleteLocation = async (guid) => {
  return await api.post('inventory/location/delete/' + guid + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}

export const deleteAgeType = async (guid) => {
  return await api.post('inventory/age/delete/' + guid + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}

export const deleteInventory = async (guid) => {
  return await api.post('inventory/delete/' + guid + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}

export const getInventory = async () => {
  return await api.get('inventory' + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}

export const getInventoryByCategory = async (categoryId) => {
  return await api.get('inventory/category/' + categoryId + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}

export const getInventoryMemberships = async () => {
  return await api.get('inventory/memberships' + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}

export const getInventoryEvent = async (guid) => {
  return await api.get('inventory/event/' + guid + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}

export const removeTag = async (tag) => {
  return await api.post('inventory/tag/remove' + getAppSessionCookieHeader(), tag).then((response) => {
    return response.data;
  });
}

export const saveTag = async (tag) => {
  return await api.post('inventory/tag' + getAppSessionCookieHeader(), tag).then((response) => {
    return response.data;
  });
}

export const getInventoryItem = async (guid) => {
  return await api.get('inventory/item/' + guid + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}

export const getInventoryEventFull = async (guid) => {
  return await api.get('inventory/event/full/' + guid + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}

export const getInventoryEvents = async (req, inventoryRender) => {
  const resp = await api.post('inventory/events' + getAppSessionCookieHeader(), req).then((response) => {
    let _resp = {inventoryRender: inventoryRender, events: response.data}
    return _resp;
  });
  return resp;
}

export const getClientInventoryEvents = async (req, client, inventoryRender) => {
  const resp = await api.post('inventory/events/' + client.guid + getAppSessionCookieHeader(), req).then((response) => {
    let _resp = {inventoryRender: inventoryRender, events: response.data}
    return _resp;
  });
  return resp;
}

export const saveCustomerInventoryEventRegistration = async (reservation, guid) => {
  return await api.post('inventory/waitlist/register' + getAppSessionCookieHeader(), reservation).then((response) => {
    return response.data;
  });
}

export const saveInventoryCategorySortOrders = async (sortRequest) => {
  const resp = await api.post('inventory/event/type/sort' + getAppSessionCookieHeader(), sortRequest)
    .then((response) => {
      return response.data;
    }).catch(function (error) {
      return handleError(error);
    });
  return resp;
}

export const saveInventoryTypeSortOrders = async (sortRequest) => {
  const resp = await api.post('inventory/type/sort' + getAppSessionCookieHeader(), sortRequest)
    .then((response) => {
      return response.data;
    }).catch(function (error) {
      return handleError(error);
    });
  return resp;
}

export const saveAgeTypeSortOrders = async (sortRequest) => {
  const resp = await api.post('inventory/age/type/sort' + getAppSessionCookieHeader(), sortRequest)
    .then((response) => {
      return response.data;
    }).catch(function (error) {
      return handleError(error);
    });
  return resp;
}

export const saveLocationSortOrders = async (sortRequest) => {
  const resp = await api.post('inventory/location/sort' + getAppSessionCookieHeader(), sortRequest)
    .then((response) => {
      return response.data;
    }).catch(function (error) {
      return handleError(error);
    });
  return resp;
}

export const saveAccountTypeSortOrders = async (sortRequest) => {
  const resp = await api.post('inventory/account/type/sort' + getAppSessionCookieHeader(), sortRequest)
    .then((response) => {
      return response.data;
    }).catch(function (error) {
      return handleError(error);
    });
  return resp;
}


export const saveInventoryType = async (inventoryType) => {
  const resp = await api.post('inventory/type' + getAppSessionCookieHeader(), inventoryType)
    .then((response) => {
      return response.data;
    }).catch(function (error) {
      return handleError(error);
    });
  return resp;
}

export const saveAgeRangeType = async (ageType) => {
  const resp = await api.post('inventory/age/type' + getAppSessionCookieHeader(), ageType)
    .then((response) => {
      return response.data;
    }).catch(function (error) {
      return handleError(error);
    });
  return resp;
}

export const saveBrand = async (brand) => {
  const resp = await api.post('inventory/brand' + getAppSessionCookieHeader(), brand)
    .then((response) => {
      return response.data;
    }).catch(function (error) {
      return handleError(error);
    });
  return resp;
}

export const saveInventoryEventType = async (inventoryType) => {
  const resp = await api.post('inventory/event/type' + getAppSessionCookieHeader(), inventoryType)
    .then((response) => {
      return response.data;
    }).catch(function (error) {
      return handleError(error);
    });
  return resp;
}

export const saveAccountType = async (accountType) => {
  const resp = await api.post('inventory/accountType' + getAppSessionCookieHeader(), accountType)
    .then((response) => {
      return response.data;
    }).catch(function (error) {
      return handleError(error);
    });
  return resp;
}

export const saveEventLocation = async (location) => {
  const resp = await api.post('inventory/location' + getAppSessionCookieHeader(), location)
    .then((response) => {
      return response.data;
    }).catch(function (error) {
      return handleError(error);
    });
  return resp;
}


export const saveInventory = async (inventory) => {
  const resp = await api.post('inventory' + getAppSessionCookieHeader(), inventory)
    .then((response) => {
      return response.data;
    }).catch(function (error) {
      return handleError(error);
    });
  return resp;
}

export const saveInventoryEvent = async (inventory) => {
  const resp = await api.post('inventory/event' + getAppSessionCookieHeader(), inventory)
    .then((response) => {
      return response.data;
    }).catch(function (error) {
      return handleError(error);
    });
  return resp;
}

export const cancelInventoryEvent = async (inventory) => {
  const resp = await api.post('inventory/event/cancel/' + inventory.guid + getAppSessionCookieHeader())
    .then((response) => {
      return response.data;
    }).catch(function (error) {
      return handleError(error);
    });
  return resp;
}


export const saveInventoryMembership = async (inventory) => {
  const resp = await api.post('inventory/membership' + getAppSessionCookieHeader(), inventory)
    .then((response) => {
      return response.data;
    }).catch(function (error) {
      return handleError(error);
    });
  return resp;
}

function handleError(error) {
  let err = {
    error: true,
    message: error.response.data.message
  }
  return err;
};
