import {AppContext} from "../../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {formatCurrency} from "../../common/Utils";
import {addToCart, getInventory, getInventoryEvent} from "../../common/api/PortalCustomerApi";
import {Card, CardContent, Grid} from "@mui/material";
import MDBox from "../../../components/MDBox";
import MDButton from "../../../components/MDButton";
import ViewInventoryModal from "../modal/ViewInventoryModal";
import Switch from "@mui/material/Switch";

export default function CustomerStorePane(props) {
  const [context, setContext] = useContext(AppContext);

  const [eventReady, setEventReady] = useState(false);
  const [event, setEvent] = useState();
  const [inventoryList, setInventoryList] = useState([]);
  const [renderIndex, setRenderIndex] = useState(1);

  useEffect(() => {
    getInventoryFn()
  }, []);

  const [inventory, setInventory] = useState();
  const [showInventoryModal, setShowInventoryModal] = useState(false);
  const [savingMembership, setSavingMembership] = useState(false);
  const [brands, setBrands] = useState([]);
  const [brandsReady, setBrandsReady] = useState(false);

  const [tags, setTags] = useState([]);
  const [tagsReady, setTagsReady] = useState(false);

  const getInventoryEventFn = async (eventId) => {
    getInventoryEvent(eventId).then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setEvent(result);
        setEventReady(true);
      }
    });
  }

  const [inventoryType, setInventoryType] = useState();

  const handleClose = () => {
    setShowInventoryModal(false);
  };

  const handleSave = (data) => {
    props.handleSave(data);
  };

  const getInventoryFn = async () => {
    let result = await getInventory();
    setInventoryList(result);

    let _brands = [];
    for (let r in result) {
      let _brand = result[r].brand;
      if (_brand.id > -1 && _brands.filter(b => b.id === _brand.id).length === 0) {
        _brands.push(_brand);
      }
    }
    setBrands(_brands);
    setBrandsReady(true);

    let _tags = [];
    for (let r in result) {
      for (let tt in result[r].tags) {
        let t = result[r].tags[tt];
        if (t.id > -1 && _tags.filter(b => b.id === t.id).length === 0) {
          _tags.push(t);
        }
      }
    }
    setTags(_tags);
    setTagsReady(true);
  }

  const viewInventory = (e) => {
    setInventory(e);
    setShowInventoryModal(true);
    // navigate('/cx/register/' + e.guid);
  };

  const loadCartFn = () => {

    let _context = {...context};
    _context.reloadCart = true;
    _context.toast = {
      message: 'Added to cart!',
      type: 'success'
    }
    setContext({..._context});
  };

  const addToCartFn = async (e) => {
    const resp = await addToCart(event);
    loadCartFn();
  };

  const [brandFilter, setBrandFilter] = useState([]);
  const [tagFilter, setTagFilter] = useState([]);

  const toggleBrandFilter = (e) => {
    let _brandFilter = [...brandFilter];
    if (e.target.checked) {
      _brandFilter = [...brandFilter, parseInt(e.target.value, 10)];
    } else {
      _brandFilter.splice(brandFilter.indexOf(parseInt(e.target.value, 10)), 1);
    }
    setBrandFilter(_brandFilter);
    setRenderIndex(renderIndex + 1);
  }

  const toggleTagFilter = (e) => {
    let _tagFilter = [...tagFilter];
    if (e.target.checked) {
      _tagFilter = [...tagFilter, parseInt(e.target.value, 10)];
    } else {
      _tagFilter.splice(tagFilter.indexOf(parseInt(e.target.value, 10)), 1);
    }
    setTagFilter(_tagFilter);
    setRenderIndex(renderIndex + 1);
  }

  const showItem = (_item) => {
    let show = (brandFilter.length === 0 || brandFilter.indexOf(_item.brand?.id) > -1);
    if (show && tagFilter.length > 0) {
      let _tags = _item.tags.map(t => t.id);
      show = tagFilter.filter(t => _tags.indexOf(t) > -1).length > 0;
    }
    return show;
  };

  return (
    <>
      <MDBox>
        <Grid container cols={2} spacing={2} direction="row">
          <Grid item sm={3}>
            <Card>
              <CardContent>
                <h6>Brands</h6>
                {brandsReady && brands.map((brand, index) => {
                  return (
                    <div key={index}>
                      <label className={'cursor-pointer'}
                             htmlFor={'brandFilter-' + brand.id}>
                        <Switch
                               name={'brandFilter'}
                               id={'brandFilter-' + brand.id}
                               style={{marginRight: '5px'}}
                               value={brand.id}
                               checked={brandFilter.indexOf(brand.id) > -1}
                               onChange={(e) => {
                                 toggleBrandFilter(e);
                               }}
                        />
                        {brand.name}
                      </label>
                    </div>
                  )
                })}
              </CardContent>
            </Card>

            <Card className={'mt-2'}>
              <CardContent>
                <h6>Tags</h6>
                {tagsReady && tags.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1).map((tag, index) => {
                  return (
                    <div key={index}>
                      <label className={'cursor-pointer'}
                             htmlFor={'tagFilter-' + tag.id}>
                        <Switch
                          name={'tagFilter'}
                          id={'tagFilter-' + tag.id}
                          style={{marginRight: '5px'}}
                          value={tag.id}
                          checked={tagFilter.indexOf(tag.id) > -1}
                          onChange={(e) => {
                            toggleTagFilter(e);
                          }}
                        />
                        {tag.name}
                      </label>
                    </div>
                  )
                })}
              </CardContent>
            </Card>

          </Grid>

          <Grid item sm={9}>
            <Grid container spacing={1}>

              {inventoryList?.length > 0 && <>
                {inventoryList?.sort((a, b) => a.name.localeCompare(b.name))
                  .map((inventory, index) => {
                    return (
                      <Grid item md={4} sm={6} xs={12} key={index} className={showItem(inventory) ? 'd-inline-grid' : 'fade-out d-none'}>
                        <Card>
                          <CardContent>
                            <h5>{inventory.name}</h5>
                            {inventory.brand?.id > -1 && <h6>{inventory.brand?.name}</h6>}
                            <p>
                              <img
                                src={inventory.imageUrl}
                                className={'m-2 '}
                                style={{width:'90%'}}/>
                            </p>
                            <p>
                              {inventory.desc}
                            </p>
                            <p>
                              {formatCurrency(inventory?.price)}
                            </p>
                            <div>
                              <MDBox display="flex" justifyContent="flex-end">
                                <MDButton variant="gradient"
                                          size={'small'}
                                          color="light"
                                          onClick={(e) => {
                                            viewInventory(inventory);
                                          }}>View Details</MDButton>
                              </MDBox>
                            </div>
                          </CardContent>
                        </Card>
                      </Grid>
                    )
                  })
                }
              </>}
            </Grid>
          </Grid>
        </Grid>
      </MDBox>


      {
        inventory && <ViewInventoryModal
          handleClose={handleClose}
          handleSave={handleSave}
          show={showInventoryModal}
          inventory={inventory}
        />
      }

    </>
  )
}