import React from "react";
import {createRoot} from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import App from "./App";
import './app/App.scss';
// import './assets/scss/psb-date-picker.scss';
// Material Dashboard 2 PRO React Context Provider
import {MaterialUIControllerProvider} from "./context";
import {Provider} from "./lib/AppContext";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <Provider>
        <App/>
      </Provider>
    </MaterialUIControllerProvider>
  </BrowserRouter>
);
