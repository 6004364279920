import {Modal} from "react-bootstrap";
import {AppContext} from "../../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {formatCurrency, formatDateTime} from "../../common/Utils";
import {useNavigate} from "react-router-dom";
import {Chip} from "@mui/material";
import MDBox from "../../../components/MDBox";
import {getCustomerSales} from "../../common/api/CustomerProfileApi";
import MDTypography from "../../../components/MDTypography";

export default function ClientPurchaseHistoryModal(props) {
  const navigate = useNavigate();
  const [context, setContext] = useContext(AppContext);
  const [salesResp, setSalesResp] = useState();
  const [salesReady, setSalesReady] = useState(false);

  useEffect(() => {
    getSalesFn();
  }, [context?.sessionUser?.guid]);

  const getSalesFn = async () => {
    let result = await getCustomerSales(context?.sessionUser?.guid);
    setSalesResp(result);
    setSalesReady(true);
  }

  const handleClose = () => {
    props.handleClose();
  };

  const handleSave = (data) => {
    props.handleSave(data);
  };


  return (
    <>
      <Modal onHide={handleClose} size={'lg'} show={props.show}>
        <Modal.Header closeButton>
          <Modal.Title>View Purchase History</Modal.Title>
        </Modal.Header>
        <Modal.Body className={'modal-overflow'}>
          <>
            <MDBox p={3}>
              <MDBox>
                <ul className={'list-group'}>
                  {salesReady && salesResp?.salesItems.sort((a, b) => b.id - a.id).filter(item => (item.price - item.discount) > 0).map((item, index) => {
                      return (
                        <li
                          className={'list-group-item'}
                          key={index}>
                                    <span
                                      className={'pull-right'}>
                                      <Chip
                                        label={formatCurrency(item.price - item.discount)}
                                        variant="outlined"
                                        color="secondary"/>
                                    </span>
                          {item?.itemName}
                          <br/>
                          <small>{formatDateTime(item.purchaseDate)}</small>

                        </li>
                      )
                    }
                  )}
                </ul>
              </MDBox>
            </MDBox>
          </>
        </Modal.Body>
        <Modal.Footer>
          <MDTypography variant={'h6'}>Total Purchases: {salesReady && formatCurrency(salesResp?.totalSales)}</MDTypography>
        </Modal.Footer>
      </Modal>
    </>
  );
}