import {Col, Container, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {handleDefaultValues} from "../common/Utils";
import {useForm} from 'react-hook-form';
import {getBrands, getInventoryEventTypes, getInventoryTypes} from "../common/api/InventoryApi";
import MDButton from "../../components/MDButton";
import {Chip, FormControlLabel, InputAdornment, NativeSelect} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import MDInput from "../../components/MDInput";

export default function ManageInventoryModal(props) {

  const [context, setContext] = useContext(AppContext);
  const [inventory, setInventory] = useState(props.inventory);
  const [isInventoryEvent, setIsInventoryEvent] = useState(props.isInventoryEvent);
  const [unlimited, setUnlimited] = useState(props?.inventory?.unlimited);

  const [inventoryLabel, setInventoryLabel] = useState(props.isInventoryEvent ? 'Event' : 'Inventory');
  const [inventoryTypes, setInventoryTypes] = useState();
  const [brands, setBrands] = useState();
  const [inventoryEventTypes, setInventoryEventTypes] = useState();

  const [inventoryImage, setInventoryImage] = useState(null);
  const [inventoryImageName, setInventoryImageName] = useState(null);
  const [inventoryImageType, setInventoryImageType] = useState(null);

  const [preview, setPreview] = useState(null);
  const [formInvalid, setFormInvalid] = useState(false);

  const toggleUnlimited = (e) => {
    setUnlimited(e.target.checked);
  };

  useEffect(() => {
    getInventoryTypes().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        let _none = {id: null, name: 'None'};
        let _types = [_none, ...result];
        setInventoryTypes(result);
      }
    });
    getInventoryEventTypes().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setInventoryEventTypes(result);
      }
    });
    getBrands().then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        let _none = {id: -1, name: 'None'};
        let _brands = [_none, ...result];
        setBrands(_brands);
      }
    });

  }, [props.show]);

  useEffect(() => {
    setInventory(props?.inventory);
  }, [props?.inventory]);

  const [isNew, setIsNew] = useState(!!!props.inventory?.guid);

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(inventory)
  });

  const handleClose = () => {
    props.handleClose();
  };

  const handleSave = (data) => {
    let d = {...data};
    d.inventoryImage = inventoryImage;
    d.inventoryImageName = inventoryImageName;
    d.inventoryImageType = inventoryImageType;
    props.handleSave(d);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) {
      setPreview(null);

      setInventoryImageName(null);
      setInventoryImageType(null);
      setInventoryImage(null);
      return;
    }
    setInventoryImageName(file.name);
    setInventoryImageType(file.type);

    const reader = new FileReader();

    reader.onload = function (evt) {
      setInventoryImage(btoa(evt.target.result));
    }
    reader.readAsBinaryString(file);

    setPreview(URL.createObjectURL(file));

    let _inv = {...inventory};
    _inv.removeImage = false;
    setInventory(_inv);
  }

  return (
    <>
      <Modal onHide={handleClose} size={'lg'} show={props.show}>
        <form onSubmit={handleSubmit(handleSave)} id={'inventoryForm'}>
          <Modal.Header closeButton>
            <Modal.Title>{isNew ? 'Create New' : 'Manage'} {inventoryLabel}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col className={'col col-main'} xs={12}>
                <Container>
                  <Row>
                    <Col className={'col'} sm={12} xs={12}>
                      <div className="form-group">
                        <MDInput type="text" required
                                 {...register('name')}
                                 autoComplete="off"
                                 className="form-control" id="name"
                                 label="Enter Inventory Name"/>
                      </div>
                    </Col>
                  </Row>

                  <Row className={'mt-4'}>
                    <Col className={'col'} sm={6} xs={12}>
                      <div className="form-group">
                        <div className={'input-group'}>
                          <MDInput type="number" step="1" required
                                   {...register('quantity', {
                                     valueAsNumber: true,
                                   })}
                                   disabled={unlimited}
                                   defaultValue={'0'}
                                   autoComplete="off"
                                   className="form-control" id="quantity"
                                   label="Quantity"/>
                        </div>
                      </div>
                    </Col>
                    <Col className={'col'} sm={6} xs={12}>
                      <div className="form-group">
                        <div className={'input-group'}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                defaultChecked={props?.inventory?.unlimited}
                                {...register('unlimited')}
                                onChange={(e) => {
                                  toggleUnlimited(e);
                                }} name="unlimited"/>
                            }
                            label="Unlimited Available"
                          />
                        </div>
                      </div>


                    </Col>
                  </Row>

                  <Row className={'mt-4'}>
                    <Col className={'col'} sm={6} xs={12}>
                      <div className="form-group">
                        <div className={'input-group'}>
                          <MDInput type="text" step="0.01" required
                                   {...register('price', {
                                     valueAsDouble: true
                                   })}
                                   onChange={(e) => {
                                     e.target.value = e.target.value.replace(/[^0-9.]/g, '');
                                   }}
                                   InputProps={{
                                     startAdornment: <InputAdornment position="start">
                                       <span className={'fa fa-dollar'}></span>
                                     </InputAdornment>,
                                   }}
                                   defaultValue={'0.00'}
                                   autoComplete="off"
                                   className="form-control" id="price"
                                   label="Price"/>
                        </div>
                      </div>
                    </Col>
                    <Col className={'col'} sm={6} xs={12}>
                      <div className="form-group">
                        <div className={'input-group'}>
                          <MDInput type="text" step="0.01"
                                   {...register('wholesalePrice', {
                                     // valueAsNumber: true,
                                     valueAsDouble: true
                                   })}
                                   onChange={(e) => {
                                     e.target.value = e.target.value.replace(/[^0-9.]/g, '');
                                   }}
                                   InputProps={{
                                     startAdornment: <InputAdornment position="start">
                                       <span className={'fa fa-dollar'}></span>
                                     </InputAdornment>,
                                   }}
                                   min={0}
                                   defaultValue={'0.00'}
                                   autoComplete="off"
                                   className="form-control" id="wholesalePrice"
                                   label="Wholesale Price"/>
                        </div>
                      </div>


                    </Col>
                  </Row>

                  <Row className={'mt-2'}>
                    <Col className={'col'} sm={6} xs={12}>
                      <div className="form-group">

                        <label htmlFor="inventoryType">Inventory Type</label>
                        {inventoryTypes?.length && <NativeSelect
                          variant="outlined"
                          required
                          id={'inventoryType'} name={'inventoryType'} className={'form-control'}
                          {...register('inventoryType.id', {
                            valueAsNumber: true
                          })} >
                          {
                            inventoryTypes?.map((c) =>
                              <option value={c.id} key={c.id}>
                                {c.name}
                              </option>)
                          }
                        </NativeSelect>}
                      </div>

                      <div className="form-group mt-2">
                        <label htmlFor="brand">Brand</label>
                        {brands?.length && <NativeSelect
                          variant="outlined"
                          required
                          id={'brand'} name={'brand'} className={'form-control'}
                          {...register('brand.id', {
                            valueAsNumber: true
                          })} >
                          {
                            brands?.map((c) =>
                              <option value={c.id} key={c.id}>
                                {c.name}
                              </option>)
                          }
                        </NativeSelect>}
                      </div>
                    </Col>


                    <Col className={'col'} sm={6} xs={12}>
                      <div className="form-group">
                        <label htmlFor="inventoryImage">{inventoryLabel} Image</label>
                        <input type="file"
                               accept="image/*"
                               {...register('inventoryImage', {
                                 onChange: (e) => {
                                   handleFileChange(e);
                                 }
                               })}
                               autoComplete="off"
                               className="form-control" id="inventoryImage"/>

                        {preview && <>
                          <img className={'mt-2 img-thumbnail'} src={preview} alt="img"/>
                          <Chip label="Remove"
                                className={'cls clear-both'}
                                variant="outlined" onDelete={()=>{
                            let _inv = {...inventory}
                            _inv.imageUrl = null;
                            _inv.removeImage = true;
                            setInventory(_inv);
                            setPreview(null);
                          }} />
                        </>}
                        {!preview && inventory?.imageUrl &&
                          <><img className={'mt-2 img-thumbnail'} src={inventory.imageUrl} alt="img"/>
                            <div>
                              <Chip label="Remove"
                                    className={'cls clear-both'}
                                    variant="outlined" onDelete={()=>{
                                let _inv = {...inventory}
                                _inv.imageUrl = null;
                                _inv.removeImage = true;
                                setInventory(_inv);
                                setPreview(null);
                              }} />
                            </div>
                          </>}
                      </div>
                    </Col>

                  </Row>

                  <Row className={'mt-2'}>
                    <Col className={'col'} sm={12} xs={12}>
                      <div className="form-group">
                        <label htmlFor="name">{inventoryLabel} Description</label>
                        <textarea type="text"
                                  {...register('desc')}
                                  autoComplete="off"
                                  maxLength={255}
                                  className="form-control" id="desc"
                                  placeholder="Enter Inventory Description"/>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>

          </Modal.Body>
          <Modal.Footer>
            <MDButton type="submit" color="info" disabled={formInvalid || props.saving}>
              {props.saving ? 'Saving...' : 'Save'}
            </MDButton>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}