import api from "../../Api";
import {getAppSessionCookieHeader, handleSessionId} from "../appCookie";
import {getSessionUser} from "./WebApi";

export const getInventoryMemberships = async () => {
  return await api.get('cx/inventory/memberships' + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}

export const getClientInventoryEvents = async (req, client, inventoryRender) => {
  const resp = await api.post('cx/inventory/events/' + client.guid + getAppSessionCookieHeader(), req).then((response) => {
    let _resp = {inventoryRender: inventoryRender, events: response.data}
    return _resp;
  });
  return resp;
}

export const getInventoryEvents = async (req, inventoryRender) => {
  const resp = await api.post('cx/inventory/events' + getAppSessionCookieHeader(), req).then((response) => {
    let _resp = {inventoryRender: inventoryRender, events: response.data}
    return _resp;
  });
  return resp;
}

export const getInventoryEvent = async (guid) => {
  return await api.get('cx/event/' + guid + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}

export const saveInventoryEventCancellation = async (reservation) => {
  return await api.post('cx/inventory/event/cancel' + getAppSessionCookieHeader(), reservation).then((response) => {
    return response.data;
  });
}

export const getInventoryEventRegistration = async (eventGuid, customerGuid) => {
  return await api.get('cx/inventory/event/registration/' + eventGuid + '/' + customerGuid + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}

export const createProfile = async (customer) => {
  const resp = await api.post('cx/profile/save' + getAppSessionCookieHeader(), customer).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const saveCustomerFamily = async (customer) => {
  const resp = await api.post('cx/profile/family/save' + getAppSessionCookieHeader(), customer).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getCustomerSales = async (guid) => {
  const resp = await api.get('cx/sales/' + guid + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}
export const saveInventoryEventRegistration = async (reservation, guid) => {
  return await api.post('cx/inventory/waitlist/register/' + guid + getAppSessionCookieHeader(), reservation).then((response) => {
    return response.data;
  });
}


export const getCustomerIntentSuccess = async (paymentIntent) => {
  const resp = await api.get('cx/sales/intent/' + paymentIntent + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}
export const getInventoryEventWaitlist = async (waitlistGuid, customerGuid) => {
  return await api.get('cx/inventory/event/waitlist/' + waitlistGuid + '/' + customerGuid + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}

export const getInventory = async () => {
  return await api.get('cx/inventory' + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}

export const checkout = async (cart) => {
  return await api.post('cx/cart/checkout' + getAppSessionCookieHeader(), cart).then((response) => {
    return response.data;
  });
}

export const getCart = async () => {
  return await api.get('cx/cart' + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}

export const getCartFull = async () => {
  return await api.get('cx/cart/full' + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}

export const getAgeRangeTypes = async () => {
  return await api.get('cx/age/types' + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}

export const getInventoryEventTypes = async () => {
  return await api.get('cx/event/types' + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  });
}

export const addtoWaitlist = async (inventory) => {
  return await api.post('cx/waitlist' + getAppSessionCookieHeader(), inventory).then((response) => {
    return response.data;
  });
}
export const submitPublicWaiver = async (waiver) => {
  const resp = await api.post('cx/company/waiver/public/submit' + getAppSessionCookieHeader(), waiver).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getPublicWaiver = async (guid) => {
  const resp = await api.get('cx/company/waiver/public/' + guid + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const addToCart = async (inventory) => {
  return await api.post('cx/cart/add' + getAppSessionCookieHeader(), inventory).then((response) => {
    return response.data;
  });
}

export const checkPassResetExpiration = async (tokenGuid) => {
  const resp = await api.get('cx/pass/reset/expiration/' + tokenGuid + getAppSessionCookieHeader()).then((response) => {
    handleSessionId(response);
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const login = async (auth) => {
  const resp = await api.post('cx/login' + getAppSessionCookieHeader(), auth).then((response) => {
    handleSessionId(response);
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const resetPassConfirm = async (auth) => {
  const resp = await api.post('cx/pass/reset/confirm' + getAppSessionCookieHeader(), auth).then((response) => {
    handleSessionId(response);
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}


export const resetPass = async (auth) => {
  const resp = await api.post('cx/pass/reset' + getAppSessionCookieHeader(), auth).then((response) => {
    handleSessionId(response);
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const customerSaveClientAccount = async (account) => {
  const resp = await api.post('cx/account' + getAppSessionCookieHeader(), account).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getStates = async () => {
  const resp = await api.get('cx/states' + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const logout = async () => {
  const resp = await api.post('cx/logout' + getAppSessionCookieHeader()).then((response) => {
    return getSessionUser();
  });
  return resp;
}

export const saveSpecialRequest = async (request) => {
  const resp = await api.post('cx/reservations/specialRequest' + getAppSessionCookieHeader(), request).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const saveMyProfile = async (customer) => {
  const resp = await api.post('cx/profile/save' + getAppSessionCookieHeader(), customer).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}
export const saveMyCustomerFamily = async (customer) => {
  const resp = await api.post('cx/my/family/save' + getAppSessionCookieHeader(), customer).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getMyReservations = async () => {
  const resp = await api.get('cx/reservations/my' + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}

export const getMyProfile = async () => {
  const resp = await api.get('cx/my/profile' + getAppSessionCookieHeader()).then((response) => {
    return response.data;
  }).catch(function (error) {
    return handleError(error);
  });

  return resp;
}
export const deleteMyCustomerFamily = async (familyMember) => {
  return await api.post('cx/my/family/delete/' + familyMember.guid);
}

export const removeCartItem = async (cartItem) => {
  return await api.post('cx/cart/remove' + getAppSessionCookieHeader(), cartItem).then((response) => {
    return response.data;
  });
}

function handleError(error) {
  let err = {
    error: true,
    message: error?.response?.data?.message || JSON.stringify(error)
  }
  return err;
}
