import {Col, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import {useContext, useState} from "react";
import ClientInventoryPanel from "./ClientInventoryPanel";
import {Collapse, Divider, IconButton} from "@mui/material";
import {ExpandCircleDown, ExpandCircleDownOutlined} from "@mui/icons-material";
import MDTypography from "../../components/MDTypography";
import EventsMiniDataTable from "./pane/EventsMiniDataTable";

export default function ClientLookupEventsModal(props) {

  const [context, setContext] = useContext(AppContext);
  const [showMemberships, setShowMemberships] = useState(false);

  const handleClose = () => {
    props.handleClose();
  };

  const toggleMemberships = () => {
    setShowMemberships(!showMemberships);
  };

  const registerFn = (event) => {
    props.handleSave(event);
  }

  const handleMembershipSave = (event) => {
    toggleMemberships();
  }
  const rotate = {
    'transform': 'rotate(270deg)'
  };

  return (
    <Modal onHide={handleClose} size={'lg'} show={true}>
      <Modal.Header closeButton>
        <Modal.Title>Events
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className={'px-4'}>
            <MDTypography variant={'h6'}>Memberships
              <IconButton
                onClick={(e) => {
                  toggleMemberships();
                }}
                size={'small'} className={'btn btn-sm'} color={'primary'}>
                {showMemberships && <ExpandCircleDown sx={rotate}/>}
                {!showMemberships && <ExpandCircleDownOutlined/>}
              </IconButton>
              <Divider/>
            </MDTypography>
          </Col>
        </Row>
        <Row>
          <Collapse collapsedSize={0}
                    in={showMemberships}>
            <ClientInventoryPanel
              client={props.client}
              onSave={handleMembershipSave}
            />
          </Collapse>
        </Row>

        <EventsMiniDataTable
          hideTitle={true}
          client={props.client}
          register={registerFn}
        />
      </Modal.Body>
    </Modal>
  );
}