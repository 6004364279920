import {Col, Container, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../lib/AppContext";
import {useContext, useEffect, useState} from "react";
import {handleDefaultValues} from "../common/Utils";
import {useForm} from 'react-hook-form';
import MDButton from "../../components/MDButton";
import MDInput from "../../components/MDInput";
import MDTypography from "../../components/MDTypography";

export default function ManageBrandModal(props) {

  const [context, setContext] = useContext(AppContext);
  const [brand, setBrand] = useState(props.brand || {});
  const [formInvalid, setFormInvalid] = useState(false);

  useEffect(() => {
    setBrand(props?.brand);
  }, [props?.brand]);

  const handleFormChange = (e) => {
    setValue(e.target.name, e.target.value);
    let _obj = {...brand};
    _obj[e.target.name] = e.target.value;
    setBrand({..._obj});
  };

  const [isNew, setIsNew] = useState(!!!props.brand?.guid);

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(brand)
  });

  const handleClose = () => {
    props.handleClose();
  };

  const handleSave = (data) => {
    props.handleSave(data);
  };


  return (
    <Modal onHide={handleClose} size={'lg'} show={props.show}>
      <form onSubmit={handleSubmit(handleSave)} id={'brandForm'}>
        <Modal.Header closeButton>
          <Modal.Title>{isNew ? 'Create New' : 'Manage'} Brand</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className={'col col-main'} xs={12}>
              <Container>
                <Row>
                  <Col className={'col'} sm={12} xs={12}>
                    <div className="form-group">
                      <MDInput
                        type="text"
                        {...register('name')}
                        autoComplete="off" required
                        className="form-control" id="name"
                        label="Enter Brand Name"/>

                    </div>
                  </Col>
                </Row>

                <Row className={'mt-2'}>
                  <Col className={'col'} sm={12} xs={12}>
                    <MDTypography fontWeight={'regular'} variant={'h6'}>
                      Contact Info
                    </MDTypography>
                  </Col>
                </Row>

                <Row className={'mt-2'}>
                  <Col className={'col'} sm={6} xs={12}>
                    <div className="form-group">

                      <MDInput
                        type="text"
                        {...register('contactName')}
                        autoComplete="off"
                        className="form-control" id="contactName"
                        label="Enter Contact Name"/>

                    </div>
                  </Col>

                  <Col className={'col'} sm={6} xs={12}>
                    <div className="form-group">

                      <MDInput
                        type="text"
                        {...register('contactPhone')}
                        autoComplete="off"
                        className="form-control" id="contactPhone"
                        label="Enter Contact Phone"/>

                    </div>
                  </Col>
                </Row>

                <Row className={'mt-2'}>
                  <Col className={'col'} sm={6} xs={12}>
                    <div className="form-group">

                      <MDInput
                        type="text"
                        {...register('contactEmail')}
                        autoComplete="off"
                        className="form-control" id="contactEmail"
                        label="Enter Contact Email"/>

                    </div>
                  </Col>

                  <Col className={'col'} sm={6} xs={12}>
                    <div className="form-group">

                      <MDInput
                        type="text"
                        {...register('contactWebsite')}
                        autoComplete="off"
                        className="form-control" id="contactWebsite"
                        label="Enter Contact Website"/>

                    </div>
                  </Col>
                </Row>

                <Row className={'mt-2'}>
                  <Col className={'col'} sm={12} xs={12}>
                    <div className="form-group">
                      <textarea
                        rows={5}
                        {...register('notes')}
                        className="form-control" id="notes"
                        placeholder="Enter Notes"/>

                    </div>
                  </Col>
                </Row>


              </Container>
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <MDButton type="submit" color="info" disabled={formInvalid || props.saving}>
            {props.saving ? 'Saving...' : 'Save'}
          </MDButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
}