import {useNavigate, useParams} from "react-router-dom";
import {AppContext} from "../../lib/AppContext";
import {useContext, useEffect, useState} from "react";
import {setAppGuidCookie} from "../common/appCookie";
import {Card, CardContent} from "@mui/material";
import MDBox from "../../components/MDBox";
import CustomerHeader from "../common/CustomerHeader";
import Footer from "../Footer";
import CustomerPageLayout from "../Layout/CustomerPageLayout";
import Grid from "@mui/material/Grid";
import MDTypography from "../../components/MDTypography";
import {getInventoryEventWaitlist, saveInventoryEventRegistration} from "../common/api/PortalCustomerApi";
import Confirm from "../common/Confirm";
import CompanyCard from "./pane/CompanyCard";

export default function CustomerConfirmReservation(props) {
  const [context, setContext] = useContext(AppContext);
  const [company, setCompany] = useState();
  const [confirmation, setConfirmation] = useState(null);

  const [confirmRegister, setConfirmRegister] = useState(false);
  const [saving, setSaving] = useState(false);

  const navigate = useNavigate();
  let {guid, reservationGuid, customerGuid} = useParams();

  const handleEventRegistration = (reservation) => {
    saveInventoryEventRegistration(reservation, reservationGuid).then(result => {
      setConfirmRegister(false);
      setConfirmation(null);
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        let c = {...context};
        c.reloadCart = true;
        setContext(c);
      }
    });
  }

  const handleCloseConfirm = () => {
    setConfirmation(null);
    setConfirmRegister(false);
  };

  const handleEventConfirm = (reservation) => {
    let confirmation = {
      title: 'Event Confirmation',
      message: 'Confirm registration for ' + reservation.eventName + '.',
      entity: reservation
    }
    setConfirmation(confirmation);
    setConfirmRegister(true);
  }

  useEffect(() => {
    if (guid) {
      setAppGuidCookie(guid);
    }
    if (reservationGuid && customerGuid) {
      getInventoryEventWaitlist(reservationGuid, customerGuid).then(result => {
        if (result.error) {
          setContext({...context, error: {message: result.message}});
        } else {
          handleEventConfirm(result);
        }
      });
    }
  }, [guid]);

  useEffect(() => {
    if (context.sessionUser?.companyGk) {
      setPageReady(true);
      setCompany(context.sessionUser.company);
    }
  }, [context?.sessionUser]);

  const [pageReady, setPageReady] = useState(false);


  return (
    <>
      <CustomerPageLayout>

        <CustomerHeader props={props}/>

        {pageReady && <MDBox p={3}>
          <MDBox m={3}>
            <Grid container cols={3} spacing={3} direction="row">
              <Grid item sm={3} xs={12}>
                <CompanyCard/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card>
                  <CardContent>
                    <MDTypography variant="h5" color="secondary" fontWeight="light">
                      Confirm Event Registration
                    </MDTypography>
                    <MDTypography variant="p" color="primary" fontWeight="regular">
                      Confirm Event Registration.
                    </MDTypography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
        }
        <Footer/>
      </CustomerPageLayout>

      {confirmRegister && <Confirm confirmation={confirmation}
                                   show={confirmRegister}
                                   saving={saving}
                                   handleConfirm={handleEventRegistration}
                                   handleClose={handleCloseConfirm}/>}

    </>
  )
}