import {AppContext} from "../../lib/AppContext";
import {useContext, useEffect, useState} from "react";
import {Card, CardContent} from "@mui/material";
import ManageInventoryEventForm from "./ManageInventoryEventForm";
import Meta from "../common/Meta";
import ContextSearchNavbar from "../navbars/ContextSearchNavbar";
import DashboardLayout from "../LayoutContainers/DashboardLayout";
import MDBox from "../../components/MDBox";
import Footer from "../Footer";
import {getInventoryEvent} from "../common/api/InventoryApi";
import {useParams, useSearchParams} from "react-router-dom";
import Loading from "../common/Loading";

export default function ManageInventoryEvent(props) {

  const [context, setContext] = useContext(AppContext);
  let {guid} = useParams();

  const [event, setEvent] = useState();
  const [refreshEvent, setRefreshEvent] = useState(false);
  const [isNew, setIsNew] = useState(!!!props.inventory?.guid);
  const [inventory, setInventory] = useState();
  const [inventoryName, setInventoryName] = useState('');
  const [inventoryReady, setInventoryReady] = useState(false);

  const getEventFn = async (guid) => {
    return await getInventoryEvent(guid);
  }

  useEffect(() => {
    if (guid) {
      getEventFn(guid).then(result => {
        if (result.error) {
          setContext({...context, error: {message: result.message}});
        } else {
          let e = {...result};
          e.eventDate = new Date(e.eventDate);
          e.eventEndDate = new Date(e.eventEndDate);

          setInventory(e);
          setInventoryName(' - ' + e.name);
          setRefreshEvent(false);
          setIsNew(false);
          setInventoryReady(true);
        }
      });
    } else {
      setIsNew(true);
      setInventoryReady(true);
    }
  }, [guid, refreshEvent]);

  return (
    <DashboardLayout>

      <Meta pageTitle={'Inventory - Manage Event'}/>

      <ContextSearchNavbar title={'Manage Event' + (inventoryName)}/>

      <MDBox py={3}>
        <MDBox mb={3}>
          <Card className={'mt-4'}>
            <CardContent>
              {!inventoryReady &&
                <Loading />
              }
              {inventoryReady && <ManageInventoryEventForm props={props}
                                                           inventory={inventory}
                                                           isInventoryEvent={true}
            />}
            </CardContent>
          </Card>
        </MDBox>
      </MDBox>
      <Footer mt={5}/>
    </DashboardLayout>
  );
}