import {Col, Container, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../../lib/AppContext";
import React, {useContext, useEffect, useState} from "react";
import {
  displayDay,
  displayDuration,
  formatCurrency,
  formatDateTime,
  handleDefaultValues,
  inventoryAvailable
} from "../../common/Utils";
import {useForm} from 'react-hook-form';
import {useNavigate} from "react-router-dom";
import MDButton from "../../../components/MDButton";
import {addToCart, getInventoryEvent} from "../../common/api/PortalCustomerApi";
import Loading from "../../common/Loading";
import Avatar from "@mui/material/Avatar";
import {Card, CardContent} from "@mui/material";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import MDTypography from "../../../components/MDTypography";
import Icon from "@mui/material/Icon";

export default function RegisterEventModal(props) {
  const navigate = useNavigate();
  const [context, setContext] = useContext(AppContext);
  const [event, setEvent] = useState(props.event);

  const [addingToCart, setAddingToCart] = useState(false);
  const [eventLoading, setEventLoading] = useState(false);

  const [inventoryType, setInventoryType] = useState(props.inventoryType);
  const [formInvalid, setFormInvalid] = useState(false);

  const [memberAccount, setMemberAccount] = useState(props?.event?.memberAccount);

  useEffect(() => {
    setMemberAccount(props?.event?.memberAccount);
  }, [props?.event?.memberAccount]);

  useEffect(() => {
    setEventLoading(true);
    getInventoryEvent(props?.event?.guid).then(result => {
      if (result.error) {
        setContext({...context, error: {message: result.message}});
      } else {
        setEvent(result);
        setEventLoading(false);
      }
    });
  }, [props?.event]);

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(inventoryType)
  });

  const addToCartFn = async (event) => {
    setAddingToCart(true);
    let _event = {...event};
    _event.memberAccount = memberAccount;

    addToCart(_event).then((result) => {
      setAddingToCart(false);
      let _context = {...context};
      _context.reloadCart = true;
      _context.viewCart = true;
      setContext({..._context});
      handleClose();
    });
  };

  const addOnToCartFn = async (item) => {
    let _item = {...item};
    item.addingToCart = true;
    _item.quantity = 1;
    addToCart(_item).then((result) => {
      item.addingToCart = false;
      item.addedToCart = true;
      let _context = {...context};
      _context.reloadCart = true;
      // _context.viewCart = true;
      setContext({..._context});
      // handleClose();
    });
  };

  const handleClose = () => {
    props.handleClose();
  };

  const handleSave = (data) => {
    props.handleSave(data);
  };


  return (
    <Modal onHide={handleClose} size={'xl'} show={props.show}>
      {eventLoading && <Modal.Body>
        <Loading/>
      </Modal.Body>}
      {!eventLoading &&
        <form onSubmit={handleSubmit(handleSave)} id={'registerEventForm'}>
          <Modal.Header closeButton>
            <Modal.Title>{event.eventType.name} Registration</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col className={'col col-main'} xs={12}>
                <Container>
                  <Row>
                    <Col className={'col'} sm={12}>
                      <MDTypography variant="h5" fontWeight="medium">
                        {event.name}
                        {event.imageUrl && <Avatar alt={event?.name}
                                                   className={'pull-right m-3'}
                                                   sx={{width: 186, height: 186}}>
                          {event?.imageUrl && <img src={event?.imageThumbUrl || event?.imageUrl}
                                                   style={{width: '200%', height: 'auto'}}
                                                   alt={event?.name}/>}
                        </Avatar>}
                      </MDTypography>
                      {event?.sessionEvent && <>
                        <h5 htmlFor="sessionEvent"><Icon>event_repeat</Icon> Session Event</h5>
                        <p className={'fw-light'}>
                          This Session Event is part of an event Series.
                        </p>
                        <h5 htmlFor="sessionEvent">Session Events</h5>
                        {event?.sessionEvents?.length > 0 && event?.sessionEvents?.sort((a, b) => new Date(a.eventDate).getTime() - new Date(b.eventDate).getTime())
                          .map((event, key) =>  {
                            return (<li className={'list-group-item'} key={key}>
                              {event.name} - {formatDateTime(event.eventDate)}
                            </li>)
                          })
                        }
                      </>}
                    </Col>
                    <Col className={'col'} sm={12} xs={12}>
                    <h5 htmlFor="name">Details</h5>
                      <p>
                        {event.desc}
                      </p>
                    </Col>
                    <Col className={'col'} lg={3} md={6} sm={12} xs={12}>
                      <h5 htmlFor="name">Age</h5>
                      <p>
                        {event.ageRange?.name}
                      </p>
                    </Col>
                    <Col className={'col'} lg={3} md={6} sm={12} xs={12}>
                      <h5 htmlFor="name">Price</h5>
                      <p>
                        {formatCurrency(event.price)}
                      </p>
                      {event.initialDeposit &&
                        <>
                        <h5 htmlFor="name">Deposit</h5>
                        <p>
                          {formatCurrency(event.initialDeposit)}
                        </p>
                        </>
                    }

              </Col>
              <Col className={'col'} lg={3} md={6} sm={12} xs={12}>
                <h5 htmlFor="name">Length</h5>
                <p>
                  {displayDuration(event)}
                </p>
              </Col>
              <Col className={'col'} lg={3} md={6} sm={12} xs={12}>
                <h5 htmlFor="name">Available</h5>
                <p>
                  {inventoryAvailable(event)}
                </p>
              </Col>
            </Row>

            <Row>
              <Col className={'col'} sm={12}>
                <h5 htmlFor="name">Date</h5>
                <p>
                  {formatDateTime(event.eventDate)} - {displayDay(event.eventEndDate, 'hh:mm a')}
                </p>
              </Col>
            </Row>

            {event?.addOns && event?.addOns.length > 0 &&
              <>
                <Row>
                  <Col className={'col'} sm={12}>
                    <Divider/>
                    <h5 htmlFor="name">Add-Ons</h5>
                  </Col>
                </Row>
                <Grid container spacing={4} alignItems="stretch">
                  {event?.addOns.map((addOn, index) => {
                    return (
                      <Grid item sm={12} md={4} index={index} key={index}>
                        <Card p={1}>
                          <CardContent>
                            <h6><Avatar alt={addOn.name}
                                        className={'pull-right mx-2'}
                                        sx={{width: 55, height: 55}}>
                              <img src={addOn.imageUrl}
                                   style={{width: '200%', height: 'auto'}}
                                   alt={addOn.name}/>
                            </Avatar>{addOn.name}</h6>
                            <p>{addOn.desc}</p>
                            <p>Price: {formatCurrency(addOn.price)}</p>

                            <div className={'d-block'}>
                              <MDButton variant="gradient"
                                        color="secondary"
                                        size="small"
                                        className={'pull-right mt-n1'}
                                        onClick={(e) => {
                                          addOnToCartFn(addOn)
                                        }}
                                        disabled={addOn.addingToCart || addOn.addedToCart}>
                                &nbsp;{addOn.addingToCart && 'Adding to Cart...'}
                                {!addOn.addingToCart && <>
                                  {addOn.addedToCart ? (<><span
                                    className={'fa fa-check-circle-o ml-n2'}></span> Selected</>) : 'Select'}
                                </>}

                              </MDButton>
                            </div>
                          </CardContent>
                        </Card>
                      </Grid>
                    )
                  })}

                </Grid>
              </>
            }

          </Container>
        </Col>
        </Row>

      {event?.reservationStatus?.id > -1 &&
        <p className={'text-center text-info'}>
      <span className={'fa fa-thumbs-o-up'}></span>
      &nbsp;You are already registered for this event.</p>
}
</Modal.Body>
  <Modal.Footer>
    <MDButton variant="gradient"
              color="info"
              onClick={(e) => {
                addToCartFn(event)
              }}
              disabled={addingToCart || event?.reservationStatus?.id > -1}>
      {addingToCart ? 'Adding to Cart...' : 'Add to Cart'}
    </MDButton>

  </Modal.Footer>
</form>
}
</Modal>
)
  ;
}