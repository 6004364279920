import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../lib/AppContext";
import Snackbar from "@mui/material/Snackbar";
import {IconButton} from "@mui/material";
import Icon from "@mui/material/Icon";
import {CONSTANTS} from "./constants";

export default function Toast(props) {
  const [context, setContext] = useContext(AppContext);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const handleClose = () => {
    setShowToast(false);
  };

  useEffect(() => {
    if (context.toast && context.toast.message) {
      setShowToast(true);
      setToastMessage(context.toast.message);
      setTimeout(() => {
        setContext({...context, toast: {}});
        setShowToast(false);
        setToastMessage(null);
      }, CONSTANTS.TOAST_TIMEOUT);
    }
  }, [context?.toast]);

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
      <Icon fontSize="small">close</Icon>
    </IconButton>
  );


  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={showToast}
      onClose={handleClose}
      message={toastMessage}
      autoHideDuration={CONSTANTS.TOAST_TIMEOUT}
      action={action}
    />
  );

}