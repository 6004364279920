import Badge from "@mui/material/Badge";
import {colorize, customerInitials} from "../common/Utils";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../lib/AppContext";
import Avatar from "@mui/material/Avatar";

export default function UserBadge(props) {

  const [context, setContext] = useContext(AppContext);
  const [user, setUser] = useState();
  const [admin, setAdmin] = useState(props.admin);

  useEffect(() => {
    if (!context?.sessionUser) {
      return;
    }
    if (admin) {
      setUser(context.sessionUser?.adminUser || context.sessionUser);
    } else {
      setUser(context.sessionUser);
    }
  }, [context?.sessionUser]);

  return (
    <Badge>
      <Avatar alt={user?.email} style={{
        backgroundColor: colorize(user?.email),
      }}>
        {customerInitials(user)}
      </Avatar>
    </Badge>
  )
}