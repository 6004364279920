import {Row} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../lib/AppContext";
import {useNavigate, useParams} from "react-router-dom";
import {checkPassResetExpiration, logout, resetPassConfirm} from "./common/api/WebApi";
import DashboardLayout from "./LayoutContainers/DashboardLayout";
import MDBox from "../components/MDBox";
import {Card, CardContent, Grid} from "@mui/material";
import Footer from "./Footer";
import MDInput from "../components/MDInput";
import MDTypography from "../components/MDTypography";
import MDButton from "../components/MDButton";

export default function ResetPass(props) {
  const [context, setContext] = useContext(AppContext);
  let {guid} = useParams();
  const [pageReady, setPageReady] = useState(false);
  const [requestValid, setRequestValid] = useState(false);
  const [passwd, setPasswd] = useState();
  const [code, setCode] = useState();
  const [confirmPasswd, setConfirmPasswd] = useState();
  const [loginError, setLoginError] = useState();
  const [loggingIn, setLoggingIn] = useState(false);
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    checkPassResetExpiration(guid).then((result) => {
      if (result.error) {
        setRequestValid(false);
      } else {
        setRequestValid(true);
      }
      setPageReady(true);
    });
  }, [guid]);

  const navigate = useNavigate();

  const logoutFn = async () => {
    let path = '/';
    let resp = await logout();
    if (resp) {
      // window.location.href = path;
    }
  }

  const resetPasswdFn = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const auth = {
      code: code,
      passwd: passwd,
      guid: guid,
      confirmPasswd: confirmPasswd
    };

    setLoginError(null);
    setLoggingIn(true);
    let result = await resetPassConfirm(auth);

    if (result.error) {
      setLoginError(result.message);
    } else {
      setConfirmPasswd('');
      setPasswd('');
      navigate('/login');
    }
    setLoggingIn(false);
  }

  const checkFormValid = () => {
    var isValid = code && passwd && confirmPasswd && passwd.length > 6 && passwd === confirmPasswd;
    setFormValid(isValid);
  }

  useEffect(() => {
    checkFormValid();
  }, [code, passwd, confirmPasswd]);

  return (
    <>
      <DashboardLayout>
        <MDBox py={3}>
          <MDBox mb={3}>
            <Row>
              <Card>
                <CardContent>
                  {pageReady && <>
                    {requestValid &&
                      <form onSubmit={resetPasswdFn}>
                        <Grid container p={1}>
                          <Grid item xs={6} sm={6} p={2}>
                            <div className="form-group">
                              <label htmlFor="username">Code</label>
                              <MDInput type="text"
                                       required
                                       autoComplete={'off'}
                                       variant="outlined"
                                       value={code || ''}
                                       onChange={(e) => setCode(e.target.value)}
                                       className="form-control" id="code" aria-describedby="codeHelp"
                                       label="Enter Code"/>
                            </div>
                          </Grid>
                          <Grid item xs={6} sm={6} p={2}>
                            &nbsp;
                          </Grid>
                          <Grid item xs={6} sm={6} p={2}>
                            <div className="form-group">
                              <label htmlFor="username">Password</label>
                              <MDInput type="password"
                                       required
                                       autoComplete={'off'}
                                       variant="outlined"
                                       value={passwd || ''}
                                       onChange={(e) => setPasswd(e.target.value)}
                                       className="form-control" id="password" aria-describedby="passwordHelp"
                                       label="Enter Password"/>
                            </div>
                          </Grid>
                          <Grid item xs={6} sm={6} p={2}>
                            <div className="form-group">
                              <label htmlFor="confirmPasswd">Confirm Password</label>
                              <MDInput type="password"
                                       required
                                       autoComplete={'off'}
                                       variant="outlined"
                                       value={confirmPasswd || ''}
                                       onChange={(e) => setConfirmPasswd(e.target.value)}
                                       className="form-control" id="confirmPasswd"
                                       aria-describedby="confirmPasswordHelp"
                                       label="Confirm Password"/>
                            </div>
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <MDTypography variant="h5" fontWeight="medium">
                              {loginError && <p className={'text-center text-danger'}>{loginError}</p>}

                              {<MDButton type="submit" className={'pull-right'} color="info"
                                                      disabled={!formValid || loggingIn}>
                                {loggingIn ? 'Resetting Password...' : 'Reset Password'}
                              </MDButton>
                              }
                            </MDTypography>
                          </Grid>

                        </Grid>
                      </form>
                    }
                    {!requestValid && <p className={'text-danger'}>Your token is either invalid or expired.<br/>
                      <a href={'/login'}> Please request a new token</a>
                    </p>}
                  </>}
                </CardContent>
              </Card>
            </Row>
          </MDBox>
        </MDBox>

        <Footer mt={5}/>

      </DashboardLayout>
    </>
  );
}