import {useContext, useEffect, useMemo, useState} from "react";
import {AppContext} from "../../lib/AppContext";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {getInventoryEvents} from "../common/api/InventoryApi";
import moment from "moment";
import {formatDateMask, formatDateTime} from "../common/Utils";
import Grid from "@mui/material/Grid";
import {useNavigate} from "react-router-dom";

export default function CalendarPane(props) {

  const navigate = useNavigate();

  const [context, setContext] = useContext(AppContext);
  const [eventDate, setEventDate] = useState(null);
  const [eventsLoading, setEventsLoading] = useState(true);
  const [inventoryRender, setInventoryRender] = useState(1);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [specialEvents, setSpecialEvents] = useState(props?.specialEvents);

  useEffect(()=> {
    setSpecialEvents(props?.specialEvents);
  }, [props?.specialEvents]);

  const dateClick = function (info) {
    navigate(`/sales/create/event?initDate=${info.dateStr}`);
  }

  const eventClick = function (info) {
    navigate(`/event/view/${info.event?.extendedProps?.guid}`);
  }

  const toolbar = {
    left: 'prev,next',
    center: 'title',
    right: 'timeGridDay,dayGridWeek,dayGridMonth' // user can switch between the two
  };

  useEffect(() => {
    let d = new Date();
    d.setDate(1);
    setEventDate(d);
  }, []);



  const getInventoryEventsFn = async (req, client) => {
    setEventsLoading(true);
    let result = client ? await getInventoryEvents(req, inventoryRender) : await getInventoryEvents(req, inventoryRender);
    let _events = [];

    for (let i = 0; i < result?.events?.length; i++) {
      let event = result.events[i];
      let endDate = new Date(event.eventDate);
      endDate.setTime(endDate.getTime() + (event.eventDuration * 1000 * 60));

      let _e = {
        guid: event.guid,
        title: event.name + ' - (' + formatDateMask(event.eventDate, 'hh:mm a') + ')',
        start: formatDateMask(event.eventDate, 'YYYY-MM-DD'),
        end: formatDateMask(endDate, 'YYYY-MM-DD'),
      }
      _events.push(_e);
    }

    setEvents(_events);
    setEventsLoading(false);
    setLoading(false);
  }

  const handleDatesSet = (info) => {
    let startDate = new Date(info.start);
    startDate.setHours(0, 0, 0, 0);
    let endDate = new Date(info.end);

    let req = {
      start: startDate,
      end: endDate,
      specialEvents: specialEvents
    }
    getInventoryEventsFn(req, null);
  }

  return (
    <div>
      <h5>Calendar</h5>
      <Grid container spacing={3}>
        <Grid item xs={12} xl={12} sx={{height: "max-content"}}>
          {useMemo(
            () => (<FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView='dayGridMonth'
              weekends={true}
              datesSet={handleDatesSet}
              events={events}
              headerToolbar={toolbar}
              dateClick={dateClick}
              eventClick={eventClick}
             // eventContent={renderEventContent}
            />), [events]
          )}
        </Grid>
      </Grid>
    </div>
  )
}
