import {Container} from "react-bootstrap";
import {useContext, useEffect} from "react";
import {AppContext} from "../lib/AppContext";
import {Link, useNavigate} from "react-router-dom";
import {logout} from "./common/api/WebApi";
import {isSessionCustomer} from "./common/Utils";

export default function Logout(props) {
  const [context, setContext] = useContext(AppContext);
  const navigate = useNavigate();

  const logoutFn = async () => {
    let path = '/';
    if (isSessionCustomer(context?.sessionUser)) {
      path += 'cx/' + context.sessionUser.companyGuid;
    }
    let resp = await logout();
    if (resp) {
      window.location.href = path;
    }
  }

  useEffect(() => {
    logoutFn();
  }, []);

  return (

    <Container className="dashboard">
      <Link to="/Dashboard">Dashboard</Link>
    </Container>
  );
}