const dev = {
  api: {
    ENDPOINT: "http://localhost:8888/app",
  },
  ENVIRONMENT: 'Development'
};

const devio = {
  api: {
    ENDPOINT: "https://dev.playspacebrain.io/app",
  },
  ENVIRONMENT: 'QA'
};

const qa = {
  api: {
    ENDPOINT: "//qa.playspacebrain.com/app",
  },
  ENVIRONMENT: 'QA'
};

const prod = {
  api: {
    ENDPOINT: "/app",
  },
  ENVIRONMENT: 'Production'
};

const config = determineConfig();

function determineConfig() {

  console.dir(process.env.REACT_APP_ENV);

  switch (process.env.REACT_APP_ENV) {
    case "production":
      return prod;
    case "devio":
      return devio;
    case "qa":
      return qa;
    default:
      return dev;
  }
}

export default {
  // Add common config values here
  ...config
};