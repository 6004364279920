import {Col, Container, Modal, Row} from "react-bootstrap";
import {AppContext} from "../../../lib/AppContext";
import React, {forwardRef, useContext, useEffect, useState} from "react";
import {checkFormValidity, formatCreditCard, formatExpiration, handleDefaultValues} from "../../common/Utils";
import {useForm} from 'react-hook-form';
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import ReactDatePicker from "react-datepicker";
import {InputAdornment} from "@mui/material";

export default function ClientManageAccountModal(props) {

  const [context, setContext] = useContext(AppContext);
  const [account, setAccount] = useState(props?.account);
  const [billingEndDate, setBillingEndDate] = useState(null);
  const [numberVisits, setNumberVisits] = useState(props?.account?.numberVisits);

  useEffect(()=> {
    setBillingEndDate(null);
    if (props?.account?.billingEndDate) {
      setBillingEndDate(new Date(props?.account?.billingEndDate));
    }
  }, [props?.account?.billingEndDate]);

  useEffect(() => {
    if (props?.account) {
      setAccount(props?.account);
    }
  }, [props?.account]);

  const [formInvalid, setFormInvalid] = useState(false);

  useEffect(()=> {
    let _account = {...account};
    _account.billingEndDate = billingEndDate;
    setAccount(_account);
  }, [billingEndDate]);

  const {register, handleSubmit, setValue, control} = useForm({
    values: handleDefaultValues(account)
  });

  const handleClose = () => {
    props.handleClose();
  };


  const handleSave = (data) => {
    props.handleSave(data);
  };

  return (
    <Modal onHide={handleClose} size={'lg'} show={props.show}>
      <form
        onChange={(e) => setFormInvalid(!!!checkFormValidity(e))}
        onSubmit={handleSubmit(handleSave)} id={'memberForm'}>
        <Modal.Header closeButton>
          <Modal.Title>Manage Member Account</Modal.Title>
        </Modal.Header>
        {account && <Modal.Body>
          <Row>
            <Col className={'col col-main'} xs={12}>
              <Container>
                <Row>
                  <Col className={'col'} sm={6} xs={12}>
                    <MDTypography variant={'h6'} className={'mb-2'}>{account?.accountType?.name}</MDTypography>
                  </Col>
                </Row>
                <Row>
                  <Col className={'col'} sm={12} xs={12}>
                    <div className="form-group">
                      <label htmlFor="billingEndDate">Billing End Date</label>
                      <div className={'input-group d-block'}>
                        <ReactDatePicker
                          disabled={!account?.recurring}
                          minDate={new Date()}
                          className="form-control p-2"
                          selected={billingEndDate} onChange={(date) => setBillingEndDate(date)}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>

              </Container>
            </Col>
          </Row>

        </Modal.Body>}
        <Modal.Footer>
          <MDButton type="submit" color="info" disabled={formInvalid || props.saving}>
            {props.saving ? 'Saving...' : 'Save'}
          </MDButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
}